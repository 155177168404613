import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Locale } from 'date-fns';
import ruLocale from 'date-fns/locale/ru/index';

import { Language, langLocaleMap } from './i18n';

export interface DateFnsLocaleService {
  locale: Locale;
}

const importLocale = async (language: string) => {
  const lazyLocale = await import(
    `date-fns/locale/${langLocaleMap[language as Language]}/index`
  );

  return lazyLocale.default as Locale;
};

export const useDateFnsLocaleService = (): DateFnsLocaleService => {
  const [locale, setLocale] = useState(ruLocale);
  const { i18n } = useTranslation();

  const updateLocale = useCallback(async () => {
    if (!i18n.language) return;
    const newLocale = await importLocale(i18n.language);
    setLocale(newLocale);
  }, [i18n.language]);

  useEffect(() => {
    updateLocale();
  }, [updateLocale]);

  return { locale };
};
