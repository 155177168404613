import { VFC } from 'react';

import { useTranslation } from 'services/i18n';

import {
  ButtonsContainer,
  ButtonsWrapper,
  ResetButton,
  SubmitButton,
} from './styles';
import { FormButtonsProps } from './types';

export const FormButtons: VFC<FormButtonsProps> = ({
  handleCancel,
  submitText,
  cancelText,
  mode,
  handleSubmit,
  submitDisabled,
  cancelDisabled,
}) => {
  const { t } = useTranslation('common');
  return (
    <ButtonsWrapper>
      <ButtonsContainer>
        {(!mode || mode === 'cancel') && (
          <ResetButton
            disabled={cancelDisabled}
            type="reset"
            onClick={handleCancel}
          >
            {cancelText ?? t('cancel')}
          </ResetButton>
        )}
        {(!mode || mode === 'submit') && (
          <SubmitButton
            disabled={submitDisabled}
            type="submit"
            onClick={handleSubmit}
          >
            {submitText ?? t('save')}
          </SubmitButton>
        )}
      </ButtonsContainer>
    </ButtonsWrapper>
  );
};
