/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum BannerPosition {
    Top = 1,
    Bottom = 2,
    RightSideBottom = 3,
    LayoutTop = 4,
    RightSideBottom2 = 5,
    RightSideBottom3 = 6,
    RightSideBottom4 = 7,
    RightSideBottom5 = 8,
    DashboardTop = 9
}


