import styled from 'styled-components';

import { Button } from 'ui/Button';

export const ModalCloseButton = styled(Button)`
  color: #4872f2;
  background-color: #fff;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  border-radius: 50%;
  padding: 10px;

  &:hover {
    color: #fff;
    background-color: #3965ee;
  }
`;
