import { VFC } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { MediaModal } from 'components/MediaGallery/MediaModal';
import { useAuth } from 'services/auth';
import { MediaGalleryProvider } from 'services/mediaGallery';
import {
  UserCredentialProvider,
  UserNotificationProvider,
} from 'services/userCredential';

import { Header } from '../Header';
import { Layout } from '../Layout';

export const ProtectedRoute: VFC = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (isLoggedIn) {
    return (
      <UserCredentialProvider>
        <UserNotificationProvider>
          <MediaGalleryProvider>
            <Header />
            <Layout>
              <Outlet />
            </Layout>
            <MediaModal />
          </MediaGalleryProvider>
        </UserNotificationProvider>
      </UserCredentialProvider>
    );
  }

  return <Navigate state={{ from: location }} to="/login" />;
};
