import styled, { css } from 'styled-components';

import flagEn from 'assets/flags/flag-en.svg';
import flagRu from 'assets/flags/flag-ru.svg';
import { Button as uiButton } from 'ui/Button';
import { blueBrilliant } from 'ui/theme/colors';

export const Button = styled(uiButton)<{
  isActive: boolean;
  isDisable: boolean;
}>`  
  padding: 0;
  width: 32px;
  height: 32px;
  background-color: ${(props) =>
    props.isActive ? blueBrilliant : 'transparent'};
  border: none;
  border-radius: 50%;
  font-weight: 600;
  font-size: 16px;
  box-shadow: none;
  &:hover {
    background-color: ${blueBrilliant}};
  }
  ${(props) =>
    props.isActive &&
    css`
      color: #4872f2;
    `}
  ${(props) =>
    props.isDisable &&
    css`
      pointer-events: none;
      color: lightgray;
    `}
`;

export const LangButton = styled.div<{
  lang: 'rus' | 'eng';
  isActive: boolean;
}>`    
  background: url(${(props) => {
    if (props.lang === 'eng') return flagEn;
    if (props.lang === 'rus') return flagRu;
    return false;
  }}) no-repeat center/cover !important;    
  min-width: 0;
  height: 26px;
  width: 26px;    
  border-radius: 50%;  
  flex-shrink: 0;
  border:2px solid #EDF1FE;     
  cursor: pointer;
  &:hover,
  &:focus {
    border-color: #4872F2;
  }
  ${(props) =>
    props.isActive &&
    css`
      border-color: #4872f2;
    `}}
`;

export const Container = styled.div`
  position: relative;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 9px;
  row-gap: 4px;
`;

export const LangButtonWrapper = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

export const LangContainer = styled.div`
  display: flex;
  column-gap: 9px;
  flex: 1;
  justify-content: flex-end;
`;
