import { VFC } from 'react';

import { Spin } from 'antd';

import { useTranslation } from 'services/i18n';

import { EmptyItem, List, LoadingMoreItem } from './styles';
import { UsersMenuListProps } from './types';
import { UserMenuListItem } from './UserMenuListItem';

export const UsersMenuList: VFC<UsersMenuListProps> = ({
  data,
  showLoadingMoreElement = false,
  loadingMoreElementRef,
  onItemClick,
}) => {
  const { t } = useTranslation('common');

  const isEmpty = data.length === 0;

  return (
    <List>
      {data.map((userData) => (
        <UserMenuListItem
          key={userData.id}
          userData={userData}
          onClick={onItemClick}
        />
      ))}
      {isEmpty && <EmptyItem>{t('empty_list')}</EmptyItem>}
      {!isEmpty && showLoadingMoreElement && (
        <LoadingMoreItem ref={loadingMoreElementRef}>
          <Spin />
        </LoadingMoreItem>
      )}
    </List>
  );
};
