import styled from 'styled-components';

import { Link } from 'ui';

export const StyledLink = styled(Link)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    text-decoration: underline ${({ theme }) => theme.palette.primary.light};
  }
`;
