import { useCallback, useState } from 'react';

import { Editor } from '@tiptap/core';
import { BaseEmoji } from 'emoji-mart';

import { OpenGraphItemModel } from 'services/api';
import { usePostEditor } from 'services/editors/post/postEditorContext';
import { useOpenGraphService } from 'services/openGraph';

import { PostEditorContentProps } from '../types';

export const useEditorWithOg = ({
  defaultValues,
}: Pick<PostEditorContentProps, 'defaultValues'>) => {
  const [openGraph, setOpenGraph] = useState<OpenGraphItemModel | undefined>(
    defaultValues?.postContent?.openGraphItem,
  );
  const { getOpenGraph } = useOpenGraphService();

  const onUpdate = async ({ editor: updatedEditor }: { editor: Editor }) => {
    const content: string = updatedEditor.getHTML();
    const res = content.match(
      /https?:\/\/(www\.)?([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d+)?)(?:[-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
    );
    if (res) {
      const og = await getOpenGraph(res[res.length - 1]);
      setOpenGraph(og);
    }
  };

  const editor = usePostEditor();
  editor?.on('update', onUpdate);

  const handleEmojiClick = useCallback(
    (emoji: BaseEmoji) => {
      editor?.chain().insertContent(`${emoji.native}`).run();
    },
    [editor],
  );

  return {
    openGraph,
    setOpenGraph,
    editor,
    handleEmojiClick,
  };
};
