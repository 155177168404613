import styled from 'styled-components';

import { Avatar } from 'ui';

export const StyledColleagueListItemRoot = styled.div`
  display: flex;
  align-items: start;
  overflow: hidden;
`;

export const StyledColleagueName = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin: 0;
  display: grid;
  grid-template-columns: auto 22px 1fr;

  & > a {
    color: ${({ theme }) => theme.palette.text.primary};
    display: inline-block;
    width: 100%;
    vertical-align: text-bottom;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`;

export const StyledColleagueDescription = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledColleagueDescriptionWrapper = styled.div`
  overflow: hidden;
  margin-right: 18px;
`;

export const StyledAvatar = styled(Avatar)`
  flex: 0 0 auto;
  margin-right: 12px;
`;
