import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { RoleName } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';

import { AdminLinksMenu } from './AdminLinksMenu';
import { OtherLinksMenu } from './OtherLinksMenu';
import { QuickLinksMenu } from './QuickLinksMenu';
import { NavMenu, NavMenuItem } from './styles';
import { TuiLifeMenu } from './TuiLifeMenu';

export const Nav: FC = React.memo(() => {
  const { hasRole } = useUserCredential();
  const { t } = useTranslation('common');

  return (
    <NavMenu>
      <NavMenuItem as={NavLink} to="/dashboard">
        {t('home')}
      </NavMenuItem>
      <TuiLifeMenu />
      <QuickLinksMenu />
      <NavMenuItem as={NavLink} to="/groups/all">
        {t('groups')}
      </NavMenuItem>
      {hasRole(RoleName.Administrator) && <AdminLinksMenu />}
      <OtherLinksMenu />
    </NavMenu>
  );
});
