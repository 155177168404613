import React from 'react';

import { BaseSwitch } from '../BaseSwitch';
import { StyledLabel } from '../BaseSwitch/styles';
import { CompletedIcon } from './CompletedIcon';
import { BaseInputProps, StyledInputRoot } from './styles';
import { UnCompletedIcon } from './UnCompletedIcon';

export interface CheckboxProps {
  label?: React.ReactNode;
  id?: BaseInputProps['id'];
  name?: BaseInputProps['name'];
  size?: number;
  defaultChecked?: BaseInputProps['defaultChecked'];
  checked?: BaseInputProps['checked'];
  required?: BaseInputProps['required'];
  disabled?: BaseInputProps['disabled'];
  onChange?: BaseInputProps['onChange'];
  onBlur?: BaseInputProps['onBlur'];
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { id, label, required = false, disabled = false, size = 20, ...inputProps },
    ref,
  ) => {
    return (
      <StyledInputRoot required={required}>
        <BaseSwitch
          key="input"
          checkedIcon={<CompletedIcon size={size} />}
          disabled={disabled}
          icon={<UnCompletedIcon size={size} />}
          id={id}
          ref={ref}
          type="checkbox"
          {...inputProps}
        />
        {label && (
          <StyledLabel key="label" data-disabled={disabled} htmlFor={id}>
            {label}
          </StyledLabel>
        )}
      </StyledInputRoot>
    );
  },
);
