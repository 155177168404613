import { VFC } from 'react';

import { CountryFilter } from 'components/Filters';
import { Title } from 'ui/Title';

import { CountryFilterContainer, Heading } from './style';

interface HeaderProps {
  title: string;
}

export const EmployeesHeader: VFC<HeaderProps> = ({ title }) => {
  return (
    <>
      <Heading>
        <Title variant="h1">{title}</Title>
      </Heading>
      <CountryFilterContainer>
        <CountryFilter />
      </CountryFilterContainer>
    </>
  );
};
