import { useCallback, useRef } from 'react';

export interface DeletedFilesBuffer<T> {
  push: (file: T) => void;
  getValues: () => T[];
  clear: () => void;
}

export const useDeletedFilesBuffer = <T = unknown>(): DeletedFilesBuffer<T> => {
  const setRef = useRef(new Set<T>());

  const push = useCallback((file: T) => {
    setRef.current.add(file);
  }, []);

  const getValues = useCallback(() => [...setRef.current], []);

  const clear = useCallback(() => {
    setRef.current.clear();
  }, []);

  return {
    push,
    getValues,
    clear,
  };
};
