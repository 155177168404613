/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { LocalizationKeyListModel } from '../models';
// @ts-ignore
import { LocalizationKeyListModelOffsetModel } from '../models';
// @ts-ignore
import { LocalizationSessionModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * LocalizationKeyApi - axios parameter creator
 * @export
 */
export const LocalizationKeyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models
         * @param {string} [searchText] Поиск по тексту
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysGet: async (searchText?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/localization-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update models
         * @param {Array<LocalizationKeyListModel>} [localizationKeyListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysPut: async (localizationKeyListModel?: Array<LocalizationKeyListModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/localization-keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localizationKeyListModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Close session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysSessionsDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/localization-keys/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Close session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysSessionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/localization-keys/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Open session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysSessionsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/localization-keys/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Expire session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysSessionsPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/localization-keys/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalizationKeyApi - functional programming interface
 * @export
 */
export const LocalizationKeyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalizationKeyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models
         * @param {string} [searchText] Поиск по тексту
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalizationKeysGet(searchText?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalizationKeyListModelOffsetModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalizationKeysGet(searchText, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update models
         * @param {Array<LocalizationKeyListModel>} [localizationKeyListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalizationKeysPut(localizationKeyListModel?: Array<LocalizationKeyListModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalizationKeysPut(localizationKeyListModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Close session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalizationKeysSessionsDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalizationKeysSessionsDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Close session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalizationKeysSessionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalizationSessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalizationKeysSessionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Open session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalizationKeysSessionsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalizationSessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalizationKeysSessionsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Expire session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LocalizationKeysSessionsPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalizationSessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LocalizationKeysSessionsPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalizationKeyApi - factory interface
 * @export
 */
export const LocalizationKeyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalizationKeyApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models
         * @param {string} [searchText] Поиск по тексту
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysGet(searchText?: string, count?: number, offset?: number, options?: any): AxiosPromise<LocalizationKeyListModelOffsetModel> {
            return localVarFp.apiV1LocalizationKeysGet(searchText, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update models
         * @param {Array<LocalizationKeyListModel>} [localizationKeyListModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysPut(localizationKeyListModel?: Array<LocalizationKeyListModel>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1LocalizationKeysPut(localizationKeyListModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Close session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysSessionsDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1LocalizationKeysSessionsDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Close session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysSessionsGet(options?: any): AxiosPromise<LocalizationSessionModel> {
            return localVarFp.apiV1LocalizationKeysSessionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Open session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysSessionsPost(options?: any): AxiosPromise<LocalizationSessionModel> {
            return localVarFp.apiV1LocalizationKeysSessionsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Expire session for update models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LocalizationKeysSessionsPut(options?: any): AxiosPromise<LocalizationSessionModel> {
            return localVarFp.apiV1LocalizationKeysSessionsPut(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1LocalizationKeysGet operation in LocalizationKeyApi.
 * @export
 * @interface LocalizationKeyApiApiV1LocalizationKeysGetRequest
 */
export interface LocalizationKeyApiApiV1LocalizationKeysGetRequest {
    /**
     * Поиск по тексту
     * @type {string}
     * @memberof LocalizationKeyApiApiV1LocalizationKeysGet
     */
    readonly searchText?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof LocalizationKeyApiApiV1LocalizationKeysGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof LocalizationKeyApiApiV1LocalizationKeysGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1LocalizationKeysPut operation in LocalizationKeyApi.
 * @export
 * @interface LocalizationKeyApiApiV1LocalizationKeysPutRequest
 */
export interface LocalizationKeyApiApiV1LocalizationKeysPutRequest {
    /**
     * 
     * @type {Array<LocalizationKeyListModel>}
     * @memberof LocalizationKeyApiApiV1LocalizationKeysPut
     */
    readonly localizationKeyListModel?: Array<LocalizationKeyListModel>
}

/**
 * LocalizationKeyApi - object-oriented interface
 * @export
 * @class LocalizationKeyApi
 * @extends {BaseAPI}
 */
export class LocalizationKeyApi extends BaseAPI {
    /**
     * 
     * @summary Get models
     * @param {LocalizationKeyApiApiV1LocalizationKeysGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationKeyApi
     */
    public apiV1LocalizationKeysGet(requestParameters: LocalizationKeyApiApiV1LocalizationKeysGetRequest = {}, options?: AxiosRequestConfig) {
        return LocalizationKeyApiFp(this.configuration).apiV1LocalizationKeysGet(requestParameters.searchText, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update models
     * @param {LocalizationKeyApiApiV1LocalizationKeysPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationKeyApi
     */
    public apiV1LocalizationKeysPut(requestParameters: LocalizationKeyApiApiV1LocalizationKeysPutRequest = {}, options?: AxiosRequestConfig) {
        return LocalizationKeyApiFp(this.configuration).apiV1LocalizationKeysPut(requestParameters.localizationKeyListModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Close session for update models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationKeyApi
     */
    public apiV1LocalizationKeysSessionsDelete(options?: AxiosRequestConfig) {
        return LocalizationKeyApiFp(this.configuration).apiV1LocalizationKeysSessionsDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Close session for update models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationKeyApi
     */
    public apiV1LocalizationKeysSessionsGet(options?: AxiosRequestConfig) {
        return LocalizationKeyApiFp(this.configuration).apiV1LocalizationKeysSessionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Open session for update models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationKeyApi
     */
    public apiV1LocalizationKeysSessionsPost(options?: AxiosRequestConfig) {
        return LocalizationKeyApiFp(this.configuration).apiV1LocalizationKeysSessionsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Expire session for update models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationKeyApi
     */
    public apiV1LocalizationKeysSessionsPut(options?: AxiosRequestConfig) {
        return LocalizationKeyApiFp(this.configuration).apiV1LocalizationKeysSessionsPut(options).then((request) => request(this.axios, this.basePath));
    }
}
