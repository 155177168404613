import { FC } from 'react';

export const Logo: FC = () => (
  <svg
    fill="none"
    height="36"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.13357 25.3388C5.84317 22.456 7.48297 19.8799 9.79616 19.585C12.1094 19.2901 14.22 21.388 14.5104 24.2708C14.8008 27.1536 14.4747 30.3718 10.2195 29.6284C7.86504 29.3094 6.42397 28.2216 6.13357 25.3388Z"
      fill="#EEC317"
    />
    <path
      d="M25.8904 14.8002C24.5122 16.3574 21.4632 16.0381 20.1244 16.5804C20.0231 16.9068 21.0658 18.4251 21.2589 19.8497C21.5002 21.6304 26.8813 24.6369 28.7421 24.0513C30.603 23.4657 31.026 21.2552 30.3101 19.6363C29.0269 16.7343 28.6506 15.4586 28.0985 14.5884C27.7446 14.0306 26.502 14.1092 25.8904 14.8002Z"
      fill="#EEC317"
    />
    <path
      clipRule="evenodd"
      d="M23.6135 13.5618C23.8424 12.9945 24.1843 12.1471 24.1532 10.1864C24.0968 6.64038 20.5673 2.56174 15.2783 3.23605C11.0233 3.77852 7.70771 8.61924 8.18991 13.406C8.27744 14.2749 8.62118 15.0867 8.93051 15.8172C9.3209 16.7391 9.65649 17.5316 9.35307 18.1462C9.33714 18.1748 9.3212 18.2037 9.30524 18.2328C9.30247 18.2374 9.29966 18.2419 9.29681 18.2464C9.29702 18.2466 9.29723 18.2469 9.29744 18.2471C8.29782 20.0807 7.24098 23.0461 7.46529 25.2728L7.49301 25.548C7.90249 29.6129 10.8066 31.8129 14.7769 32.0741C17.4104 32.2474 18.1517 32.266 20.2904 31.984C20.618 31.9408 20.904 31.9056 21.1653 31.8735C22.5466 31.7037 23.2359 31.6189 25.7104 30.8843C28.6529 30.0107 31.6878 26.5805 31.2783 22.5155L31.209 21.8276C30.8508 18.2718 27.8349 16.9796 25.6765 16.0548C24.4736 15.5394 23.537 15.1382 23.475 14.5228L23.4419 14.5319C23.3655 14.1763 23.466 13.9272 23.6135 13.5618Z"
      fill="#FFD428"
      fillRule="evenodd"
    />
    <path
      d="M11.0104 10.5459C10.0441 10.5995 9.65057 11.8979 8.83709 12.8308C8.09398 13.6831 6.93095 14.1664 7.08666 14.9442C7.31063 16.0631 10.2064 17.1326 11.2989 17.1211C12.3915 17.1096 13.3731 16.5632 14.3047 15.872C15.2363 15.1808 16.401 14.8205 16.3493 13.6434C16.3009 12.5403 14.6677 13.1951 13.1578 11.6563L13.1559 11.6543C12.4729 10.9583 12.0136 10.4902 11.0104 10.5459Z"
      fill="#E1451F"
    />
    <ellipse
      fill="black"
      rx="1.78633"
      ry="2.0027"
      transform="matrix(0.991729 -0.126438 0.100247 0.995157 16.9748 10.2642)"
    />
    <ellipse
      fill="black"
      rx="1.78633"
      ry="2.0027"
      transform="matrix(0.991729 -0.126438 0.100247 0.995157 16.9748 10.2642)"
    />
    <ellipse
      fill="white"
      rx="0.510381"
      ry="0.572201"
      transform="matrix(0.991729 -0.126438 -0.100247 -0.995157 16.5328 9.88948)"
    />
    <path
      clipRule="evenodd"
      d="M7.34439 15.4192C7.21422 15.2633 7.12625 15.1032 7.09391 14.9421C6.99115 14.4302 7.46195 14.0458 8.00835 13.5997C8.2917 13.3683 8.59539 13.1204 8.84996 12.8294C9.11336 12.5284 9.33288 12.1893 9.542 11.8663C9.98078 11.1886 10.3738 10.5816 11.0302 10.5452C12.0364 10.4893 12.4971 10.9571 13.182 11.6527L13.1839 11.6546C13.9466 12.4291 14.7407 12.6474 15.3472 12.8142C15.8458 12.9513 16.2177 13.0535 16.3414 13.4011C16.3387 13.5922 16.287 13.7198 16.0883 13.8548C15.5364 14.2298 14.8973 14.4397 13.8504 14.6677C12.9409 14.8658 11.9377 14.9215 11.2399 14.9603C10.542 14.999 9.70898 14.9469 9.00681 14.8875C8.95234 14.8829 8.89919 14.8782 8.84731 14.8737C8.23043 14.8194 7.79448 14.781 7.48453 15.0704C7.37351 15.1741 7.33962 15.295 7.34439 15.4192Z"
      fill="#F35122"
      fillRule="evenodd"
    />
    <path
      d="M29.8222 22.5379C30.1051 25.3464 28.236 27.8907 25.6474 28.2207C23.0589 28.5507 20.7311 26.5415 20.4482 23.7331C20.1653 20.9246 20.5759 17.7775 25.3191 18.4256C27.945 18.694 29.5393 19.7295 29.8222 22.5379Z"
      fill="#EEC317"
    />
    <ellipse
      fill="black"
      rx="0.635139"
      ry="1.47103"
      transform="matrix(0.970628 0.230203 -0.184552 0.984843 8.64725 10.6213)"
    />
    <ellipse
      fill="black"
      rx="0.635139"
      ry="1.47103"
      transform="matrix(0.970628 0.230203 -0.184552 0.984843 8.64725 10.6213)"
    />
    <path
      d="M4.7666 20.2646L16.2583 21.7793L13.3829 29.9507L5.8834 28.9622L4.7666 20.2646Z"
      fill="#F7EAD6"
    />
    <ellipse
      fill="#F7EAD6"
      rx="5.9052"
      ry="2.01364"
      transform="matrix(0.97664 0.128727 -0.104863 1.00691 10.5307 20.8463)"
    />
    <ellipse
      fill="#D0BFA3"
      rx="4.58677"
      ry="1.10552"
      transform="matrix(0.97664 0.128727 -0.104863 1.00691 10.538 20.7788)"
    />
    <mask
      height="4"
      id="mask0_580_38982"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="10"
      x="6"
      y="19"
    >
      <ellipse
        fill="#D0BFA3"
        rx="4.58598"
        ry="1.1179"
        transform="matrix(0.97664 0.128727 -0.104863 1.00691 10.5355 20.7912)"
      />
    </mask>
    <g mask="url(#mask0_580_38982)">
      <ellipse
        fill="#1B1818"
        rx="4.58983"
        ry="1.11697"
        transform="matrix(0.982053 0 0.0186877 1.0151 10.2632 21.9288)"
      />
    </g>
    <ellipse
      fill="#F7EAD6"
      rx="3.83838"
      ry="1.44982"
      transform="matrix(0.97664 0.128727 -0.104863 1.00691 9.6377 29.4217)"
    />
    <path
      d="M4.02282 23.9165C3.83618 22.1379 4.60563 20.3134 5.10761 21.4665C5.60958 22.6196 6.24413 22.0574 6.58202 23.1921C7.12846 25.0273 6.1073 27.1531 5.53094 27.01C4.79589 26.8276 4.20945 25.695 4.02282 23.9165Z"
      fill="#EEC317"
    />
  </svg>
);
