import { VFC, useCallback, useState } from 'react';

import { CommentForm } from 'components/CommentForm';
import { CommentView } from 'components/Wall/Comments/CommentView';
import { CommentModel } from 'services/api';

import { StyledReply } from './styles';
import { ReplyProps } from './types';

export const Reply: VFC<ReplyProps> = ({
  data,
  postId,
  afterSubmit,
  afterDelete,
}) => {
  const [showReplyForm, setShowReplyForm] = useState(false);

  const switchReplyForm = useCallback(() => {
    setShowReplyForm(!showReplyForm);
  }, [showReplyForm]);

  const submitCallback = (reply: CommentModel | undefined) => {
    setShowReplyForm(false);
    if (afterSubmit) afterSubmit(reply);
  };

  if (data.postContent && data.likes) {
    return (
      <StyledReply>
        <CommentView
          isReply
          afterDelete={afterDelete}
          data={data}
          handleCommentReply={switchReplyForm}
          postId={postId}
        />
        {showReplyForm && (
          <div style={{ marginLeft: 50 }}>
            <CommentForm
              isReply
              author={data.fromAuthor}
              postId={postId}
              replyCommentId={data.id as number}
              submitCallback={submitCallback}
            />
          </div>
        )}
      </StyledReply>
    );
  }
  return <></>;
};
