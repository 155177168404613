import { VFC, useEffect, useState } from 'react';

import { ExpandMore } from '@styled-icons/material-outlined';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';

import { CommentModel, api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useWallData } from 'services/wall';

import { Comment } from './Comment';
import { MoreButton, SpinContainer } from './styles';
import { CommentsProps } from './types';

export const Comments: VFC<CommentsProps> = ({ data, postId, afterDelete }) => {
  const { t } = useTranslation('common');
  const [comments, setComments] = useState(data.items || []);
  const [, setTotal] = useState(data.totalFirstLevelCount || 0);
  const [loading, setLoading] = useState(false);

  const { list, setList } = useWallData();

  useEffect(() => {
    setComments(data.items || []);
  }, [data.items]);

  useEffect(() => {
    setTotal(data.totalFirstLevelCount || 0);
  }, [data.totalFirstLevelCount]);

  const handleLoadMore = () => {
    // forceUpdate();
    setLoading(true);
    api.comment
      .apiV1CommentsGet({ byWallId: postId, offset: comments.length, count: 3 })
      .then((res) => {
        // forceUpdate();
        const clone = cloneDeep(list);
        const post = clone.find((entry) => entry.id === postId);
        if (post && post.comments) {
          post.comments.items?.push(...(res.data.items as CommentModel[]));
        }
        setList(clone);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  if (comments.length > 0)
    return (
      <>
        {comments.map((comment) => (
          <Comment
            key={`comment=${comment.id}`}
            afterDelete={afterDelete}
            data={comment}
            postId={postId}
          />
        ))}
        {loading && (
          <SpinContainer>
            <Spin spinning={loading} />
          </SpinContainer>
        )}
        {Number(data.totalFirstLevelCount) > comments.length && (
          <MoreButton onClick={handleLoadMore}>
            {t('show_more')} <ExpandMore size={14} />
          </MoreButton>
        )}
      </>
    );
  return <></>;
};
