import { ComponentPropsWithRef } from 'react';

import styled from 'styled-components';

export type BaseInputProps = ComponentPropsWithRef<'input'>;

interface StyledIconContainerProps {
  size?: number;
}

export const StyledTodoCheckboxRoot = styled.div`
  display: flex;
  min-width: 0;
  align-items: center;
  user-select: none;
`;

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  cursor: pointer;
  width: '${({ size }) => size}px';
  height: '${({ size }) => size}px';
`;

export const StyledLabel = styled.div`
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-left: 10px;

  &[data-completed='true'] {
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`;
