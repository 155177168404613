import { FC, Suspense } from 'react';

import { lazily } from 'react-lazily';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Spin } from 'antd';

import { ProtectedRoute, RoleRoute } from 'components/Routes';
import { RoleName } from 'services/api';
import { BackToTopButton } from 'ui';

import { PushManagementPage } from './pages/Management/PushManagementPage';

const { BirthdaysPage } = lazily(() => import('pages/Employees/BirthdaysPage'));
const { EmployeesAllPage } = lazily(
  () => import('pages/Employees/EmployeesAllPage'),
);
const { EmployeesWtapper } = lazily(
  () => import('pages/Employees/EmployeesWtapper'),
);
const { StructurePage } = lazily(() => import('pages/Employees/StructurePage'));
const { SubscribersPage } = lazily(
  () => import('pages/Employees/SubscribersPage'),
);
const { SubscriptionsPage } = lazily(
  () => import('pages/Employees/SubscriptionsPage'),
);
const { EditGiftcardPage } = lazily(
  () => import('pages/Giftcards/EditGiftcardPage'),
);
const { GiftcardsPage } = lazily(() => import('pages/Giftcards/GiftcardsPage'));
const { NewGiftcardPage } = lazily(
  () => import('pages/Giftcards/NewGiftcardPage'),
);
const { GroupsPageWrapper } = lazily(
  () => import('pages/Groups/GroupsPageWrapper'),
);
const { NewGroupsPage } = lazily(() => import('pages/Groups/NewGroupsPage'));
const { IdeasPage } = lazily(() => import('pages/IdeasPage'));
const { LocalizationPage } = lazily(() => import('pages/LocalizationPage'));
const { LoginPage } = lazily(() => import('pages/LoginPage'));
const { LinkSettingsPage } = lazily(
  () => import('pages/Management/LinkSettingsPage'),
);
const { RoleManagmentPage } = lazily(
  () => import('pages/Management/RoleManagmentPage'),
);
const { SubscriptionsManagementPage } = lazily(
  () => import('pages/Management/SubscriptionsManagementPage'),
);
const { NotFoundPage } = lazily(() => import('pages/NotFoundPage'));
const { NotificationHistoryPage } = lazily(
  () => import('pages/Notifications/HistoryPage'),
);
const { NotificationPage } = lazily(
  () => import('pages/Notifications/NotificationPage'),
);
const { PushNotificationPage } = lazily(
  () => import('pages/Notifications/PushNotificationPage'),
);
const { NotificationSettingsPage } = lazily(
  () => import('pages/Notifications/SettingPage'),
);
const { PostPage } = lazily(() => import('pages/PostPage'));
const { ProfileEditPage } = lazily(
  () => import('pages/Profile/ProfileEditPage'),
);
const { ProfilePage } = lazily(() => import('pages/Profile/ProfilePage'));
const { SearchResultsPage } = lazily(() => import('pages/SearchResultsPage'));
const { TUICoinsRatingPage } = lazily(() => import('pages/TUICoinsRatingPage'));
const { NewTUILifePage } = lazily(() => import('pages/TUILife/NewTUILifePage'));
const { TUILifePage } = lazily(() => import('pages/TUILife/TUILifePage'));
const { TUILifePageWrapper } = lazily(
  () => import('pages/TUILife/TUILifePageWrapper'),
);
const { TUIWelcomeMailPage } = lazily(() => import('pages/TUIWelcomeMailPage'));
const { DashboardPage } = lazily(() => import('pages/DashboardPage'));
const { AllGroupsPage } = lazily(() => import('pages/Groups/AllGroupsPage'));
const { GroupPage } = lazily(() => import('pages/Groups/GroupPage'));
const { MyGroupsPage } = lazily(() => import('pages/Groups/MyGroupsPage'));
const { GroupsArchivePage } = lazily(
  () => import('pages/Groups/GroupsArchivePage'),
);
const { GroupMembersModal } = lazily(
  () => import('components/Groups/GroupMembersWidget/GroupMembersModal'),
);
const { TUILifeAllPages } = lazily(
  () => import('pages/TUILife/TUILifeAllPages'),
);

export const App: FC = () => {
  return (
    <Suspense
      fallback={
        <Spin style={{ position: 'absolute', top: '50%', left: '50%' }} />
      }
    >
      <Routes>
        <Route element={<Navigate to="/dashboard" />} path="/" />

        <Route element={<LoginPage />} path="login" />

        <Route element={<ProtectedRoute />}>
          <Route element={<DashboardPage />} path="dashboard" />
          <Route element={<ProfilePage />} path="profile" />
          <Route element={<ProfilePage />} path="profile/:id" />
          <Route element={<ProfileEditPage />} path="/profile/edit" />

          {/* Groups */}
          <Route element={<GroupPage />} path="group/:id">
            <Route element={<GroupMembersModal />} path="members" />
          </Route>
          <Route element={<GroupsPageWrapper />}>
            <Route element={<AllGroupsPage />} path="/groups/all" />
            <Route element={<MyGroupsPage />} path="/groups/my" />
            <Route element={<RoleRoute roles={RoleName.Administrator} />}>
              <Route element={<GroupsArchivePage />} path="/groups/archive" />
            </Route>
          </Route>
          <Route element={<RoleRoute roles={RoleName.Administrator} />}>
            <Route element={<NewGroupsPage />} path="/management/new-groups" />
          </Route>

          <Route element={<RoleRoute roles={RoleName.SubAdministrator} />}>
            <Route
              element={<SubscriptionsManagementPage />}
              path="admin/subscriptions"
            />
          </Route>

          <Route element={<RoleRoute roles={RoleName.SubAdministrator} />}>
            <Route
              element={<SubscriptionsManagementPage />}
              path="admin/subscriptions"
            />
          </Route>

          <Route element={<RoleRoute roles={RoleName.Administrator} />}>
            <Route element={<LocalizationPage />} path="localization/admin" />
            <Route element={<GiftcardsPage />} path="giftcards" />
            <Route element={<EditGiftcardPage />} path="giftcards/:id" />
            <Route element={<NewGiftcardPage />} path="giftcards/newcard" />
            <Route element={<TUIWelcomeMailPage />} path="welcome" />
            <Route
              element={<PushManagementPage />}
              path="notifications/management"
            />
            <Route
              element={<LinkSettingsPage />}
              path="management/navigation"
            />
            <Route element={<RoleManagmentPage />} path="admin/rolemanagment" />
          </Route>

          <Route element={<TUICoinsRatingPage />} path="coins-rating" />

          <Route element={<NotificationPage />}>
            <Route
              element={<NotificationHistoryPage />}
              path="notifications/histories"
            />
            <Route
              element={<PushNotificationPage />}
              path="notifications/push-notifications"
            />
            <Route
              element={<NotificationSettingsPage />}
              path="notifications/settings"
            />
          </Route>

          <Route element={<PostPage />} path="posts/:id" />
          <Route element={<IdeasPage />} path="ideas" />
          <Route element={<TUILifePageWrapper />}>
            <Route element={<RoleRoute roles={RoleName.Administrator} />}>
              <Route element={<TUILifeAllPages />} path="pages/all" />
            </Route>
            <Route element={<TUILifePage />} path="pages/:id" />
            <Route
              element={
                <RoleRoute
                  roles={[RoleName.Administrator, RoleName.ContentManager]}
                />
              }
            >
              <Route element={<NewTUILifePage />} path="pages/newpage" />
            </Route>
          </Route>

          <Route element={<EmployeesWtapper />} path="employees">
            <Route element={<EmployeesAllPage />} path="all" />
            <Route element={<StructurePage />} path="structure" />
            <Route element={<BirthdaysPage />} path="birthdays" />
            <Route element={<SubscribersPage />} path="subscribers" />
            <Route element={<SubscriptionsPage />} path="subscriptions" />
          </Route>

          <Route element={<SearchResultsPage />} path="search/:filter" />

          <Route element={<NotFoundPage />} path="404" />
          <Route element={<Navigate replace to="404" />} path="*" />
        </Route>
      </Routes>
      <BackToTopButton />
    </Suspense>
  );
};
