import { useCallback, useEffect } from 'react';

import { useQueryClient } from 'react-query';

import globalAxios from 'axios';

import { useStorage } from 'hooks/useStorage';
import { api } from 'services/api';

const TOKEN_KEY = 'token';

export enum UserDomain {
  tuiru = 'domain1',
  ttvk = 'domain2',
}

export type logInHandler = (
  login: Login,
  domain: string,
  password: Password,
) => Promise<void>;

export type logOutHandler = () => Promise<void>;

export interface AuthenticationService {
  isLoggedIn: boolean;
  token: Nullable<Token>;
  logIn: logInHandler;
  logOut: logOutHandler;
}

export const useAuthenticationService = (): AuthenticationService => {
  const [token, setToken] = useStorage<Token | null>(TOKEN_KEY, null);
  const queryClient = useQueryClient();

  const logIn: logInHandler = async (login, domain, password) => {
    const { data: authData } = await api.identity.apiV1IdentityTokenPost({
      loginModel: {
        login: `${domain}\\${login}`,
        password: btoa(unescape(encodeURIComponent(password))),
      },
    });
    setToken(authData.token as Token);
  };

  // TODO logout to apiAdapter
  const logOut: logOutHandler = useCallback(async () => {
    // FIXME: logout don't work
    // await apiService.apiIdentityLogoutPost();
    queryClient.removeQueries('user', { exact: true });
    setToken(null);
  }, [queryClient, setToken]);

  useEffect(() => {
    globalAxios.interceptors.response.use(undefined, (error) => {
      if (error.response?.status === 401) logOut();

      return Promise.reject(error);
    });
  }, [logOut]);

  useEffect(() => {
    if (token) {
      globalAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  return { isLoggedIn: Boolean(token), token, logIn, logOut };
};
