import React from 'react';

import styled, { css } from 'styled-components';

import { IconButton } from 'ui/IconButton';
import { headingStyles } from 'ui/Title/styles';

export interface WrapperProps extends React.ComponentProps<any> {
  type?: 'small' | 'default';
}

export const typeStyles = {
  small: css`
    display: grid;
    grid-template-areas: 'first second';
    grid-template-columns: min-content 455px;
    max-height: 124px;

    img {
      width: 124px !important;
      height: 100% !important;
      object-fit: cover;
    }
  `,
  default: css``,
};

export const styledWrapperCss = css<WrapperProps>`
  position: relative;

  ${({ type = 'default' }) => typeStyles[type]}
`;

export const OpenGraphWrapper = styled.div<WrapperProps>`
  ${styledWrapperCss};
`;

export const OpenGraphContent = styled.div`
  grid-area: second;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #f6f8fa;
  padding: 12px 60px 12px 12px;
`;

export const OpenGraphTitle = styled.div`
  ${headingStyles.h3};
  margin: 4px 0;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 40px;
`;

export const OpenGraphToolbar = styled.div`
  z-index: 2;
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const OpenGraphToolbarButton = styled(IconButton)`
  color: #4872f2;
  height: 36px;
  width: 36px;
  background-color: white;
  border-radius: 100px;

  :hover {
    background-color: #4872f2;
    color: white;
  }
`;

export const OpenGraphImage = styled.div`
  cursor: pointer;
  overflow: hidden;

  & img {
    width: 100%;
    object-fit: cover;
  }
`;
