import { Spin } from 'antd';
import styled from 'styled-components';

import { Avatar } from 'ui/Avatar';

import { ListItemMeta } from '../ListItemMeta';

export const FilesBull = styled.span`
  margin: 0 3px;
`;

export const FilesMeta = styled(ListItemMeta)`
  .ant-list-item-meta-title {
    &:only-child {
      margin-top: 0;
    }
    white-space: nowrap;
    max-width: 100%;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 8px;
  }

  .ant-list-item-meta-content {
    max-width: 244px;
  }
  height: 40px;
  max-width: 296px;
`;

export const FilesAvatar = styled(Avatar)`
  border-radius: 8px;
`;

export const FileWrapper = styled.button`
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: white;
  }
`;
