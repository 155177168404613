import { FC, useState } from 'react';

import { Download } from '@styled-icons/material-outlined';
import { TextSnippet } from '@styled-icons/material-sharp';
import { format, parseISO } from 'date-fns';

import { handleClickDownload } from 'domain/file';

import {
  FileWrapper,
  FilesAvatar,
  FilesBull,
  FilesMeta,
  StyledSpin,
} from './styles';
import { FileProps } from './types';

export const File: FC<FileProps> = ({
  dateString,
  size,
  name,
  isSmall,
  url,
  isLoading,
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <FileWrapper
      type="button"
      onClick={() => handleClickDownload(url, name)}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <FilesMeta
        avatar={
          <FilesAvatar
            icon={(() => {
              if (isLoading) return <StyledSpin size="small" />;
              if (!isLoading && isHovering)
                return <Download size={isSmall ? 15 : 18} />;
              return <TextSnippet size={isSmall ? 15 : 18} />;
            })()}
            shape="square"
            size={isSmall ? 34 : 40}
            style={{
              backgroundColor: isHovering ? '#4872f2' : '#b3b9c0',
            }}
          />
        }
        description={
          <div style={{ display: 'flex' }}>
            {size !== undefined && (
              <>
                <div>{`${(size / 1024).toFixed(1)}KB`}</div>
                <FilesBull>&bull;</FilesBull>
              </>
            )}
            <div>
              {dateString &&
                format(parseISO(dateString), 'dd-MM-yyyy').replaceAll('-', '.')}
            </div>
          </div>
        }
        title={name}
      />
    </FileWrapper>
  );
};
