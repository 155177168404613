export const GroupAvatarPlaceholder = () => {
  return (
    <svg
      fill="none"
      height="172"
      style={{
        borderRadius: '8px',
      }}
      viewBox="0 0 172 172"
      width="172"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="white" height="172" width="172" />
      <path
        d="M172 97.8083H168.846V70.9717L148.643 70.336V109.176L146.801 109.209H144.456V75.6849H141.103V65.9944H136.244V37.7134H129.208V65.9944H124.348V75.6849H120.999V95.5004H116.977V31.951H114.799V22H95.6971V31.951H93.5202V79.1085L87.1525 78.8817V48.7109H69.0569L68.2456 105.429L65.5384 105.2V86.0585H59.674V70.9991H56.1565V60.885H42.4168V70.9991H38.8983V86.0585H33.0338V121.4L28.0077 121.173V86.1232H11.2037V119.1V124.457H0V172H172V97.8083Z"
        fill="#DBE0E5"
        opacity="0.6"
      />
      <path
        d="M14 5H158V-5H14V5ZM167 14V158H177V14H167ZM158 167H14V177H158V167ZM5 158V14H-5V158H5ZM14 167C9.02944 167 5 162.971 5 158H-5C-5 168.493 3.50658 177 14 177V167ZM167 158C167 162.971 162.971 167 158 167V177C168.493 177 177 168.493 177 158H167ZM158 5C162.971 5 167 9.02944 167 14H177C177 3.50659 168.493 -5 158 -5V5ZM14 -5C3.50659 -5 -5 3.50658 -5 14H5C5 9.02944 9.02944 5 14 5V-5Z"
        fill="white"
      />
    </svg>
  );
};
