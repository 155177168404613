import styled from 'styled-components';

import { Button } from 'ui/Button';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-height: 40px;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, min-content);
  grid-column-gap: 12px;
  font-weight: 600;
`;

export const SubmitButton = styled(Button)`
  grid-column: 2;
  border-radius: 8px;
  color: white;
  background-color: #4872f2;
  font-size: 14px;

  :hover {
    background-color: #3965ee;
  }
`;

export const ResetButton = styled(Button)`
  grid-column: 1;
  border: solid 1px #4872f2;
  border-radius: 8px;
  color: #4872f2;
  background-color: white;
  font-size: 14px;

  :hover {
    border-color: #3965ee;
    color: #3965ee;
    background-color: white;
  }
`;
