import { VFC, useCallback } from 'react';

import { ModeComment, Share } from '@styled-icons/material-outlined';
import { Visibility } from '@styled-icons/material-sharp';
import { Col, Row, Spin } from 'antd';

import { CommentForm } from 'components/CommentForm';
import { PostEditModal } from 'components/PostEditModal';
import { PostForm } from 'components/PostForm';
import { PostUpdateModel } from 'components/PostForm/types';
import { PostPushModal } from 'components/PostPushModal';
import { PostMeta } from 'components/Wall/Post/PostMeta';
import { CommentsModel, WallContentType } from 'services/api';
import { useUserCredential } from 'services/userCredential';
import { Title } from 'ui';

import { VoteIdeaDate } from '../../TUIIdeas/VoteIdeaDate';
import { Comments } from '../Comments';
import { useLocalModel } from './model';
import { PostContent } from './PostContent';
import { PostRightMenu } from './PostRightMenu';
import {
  CommentFormContainer,
  ControlPaper,
  LikePostButton,
  PostButton,
  PostComments,
  PostFooter,
  PostHeader,
  PostMain,
  PostViewsContainer,
  WallItem,
} from './styles';
import { PostProps } from './types';

export const Post: VFC<PostProps> = (props) => {
  const { data } = props;
  const { user } = useUserCredential();

  const {
    defaultValues,
    post,
    postWrapper,
    onSwitchNotifications,
    onDeletePost,
    onEditPost,
    onPushPost,
    onPinPost,
    onCopyPost,
    setIsPostCopyModalVisible,
    setIsPostPushModalVisible,
    setIsPostEditModalVisible,
    isPostCloneModalVisible,
    isReplyVisible,
    isPostPushModalVisible,
    isPostEditModalVisible,
    isCommentsVisible,
    isLoading,
    isLikeLoading,
    likeMutation,
    handleCommentClick,
    updatePollAnswer,
    handlePostPublish,
    handleIsReplyVisible,
    handleReplyClick,
    afterCommentDelete,
    afterCommentSubmit,
    setPost,
  } = useLocalModel(props);

  const renderRightMenu = useCallback(
    () => (
      <PostRightMenu
        isDisableNotifications={Boolean(post.isDisableNotifications)}
        isIdea={post.wallContentType === WallContentType.Idea}
        isPinned={Boolean(post.isPinned)}
        isPublished={Boolean(post.publishedTime)}
        userId={Number(post.from?.userId)}
        onCopyPost={onCopyPost}
        onDeletePost={onDeletePost}
        onEditPost={onEditPost}
        onPinPost={onPinPost}
        onPublishPost={handlePostPublish}
        onPushPost={onPushPost}
        onSwitchNotifications={onSwitchNotifications}
      />
    ),
    [
      handlePostPublish,
      onCopyPost,
      onDeletePost,
      onPinPost,
      onPushPost,
      onSwitchNotifications,
      post.from?.userId,
      post.isDisableNotifications,
      post.isPinned,
      post.publishedTime,
      post.wallContentType,
    ],
  );

  return (
    <div ref={postWrapper}>
      <Spin spinning={isLoading}>
        <WallItem>
          <PostMain>
            <PostHeader>
              {post.wallContentType === WallContentType.Idea && (
                <Row justify="space-between" style={{ width: '100%' }}>
                  <Col span={22}>
                    <Title variant="h2">{post.idea?.name}</Title>
                  </Col>
                  <Col>{renderRightMenu()}</Col>
                </Row>
              )}
              <Row justify="space-between" style={{ width: '100%' }}>
                <Col style={{ maxWidth: 'calc(100% - 32px)' }}>
                  <PostMeta
                    isSmall={post.wallContentType === WallContentType.Idea}
                    postMetaData={post}
                  />
                </Col>
                {post.wallContentType === WallContentType.Idea && (
                  <Col>
                    <VoteIdeaDate voteDate={post.idea?.voteEndDate as string} />
                  </Col>
                )}
                {post.wallContentType !== WallContentType.Idea &&
                  renderRightMenu()}
              </Row>
            </PostHeader>

            <PostContent data={post} updatePollAnswer={updatePollAnswer} />

            {defaultValues?.id && (
              <PostEditModal
                defaultValues={defaultValues}
                mode="copy"
                setVisible={setIsPostCopyModalVisible}
                type="post"
                visible={isPostCloneModalVisible}
              />
            )}

            {defaultValues?.id && (
              <PostEditModal
                defaultValues={defaultValues}
                mode="edit"
                setVisible={setIsPostEditModalVisible}
                type={
                  post.wallContentType === WallContentType.Idea
                    ? 'idea'
                    : 'post'
                }
                visible={isPostEditModalVisible}
              />
            )}

            {post.id && (
              <PostPushModal
                postId={post.id}
                postText={post.postContent?.text ?? undefined}
                setVisible={setIsPostPushModalVisible}
                visible={isPostPushModalVisible}
              />
            )}

            <PostFooter>
              <ControlPaper>
                {post.likes && (
                  <LikePostButton
                    disabled={isLikeLoading || !post.publishedTime}
                    isLiked={Boolean(post.isLiked)}
                    likes={post.likes}
                    onClick={() => likeMutation.mutate(post)}
                  />
                )}
                <PostButton
                  disabled={!post.publishedTime}
                  onClick={handleCommentClick}
                >
                  <ModeComment size={13} />
                  {post.comments?.total || 0}
                </PostButton>
                <PostButton
                  disabled={!post.publishedTime}
                  onClick={handleReplyClick}
                >
                  <Share size={13} />
                  {post?.shares?.total}
                </PostButton>
              </ControlPaper>

              <PostViewsContainer>
                <Visibility size={13} />
                {post.views}
              </PostViewsContainer>
            </PostFooter>
          </PostMain>
          {isReplyVisible && (
            <PostForm
              isForceFocused
              defaultValues={{ id: post.id } as PostUpdateModel}
              owner={{ userId: user?.id }}
              setParentIsVisible={handleIsReplyVisible}
            />
          )}
          {isCommentsVisible && !isReplyVisible && (
            <PostComments>
              <Comments
                afterDelete={afterCommentDelete}
                data={post.comments as CommentsModel}
                postId={Number(post.id)}
              />
            </PostComments>
          )}
          {post.publishedTime && !isReplyVisible && (
            <CommentFormContainer>
              <CommentForm
                author={data.owner}
                postId={post.id as number}
                submitCallback={afterCommentSubmit}
              />
            </CommentFormContainer>
          )}
        </WallItem>
      </Spin>
    </div>
  );
};
