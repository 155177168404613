import { useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { getAdminGroupsList } from 'components/PostForm/PostEditorContent/api';
import { AuthorFilter } from 'services/api';

import type { PostEditorContentProps } from '../types';

export const usePostOwner = ({
  owner,
}: Pick<PostEditorContentProps, 'owner'>) => {
  const [targetOwnerId, setTargetOwnerId] = useState<number | null>(null);

  const { data: userAdminGroups = [] } = useQuery(
    ['userAdminGroups'],
    () => getAdminGroupsList(owner?.userId as number),
    {
      suspense: false,
    },
  );

  const currentOwner: AuthorFilter = useMemo(() => {
    return targetOwnerId || !owner ? { groupId: targetOwnerId } : owner;
  }, [owner, targetOwnerId]);

  const handleWallOwnerChange = (postOwnerId: number | null) => {
    setTargetOwnerId(postOwnerId);
  };

  return {
    currentOwner,
    handleWallOwnerChange,
    userAdminGroups,
  };
};
