import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LoadingMoreItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 8px 16px;
`;

export const EmptyItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 8px 16px;
`;

export const ItemContainer = styled.div.attrs({
  role: 'menuitem',
})`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #edf1fe;
  }
`;
