import { VFC } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';

import { FormButtons } from 'components/FormButtons';
import { UserList } from 'components/PostPushModal/UserList';
import { api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useNotify } from 'services/systemNotify';
import {
  PostPushSendFormValues,
  postPushSchema,
} from 'services/validation/postPushSchema';
import { Form, FormItem } from 'ui';

export interface PostPushSendFormProps {
  postId: number;
  handleClose: () => void;
}

const mutatePushNotification =
  (postId: number) =>
  async ({ users }: PostPushSendFormValues) => {
    if (users.length > 0) {
      await api.pushNotification.apiV1PushNotificationsPost({
        pushNotificationCreateModel: {
          users: users.map((u) => u.id) as number[],
          wallId: postId,
        },
      });
    }
  };

export const PostPushSendForm: VFC<PostPushSendFormProps> = ({
  postId,
  handleClose,
}) => {
  const { t } = useTranslation('common');
  const { pushSuccessNotify, pushErrorNotify } = useNotify();
  const { formState, control, handleSubmit } = useForm<PostPushSendFormValues>({
    resolver: yupResolver(postPushSchema),
  });

  const mutation = useMutation(mutatePushNotification(postId), {
    onSuccess: () => {
      pushSuccessNotify(t('notification_sent_notify'));
      handleClose();
    },
    onError: () => {
      pushErrorNotify(t('something_went_wrong'));
    },
  });

  const onSubmit = (data: PostPushSendFormValues) => {
    mutation.mutate(data);
  };

  return (
    <Spin spinning={mutation.isLoading}>
      <Form layout="vertical">
        <Controller
          control={control}
          defaultValue={[]}
          name="users"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormItem
              help={error?.message}
              label={t('select_users')}
              validateStatus={error ? 'error' : undefined}
            >
              <UserList users={value} onChange={onChange} />
            </FormItem>
          )}
        />
        <FormButtons
          handleCancel={handleClose}
          handleSubmit={handleSubmit(onSubmit)}
          submitDisabled={formState.isSubmitting || !formState.isDirty}
          submitText={t('send')}
        />
      </Form>
    </Spin>
  );
};
