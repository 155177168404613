import { FC } from 'react';

type AvatarPreviewProps = {
  gender: string | null;
};

export const AvatarPreview: FC<AvatarPreviewProps> = ({ gender }) => {
  if (gender === 'male')
    return (
      <svg
        fill="none"
        height="284"
        viewBox="0 0 284 284"
        width="284"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4016_59945)">
          <rect fill="white" height="284" width="284" />
          <path
            d="M205.182 99.11C205.182 146.871 177.225 185.592 142.743 185.592C108.257 185.592 80.299 146.871 80.299 99.11C80.299 51.346 108.257 12.627 142.743 12.627C177.225 12.626 205.182 51.346 205.182 99.11Z"
            fill="#EFF2F6"
          />
          <path
            d="M141.754 180.061C89.489 180.061 42.765 192.582 11.688 212.26C40.762 253.319 88.603 280.147 142.743 280.147C196.497 280.147 244.046 253.696 273.174 213.129C242.109 192.951 194.787 180.061 141.754 180.061Z"
            fill="#EFF2F6"
          />
          <path
            d="M152.249 221.568H133.231C120.111 221.568 109.377 210.838 109.377 197.714V143.49C109.377 130.368 120.111 119.635 133.231 119.635H152.249C165.369 119.635 176.104 130.368 176.104 143.49V197.715C176.104 210.838 165.369 221.568 152.249 221.568Z"
            fill="#EFF2F6"
          />
          <path
            d="M90.159 104.022C92.03 117.887 88.951 129.91 83.283 130.872C77.616 131.839 71.508 121.379 69.636 107.514C67.768 93.6489 70.846 81.6269 76.513 80.6629C82.182 79.6969 88.291 90.157 90.159 104.022Z"
            fill="#EFF2F6"
          />
          <path
            d="M195.322 104.022C193.45 117.887 196.529 129.91 202.198 130.872C207.864 131.839 213.973 121.379 215.843 107.514C217.713 93.6489 214.635 81.6269 208.968 80.6629C203.299 79.6969 197.191 90.157 195.322 104.022Z"
            fill="#EFF2F6"
          />
          <path
            d="M82.482 95.706C80.383 81.861 78.282 68.017 76.184 54.173C75.422 49.148 74.686 43.849 76.52 39.109C78.354 34.367 83.735 30.593 88.499 32.373C86.591 22.742 97.247 15.629 106.531 12.432C122.087 7.078 138.77 4.349 155.086 6.458C171.405 8.566 187.336 15.785 198.336 28.02C201.819 31.893 204.818 36.291 206.479 41.229C209.669 50.728 207.637 61.096 205.582 70.903C203.945 78.695 202.311 86.486 200.676 94.279C200.303 96.058 199.887 97.926 198.671 99.278C196.735 101.434 193.471 101.595 190.572 101.602C159.611 101.668 128.65 101.735 97.688 101.802C91.864 101.815 84.264 100.381 83.497 94.609"
            fill="#EFF2F6"
          />
          <path
            d="M284 220.268C254.261 195.682 201.049 179.298 140.343 179.298C81.832 179.298 30.281 194.518 0 217.632V284H284V220.268Z"
            fill="#EFF2F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_4016_59945">
            <rect fill="white" height="284" width="284" />
          </clipPath>
        </defs>
      </svg>
    );

  return (
    <svg
      fill="none"
      height="284"
      viewBox="0 0 284 284"
      width="284"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="white" height="284" width="284" />
      <path
        d="M284 219.899C254.262 195.313 201.049 178.929 140.343 178.929C81.832 178.929 30.281 194.149 0 217.263V283.631H284V219.899Z"
        fill="#EFF2F6"
      />
      <path
        d="M208.969 80.2944C207.319 80.0134 205.632 80.7054 204.023 82.1624C203.909 81.3484 203.782 80.5414 203.651 79.7364C204.296 76.6694 204.938 73.6014 205.583 70.5334C207.638 60.7264 209.67 50.3584 206.479 40.8594C204.819 35.9214 201.819 31.5234 198.336 27.6504C187.336 15.4154 171.404 8.19637 155.086 6.08837C138.77 3.97937 122.087 6.70837 106.531 12.0624C97.247 15.2594 86.591 22.3724 88.499 32.0034C83.735 30.2234 78.354 33.9974 76.52 38.7394C74.686 43.4794 75.422 48.7784 76.184 53.8034C77.573 62.9654 78.962 72.1274 80.352 81.2894C79.085 80.4414 77.788 80.0764 76.513 80.2934C70.846 81.2574 67.768 93.2794 69.636 107.144C71.508 121.009 77.616 131.469 83.283 130.502C83.72 130.428 84.139 130.28 84.545 130.079C89.412 147.4 98.187 162.005 109.377 171.825V197.344C109.377 210.468 120.111 221.198 133.231 221.198H152.249C165.369 221.198 176.103 210.468 176.103 197.344V171.825C187.293 162.005 196.069 147.4 200.935 130.077C201.341 130.279 201.76 130.426 202.198 130.5C207.863 131.467 213.972 121.007 215.844 107.142C217.713 93.2804 214.635 81.2584 208.969 80.2944Z"
        fill="#EFF2F6"
      />
      <path
        d="M284 237.899C254.262 213.313 201.049 196.929 140.343 196.929C81.832 196.929 30.281 212.149 0 235.263V283.631H284V237.899Z"
        fill="#EFF2F6"
      />
      <path
        d="M147.554 219.09H139.339C126.917 219.09 116.754 208.925 116.754 196.505V145.165C116.754 132.743 126.917 122.58 139.339 122.58H147.554C159.974 122.58 170.139 132.743 170.139 145.165V196.505C170.139 208.925 159.974 219.09 147.554 219.09Z"
        fill="#EFF2F6"
      />
      <path
        d="M215.973 107.838C214.674 88.9674 215.32 69.6014 209.246 51.6894C202.422 31.5614 186.712 14.3494 166.789 6.94537C146.868 -0.458627 123.09 2.59337 106.444 15.8054C100.611 20.4354 95.368 27.0804 95.955 34.5044C85.847 31.0834 74.574 38.2634 69.849 47.8344C65.125 57.4034 65.356 68.5884 66.028 79.2394C67.996 110.454 72.756 142.46 64.311 172.544C90.218 173.329 116.124 174.113 142.033 174.898C150.326 175.15 158.63 175.399 166.921 175.042C188.077 174.131 210.007 169.361 229.929 176.534C222.288 154.378 217.583 131.215 215.973 107.838Z"
        fill="#EFF2F6"
      />
    </svg>
  );
};
