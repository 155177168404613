import { FC } from 'react';

import { StyledIconProps } from '@styled-icons/styled-icon';

import { iconsMap } from './icons';

export interface IconProps extends StyledIconProps {
  iconName: string;
}

export const Icon: FC<IconProps> = ({ iconName, ...props }) => {
  const IconComponent =
    iconName in iconsMap ? iconsMap[iconName] : iconsMap.default;

  return <IconComponent {...props} />;
};
