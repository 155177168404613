import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import flagUs from 'assets/lang/en-us.svg';
import flagRu from 'assets/lang/ru.svg';
import flagTr from 'assets/lang/tr.svg';

export const LANGUAGES_KEY = 'lang';

export type Language = 'ru' | 'en' | 'tr';

export enum Locales {
  RU = 'ru',
  EN = 'en-US',
  TR = 'tr',
}

export enum LanguageId {
  ru = 1,
  en,
  tr,
}

export enum CountryId {
  rus = 1,
  ukr,
  blt,
  tur,
  kaz,
}

export enum LanguageName {
  RU = 'Русский',
  EN = 'English',
  TR = 'Türk',
}

export interface LanguageData {
  id: number;
  name: string;
  title: string;
  flag: string;
}

export const LANGUAGES_DATA = [
  {
    id: 1,
    name: 'ru',
    title: 'RUS',
    flag: flagRu,
  },
  {
    id: 2,
    title: 'ENG',
    name: 'en',
    flag: flagUs,
  },
  {
    id: 3,
    title: 'TUR',
    name: 'tr',
    flag: flagTr,
  },
];

export const LANGUAGES: Language[] = LANGUAGES_DATA.map(
  (el) => el.name,
) as Language[];

export const LanguageNameMap: Record<LanguageId, string> = {
  [LanguageId.ru]: LanguageName.RU,
  [LanguageId.en]: LanguageName.EN,
  [LanguageId.tr]: LanguageName.TR,
};

export const langLocaleMap: Record<Language, Locales> = {
  ru: Locales.RU,
  en: Locales.EN,
  tr: Locales.TR,
};

export const CountrylanguageIdMap: Record<CountryId, LanguageId> = {
  [CountryId.rus]: LanguageId.ru,
  [CountryId.ukr]: LanguageId.ru,
  [CountryId.blt]: LanguageId.en,
  [CountryId.tur]: LanguageId.tr,
  [CountryId.kaz]: LanguageId.ru,
};

export const countryIdToLanguageId = (countryId: CountryId) =>
  CountrylanguageIdMap[countryId];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    supportedLngs: LANGUAGES,
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: LANGUAGES_KEY,
    },
    react: {
      useSuspense: false,
    },
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
