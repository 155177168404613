import { Modal } from 'antd';
import styled, { css } from 'styled-components';

import { ModalConfirmType, ModalProps } from './types';

export const ModalBody = styled.div<{ type?: ModalConfirmType }>`
  display: flex;
  & > svg:first-child {
    display: inline-block;
    margin: 2.3px 18px 0 0;
    color: ${({ theme, type = 'info' }) =>
      type === 'warning'
        ? theme.palette.error.main
        : theme.palette.primary.main};
  }
`;

export const ModalContentBody = styled.div`
  width: 100%;
`;

export const StyledModal = styled(Modal)<ModalProps>`
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-close {
    ${({ size = 'default' }) =>
      size === 'small' &&
      css`
        display: none;
      `}
  }

  .ant-modal-close-x {
    margin-top: 31px;
    margin-right: 29px;
    width: 14px;
    line-height: 0;
  }

  .ant-modal-close-x svg {
    width: 14px;
    height: 14px;
  }

  .ant-modal-header {
    ${({ size = 'default' }) =>
      size === 'small' &&
      css`
        display: none;
      `}
    padding: 24px 24px 0px 24px;
    border: none;
    border-radius: 8px 8px 0 0;
  }

  .ant-modal-body {
    padding: 32px 32px 32px 32px;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }
`;
