import { ChangeEvent, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Spin } from 'antd';

import { QueryHistoryList } from 'components/Header/Search/QueryHistoryList';
import {
  Popover,
  SearchAutoCompleteRoot,
} from 'components/Header/Search/styles';
import { useLoadItems } from 'components/Header/Search/useLoadItems';
import { useClickAway, useDebouncedValue, useKeyboard } from 'hooks';
import { useTranslation } from 'services/i18n';
import { SearchField } from 'ui/SearchField';

const inputId = 'searchAutoCompleteInput';

export const Search = () => {
  const [query, setQuery] = useState('');
  const [show, setShow] = useState(true);

  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const debouncedQuery = useDebouncedValue(query);

  const ref = useKeyboard<HTMLInputElement>({
    Enter: (event) => {
      const inputEl = event.target as HTMLInputElement;
      inputEl.blur();
      setQuery('');
      navigate({ pathname: 'search/posts', search: `query=${query}` });
    },
  });

  const rootRef = useClickAway<HTMLDivElement>(() => {
    if (document.activeElement?.id === inputId) return;

    setShow(false);
  });

  const {
    data: searchHistory,
    isLoading,
    hasNextPage,
    sentryRef,
  } = useLoadItems(debouncedQuery);

  const handleFocus = () => {
    setShow(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleClear = () => {
    setQuery('');
  };

  const handleSelect = (selectedQuery?: string | null) => {
    if (selectedQuery) {
      setQuery('');
      setShow(false);
      ref?.current?.blur();
      navigate({ pathname: 'search/posts', search: `query=${selectedQuery}` });
    }
  };

  return (
    <>
      <SearchField
        inputId={inputId}
        placeholder={t('search')}
        ref={ref}
        value={query}
        onChange={handleChange}
        onClear={handleClear}
        onFocus={handleFocus}
      />
      {show && debouncedQuery.length > 3 && (
        <Popover>
          {isLoading ? (
            <Spin />
          ) : (
            searchHistory?.items && (
              <SearchAutoCompleteRoot ref={rootRef}>
                <QueryHistoryList
                  data={[...new Set([...searchHistory.items])]}
                  loadingMoreElementRef={sentryRef}
                  showLoadingMoreElement={hasNextPage}
                  onItemClick={handleSelect}
                />
              </SearchAutoCompleteRoot>
            )
          )}
        </Popover>
      )}
    </>
  );
};
