import { VFC } from 'react';

import { Restore } from '@styled-icons/material-outlined';
import { Tooltip } from 'antd';

import { VideoProcessedCoverRoot } from './styles';

export const VideoProcessed: VFC = () => (
  <VideoProcessedCoverRoot>
    <Tooltip title="Ваше видео обрабатывается">
      <Restore size={32} />
    </Tooltip>
  </VideoProcessedCoverRoot>
);
