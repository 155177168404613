import { VFC, memo, useEffect, useState } from 'react';

import { LanguageMenu } from 'components/LanguageMenu';
import { PostPushReadModal } from 'components/PostPushReadModal';
import { ProfileMenu } from 'components/Profile/ProfileMenu';
import { useNotification } from 'services/userCredential';

import { Search } from '../Search';
import { PopupNotifications } from './PopupNotifications';
import { StyledSideNavWrapper } from './styles';

export const SideNav: VFC = memo(() => {
  const { pushNotification, readCurrentPush } = useNotification();

  const [notifications, setNotifications] = useState(
    new Map<number, boolean>(),
  );
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (pushNotification?.id) {
      setShowNotification(!notifications.has(pushNotification.id));
      notifications.set(pushNotification.id, true);
      setNotifications(notifications);
    }
  }, [notifications, pushNotification?.id]);

  return (
    <StyledSideNavWrapper>
      <Search />
      <PopupNotifications />
      <LanguageMenu />
      <ProfileMenu />

      {showNotification && (
        <PostPushReadModal
          handleRead={readCurrentPush}
          notificationId={pushNotification?.id ?? 0}
          postId={pushNotification?.wallId ?? 0}
          visible={!!pushNotification}
        />
      )}
    </StyledSideNavWrapper>
  );
});
