import { GroupUpdateModel, api } from 'services/api';

interface GetGroupsByParams {
  isArchived?: boolean;
  isNotApproved?: boolean;
  userId?: number;
  countryCategoryId?: number;
  offset: number;
  count: number;
  signal?: AbortSignal;
}

interface SubscribeParams {
  groupId: number;
  userId: number;
}

interface RejectParams {
  id: number;
  message: string;
}

const getGroups = async ({ signal, ...params }: GetGroupsByParams) => {
  const { data } = await api.group.apiV1GroupsGet(params, { signal });

  return data;
};

const getGroupById = async (id: number) => {
  const { data } = await api.group.apiV1GroupsIdGet({ id });

  return data;
};

const getCountryCategories = async () => {
  const { data } = await api.country.apiV1CountriesCategoriesGet();

  return data;
};

const postGroup = async (params: GroupUpdateModel) => {
  await api.group.apiV1GroupsPost({
    groupUpdateModel: params,
  });
};

const putGroup = async (params: GroupUpdateModel) => {
  await api.group.apiV1GroupsPut({
    groupUpdateModel: params,
  });
};

const deleteGroup = async (id: number) => {
  await api.group.apiV1GroupsIdDelete({ id });
};

const approveGroup = async (id: number) => {
  const { data } = await api.group.apiV1GroupsIdPatch({
    id,
    operation: [
      {
        path: '/isApproved',
        op: 'replace',
        value: true,
      },
    ],
  });

  return data;
};

const getNotGroupMembersById = async (id: number) => {
  const { data } = await api.group.apiV1GroupsIdNotMembersGet({
    id,
    count: 5,
  });

  return data.items;
};

const postGroupsMultiMembers = async ({
  id,
  userIds,
}: {
  id: number;
  userIds: number[];
}) => {
  await api.group.apiV1GroupsIdMultiMembersPost({
    id,
    createGroupMembersFilterRequest: {
      groupId: id,
      userIds,
    },
  });
};

const rejectGroup = async (params: RejectParams) => {
  const { data } = await api.group.apiV1GroupsIdPatch({
    id: params.id,
    operation: [
      {
        path: '/isApproved',
        op: 'replace',
        value: false,
      },
      {
        path: '/approveMessage',
        op: 'replace',
        value: params.message,
      },
    ],
  });

  return data;
};

const subscribe = async (params: SubscribeParams) => {
  const { data } = await api.group.apiV1GroupsIdMembersPost({
    id: params.groupId,
    userId: params.userId,
  });

  return data;
};

const unsubscribe = async (params: SubscribeParams) => {
  await api.group.apiV1GroupsIdMembersDelete({
    id: params.groupId,
    userId: params.userId,
  });
};

const archiveGroup = async (id: number) => {
  await api.group.apiV1GroupsIdPatch({
    id,
    operation: [
      {
        op: 'replace',
        path: '/isArchived',
        value: true,
      },
    ],
  });
};

const unarchiveGroup = async (id: number) => {
  await api.group.apiV1GroupsIdPatch({
    id,
    operation: [
      {
        op: 'replace',
        path: '/isArchived',
        value: false,
      },
    ],
  });
};

export const useGroupApi = () => ({
  getGroups,
  getGroupById,
  getNotGroupMembersById,
  getCountryCategories,
  postGroup,
  postGroupsMultiMembers,
  putGroup,
  deleteGroup,
  approveGroup,
  rejectGroup,
  subscribe,
  unsubscribe,
  archiveGroup,
  unarchiveGroup,
});
