import { Context, FC, createContext, useContext } from 'react';

import {
  UserNotificationService,
  useUserNotificationService,
} from './useUserNotificationService';

const UserNotificationContext = createContext<UserNotificationService | null>(
  null,
);

export const useNotification = (): UserNotificationService =>
  useContext(UserNotificationContext as Context<UserNotificationService>);

export const UserNotificationProvider: FC = ({ children }) => {
  const userNotificationService = useUserNotificationService();

  return (
    <UserNotificationContext.Provider value={userNotificationService}>
      {children}
    </UserNotificationContext.Provider>
  );
};
