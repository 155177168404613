import { CSSProperties, VFC, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { IndexRange, List } from 'react-virtualized';

import {
  Close,
  History,
  Settings,
  StickyNote2,
} from '@styled-icons/material-outlined';
import { Notifications as NotificationsIcon } from '@styled-icons/material-outlined/Notifications';
import { Badge, Dropdown, Menu } from 'antd';

import { StyledMenuWrapper } from 'components/Profile/ProfileMenu/styles';
import { NotificationType, api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useNotification } from 'services/userCredential/userNotificationContext';
import { Button, Col, IconButton, Row, Title } from 'ui';

import { PopupElement } from './PopupElement';
import { StyledLink } from './styles';

const getNotifications = async ({ startIndex, stopIndex }: IndexRange) => {
  const { data } = await api.notification.apiV1NotificationsPreviewGet({
    showRead: false,
    isPushNotification: false,
    offset: startIndex,
    count: stopIndex - startIndex + 1,
  });
  return {
    items: (data.items ?? [])
      .filter(
        (item) =>
          item.notificationType === NotificationType.BirthDaySubordinateNew ||
          ((item.wallId || item.byUser || item.byGroup) &&
            item.notificationType !== NotificationType.GroupRequestNew &&
            item.notificationType !== NotificationType.BirthDaySubscriberNew &&
            item.notificationType !==
              NotificationType.UserDiscoveryStepRequestNew),
      )
      .slice(0, 5),
    total: data.total,
  };
};

const getPushNotifications = async ({ startIndex, stopIndex }: IndexRange) => {
  const { data } = await api.notification.apiV1NotificationsGet({
    showRead: false,
    isPushNotification: true,
    offset: startIndex,
    count: stopIndex - startIndex + 1,
  });
  return { items: data.items ?? [], total: data.total };
};

const readAll = async () => {
  await api.notification.apiV1NotificationsReadAllPost();
};

export const PopupNotifications: VFC = () => {
  const { t } = useTranslation('common');

  const { data: pushData = { items: [], total: 0 }, refetch: pushRefetch } =
    useQuery(
      'popup-push-notifications',
      () => getPushNotifications({ startIndex: 0, stopIndex: 0 }),
      { suspense: false },
    );

  const { data = { items: [], total: 0 }, refetch } = useQuery(
    'popup-notifications',
    () => getNotifications({ startIndex: 0, stopIndex: -2 }),
    { suspense: false },
  );

  const notificate = useNotification();

  useEffect(() => {
    if (pushData.items.length > 0) {
      notificate.setNewPushNotification(
        pushData.items[pushData.items.length - 1],
      );
    }
  }, [notificate, pushData]);

  useEffect(() => {
    // if (prevNotify !== notify.notify)
    refetch();
    // if (prevPushNotify !== notify.pushNotify)
    pushRefetch();
  }, [notificate, pushRefetch, refetch]);

  const handleReadAll = async () => {
    await readAll();
    notificate.setNotify(notificate.notify + 1);
  };

  const mutationReadAll = useMutation(handleReadAll, {
    onSuccess: () => {
      refetch();
    },
  });

  const rowRenderer = ({
    key,
    index,
    style,
  }: {
    key: string;
    index: number;
    style: CSSProperties;
  }) => {
    const items = data ? data.items : [];
    const notification = items[index];
    return (
      <div key={key} style={style}>
        <PopupElement notification={notification} />
      </div>
    );
  };
  const rowHeight = 76;
  const height = rowHeight * 5;
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  return (
    <Dropdown
      overlay={
        <Menu style={{ width: '400px' }}>
          <Row
            align="middle"
            justify="space-between"
            style={{ padding: '12px 24px 0 24px' }}
          >
            <Col>
              <Title variant="h4">{t('notifications')}</Title>
            </Col>
            <Col>
              <Row justify="space-around">
                <Col>
                  <IconButton
                    onClick={() => navigate('/notifications/settings')}
                  >
                    <Settings size={22} />
                  </IconButton>
                </Col>
                <Col>
                  <IconButton onClick={() => setVisible(false)}>
                    <Close size={22} />
                  </IconButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ padding: '0 19px' }}>
            <Col>
              <StyledLink
                as={RouterLink}
                to="/notifications/push-notifications"
              >
                <StickyNote2 size={16} style={{ margin: '3px' }} />
                {`${t('push_notifications')} (${
                  pushData?.total ? pushData?.total : 0
                })`}
              </StyledLink>
            </Col>
            <Col flex="24px" />
            <Col>
              <StyledLink as={RouterLink} to="/notifications/histories">
                <History size={16} style={{ margin: '3px' }} />
                {t('history')}
              </StyledLink>
            </Col>
          </Row>
          {data?.items.length > 0 ? (
            <>
              <List
                autoHeight
                data={data.items}
                height={rowHeight * 5}
                overscanRowCount={10}
                rowCount={data.items.length}
                rowHeight={rowHeight}
                rowRenderer={rowRenderer}
                rowStyle={{ height }}
                style={{ paddingTop: '10px' }}
                width={400}
              />
              <Row justify="end" style={{ padding: '12px 24px' }}>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => mutationReadAll.mutateAsync()}
                >
                  {t('read_all')}
                </Button>
              </Row>
            </>
          ) : (
            <div style={{ padding: '22px 24px' }}>{t('no_notifications')}</div>
          )}
        </Menu>
      }
      overlayStyle={{ position: 'fixed' }}
      visible={visible}
      onVisibleChange={() => setVisible(false)}
    >
      <StyledMenuWrapper>
        <Badge
          dot
          offset={[-10, 10]}
          status={data.items.length || pushData.total ? 'error' : 'default'}
        >
          <IconButton onMouseOver={() => setVisible(true)}>
            <NotificationsIcon size={24} />
          </IconButton>
        </Badge>
      </StyledMenuWrapper>
    </Dropdown>
  );
};
