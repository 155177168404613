import React from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from 'services/auth';
import { I18nProvider } from 'services/i18n';
import { YandexMetrikaInitializer } from 'services/yandexMetrika';
import themes from 'ui/theme';

import { App } from './App';
import { GlobalStyle } from './GlobalStyle';

import '@fontsource/open-sans/cyrillic.css';
import '@fontsource/open-sans/latin.css';
import 'react-virtualized/styles.css';
import 'antd/dist/antd.min.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: true,
    },
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-MF8P73G',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <YandexMetrikaInitializer>
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <I18nProvider>
            <BrowserRouter>
              <AuthProvider>
                <ThemeProvider theme={themes.light}>
                  <GlobalStyle />
                  <App />
                </ThemeProvider>
              </AuthProvider>
            </BrowserRouter>
          </I18nProvider>
        </DndProvider>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </YandexMetrikaInitializer>
  </React.StrictMode>,
  document.getElementById('root'),
);
