import React, { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Pagination as AntPagination } from 'antd';

import { PaginationContainer } from './styles';
import { PaginationPropsTypes } from './types';

export const PAGE_PARAM = 'page';

export const Pagination: React.FC<PaginationPropsTypes> = (props) => {
  const { onHandleChange } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultPage, setDefaultPage] = useState<string | null>();

  useEffect(() => {
    const param = searchParams.get(PAGE_PARAM);
    if (param) {
      setDefaultPage(param);
    }
    if (!param) {
      searchParams.set(PAGE_PARAM, '1');
      setSearchParams(searchParams);
      setDefaultPage('1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (num) => {
      searchParams.set(PAGE_PARAM, num);
      setSearchParams(searchParams);
      setDefaultPage(num);
      if (onHandleChange) onHandleChange(num);
    },
    [onHandleChange, searchParams, setSearchParams],
  );

  useEffect(
    function onOutsideChangePage() {
      const param = searchParams.get(PAGE_PARAM);
      if (param !== null && param !== defaultPage) {
        handleChange(param);
      }
    },
    [defaultPage, handleChange, searchParams],
  );

  return (
    <PaginationContainer>
      <AntPagination
        {...props}
        current={Number(defaultPage)}
        onChange={handleChange}
      />
    </PaginationContainer>
  );
};
