import { FC } from 'react';

import { YMInitializer } from 'react-yandex-metrika';

const ACCOUNT = process.env.NODE_ENV === 'development' ? 87890106 : 87877124;

export const YandexMetrikaInitializer: FC = ({ children }) => {
  return (
    <YMInitializer
      accounts={[ACCOUNT]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true,
        ecommerce: 'dataLayer',
      }}
    >
      {children}
    </YMInitializer>
  );
};
