import React, { FC, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ChevronDoubleRight } from '@styled-icons/heroicons-outline';
import { Tooltip } from 'antd';

import { UsersModal } from 'components/Employees/UsersModal';
import { getPhotoPreview } from 'domain/photo';
import { UserNotificationPreviewModel, UserProfileModel } from 'services/api';
import { useTranslation } from 'services/i18n';
import { Avatar, AvatarGroup } from 'ui';
import { StyledLink } from 'ui/Link';

import {
  PushNotificationViewerList,
  PushNotificationViewerWrapper,
} from '../styles';
import { StyledMoreLink } from './style';

export interface PushNotificationViewerListProps {
  viewers: UserNotificationPreviewModel[];
  total: number;
}

export const PushNotificationViewersList: FC<
  PushNotificationViewerListProps
> = ({ viewers }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const [openModal, setOpenModal] = useState(false);

  const handleOpenViewersModal = useCallback(() => {
    setOpenModal(true);
  }, []);
  const handleCloseViewersModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  // TODO: костыльное преобразование типов данных
  // по хорошему надо чтобы AuthorModel (toUser) лучше
  // ложилась бы на модель UserProfileModel
  const viewerToUser = (viewer: UserNotificationPreviewModel) => {
    const { toUser = {} } = viewer;
    const { userId, avatar, country, name = '', positionName } = toUser;
    const user: UserProfileModel = {
      id: Number(userId),
      avatar,
      country,
      firstName: name,
      lastName: '',
      middleName: '',
      positionName,
    };
    return user;
  };

  return (
    <>
      {viewers && (
        <PushNotificationViewerWrapper>
          <PushNotificationViewerList>
            <AvatarGroup style={{ margin: 10 }}>
              {viewers.slice(0, 7).map((viewer) => (
                <Tooltip
                  key={viewer.toUser?.userId}
                  title={
                    <StyledLink
                      style={{ color: '#fff' }}
                      onClick={() =>
                        navigate(`/profile/${viewer.toUser?.userId}`)
                      }
                    >
                      {viewer.toUser?.name}
                    </StyledLink>
                  }
                >
                  <Avatar
                    size={40}
                    src={
                      viewer.toUser?.avatar &&
                      getPhotoPreview(viewer.toUser?.avatar, 50)
                    }
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
            {viewers.length > 7 && (
              <StyledMoreLink onClick={handleOpenViewersModal}>
                <ChevronDoubleRight size={24} />
              </StyledMoreLink>
            )}
          </PushNotificationViewerList>
        </PushNotificationViewerWrapper>
      )}
      <UsersModal
        open={openModal}
        title={t('push_read')}
        users={viewers.map((v) => viewerToUser(v)) as UserProfileModel[]}
        onClose={handleCloseViewersModal}
      />
    </>
  );
};
