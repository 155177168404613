import { VFC } from 'react';

import { getUserCountryShort } from 'domain/user';
import { AuthorModel, UserProfileModel } from 'services/api';
import { Avatar } from 'ui/Avatar';
import { GeoBadge, GeoBadgeProps } from 'ui/GeoBadge';

import {
  ItemAction,
  ItemAvatar,
  ItemContainer,
  ItemData,
  ItemProfileLink,
  ItemRole,
  NameData,
} from './styles';
import { UserItemProps } from './types';

export { UserCardItemSkeleton } from './Skeleton';

const mapSize = {
  default: {
    avatarSize: 60,
    geobadge: {
      size: 24,
      offset: [-10, 50] as GeoBadgeProps['offset'],
    },
  },
  small: {
    avatarSize: 40,
    geobadge: {
      size: 16,
      offset: [-8, 32] as GeoBadgeProps['offset'],
    },
  },
};

export const UserCardItem: VFC<UserItemProps> = (props) => {
  const { userData, actionElement, inverseColor, size = 'default' } = props;

  const id =
    'id' in userData
      ? (userData as UserProfileModel).id
      : (userData as AuthorModel).userId;

  const name =
    'name' in userData
      ? (userData as AuthorModel).name
      : `${(userData as UserProfileModel).firstName} ${
          (userData as UserProfileModel).lastName
        }`;

  return (
    <ItemContainer {...props} inverseColor={inverseColor}>
      <ItemAvatar>
        <GeoBadge
          locale={getUserCountryShort(userData)}
          offset={mapSize[size].geobadge.offset}
          size={mapSize[size].geobadge.size}
        >
          <Avatar
            shape="circle"
            size={mapSize[size].avatarSize}
            src={userData.avatar?.fileUrl}
          />
        </GeoBadge>
      </ItemAvatar>
      <ItemData>
        <NameData>
          <ItemProfileLink to={`/profile/${id}`}>{name}</ItemProfileLink>
        </NameData>
        <ItemRole>{userData.positionName}</ItemRole>
      </ItemData>
      <ItemAction>{actionElement}</ItemAction>
    </ItemContainer>
  );
};

export type { UserItemProps } from './types';
