import styled from 'styled-components';

import { IconButton, ListItem } from 'ui';

import { LikeButton } from '../LikeButton';

export const ControlPaper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 16px;
  justify-content: start;
`;

export const PostButton = styled(IconButton)`
  background-color: #f6f8fa;
  border-radius: 16px;
  display: grid;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 5px;
  padding: 6px 12px;
  height: 32px;
  width: auto;
`;

export const LikePostButton = styled(LikeButton)`
  background-color: #f6f8fa;
  border-radius: 16px;
  display: grid;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 5px;
  padding: 6px 12px;
  height: 32px;
  width: auto;
`;
export const PostHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

// FIXME overwritten styles
export const WallItem = styled(ListItem)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 16px 0;
  min-width: 100%;
  line-height: 1.5;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: repeat(3, min-content);
  word-wrap: break-word;
  grid-row-gap: 16px;
  box-shadow: none;
  border-radius: 8px;
  display: block;
  background-color: #fff;
  padding: 24px 0;
`;

export const PostMain = styled.div`
  min-width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: repeat(3, min-content);
  grid-row-gap: 16px;
  word-wrap: break-word;
  padding: 0 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  .ant-list-item-meta-title:only-child {
    margin-top: 0;
  }
`;

export const PostViewsContainer = styled.div`
  display: grid;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.text.secondary};
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 5px;
  padding: 6px 12px;
  align-items: center;
`;

export const PostFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PostComments = styled.div`
  padding: 0 24px;
  border-top: 1px solid #eff2f6;
`;

export const CommentFormContainer = styled.div`
  padding: 0 24px;
`;
