/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DepartmentsModel } from '../models';
/**
 * DepartmentApi - axios parameter creator
 * @export
 */
export const DepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [parentDepId] Фильтровать по родительским подразделениям
         * @param {boolean} [isHeadOnly] Фильтровать только по верхнеуровневым подразделениям
         * @param {number} [countryId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsGet: async (orderByCoins?: boolean, parentDepId?: string, isHeadOnly?: boolean, countryId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderByCoins !== undefined) {
                localVarQueryParameter['OrderByCoins'] = orderByCoins;
            }

            if (parentDepId !== undefined) {
                localVarQueryParameter['ParentDepId'] = parentDepId;
            }

            if (isHeadOnly !== undefined) {
                localVarQueryParameter['IsHeadOnly'] = isHeadOnly;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentApi - functional programming interface
 * @export
 */
export const DepartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [parentDepId] Фильтровать по родительским подразделениям
         * @param {boolean} [isHeadOnly] Фильтровать только по верхнеуровневым подразделениям
         * @param {number} [countryId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DepartmentsGet(orderByCoins?: boolean, parentDepId?: string, isHeadOnly?: boolean, countryId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DepartmentsGet(orderByCoins, parentDepId, isHeadOnly, countryId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentApi - factory interface
 * @export
 */
export const DepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [parentDepId] Фильтровать по родительским подразделениям
         * @param {boolean} [isHeadOnly] Фильтровать только по верхнеуровневым подразделениям
         * @param {number} [countryId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DepartmentsGet(orderByCoins?: boolean, parentDepId?: string, isHeadOnly?: boolean, countryId?: number, count?: number, offset?: number, options?: any): AxiosPromise<DepartmentsModel> {
            return localVarFp.apiV1DepartmentsGet(orderByCoins, parentDepId, isHeadOnly, countryId, count, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DepartmentsGet operation in DepartmentApi.
 * @export
 * @interface DepartmentApiApiV1DepartmentsGetRequest
 */
export interface DepartmentApiApiV1DepartmentsGetRequest {
    /**
     * Сортировать по коинам
     * @type {boolean}
     * @memberof DepartmentApiApiV1DepartmentsGet
     */
    readonly orderByCoins?: boolean

    /**
     * Фильтровать по родительским подразделениям
     * @type {string}
     * @memberof DepartmentApiApiV1DepartmentsGet
     */
    readonly parentDepId?: string

    /**
     * Фильтровать только по верхнеуровневым подразделениям
     * @type {boolean}
     * @memberof DepartmentApiApiV1DepartmentsGet
     */
    readonly isHeadOnly?: boolean

    /**
     * 
     * @type {number}
     * @memberof DepartmentApiApiV1DepartmentsGet
     */
    readonly countryId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof DepartmentApiApiV1DepartmentsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof DepartmentApiApiV1DepartmentsGet
     */
    readonly offset?: number
}

/**
 * DepartmentApi - object-oriented interface
 * @export
 * @class DepartmentApi
 * @extends {BaseAPI}
 */
export class DepartmentApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {DepartmentApiApiV1DepartmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiV1DepartmentsGet(requestParameters: DepartmentApiApiV1DepartmentsGetRequest = {}, options?: AxiosRequestConfig) {
        return DepartmentApiFp(this.configuration).apiV1DepartmentsGet(requestParameters.orderByCoins, requestParameters.parentDepId, requestParameters.isHeadOnly, requestParameters.countryId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}
