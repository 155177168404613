import { VFC } from 'react';

import { FormFilesGrid } from 'components/FormFiles';
import { TDocumentType } from 'components/PostForm//PostEditorContent/types';
import { PostContentModel, WallModel } from 'services/api';

import { PostMediaAttachmentGrid } from './PostMediaAttachmentGrid';
import { PostOpenGraphContent } from './PostOpenGraphContent';
import { PostPoll } from './PostPoll';
import { PostTextContent } from './PostTextContent';

export type PostTextContentProps = {
  data: PostContentModel;
  updatePollAnswer?: (answerId?: number) => void;
} & Pick<WallModel, 'poll' | 'from'>;

export const PostStandardContent: VFC<PostTextContentProps> = ({
  data,
  from,
  poll,
  updatePollAnswer,
}) => {
  const media = data.postContentAttachments?.filter(
    (attacment) => attacment.photoId || attacment.videoId,
  );
  const documents = data.postContentAttachments
    ?.filter((attachment) => attachment.documentId)
    .map((attachment) => ({
      id: attachment.documentId,
      name: attachment.postedFile?.fileName,
      size: attachment.size,
      fileUrl: attachment.postedFile?.fileUrl,
    })) as TDocumentType[];

  const isSlimOpenGraph = () => {
    return (
      data &&
      data.postContentAttachments &&
      data.postContentAttachments?.length > 0
    );
  };

  return (
    <div>
      <PostTextContent data={data} />

      {media && media.length > 0 && (
        <PostMediaAttachmentGrid attachments={media} />
      )}

      {documents && documents.length > 0 && (
        <FormFilesGrid documents={documents} />
      )}
      {poll && (
        <PostPoll {...poll} from={from} updatePollAnswer={updatePollAnswer} />
      )}

      {data.openGraphItem && (
        <PostOpenGraphContent
          openGraph={data.openGraphItem}
          type={isSlimOpenGraph() ? 'small' : 'default'}
        />
      )}
    </div>
  );
};
