import { VFC, useCallback, useState } from 'react';

import { UserProfileModel, api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { Button } from 'ui/Button';

type SubscribeButtonProps = {
  user: UserProfileModel;
};

export const SubscribeButton: VFC<SubscribeButtonProps> = ({
  user,
}: SubscribeButtonProps) => {
  const [isSubscribed, setSubscribed] = useState<boolean>(
    user?.isFriend ?? false,
  );

  const { t } = useTranslation('common');

  const handleUserUnsubscribe = useCallback(
    async (subscribedUser: UserProfileModel) => {
      await api.user.apiV1UsersFriendDelete({
        userId: subscribedUser.id,
      });
      setSubscribed(false);
    },
    [],
  );

  const handleUserSubscribe = useCallback(
    async (unSubscribedUser: UserProfileModel) => {
      await api.user.apiV1UsersFriendPost({
        userId: unSubscribedUser.id,
      });
      setSubscribed(true);
    },
    [],
  );

  return (
    <>
      {isSubscribed && (
        <Button
          color="primary"
          variant="outlined"
          onClick={async () => {
            await handleUserUnsubscribe(user);
          }}
        >
          {t('unsubscribe')}
        </Button>
      )}
      {!isSubscribed && (
        <Button
          color="primary"
          onClick={async () => {
            await handleUserSubscribe(user);
          }}
        >
          {t('subscribe')}
        </Button>
      )}
    </>
  );
};
