import styled from 'styled-components';

export const PopupItem = styled.div`
  display: flex;
  width: 100%;
  padding: 13px 24px;
  background: #ffffff;
  &:hover {
    background: #f6f8fa;
  }
`;

export const PopupPublisher = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2e2e32;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PopupDate = styled.div`
  position: static;
  height: 20px;
  left: 0px;
  top: 22px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7e8389;
`;

export const PopupMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2e2e32;
`;

export const PopupInfo = styled.div`
  flex: none;
  margin: 0 0 0 10px;
`;
