import { VFC } from 'react';

import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { ExpandMore as ExpandMoreIcon } from '@styled-icons/material-outlined/ExpandMore';

import { createLinkWithGeneratedPath, isExternal } from 'domain/navigation';
import { LinkType, RoleName } from 'services/api';
import { useAuth } from 'services/auth';
import { useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { Dropdown, Menu, MenuItem, UniversalLink } from 'ui';

import { NavMenuItem } from './styles';
import { useLinksApi } from './useLinksApi';

export const AdminLinksMenu: VFC = () => {
  const { user, hasRole } = useUserCredential();
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { getLinks } = useLinksApi();
  const { token } = useAuth();

  const { data: rawAdminLinks = [] } = useQuery(
    ['header', 'links', 'admin', user?.country.id],
    () => getLinks(user?.country.id as number, LinkType.Administration),
    {
      suspense: false,
    },
  );

  const { data: rawHrLinks = [] } = useQuery(
    ['header', 'links', 'hr', user?.country.id],
    () => getLinks(user?.country.id as number, LinkType.HrManagement),
    {
      suspense: false,
    },
  );

  const adminLinks = rawAdminLinks.map(createLinkWithGeneratedPath(token));
  const hrLinks = rawHrLinks.map(createLinkWithGeneratedPath(token));

  const filteredAdminLinks = adminLinks.filter((link) => {
    if (!hasRole(RoleName.SubAdministrator)) {
      return link.href !== '/admin/subscriptions';
    }
    return true;
  });

  return (
    <Dropdown
      overlay={
        <Menu mode="vertical" selectedKeys={[pathname]}>
          {filteredAdminLinks.map((link) => (
            <MenuItem key={link.id}>
              <UniversalLink
                external={isExternal(link.href)}
                target={isExternal(link.href) ? '_blank' : '_self'}
                to={link.href}
              >
                {t(link.title as string)}
              </UniversalLink>
            </MenuItem>
          ))}
          {hrLinks.map((link) => (
            <MenuItem key={link.href}>
              <UniversalLink
                external={isExternal(link.href)}
                target={isExternal(link.href) ? '_blank' : '_self'}
                to={link.href}
              >
                {t(link.title as string)}
              </UniversalLink>
            </MenuItem>
          ))}
        </Menu>
      }
      overlayStyle={{ position: 'fixed' }}
    >
      <NavMenuItem>
        {t('administration')} <ExpandMoreIcon size={24} />
      </NavMenuItem>
    </Dropdown>
  );
};
