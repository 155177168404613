import { MutableRefObject, useEffect, useMemo, useState } from 'react';

export const useOnScreen = function useOnScreen<T extends Element>(
  ref: MutableRefObject<T>,
  rootMargin = '0px',
): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const el: T | undefined = ref && ref.current;
    if (el) {
      observer.observe(el);
    }
    return () => {
      if (el) observer.unobserve(el);
    };
  }, [observer, ref]);

  return isIntersecting;
};
