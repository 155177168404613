import styled from 'styled-components';

export const PaginationContainer = styled.div`
  .ant-pagination {
    text-align: center;
  }
  .ant-pagination-item-active {
    background-color: #4872f2;
    color: white;
    border-radius: 8px;
    a {
      color: #ffffff;
    }
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }

  .ant-pagination-item {
    border: none;
  }
`;
