import styled from 'styled-components';

import { MenuItem } from 'ui';

export const StyledMenuItem = styled(MenuItem)`
  & svg {
    margin-right: 12px;
    color: #4872f2;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
