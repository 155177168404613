/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { RoleModel } from '../models';
// @ts-ignore
import { RoleName } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/role/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {Array<RoleName>} [roleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleSetPost: async (userId?: string, roleName?: Array<RoleName>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/role/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleName, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleUserGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/role/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RoleAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RoleAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {Array<RoleName>} [roleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RoleSetPost(userId?: string, roleName?: Array<RoleName>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RoleSetPost(userId, roleName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RoleUserGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RoleUserGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleAllGet(options?: any): AxiosPromise<RoleModel> {
            return localVarFp.apiV1RoleAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {Array<RoleName>} [roleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleSetPost(userId?: string, roleName?: Array<RoleName>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1RoleSetPost(userId, roleName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RoleUserGet(id?: string, options?: any): AxiosPromise<RoleModel> {
            return localVarFp.apiV1RoleUserGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1RoleSetPost operation in RoleApi.
 * @export
 * @interface RoleApiApiV1RoleSetPostRequest
 */
export interface RoleApiApiV1RoleSetPostRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleApiApiV1RoleSetPost
     */
    readonly userId?: string

    /**
     * 
     * @type {Array<RoleName>}
     * @memberof RoleApiApiV1RoleSetPost
     */
    readonly roleName?: Array<RoleName>
}

/**
 * Request parameters for apiV1RoleUserGet operation in RoleApi.
 * @export
 * @interface RoleApiApiV1RoleUserGetRequest
 */
export interface RoleApiApiV1RoleUserGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleApiApiV1RoleUserGet
     */
    readonly id?: string
}

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiV1RoleAllGet(options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiV1RoleAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleApiApiV1RoleSetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiV1RoleSetPost(requestParameters: RoleApiApiV1RoleSetPostRequest = {}, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiV1RoleSetPost(requestParameters.userId, requestParameters.roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoleApiApiV1RoleUserGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiV1RoleUserGet(requestParameters: RoleApiApiV1RoleUserGetRequest = {}, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiV1RoleUserGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
