import { ChangeEventHandler, VFC, useState } from 'react';

import { Spin } from 'antd';

import { useClickAway, useDebouncedValue } from 'hooks';
import { UserProfileModel } from 'services/api';
import { TextField } from 'ui/TextField';

import { Popover, UsersAutoCompleteRoot } from './styles';
import { UsersAutoCompleteProps } from './types';
import { useLoadItems } from './useLoadItems';
import { UsersMenuList } from './UsersMenuList';

const inputId = 'usersAutoCompleteInput';

export const UsersAutoComplete: VFC<UsersAutoCompleteProps> = ({
  onSelect,
  placeholder,
  defaultValue = '',
}) => {
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState('');

  const debouncedQuery = useDebouncedValue(query, 400);

  const rootRef = useClickAway<HTMLDivElement>(() => {
    if (document.activeElement?.id === inputId) return;
    setShow(false);
  });

  const {
    data: users,
    isLoading,
    hasNextPage,
    sentryRef,
  } = useLoadItems(debouncedQuery);

  const handleFocus = () => {
    setShow(true);
  };

  const handleSelect = (userData: UserProfileModel) => {
    setQuery('');
    setShow(false);
    if (onSelect) onSelect(userData);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setQuery(event.target.value);
  };

  return (
    <UsersAutoCompleteRoot ref={rootRef}>
      <TextField
        defaultValue={defaultValue}
        id={inputId}
        placeholder={placeholder}
        size="large"
        value={query}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      {show && debouncedQuery.length > 3 && (
        <Popover>
          {isLoading ? (
            <Spin />
          ) : (
            users?.items && (
              <UsersMenuList
                data={users.items}
                loadingMoreElementRef={sentryRef}
                showLoadingMoreElement={hasNextPage}
                onItemClick={handleSelect}
              />
            )
          )}
        </Popover>
      )}
    </UsersAutoCompleteRoot>
  );
};
