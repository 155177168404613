import styled from 'styled-components';

export const StyledSideNavWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
  width: 332px;
`;
