import styled from 'styled-components';

export const StyledInputRoot = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 40px;
  width: 40px;
`;

export const StyledAdornment = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 100%;
  color: ${({ theme }) => theme.palette.gray[500]};
`;

export const StyledInput = styled.input`
  display: block;
  border: none;
  color: currentcolor;
  box-sizing: content-box;
  background: none;
  min-width: 0;
  width: 0;
  height: 16px;
  font: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 0;
  margin: 0;
  ::placeholder {
    color: ${({ theme }) => theme.palette.text.disabled};
  }
  &:focus {
    outline: 0;
  }
`;

export const StyledInputWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;

  &[data-open='true'] {
    background-color: ${({ theme }) => theme.palette.gray[50]};
    width: 100%;
    ${StyledInput} {
      width: 100%;
    }
  }
`;
