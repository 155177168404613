/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { MySectionPageDtoSearchResult } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { SectionPageModel } from '../models';
// @ts-ignore
import { SectionPageTreeDtoSearchResult } from '../models';
// @ts-ignore
import { SectionPageUpdateModel } from '../models';
// @ts-ignore
import { SectionPagesModel } from '../models';
// @ts-ignore
import { UpdateTreeVisibleRequestFilter } from '../models';
// @ts-ignore
import { UpdateVisibilitySectionPagesRequest } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * SectionPageApi - axios parameter creator
 * @export
 */
export const SectionPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {string} [query] 
         * @param {number} [parentSectionPageId] 
         * @param {boolean} [isTopLevelOnly] 
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isVisible] Фильтр по доступности
         * @param {boolean} [isDraft] Фильтр по черновикам
         * @param {string} [tags] Фильтр по тэгам, разделяются запятыми
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesGet: async (query?: string, parentSectionPageId?: number, isTopLevelOnly?: boolean, countryId?: number, isVisible?: boolean, isDraft?: boolean, tags?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/section-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (parentSectionPageId !== undefined) {
                localVarQueryParameter['ParentSectionPageId'] = parentSectionPageId;
            }

            if (isTopLevelOnly !== undefined) {
                localVarQueryParameter['IsTopLevelOnly'] = isTopLevelOnly;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (isVisible !== undefined) {
                localVarQueryParameter['IsVisible'] = isVisible;
            }

            if (isDraft !== undefined) {
                localVarQueryParameter['IsDraft'] = isDraft;
            }

            if (tags !== undefined) {
                localVarQueryParameter['Tags'] = tags;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SectionPagesIdDelete', 'id', id)
            const localVarPath = `/api/v1/section-pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SectionPagesIdGet', 'id', id)
            const localVarPath = `/api/v1/section-pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SectionPagesIdPatch', 'id', id)
            const localVarPath = `/api/v1/section-pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current users pages
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {boolean} [isManager] 
         * @param {boolean} [isAuthor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesMyGet: async (count?: number, offset?: number, isManager?: boolean, isAuthor?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/section-pages/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (isManager !== undefined) {
                localVarQueryParameter['IsManager'] = isManager;
            }

            if (isAuthor !== undefined) {
                localVarQueryParameter['IsAuthor'] = isAuthor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {SectionPageUpdateModel} [sectionPageUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesPost: async (sectionPageUpdateModel?: SectionPageUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/section-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionPageUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {SectionPageUpdateModel} [sectionPageUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesPut: async (sectionPageUpdateModel?: SectionPageUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/section-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionPageUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get section page tree
         * @param {number} [parentSectionPageId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesTreeGet: async (parentSectionPageId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/section-pages/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (parentSectionPageId !== undefined) {
                localVarQueryParameter['ParentSectionPageId'] = parentSectionPageId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update visibility for tree node and its childs
         * @param {UpdateTreeVisibleRequestFilter} [updateTreeVisibleRequestFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesTreeVisiblePost: async (updateTreeVisibleRequestFilter?: UpdateTreeVisibleRequestFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/section-pages/tree/visible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTreeVisibleRequestFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {UpdateVisibilitySectionPagesRequest} [updateVisibilitySectionPagesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesVisiblePut: async (updateVisibilitySectionPagesRequest?: UpdateVisibilitySectionPagesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/section-pages/visible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVisibilitySectionPagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionPageApi - functional programming interface
 * @export
 */
export const SectionPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {string} [query] 
         * @param {number} [parentSectionPageId] 
         * @param {boolean} [isTopLevelOnly] 
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isVisible] Фильтр по доступности
         * @param {boolean} [isDraft] Фильтр по черновикам
         * @param {string} [tags] Фильтр по тэгам, разделяются запятыми
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesGet(query?: string, parentSectionPageId?: number, isTopLevelOnly?: boolean, countryId?: number, isVisible?: boolean, isDraft?: boolean, tags?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionPagesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesGet(query, parentSectionPageId, isTopLevelOnly, countryId, isVisible, isDraft, tags, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionPageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionPageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current users pages
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {boolean} [isManager] 
         * @param {boolean} [isAuthor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesMyGet(count?: number, offset?: number, isManager?: boolean, isAuthor?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MySectionPageDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesMyGet(count, offset, isManager, isAuthor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {SectionPageUpdateModel} [sectionPageUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesPost(sectionPageUpdateModel?: SectionPageUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionPageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesPost(sectionPageUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {SectionPageUpdateModel} [sectionPageUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesPut(sectionPageUpdateModel?: SectionPageUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionPageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesPut(sectionPageUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get section page tree
         * @param {number} [parentSectionPageId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesTreeGet(parentSectionPageId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionPageTreeDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesTreeGet(parentSectionPageId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update visibility for tree node and its childs
         * @param {UpdateTreeVisibleRequestFilter} [updateTreeVisibleRequestFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesTreeVisiblePost(updateTreeVisibleRequestFilter?: UpdateTreeVisibleRequestFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesTreeVisiblePost(updateTreeVisibleRequestFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {UpdateVisibilitySectionPagesRequest} [updateVisibilitySectionPagesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SectionPagesVisiblePut(updateVisibilitySectionPagesRequest?: UpdateVisibilitySectionPagesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SectionPagesVisiblePut(updateVisibilitySectionPagesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionPageApi - factory interface
 * @export
 */
export const SectionPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionPageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {string} [query] 
         * @param {number} [parentSectionPageId] 
         * @param {boolean} [isTopLevelOnly] 
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isVisible] Фильтр по доступности
         * @param {boolean} [isDraft] Фильтр по черновикам
         * @param {string} [tags] Фильтр по тэгам, разделяются запятыми
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesGet(query?: string, parentSectionPageId?: number, isTopLevelOnly?: boolean, countryId?: number, isVisible?: boolean, isDraft?: boolean, tags?: string, count?: number, offset?: number, options?: any): AxiosPromise<SectionPagesModel> {
            return localVarFp.apiV1SectionPagesGet(query, parentSectionPageId, isTopLevelOnly, countryId, isVisible, isDraft, tags, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SectionPagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesIdGet(id: number, options?: any): AxiosPromise<SectionPageModel> {
            return localVarFp.apiV1SectionPagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<SectionPageModel> {
            return localVarFp.apiV1SectionPagesIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current users pages
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {boolean} [isManager] 
         * @param {boolean} [isAuthor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesMyGet(count?: number, offset?: number, isManager?: boolean, isAuthor?: boolean, options?: any): AxiosPromise<MySectionPageDtoSearchResult> {
            return localVarFp.apiV1SectionPagesMyGet(count, offset, isManager, isAuthor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {SectionPageUpdateModel} [sectionPageUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesPost(sectionPageUpdateModel?: SectionPageUpdateModel, options?: any): AxiosPromise<SectionPageModel> {
            return localVarFp.apiV1SectionPagesPost(sectionPageUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {SectionPageUpdateModel} [sectionPageUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesPut(sectionPageUpdateModel?: SectionPageUpdateModel, options?: any): AxiosPromise<SectionPageModel> {
            return localVarFp.apiV1SectionPagesPut(sectionPageUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get section page tree
         * @param {number} [parentSectionPageId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesTreeGet(parentSectionPageId?: number, count?: number, offset?: number, options?: any): AxiosPromise<SectionPageTreeDtoSearchResult> {
            return localVarFp.apiV1SectionPagesTreeGet(parentSectionPageId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update visibility for tree node and its childs
         * @param {UpdateTreeVisibleRequestFilter} [updateTreeVisibleRequestFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesTreeVisiblePost(updateTreeVisibleRequestFilter?: UpdateTreeVisibleRequestFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SectionPagesTreeVisiblePost(updateTreeVisibleRequestFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {UpdateVisibilitySectionPagesRequest} [updateVisibilitySectionPagesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SectionPagesVisiblePut(updateVisibilitySectionPagesRequest?: UpdateVisibilitySectionPagesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SectionPagesVisiblePut(updateVisibilitySectionPagesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1SectionPagesGet operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesGetRequest
 */
export interface SectionPageApiApiV1SectionPagesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly query?: string

    /**
     * 
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly parentSectionPageId?: number

    /**
     * 
     * @type {boolean}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly isTopLevelOnly?: boolean

    /**
     * Фильтр по стране
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly countryId?: number

    /**
     * Фильтр по доступности
     * @type {boolean}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly isVisible?: boolean

    /**
     * Фильтр по черновикам
     * @type {boolean}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly isDraft?: boolean

    /**
     * Фильтр по тэгам, разделяются запятыми
     * @type {string}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly tags?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1SectionPagesIdDelete operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesIdDeleteRequest
 */
export interface SectionPageApiApiV1SectionPagesIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1SectionPagesIdGet operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesIdGetRequest
 */
export interface SectionPageApiApiV1SectionPagesIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1SectionPagesIdPatch operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesIdPatchRequest
 */
export interface SectionPageApiApiV1SectionPagesIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof SectionPageApiApiV1SectionPagesIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1SectionPagesMyGet operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesMyGetRequest
 */
export interface SectionPageApiApiV1SectionPagesMyGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesMyGet
     */
    readonly count?: number

    /**
     * 
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesMyGet
     */
    readonly offset?: number

    /**
     * 
     * @type {boolean}
     * @memberof SectionPageApiApiV1SectionPagesMyGet
     */
    readonly isManager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof SectionPageApiApiV1SectionPagesMyGet
     */
    readonly isAuthor?: boolean
}

/**
 * Request parameters for apiV1SectionPagesPost operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesPostRequest
 */
export interface SectionPageApiApiV1SectionPagesPostRequest {
    /**
     * 
     * @type {SectionPageUpdateModel}
     * @memberof SectionPageApiApiV1SectionPagesPost
     */
    readonly sectionPageUpdateModel?: SectionPageUpdateModel
}

/**
 * Request parameters for apiV1SectionPagesPut operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesPutRequest
 */
export interface SectionPageApiApiV1SectionPagesPutRequest {
    /**
     * 
     * @type {SectionPageUpdateModel}
     * @memberof SectionPageApiApiV1SectionPagesPut
     */
    readonly sectionPageUpdateModel?: SectionPageUpdateModel
}

/**
 * Request parameters for apiV1SectionPagesTreeGet operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesTreeGetRequest
 */
export interface SectionPageApiApiV1SectionPagesTreeGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesTreeGet
     */
    readonly parentSectionPageId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesTreeGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof SectionPageApiApiV1SectionPagesTreeGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1SectionPagesTreeVisiblePost operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesTreeVisiblePostRequest
 */
export interface SectionPageApiApiV1SectionPagesTreeVisiblePostRequest {
    /**
     * 
     * @type {UpdateTreeVisibleRequestFilter}
     * @memberof SectionPageApiApiV1SectionPagesTreeVisiblePost
     */
    readonly updateTreeVisibleRequestFilter?: UpdateTreeVisibleRequestFilter
}

/**
 * Request parameters for apiV1SectionPagesVisiblePut operation in SectionPageApi.
 * @export
 * @interface SectionPageApiApiV1SectionPagesVisiblePutRequest
 */
export interface SectionPageApiApiV1SectionPagesVisiblePutRequest {
    /**
     * 
     * @type {UpdateVisibilitySectionPagesRequest}
     * @memberof SectionPageApiApiV1SectionPagesVisiblePut
     */
    readonly updateVisibilitySectionPagesRequest?: UpdateVisibilitySectionPagesRequest
}

/**
 * SectionPageApi - object-oriented interface
 * @export
 * @class SectionPageApi
 * @extends {BaseAPI}
 */
export class SectionPageApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {SectionPageApiApiV1SectionPagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesGet(requestParameters: SectionPageApiApiV1SectionPagesGetRequest = {}, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesGet(requestParameters.query, requestParameters.parentSectionPageId, requestParameters.isTopLevelOnly, requestParameters.countryId, requestParameters.isVisible, requestParameters.isDraft, requestParameters.tags, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {SectionPageApiApiV1SectionPagesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesIdDelete(requestParameters: SectionPageApiApiV1SectionPagesIdDeleteRequest, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {SectionPageApiApiV1SectionPagesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesIdGet(requestParameters: SectionPageApiApiV1SectionPagesIdGetRequest, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {SectionPageApiApiV1SectionPagesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesIdPatch(requestParameters: SectionPageApiApiV1SectionPagesIdPatchRequest, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current users pages
     * @param {SectionPageApiApiV1SectionPagesMyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesMyGet(requestParameters: SectionPageApiApiV1SectionPagesMyGetRequest = {}, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesMyGet(requestParameters.count, requestParameters.offset, requestParameters.isManager, requestParameters.isAuthor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {SectionPageApiApiV1SectionPagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesPost(requestParameters: SectionPageApiApiV1SectionPagesPostRequest = {}, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesPost(requestParameters.sectionPageUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {SectionPageApiApiV1SectionPagesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesPut(requestParameters: SectionPageApiApiV1SectionPagesPutRequest = {}, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesPut(requestParameters.sectionPageUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get section page tree
     * @param {SectionPageApiApiV1SectionPagesTreeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesTreeGet(requestParameters: SectionPageApiApiV1SectionPagesTreeGetRequest = {}, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesTreeGet(requestParameters.parentSectionPageId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update visibility for tree node and its childs
     * @param {SectionPageApiApiV1SectionPagesTreeVisiblePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesTreeVisiblePost(requestParameters: SectionPageApiApiV1SectionPagesTreeVisiblePostRequest = {}, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesTreeVisiblePost(requestParameters.updateTreeVisibleRequestFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update visible Tuilife section pages
     * @param {SectionPageApiApiV1SectionPagesVisiblePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionPageApi
     */
    public apiV1SectionPagesVisiblePut(requestParameters: SectionPageApiApiV1SectionPagesVisiblePutRequest = {}, options?: AxiosRequestConfig) {
        return SectionPageApiFp(this.configuration).apiV1SectionPagesVisiblePut(requestParameters.updateVisibilitySectionPagesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
