import { VFC } from 'react';

import {
  Attachment,
  SentimentSatisfied,
} from '@styled-icons/material-outlined';

import { useTranslation } from 'services/i18n';
import { Dropdown, IconButton } from 'ui';

import { EmojiPicker } from '../EmojiPicker';
import { GeoPicker } from '../GeoPicker';
import {
  FormToolbarWrapper,
  HiddenInput,
  ToolbarMenu,
  ToolbarMenuItem,
} from './styles';
import { FormToolbarProps } from './types';

export const FormToolbar: VFC<FormToolbarProps> = ({
  handleMediaContentUpload,
  handleFileUpload,
  handleEmojiClick,
  handlePollCreate,
  setGeoPickerPos,
  geoPicker,
  hasForm,
}) => {
  const { t } = useTranslation('common');

  const menu = (
    <ToolbarMenu>
      <ToolbarMenuItem>
        <label htmlFor="imageUpload">
          <HiddenInput
            accept="image/*"
            id="imageUpload"
            type="file"
            onChange={handleMediaContentUpload}
          />
          <span> {t('photo')} </span>
        </label>
      </ToolbarMenuItem>

      <ToolbarMenuItem>
        <label htmlFor="videoUpload">
          <HiddenInput
            accept="video/*"
            id="videoUpload"
            type="file"
            onChange={handleMediaContentUpload}
          />
          <span> {t('video')} </span>
        </label>
      </ToolbarMenuItem>

      <ToolbarMenuItem>
        <label htmlFor="fileUpload">
          <HiddenInput
            id="fileUpload"
            type="file"
            onChange={handleFileUpload}
          />
          <span> {t('document')} </span>
        </label>
      </ToolbarMenuItem>
      {!hasForm && (
        <ToolbarMenuItem onClick={handlePollCreate}>
          <span> {t('poll')} </span>
        </ToolbarMenuItem>
      )}
    </ToolbarMenu>
  );

  return (
    <FormToolbarWrapper>
      <Dropdown overlay={menu} placement="bottomRight">
        <IconButton>
          <Attachment size={22} />
        </IconButton>
      </Dropdown>

      {geoPicker && setGeoPickerPos && (
        <GeoPicker setGeoPickerPos={setGeoPickerPos} />
      )}

      <Dropdown
        overlay={<EmojiPicker onEmojiClick={handleEmojiClick} />}
        placement="bottomRight"
      >
        <IconButton>
          <SentimentSatisfied size={22} />
        </IconButton>
      </Dropdown>
    </FormToolbarWrapper>
  );
};
