/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { LinkDtoSearchResult } from '../models';
// @ts-ignore
import { LinkType } from '../models';
// @ts-ignore
import { UpdateUserVisibleLinksBody } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * UserLinkApi - axios parameter creator
 * @export
 */
export const UserLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get visible links
         * @param {number} userId Фильтр по пользователю
         * @param {LinkType} [linkType] Фильтр по типу ссылки
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLinksVisibleGet: async (userId: number, linkType?: LinkType, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdLinksVisibleGet', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/links/visible`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (linkType !== undefined) {
                localVarQueryParameter['LinkType'] = linkType;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update visible links
         * @param {number} userId 
         * @param {UpdateUserVisibleLinksBody} [updateUserVisibleLinksBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLinksVisiblePut: async (userId: number, updateUserVisibleLinksBody?: UpdateUserVisibleLinksBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdLinksVisiblePut', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/links/visible`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserVisibleLinksBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserLinkApi - functional programming interface
 * @export
 */
export const UserLinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserLinkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get visible links
         * @param {number} userId Фильтр по пользователю
         * @param {LinkType} [linkType] Фильтр по типу ссылки
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdLinksVisibleGet(userId: number, linkType?: LinkType, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdLinksVisibleGet(userId, linkType, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update visible links
         * @param {number} userId 
         * @param {UpdateUserVisibleLinksBody} [updateUserVisibleLinksBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdLinksVisiblePut(userId: number, updateUserVisibleLinksBody?: UpdateUserVisibleLinksBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdLinksVisiblePut(userId, updateUserVisibleLinksBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserLinkApi - factory interface
 * @export
 */
export const UserLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserLinkApiFp(configuration)
    return {
        /**
         * 
         * @summary Get visible links
         * @param {number} userId Фильтр по пользователю
         * @param {LinkType} [linkType] Фильтр по типу ссылки
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLinksVisibleGet(userId: number, linkType?: LinkType, count?: number, offset?: number, options?: any): AxiosPromise<LinkDtoSearchResult> {
            return localVarFp.apiV1UsersUserIdLinksVisibleGet(userId, linkType, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update visible links
         * @param {number} userId 
         * @param {UpdateUserVisibleLinksBody} [updateUserVisibleLinksBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdLinksVisiblePut(userId: number, updateUserVisibleLinksBody?: UpdateUserVisibleLinksBody, options?: any): AxiosPromise<LinkDtoSearchResult> {
            return localVarFp.apiV1UsersUserIdLinksVisiblePut(userId, updateUserVisibleLinksBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UsersUserIdLinksVisibleGet operation in UserLinkApi.
 * @export
 * @interface UserLinkApiApiV1UsersUserIdLinksVisibleGetRequest
 */
export interface UserLinkApiApiV1UsersUserIdLinksVisibleGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof UserLinkApiApiV1UsersUserIdLinksVisibleGet
     */
    readonly userId: number

    /**
     * Фильтр по типу ссылки
     * @type {LinkType}
     * @memberof UserLinkApiApiV1UsersUserIdLinksVisibleGet
     */
    readonly linkType?: LinkType

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserLinkApiApiV1UsersUserIdLinksVisibleGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserLinkApiApiV1UsersUserIdLinksVisibleGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UsersUserIdLinksVisiblePut operation in UserLinkApi.
 * @export
 * @interface UserLinkApiApiV1UsersUserIdLinksVisiblePutRequest
 */
export interface UserLinkApiApiV1UsersUserIdLinksVisiblePutRequest {
    /**
     * 
     * @type {number}
     * @memberof UserLinkApiApiV1UsersUserIdLinksVisiblePut
     */
    readonly userId: number

    /**
     * 
     * @type {UpdateUserVisibleLinksBody}
     * @memberof UserLinkApiApiV1UsersUserIdLinksVisiblePut
     */
    readonly updateUserVisibleLinksBody?: UpdateUserVisibleLinksBody
}

/**
 * UserLinkApi - object-oriented interface
 * @export
 * @class UserLinkApi
 * @extends {BaseAPI}
 */
export class UserLinkApi extends BaseAPI {
    /**
     * 
     * @summary Get visible links
     * @param {UserLinkApiApiV1UsersUserIdLinksVisibleGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLinkApi
     */
    public apiV1UsersUserIdLinksVisibleGet(requestParameters: UserLinkApiApiV1UsersUserIdLinksVisibleGetRequest, options?: AxiosRequestConfig) {
        return UserLinkApiFp(this.configuration).apiV1UsersUserIdLinksVisibleGet(requestParameters.userId, requestParameters.linkType, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update visible links
     * @param {UserLinkApiApiV1UsersUserIdLinksVisiblePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLinkApi
     */
    public apiV1UsersUserIdLinksVisiblePut(requestParameters: UserLinkApiApiV1UsersUserIdLinksVisiblePutRequest, options?: AxiosRequestConfig) {
        return UserLinkApiFp(this.configuration).apiV1UsersUserIdLinksVisiblePut(requestParameters.userId, requestParameters.updateUserVisibleLinksBody, options).then((request) => request(this.axios, this.basePath));
    }
}
