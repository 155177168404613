import React, { useMemo, useState } from 'react';

import { Clear as ClearIcon } from '@styled-icons/material-outlined/Clear';
import { Search as SearchIcon } from '@styled-icons/material-outlined/Search';

import { IconButton } from '../IconButton';
import { useControlled } from '../utils/useControlled';
import {
  StyledAdornment,
  StyledInput,
  StyledInputRoot,
  StyledInputWrapper,
} from './styles';

type InputProps = React.ComponentPropsWithRef<'input'>;

export interface SearchFieldProps {
  inputId: string;
  placeholder?: string;
  value?: InputProps['value'];
  onChange?: InputProps['onChange'];
  onClear?: () => void;
  onFocus?: () => void;
}

export const SearchField = React.forwardRef<HTMLInputElement, SearchFieldProps>(
  (props, ref) => {
    const {
      value: valueProp,
      onChange,
      inputId,
      onFocus,
      onClear,
      ...inputProps
    } = props;

    const [value, setValue] = useControlled({
      controlledValue: valueProp,
      defaultValue: '',
    });

    const [focus, setFocus] = useState(false);
    const [hover, setHover] = useState(false);

    const open = useMemo(() => hover || focus, [hover, focus]);

    const handleToggleHover = () => {
      setHover((prevState) => !prevState);
    };

    const handleToggleFocus = () => {
      setFocus((prevState) => !prevState);
      if (onFocus) onFocus();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      if (onChange) onChange(event);
    };

    const handleClear = () => {
      setValue('');
      if (onClear) onClear();
    };

    return (
      <StyledInputRoot>
        <StyledInputWrapper
          data-open={open}
          onPointerEnter={handleToggleHover}
          onPointerLeave={handleToggleHover}
        >
          <StyledAdornment>
            <SearchIcon size={24} />
          </StyledAdornment>
          <StyledInput
            autoComplete="off"
            id={inputId}
            ref={ref}
            type="text"
            value={value}
            onBlur={handleToggleFocus}
            onChange={handleChange}
            onFocus={handleToggleFocus}
            {...inputProps}
          />
          {Boolean(value) && open && (
            <IconButton onClick={handleClear}>
              <ClearIcon size={18} />
            </IconButton>
          )}
        </StyledInputWrapper>
      </StyledInputRoot>
    );
  },
);
