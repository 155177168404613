import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #edf1fe;

  svg path {
    fill: #4872f2;
  }

  :hover {
    background-color: #4872f2;
  }

  :hover svg path {
    fill: white;
  }
`;
