import { VFC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ExpandMore as ExpandMoreIcon } from '@styled-icons/material-outlined/ExpandMore';

import { ChangeCountryModal } from 'components/Profile/ChangeCountryModal';
import { RoleName } from 'services/api';
import { useAuth } from 'services/auth';
import { useUserCredential } from 'services/userCredential';
import { Avatar, Dropdown, Menu, MenuItem } from 'ui/index';

import { StyledMenuWrapper } from './styles';

export const ProfileMenu: VFC = () => {
  const { t } = useTranslation('common');
  const { logOut } = useAuth();
  const { user } = useUserCredential();
  const navigate = useNavigate();
  const { hasRole } = useUserCredential();
  const [isChangeCountryModalVisible, setChangeCountryModalVisible] =
    useState(false);
  const monogram = `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`;

  const avatarImg =
    user?.avatar?.previews?.find(({ quality }) => quality === 50)?.fileUrl ??
    user?.avatar?.fileUrl;

  const handleItemClick = (to: string) => () => {
    navigate(to);
  };

  const handleChangeCountryModalOpen = () => {
    setChangeCountryModalVisible(true);
  };

  const handleChangeCountryModalClose = () => {
    setChangeCountryModalVisible(false);
  };

  return (
    <>
      {user && (
        <Dropdown
          overlay={
            <Menu>
              <MenuItem key="profile" onClick={handleItemClick('/profile')}>
                {t('my_profile')}
              </MenuItem>
              {hasRole(RoleName.Administrator) && (
                <MenuItem onClick={handleChangeCountryModalOpen}>
                  {t('change_country')}
                </MenuItem>
              )}
              <MenuItem key="logOut" onClick={logOut}>
                {t('log_off')}
              </MenuItem>
            </Menu>
          }
          overlayStyle={{ position: 'fixed' }}
        >
          <StyledMenuWrapper>
            <Avatar size={40} src={avatarImg}>
              {monogram}
            </Avatar>
            <ExpandMoreIcon size={24} />
          </StyledMenuWrapper>
        </Dropdown>
      )}
      <ChangeCountryModal
        open={isChangeCountryModalVisible}
        onClose={handleChangeCountryModalClose}
      />
    </>
  );
};
