/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * CoinsApi - axios parameter creator
 * @export
 */
export const CoinsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add coins to user
         * @param {number} [userId] 
         * @param {number} [amount] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CoinsPost: async (userId?: number, amount?: number, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/coins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CoinsReportsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/coins/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoinsApi - functional programming interface
 * @export
 */
export const CoinsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoinsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add coins to user
         * @param {number} [userId] 
         * @param {number} [amount] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CoinsPost(userId?: number, amount?: number, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CoinsPost(userId, amount, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CoinsReportsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CoinsReportsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoinsApi - factory interface
 * @export
 */
export const CoinsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoinsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add coins to user
         * @param {number} [userId] 
         * @param {number} [amount] 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CoinsPost(userId?: number, amount?: number, comment?: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1CoinsPost(userId, amount, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CoinsReportsGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1CoinsReportsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1CoinsPost operation in CoinsApi.
 * @export
 * @interface CoinsApiApiV1CoinsPostRequest
 */
export interface CoinsApiApiV1CoinsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof CoinsApiApiV1CoinsPost
     */
    readonly userId?: number

    /**
     * 
     * @type {number}
     * @memberof CoinsApiApiV1CoinsPost
     */
    readonly amount?: number

    /**
     * 
     * @type {string}
     * @memberof CoinsApiApiV1CoinsPost
     */
    readonly comment?: string
}

/**
 * CoinsApi - object-oriented interface
 * @export
 * @class CoinsApi
 * @extends {BaseAPI}
 */
export class CoinsApi extends BaseAPI {
    /**
     * 
     * @summary Add coins to user
     * @param {CoinsApiApiV1CoinsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinsApi
     */
    public apiV1CoinsPost(requestParameters: CoinsApiApiV1CoinsPostRequest = {}, options?: AxiosRequestConfig) {
        return CoinsApiFp(this.configuration).apiV1CoinsPost(requestParameters.userId, requestParameters.amount, requestParameters.comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinsApi
     */
    public apiV1CoinsReportsGet(options?: AxiosRequestConfig) {
        return CoinsApiFp(this.configuration).apiV1CoinsReportsGet(options).then((request) => request(this.axios, this.basePath));
    }
}
