import { VFC } from 'react';

import parse from 'html-react-parser';

import {
  CardPreviewText,
  CardPreviewTextWrapper,
  CardPreviewWrapper,
} from 'components/CongratulationModal/styles';
import { StyledGiftcard } from 'components/GiftcardsGrid/Giftcard/styles';

export type PostGiftContentProps = {
  giftImage: string;
  text: string;
};

export const PostGiftContent: VFC<PostGiftContentProps> = ({
  giftImage,
  text,
}) => {
  return (
    <>
      <CardPreviewWrapper>
        {text && (
          <CardPreviewTextWrapper>
            <CardPreviewText>{parse(text)}</CardPreviewText>
          </CardPreviewTextWrapper>
        )}
        <StyledGiftcard>
          <img
            alt="giftcard"
            src={giftImage}
            style={{
              height: 320,
              width: 632,
            }}
          />
        </StyledGiftcard>
      </CardPreviewWrapper>
    </>
  );
};
