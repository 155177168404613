import { VFC } from 'react';

import { FileLoadFailedRoot } from './styles';

export const FileLoadFailed: VFC = () => (
  <FileLoadFailedRoot>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#E73C3E" height="24" rx="12" width="24" />
      <path
        d="M12.0002 5.3335C8.3135 5.3335 5.3335 8.3135 5.3335 12.0002C5.3335 15.6868 8.3135 18.6668 12.0002 18.6668C15.6868 18.6668 18.6668 15.6868 18.6668 12.0002C18.6668 8.3135 15.6868 5.3335 12.0002 5.3335ZM12.0002 17.3335C9.06016 17.3335 6.66683 14.9402 6.66683 12.0002C6.66683 9.06016 9.06016 6.66683 12.0002 6.66683C14.9402 6.66683 17.3335 9.06016 17.3335 12.0002C17.3335 14.9402 14.9402 17.3335 12.0002 17.3335ZM14.3935 8.66683L12.0002 11.0602L9.60683 8.66683L8.66683 9.60683L11.0602 12.0002L8.66683 14.3935L9.60683 15.3335L12.0002 12.9402L14.3935 15.3335L15.3335 14.3935L12.9402 12.0002L15.3335 9.60683L14.3935 8.66683Z"
        fill="white"
      />
    </svg>
    <span>Ошибка</span>
  </FileLoadFailedRoot>
);
