import { Link } from 'react-router-dom';

import {
  Cake as IconCake,
  Category as IconCategory,
  Groups as IconGroups,
  PersonAddAlt as IconPersonAddAlt,
  ThumbUpOffAlt as IconThumbUpOffAlt,
} from '@styled-icons/material-outlined';
import styled, { css } from 'styled-components';

import { Menu, MenuItem } from 'ui';

export const NavMenuItem = styled(MenuItem)`
  &.ant-menu-item-selected {
    border-right: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #edf1fe !important;
  }

  &.ant-menu-item-selected a {
    color: #4872f2 !important;
  }
  &.ant-menu-item a:hover {
    color: #4872f2 !important;
  }

  &.ant-menu-item::after {
    opacity: 0 !important;
  }
`;

export const NavMenu = styled(Menu)`
  padding-right: 24px;
  border-right: white;
`;

export const NavLink = styled(Link)`
  display: flex;
  align-content: center;
  align-items: center;
`;

const iconStyles = css`
  margin-right: 12px;
  color: #4872f2;
`;

export const Cake = styled(IconCake)`
  ${iconStyles}
`;

export const Category = styled(IconCategory)`
  ${iconStyles}
`;

export const Groups = styled(IconGroups)`
  ${iconStyles}
`;

export const PersonAddAlt = styled(IconPersonAddAlt)`
  ${iconStyles}
`;

export const ThumbUpOffAlt = styled(IconThumbUpOffAlt)`
  ${iconStyles}
`;
