import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { UserItemProps } from 'components/UserCardItem/types';

export const SkeletonContainer = styled.div`
  padding: 16px;
  margin-bottom: 12px;
`;

export const ItemAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const ItemData = styled.div`
  flex-grow: 1;
  padding: 0 16px;
`;

export const NameData = styled.div`
  font-weight: 600;
`;

export const ItemRole = styled.div`
  color: #7e8389;
`;

export const ItemAction = styled.div`
  flex-shrink: 0;
`;

export const ItemProfileLink = styled(Link)`
  color: inherit;
  &:hover {
    color: #386aff;
  }
`;

export const itemSizeStyles = {
  small: css`
    padding: 8px 12px;
  `,
  default: css`
    padding: 16px;
  `,
};

export const ItemContainer = styled.div<Partial<UserItemProps>>`
  background-color: ${(props) => (props.inverseColor ? '#2e2e32' : '#f6f8fa')};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  ${({ size = 'default' }) => itemSizeStyles[size]}

  ${({ inverseColor }) =>
    inverseColor &&
    css`
      ${ItemRole} {
        color: #dbe0e5;
      }
      ${NameData} {
        color: #fff;
      }
    `}
`;
