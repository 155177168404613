/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CourseType } from '../models';
// @ts-ignore
import { CoursesModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { UserCoursesModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * CourseApi - axios parameter creator
 * @export
 */
export const CourseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {CourseType} [courseType] Фильтр по типу курса
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CoursesGet: async (courseType?: CourseType, userId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/courses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (courseType !== undefined) {
                localVarQueryParameter['CourseType'] = courseType;
            }

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [courseId] Фильтр по курсу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CoursesUsersGet: async (userId?: number, courseId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/courses/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['CourseId'] = courseId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseApi - functional programming interface
 * @export
 */
export const CourseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {CourseType} [courseType] Фильтр по типу курса
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CoursesGet(courseType?: CourseType, userId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CoursesGet(courseType, userId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [courseId] Фильтр по курсу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CoursesUsersGet(userId?: number, courseId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCoursesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CoursesUsersGet(userId, courseId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CourseApi - factory interface
 * @export
 */
export const CourseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {CourseType} [courseType] Фильтр по типу курса
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CoursesGet(courseType?: CourseType, userId?: number, count?: number, offset?: number, options?: any): AxiosPromise<CoursesModel> {
            return localVarFp.apiV1CoursesGet(courseType, userId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [courseId] Фильтр по курсу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CoursesUsersGet(userId?: number, courseId?: number, count?: number, offset?: number, options?: any): AxiosPromise<UserCoursesModel> {
            return localVarFp.apiV1CoursesUsersGet(userId, courseId, count, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1CoursesGet operation in CourseApi.
 * @export
 * @interface CourseApiApiV1CoursesGetRequest
 */
export interface CourseApiApiV1CoursesGetRequest {
    /**
     * Фильтр по типу курса
     * @type {CourseType}
     * @memberof CourseApiApiV1CoursesGet
     */
    readonly courseType?: CourseType

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof CourseApiApiV1CoursesGet
     */
    readonly userId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof CourseApiApiV1CoursesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof CourseApiApiV1CoursesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1CoursesUsersGet operation in CourseApi.
 * @export
 * @interface CourseApiApiV1CoursesUsersGetRequest
 */
export interface CourseApiApiV1CoursesUsersGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof CourseApiApiV1CoursesUsersGet
     */
    readonly userId?: number

    /**
     * Фильтр по курсу
     * @type {number}
     * @memberof CourseApiApiV1CoursesUsersGet
     */
    readonly courseId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof CourseApiApiV1CoursesUsersGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof CourseApiApiV1CoursesUsersGet
     */
    readonly offset?: number
}

/**
 * CourseApi - object-oriented interface
 * @export
 * @class CourseApi
 * @extends {BaseAPI}
 */
export class CourseApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {CourseApiApiV1CoursesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiV1CoursesGet(requestParameters: CourseApiApiV1CoursesGetRequest = {}, options?: AxiosRequestConfig) {
        return CourseApiFp(this.configuration).apiV1CoursesGet(requestParameters.courseType, requestParameters.userId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user models by filter
     * @param {CourseApiApiV1CoursesUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiV1CoursesUsersGet(requestParameters: CourseApiApiV1CoursesUsersGetRequest = {}, options?: AxiosRequestConfig) {
        return CourseApiFp(this.configuration).apiV1CoursesUsersGet(requestParameters.userId, requestParameters.courseId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}
