import styled from 'styled-components';

export const PostHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ReplyPostWrapper = styled.div`
  width: 93%;
  border-left: 4px solid #eff2f6;
  border-radius: 5px;
`;

export const ReplyPost = styled.div`
  margin-left: 16px;
`;
