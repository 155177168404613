import styled from 'styled-components';

import { IconButton } from 'ui';

export const MediaContentRoot = styled.div`
  display: flex;
  height: 768px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background: #2e2e32;
`;

export const MediaContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  & > img,
  video {
    max-width: 100%;
    max-height: 720px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 0 0 40px;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 14px;
`;

export const SlideButton = styled(IconButton)`
  &:hover {
    color: white;
  }
`;
