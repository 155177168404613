import axios from 'axios';

export const handleClickDownload = async (
  fileUrl?: string | null,
  fileName?: string | null,
) => {
  if (!fileUrl || !fileName) return;

  const { data: file } = await axios.get<Blob>(fileUrl, {
    responseType: 'blob',
  });

  const href = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(href);
};

export const classifyFileType = (fileUpload: { type: string }) => {
  return fileUpload.type.split('/')[0];
};

const getUrlExtension = (url: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return url?.split(/[#?]/)[0].split('.').pop().trim();
};

export const getBlobFile = async (fileUrl?: string | null) => {
  const fileExt = getUrlExtension(String(fileUrl));
  const { data: blob } = await axios.get<Blob>(String(fileUrl), {
    responseType: 'blob',
  });
  return new File([blob], `${new Date().toISOString()}.${fileExt}`, {
    type: blob.type,
  });
};
