import { VFC } from 'react';

import { VideoCoverRoot } from './styles';

export const PlayButtonIcon = () => (
  <svg
    fill="none"
    height="36"
    viewBox="0 0 37 36"
    width="37"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18.6667" cy="18" fill="white" fillOpacity="0.35" r="18" />
    <path
      d="M13.6667 23.2983V12.647C13.6667 11.8967 14.4623 11.4136 15.128 11.7597L25.0185 16.9017C25.7252 17.2691 25.7397 18.275 25.0439 18.6626L15.1534 24.1719C14.4868 24.5432 13.6667 24.0613 13.6667 23.2983Z"
      fill="white"
    />
  </svg>
);

export const VideoCover: VFC = () => (
  <VideoCoverRoot>
    <PlayButtonIcon />
  </VideoCoverRoot>
);
