import styled from 'styled-components';

import { IconButton, ListItem } from 'ui';

export const DocumentsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 56px;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const FilesListItem = styled(ListItem)`
  & > a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 170px;
  }
  & > span:last-of-type {
    margin-left: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  & > svg {
    color: ${({ theme }) => theme.palette.primary.main};
    margin-right: 8px;
  }
  .ant-list-item-meta-title {
    width: 100%;
  }
  width: 100%;
  padding: 8px !important;
  display: flex !important;
  overflow: hidden !important;
  flex-direction: row !important;
  border-radius: 8px;
  background-color: #f6f8fa;
  :hover {
    background-color: #edf1fe;
  }
`;

export const DeleteButton = styled(IconButton)`
  justify-self: end;
  height: 24px;
  width: 24px;
  border-radius: 20px;
  background-color: white;
  box-shadow: black;
  flex-shrink: 0;
`;
