/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum AddressComponentKind {
    None = 0,
    House = 1,
    Street = 2,
    Metro = 3,
    District = 4,
    Locality = 5,
    Area = 6,
    Province = 7,
    Country = 8,
    Hydro = 9,
    Railway = 10,
    Route = 11,
    Vegetation = 12,
    Airport = 13,
    Other = 14
}


