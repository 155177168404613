import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { formatRelative, parseISO } from 'date-fns';

import { getPhotoPreview } from 'domain/photo';
import { getUserCountryShort } from 'domain/user';
import { UserNotificationPreviewModel } from 'services/api';
import { useDateFnsLocale, useTranslation } from 'services/i18n';
import { Avatar, GeoBadge, ListItem } from 'ui/index';

import {
  PushNotificationViewerWrapper,
  PushViewerItemMeta,
  PushViewerLink,
} from '../styles';

export interface PushNotificationViewerProps {
  viewer: UserNotificationPreviewModel;
}

export const PushNotificationViewer: FC<PushNotificationViewerProps> = ({
  viewer,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { locale } = useDateFnsLocale();

  return (
    <>
      {viewer.toUser && (
        <PushNotificationViewerWrapper>
          <ListItem style={{ padding: 10 }}>
            <PushViewerItemMeta
              avatar={
                <GeoBadge
                  locale={getUserCountryShort(viewer.toUser)}
                  offset={[-8, 32]}
                  size={16}
                  style={{ border: '2px solid #fff' }}
                >
                  <Avatar
                    size={40}
                    src={
                      viewer.toUser.avatar
                        ? getPhotoPreview(viewer.toUser.avatar)
                        : undefined
                    }
                  />
                </GeoBadge>
              }
              description={
                viewer.readDate
                  ? formatRelative(
                      parseISO(viewer.readDate as string),
                      new Date(),
                      { locale },
                    )
                  : t('push_not_read')
              }
              title={
                <PushViewerLink
                  onClick={() => navigate(`/profile/${viewer.toUser?.userId}`)}
                >
                  {viewer.toUser.name}
                </PushViewerLink>
              }
            />
          </ListItem>
        </PushNotificationViewerWrapper>
      )}
    </>
  );
};
