import { FC, ReactNode } from 'react';

import { LinkProps } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { NavLink } from 'ui/NavLink';

export type ColorLinkType = 'red' | 'yellow' | 'green' | 'blue';

interface ColoredLinkProps extends LinkProps {
  title: string;
  color: ColorLinkType;
  icon?: ReactNode;
}

const mapColors = {
  red: {
    main: css`
      color: #e73c3e;
      background-color: #fdecec;
    `,
    hover: css`
      color: #fff;
      background-color: #e73c3e;
    `,
  },
  yellow: {
    main: css`
      color: #ffcd00;
      background-color: #fffce6;
    `,
    hover: css`
      color: #fff;
      background-color: #ffcd00;
    `,
  },
  green: {
    main: css`
      color: #49c8c0;
      background-color: #edfaf9;
    `,
    hover: css`
      color: #fff;
      background-color: #49c8c0;
    `,
  },
  blue: {
    main: css`
      color: #4872f2;
      background-color: #edf1fe;
    `,
    hover: css`
      color: #fff;
      background-color: #4872f2;
    `,
  },
};

const StyledColoredButton = styled(NavLink)<ColoredLinkProps>`
  & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    width: 100%;
    height: 69px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    ${({ color = 'red' }) => mapColors[color as ColorLinkType].main}
  }
  &:hover {
    ${({ color = 'red' }) => mapColors[color as ColorLinkType].hover}
  }
  & span {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ColoredButton: FC<ColoredLinkProps> = ({
  to,
  color,
  title,
  icon,
  ...props
}) => {
  return (
    <StyledColoredButton color={color} title={title} to={to} {...props}>
      {icon}
      <span>{title}</span>
    </StyledColoredButton>
  );
};
