import { Empty } from 'antd';
import styled from 'styled-components';

import emptyImg from './empty.svg';

export const EmptyBox = styled(Empty).attrs((props) => ({
  imageStyle: props.imageStyle ?? {
    height: 36,
  },
  image: props.image ?? emptyImg,
}))`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyBoxBig = styled(Empty).attrs((props) => ({
  imageStyle: props.imageStyle ?? {
    height: 208,
  },
}))`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
