import {
  AppendNodes,
  CutHTMLArgs,
  CutNode,
  DEF_FONT_PARAMS,
  GetFilledInHeight,
  MeasurerAttrs,
} from './types';

export const trimRight = (str: string, includePuctuation = false) =>
  includePuctuation
    ? str.replace(/[\s\u2800.,;:!?]+$/, '')
    : str.replace(/[\s\u2800]+$/, '');

export const trimLeft = (str: string, includePuctuation = false) =>
  includePuctuation
    ? str.replace(/^[\s\u2800.,;:!?]+/, '')
    : str.replace(/^[\s\u2800]+/, '');

export const trim = (str: string, includePuctuation = false) =>
  includePuctuation
    ? str.replace(/^[\s\u2800.,;:!?]+|[\s\u2800.,;:!?]+$/, '')
    : str.replace(/^[\s\u2800]+|[\s\u2800]+$/, '');

export const clone = (node: HTMLElement | Text) => {
  if (node.nodeType === 1) return node.cloneNode(true) as HTMLElement;
  return node.cloneNode() as Text;
};

export const appendNodes: AppendNodes = (
  container,
  elements,
  enhancer = (e) => e,
) => {
  elements.forEach((el) => {
    container.appendChild(enhancer(el));
  });
};

export const removeChilds = (node: HTMLElement) => {
  while (node.childNodes.length > 0) {
    node.removeChild(node.childNodes.item(0));
  }
};

/**
 * Функция преобразует лист узлов NodeListOf в массив DOM-узлов,
 * отфильтровывая только теги и текстовые узлы.
 * @param nodeList
 * @returns возвращает массив элементов тегов и текстовых узлов
 */
export const nodeListToElements = (nodeList: NodeListOf<Node>) => {
  return Array.from(nodeList)
    .filter((node) => node.nodeType === 1 || node.nodeType === 3)
    .map((node) => {
      if (node.nodeType === 1) return node as HTMLElement;
      return node as Text;
    });
};

/**
 * Функция преобразует HTML-разметку в массив DOM-узлов.
 * Работает аналогично одноименной функции из html-react-parser,
 * но отфильтровывает только теги и текстовые узлы.
 * @param html
 * @returns возвращает массив элементов тегов и текстовых узлов
 */
export const htmlToDOM = (html = '') => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return nodeListToElements(div.childNodes);
};

export const domToHTML = (nodes: (HTMLElement | Text)[]) => {
  const div = document.createElement('div');
  appendNodes(div, nodes);
  return div.innerHTML;
};

/**
 * Функция преобразует HTML разметку в массив DOM-узлов, отфильтровывая
 * при этом узлы не являющиеся тегами или текстовыми узлами
 * (комментарии, инструкции и проч.)
 *
 * @param html
 * @returns возвращает отфильтрованный массив DOM-узлов, полученный
 * из переданной разметки
 */
export const getNodesFromHTMLMarkup = (html: string) => {
  return htmlToDOM(html)
    .filter((n) => n.nodeType === 1 || n.nodeType === 3)
    .map((n) => {
      if (n.nodeType === 1) return n as unknown as HTMLElement;
      return n as unknown as Text;
    });
};

/**
 * Функция фильтрации массива DOM узлов или объекта NodeListOf
 * от пустых элементов и пустых строк. Также отфильтровываются из
 * объекта NodeListOf все элементы, не являющиеся тегами или
 * текстовыми узлами (комментарии, процессинговые инструкции и проч.).
 *
 * @param nodes массив DOM узлов или объект NodeListOf
 * @returns отфильтрованный массив DOM узлов
 */
export const clearEmptyNodes = (
  nodes: (HTMLElement | Text)[] | NodeListOf<ChildNode>,
): (HTMLElement | Text)[] => {
  const result: (HTMLElement | Text)[] = [];
  Array.from(nodes).forEach((n) => {
    // если узел является тегом
    if (n.nodeType === 1) {
      // то у него рекурсивно удаляются пустые потомки
      const children = clearEmptyNodes(n.childNodes);
      // и если после удаления остались не пустые узлы
      if (children.length > 0) {
        // то текущий узел клонируется
        const cloned = clone(n as HTMLElement) as HTMLElement;
        // старые его потомки заменяются отфильтрованными
        removeChilds(cloned);
        appendNodes(cloned, children);
        // и клон заносится в итоговый массив
        result.push(cloned);
      }
    }
    // если узел является текстовым узлом
    else if (n.nodeType === 3) {
      // получаем его текст
      const text = String((n as Text).textContent);
      // и если он не пустой, то в итоговый массив заносится
      // клон текущего текстового узла
      if (trim(text).length > 0) result.push(clone(n as Text));
    }
    // остальные типы узлов игнорируются
  });
  return result;
};

/**
 * Функция вычисляет высоту, которую занимает контент при указанной
 * ширине контейнера. Функция принимает на вход DOM-узел (тег или текстовый),
 * массив DOM-узлов или HTML-разметку, которая преобразуется в массив DOM-узлов.
 * Также функция принимает ширину контейнера, необходимый параметр
 * для вычисления высоты содержимого.
 * @param data
 * @param data.node
 * @param data.nodes
 * @param data.markup
 * @param data.expectingWidth
 * @param data.enhancer декоратор, применяемый к каждому из узлов
 * @param data.clearEmpty опциональный флаг, определяющий необходимость удаления пустых узлов
 * @returns возвращает объект с шириной и высотой контенера с указанным содержимым
 * и массивом DOM-узлов содержимого
 */
export const measurer: MeasurerAttrs = ({
  node,
  nodes,
  markup,
  expectingWidth,
  enhancer = (e) => e,
  clearEmpty,
  fontParams = {},
}) => {
  const fp = { ...DEF_FONT_PARAMS, ...fontParams };
  // создается невидимый контейнер с абсолютным позиционированием
  const container = document.createElement('div');
  container.style.display = 'inline-block';
  container.style.position = 'absolute';
  container.style.visibility = 'hidden';
  container.style.zIndex = '-1';
  container.style.fontSize = String(fp.fontSize);
  container.style.lineHeight = String(fp.lineHeight);
  container.style.fontWeight = String(fp.fontWeight);
  // опционально задается его ширина
  if (expectingWidth) {
    container.style.width = `${expectingWidth}px`;
  }

  // массив узлов (элементов и текстов)
  let elements: (HTMLElement | Text)[] = [];
  // узлы могут быть извлечены из разметки
  if (markup) elements = getNodesFromHTMLMarkup(markup);
  // передан может быть единственный узел
  else if (node) elements = [clone(node)];
  // или массив узлов
  else if (nodes) elements = nodes.map((n) => clone(n));

  if (clearEmpty === true) {
    elements = clearEmptyNodes(elements);
  }

  // полученный массив добавляется в контейнер
  appendNodes(container, elements, enhancer);

  // контейнер добавляется к узлу document.body
  document.body.appendChild(container);

  // считываются его размеры
  const height = container.clientHeight;
  const width = container.clientWidth;

  // после чего контейнер удаляется из разметки страницы
  document.body.removeChild(container);

  return { height, width, elements };
};

export const fillInHeight: GetFilledInHeight = ({
  nodes,
  expectingHeight,
  expectingWidth,
  fontParams,
}) => {
  const fullHeight = measurer({
    nodes,
    expectingWidth,
    clearEmpty: true,
    fontParams,
  }).height;
  let cutHeight = fullHeight;
  let last: HTMLElement | Text | null = null;
  while (nodes.length && cutHeight > expectingHeight) {
    last = nodes.pop() as HTMLElement | Text;
    cutHeight = measurer({
      nodes,
      expectingWidth,
      clearEmpty: true,
      fontParams,
    }).height;
  }
  return {
    nodes,
    last,
  };
};

/**
 *
 * @param data
 * @param data.node
 * @param data.count
 * @param data.expectingWidth
 * @returns
 */
export const cutNode: CutNode = ({
  node,
  count = 1,
  expectingWidth,
  fontParams,
}) => {
  // инициализация счетчика
  let counter = count;
  // если узел является текстовым узлом
  if (node.nodeType === 3) {
    // то получаем его текст
    let text = (node as Text).textContent;
    // и обрезаем его по одному "слову" с конца
    while (text && text.length && counter > 0) {
      text = text.replace(/[\s\u2800]*[^\s\u2800]+[\s\u2800]*$/, '');
      counter -= 1;
    }
    // если текст еще остался после обрезки
    if (text && text.length) {
      // то меняем текст текстового узла
      // eslint-disable-next-line no-param-reassign
      (node as Text).textContent = text;
      // если в вызов функции был передан параметр expectingWidth
      // то вычисляется высота обрезанного элемента
      const height = expectingWidth
        ? measurer({ node, expectingWidth, fontParams }).height
        : undefined;
      // и возвращаем текстовый узел вместе
      // с оставшимся счетчиком (если текст
      // закончился раньше)
      return { node, rest: counter, height };
    }
  }
  // если узел является тегом
  else if (node.nodeType === 1) {
    // то рекурсивно вызываем функцию cutNode для каждого
    // последнего потомка узла пока есть узлы
    // или не кончится счетчик
    while (node.childNodes.length && counter) {
      // дочерний узел извлекается из дерева потомков
      // и передается в рекурсивный вызов вместе
      // с текущим счетчиком
      const cutResult = cutNode({
        node: node.removeChild(node.lastChild as Node) as HTMLElement | Text,
        count: counter,
      });
      // счетчик обновляется из результата обрезки дочернего тега
      counter = cutResult.rest;
      // если после обрезки узел вернулся не пустым, то он
      // возвращается в дерево потомков
      if (cutResult.node) {
        node.appendChild(cutResult.node);
      }
    }
    // если после итераций обрезки в дереве потомков узла
    // остаются элементы, то узел возвращается
    if (node.childNodes.length) {
      // если в вызов функции был передан параметр expectingWidth
      // то вычисляется высота обрезанного элемента
      const height = expectingWidth
        ? measurer({ node, expectingWidth, fontParams }).height
        : undefined;
      return { node, rest: counter, height };
    }
  }
  // при всех остальных исходах возвращается значение null для узла
  // и оставшееся значение счетчика
  return { node: null, rest: counter };
};

export const cutHTML: CutHTMLArgs = ({
  html,
  expectingWidth,
  expectingHeight,
  fontParams,
}) => {
  // подсчитываем общую высоту контента без пустых тегов
  // и получаем почищенный массив элементов
  const measureFullResult = measurer({
    markup: html,
    expectingWidth,
    clearEmpty: true,
    fontParams,
  });
  const full = measureFullResult.elements;
  let cutEls: (HTMLElement | Text)[] = [];
  if (measureFullResult.height > expectingHeight) {
    // получаем массив элементов, входящих целиком
    // в котейнер заданной высоты и ширины
    const cut = fillInHeight({
      nodes: full.map((el) => clone(el)),
      expectingHeight,
      expectingWidth,
    });
    // если есть элемент, не вошедший в контейнер
    if (cut.last) {
      const diff =
        expectingHeight -
        measurer({
          nodes: cut.nodes,
          expectingWidth,
          clearEmpty: true,
          fontParams,
        }).height;
      let cutting = cutNode({
        node: clone(cut.last),
        expectingWidth,
      });
      let last = cutting.node;
      while (Number(cutting.height) > diff && last !== null) {
        cutting = cutNode({
          node: last,
          expectingWidth,
        });
        last = cutting.node;
      }
      if (last) {
        cutting = cutNode({
          node: last,
          expectingWidth,
          count: 2,
        });
        last = cutting.node;
      }
      if (last) {
        cut.nodes.push(last as HTMLElement | Text);
      }
    }
    cutEls = cut.nodes;
  }
  return {
    full,
    cut: cutEls,
  };
};
