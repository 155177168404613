import { ComponentPropsWithRef, FC } from 'react';

import { StyledCircle } from './styles';

export const CircleButton: FC<ComponentPropsWithRef<'div'>> = ({
  children,
  ...props
}) => {
  return <StyledCircle {...props}>{children}</StyledCircle>;
};
