import { FC, useCallback, useEffect, useState } from 'react';

import { ExpandMore } from '@styled-icons/material-outlined';
import { Spin } from 'antd';

import { CommentForm } from 'components/CommentForm';
import { CommentView } from 'components/Wall/Comments/CommentView';
import {
  CommentApiApiV1CommentsGetRequest,
  CommentModel,
  api,
} from 'services/api';
import { useTranslation } from 'services/i18n';

import { Reply } from './Reply';
import { MoreButton, SpinContainer, StyledComment } from './styles';
import { CommentProps } from './types';

const REPLIES_INITIAL_COUNT = 2;
const REPLIES_PAGE_SIZE = 10;

export const Comment: FC<CommentProps> = ({
  data,
  postId,
  isReply = false,
  afterDelete,
}) => {
  const { t } = useTranslation('common');

  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replies, setReplies] = useState<CommentModel[]>([]);
  const [total] = useState(data.comments?.total || 0);
  const [loading, setLoading] = useState(false);

  const switchReplyForm = useCallback(() => {
    setShowReplyForm(!showReplyForm);
  }, [showReplyForm]);

  const getReplies = async (params: CommentApiApiV1CommentsGetRequest) => {
    try {
      const result = await api.comment.apiV1CommentsGet(params);
      return result.data;
    } catch (error) {
      console.warn(error);
      return null;
    }
  };
  // FIXME: стопудово при каких-то обсоятельствах не будет работать как ожидалось
  useEffect(() => {
    if (total && !isReply) {
      setLoading(true);
      getReplies({
        byCommentId: data.id,
        isRecursive: true,
        count: REPLIES_INITIAL_COUNT,
        offset: 0,
      })
        .then((page) => {
          setReplies(page?.items || []);
        })
        .catch(() => {
          setReplies([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setReplies([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id]);

  const loadMoreReplies = async () => {
    setLoading(true);
    getReplies({
      byCommentId: data.id,
      isRecursive: true,
      count: REPLIES_PAGE_SIZE,
      offset: replies.length,
    })
      .then((page) => {
        setReplies([...replies, ...(page?.items || [])]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const afterReplySubmit = (reply: CommentModel | undefined) => {
    setShowReplyForm(false);
    if (reply) {
      setReplies([reply, ...replies]);
    }
  };

  const afterReplyDelete = (id: number) => {
    const index = replies.findIndex((reply) => reply.id === id);
    if (index !== -1) {
      replies.splice(index, 1);
      setReplies([...replies]);
    }
  };

  if (data.postContent && data.likes) {
    return (
      <>
        <StyledComment>
          <CommentView
            afterDelete={afterDelete}
            data={data}
            handleCommentReply={switchReplyForm}
            isReply={isReply}
            postId={postId}
          />
          {showReplyForm && (
            <div style={{ marginLeft: 50 }}>
              <CommentForm
                isReply
                author={data.fromAuthor}
                postId={postId}
                replyCommentId={data.id as number}
                submitCallback={afterReplySubmit}
              />
            </div>
          )}
          {loading && (
            <SpinContainer>
              <Spin spinning={loading} />
            </SpinContainer>
          )}
        </StyledComment>
        {replies.length > 0 && (
          <div style={{ marginLeft: 50 }}>
            {replies.map((entry) => (
              <Reply
                key={`reply-${entry.id}`}
                afterDelete={afterReplyDelete}
                afterSubmit={afterReplySubmit}
                data={entry}
                postId={postId}
              />
            ))}
            {replies.length < Number(data.comments?.total) && !isReply && (
              <MoreButton onClick={loadMoreReplies}>
                {t('show_more_answers', {
                  count: Math.min(total - replies.length, REPLIES_PAGE_SIZE),
                })}
                <ExpandMore size={14} />
              </MoreButton>
            )}
          </div>
        )}
      </>
    );
  }
  return <></>;
};
