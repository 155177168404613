import { getPostDescription } from 'domain/post';
import { BlockType, OpenGraphItemModel, api } from 'services/api';

export interface OpenGraphService {
  getOpenGraph: (url: string) => Promise<OpenGraphItemModel | undefined>;
}

export const useOpenGraphService = (): OpenGraphService => {
  const getSectionPageOpenGraph = async (id: number, url: string) => {
    const { data } = await api.sectionPage.apiV1SectionPagesIdGet({
      id,
    });
    if (!data) return undefined;

    const og = {
      title: data.title,
      url,
      originalUrl: url,
    } as OpenGraphItemModel;
    // old pages
    if (data.content) {
      const description = getPostDescription(data.content);
      const imgRegexp = /<img.*?src="(?<src>.*?)"[^>]+>/g;
      const imageSrc = imgRegexp.exec(data.content)?.groups?.src;

      og.description = description;
      og.imageUrl = imageSrc;
    } else if (data.blocks && data.blocks?.length > 0) {
      const descriptionBlock = data.blocks?.find(
        (x) => x.blockType === BlockType.Paragraph,
      );
      const imageBlock = data.blocks?.find(
        (x) => x.blockType === BlockType.Image,
      );

      og.description = getPostDescription(descriptionBlock?.content as string);
      og.imageUrl = imageBlock?.photos
        ? imageBlock.photos[0].postedFile?.fileUrl
        : null;
    }

    return og;
  };

  const getOpenGraph = async (url: string) => {
    const urlSplit = url.split('/');
    const routeName = urlSplit[urlSplit.length - 2];
    const routeValue = urlSplit[urlSplit.length - 1];

    if (routeName === 'pages') {
      return getSectionPageOpenGraph(Number(routeValue), url);
    }

    const { data } = await api.openGraph.apiV1OpenGraphGet({
      url,
    });

    return data;
  };

  return {
    getOpenGraph,
  };
};
