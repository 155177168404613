export const useDateTime = () => {
  /**
   * Парсит полученную строку с поправкой на текущий
   * часовой пояс браузера
   * @param date строка в формате ISO
   * @returns возращает объект даты
   */
  const parseISOWithTimezone = (date: string) => {
    return new Date(
      new Date(date).getTime() - new Date().getTimezoneOffset() * 60000,
    );
  };

  return {
    parseISOWithTimezone,
  };
};
