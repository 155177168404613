import { VFC } from 'react';

import { NavLink } from 'react-router-dom';

import { Logo } from 'ui';

export const LogoLink: VFC = () => {
  return (
    <div style={{ marginRight: 24 }}>
      <NavLink to="/dashboard">
        <Logo />
      </NavLink>
    </div>
  );
};
