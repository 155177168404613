import styled from 'styled-components';

import { Paper } from '../Paper';
import { ToolBar } from '../ToolBar';

export const WidgetToolBar = styled(ToolBar)`
  flex-direction: column;
  align-items: flex-start;
`;

export const WidgetPaper = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  max-width: 332px;
  padding: 16px 0 24px 0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &[data-type='default'] {
    padding: 24px !important;

    ${WidgetToolBar} {
      padding: 0;
    }
  }

  &[data-type='dashboard'] {
    height: 100% !important;
    padding: 16px !important;

    ${WidgetToolBar} {
      padding: 0;
    }
  }

  &[role='button'] {
    cursor: pointer;
  }
`;

export const WidgetSubtitle = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 16px;
`;

export const ExtraContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 24px;
  right: 10px;

  & > a,
  svg {
    color: ${({ theme }) => theme.palette.primary.light};
  }

  & > a:hover {
    text-decoration: underline ${({ theme }) => theme.palette.primary.light};
  }
`;

export const MoreLinkContainer = styled.div`
  padding: 16px 24px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  & > a,
  svg {
    color: ${({ theme }) => theme.palette.primary.light};
    font-weight: 600;
  }

  & > a:hover {
    text-decoration: underline ${({ theme }) => theme.palette.primary.light};
  }
`;
