import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px;
  }
`;
