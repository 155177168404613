import { VFC, useCallback, useState } from 'react';

import { FileLoadFailed } from 'components/MediaGallery/Media/FileLoadFailed';
import { MediaOverlay } from 'components/MediaGallery/Media/MediaOverlay';
import { VideoProcessed } from 'components/MediaGallery/Media/VideoProcessed';
import { getPhotoPreview, getVideoPreview } from 'domain/photo';
import { PostedFileModel } from 'services/api';
import { useMediaGallery } from 'services/mediaGallery';

import { useMediaAlbum } from '../MediaAlbum';
import { MediaButton, MediaRoot } from './styles';
import { VideoCover } from './VideoCover';

export interface MediaProps {
  media: PostedFileModel;
  quality?: number;
  disabledClick?: boolean;
  otherMedia?: number;
}

export const Media: VFC<MediaProps> = (props) => {
  const { media, quality = 144, disabledClick, otherMedia } = props;
  const { openMediaGallery } = useMediaGallery();
  const mediaAlbum = useMediaAlbum();

  const [loadedFailed, setLoadedFailed] = useState(false);

  const handleOpenGallery = useCallback(
    () => openMediaGallery(media, mediaAlbum),
    [media, mediaAlbum, openMediaGallery],
  );

  return (
    <MediaRoot>
      {media && (
        <MediaButton
          disabled={disabledClick}
          type="button"
          onClick={handleOpenGallery}
        >
          {media.videoId &&
            (media.postedFile && media.videoThumbnail?.fileUrl ? (
              <VideoCover />
            ) : (
              <VideoProcessed />
            ))}
          {media.postedFile &&
            (!loadedFailed ? (
              <img
                alt={
                  media.postedFile.fileName ?? `mediafile${media.postedFileId}`
                }
                src={
                  media.videoId
                    ? getVideoPreview(media)
                    : getPhotoPreview(media, quality)
                }
                onError={() => {
                  console.log(`error ${getPhotoPreview(media, quality)}`);
                  setLoadedFailed(true);
                }}
                onErrorCapture={() => {
                  console.log(`error ${getPhotoPreview(media, quality)}`);
                  setLoadedFailed(true);
                }}
              />
            ) : (
              <FileLoadFailed />
            ))}
          {otherMedia && <MediaOverlay otherMedia={otherMedia} />}
        </MediaButton>
      )}
    </MediaRoot>
  );
};

export { MediaItemSkeleton as Skeleton } from './Skeleton';
