import { Button } from 'antd';
import styled, { css } from 'styled-components';

import { InfoButtonProps, Size } from './types';

export const sizeStyles = {
  minimal: css`
    font-size: 10px;
    line-height: 12px;
    padding: 5px 10px;
    height: 22px;
  `,
  small: css`
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px;
    height: 28px;
  `,
  default: css`
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
    height: 32px;
  `,
};

export const IconContainer = styled.div`
  display: inline;
  margin: 0px;
`;

export const InfoButtonRoot = styled(Button)<InfoButtonProps>`
  cursor: pointer;
  background-color: #edf1fe;
  color: #4872f2;
  border: none;
  border-radius: 100px;
  padding: 6px 12px;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  white-space: nowrap;
  text-decoration: none;
  font-style: normal;
  text-decoration: none;
  ${({ sizes = 'default' }) => sizeStyles[sizes as Size]}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #3965ee;
      color: #fff;
    `}

  &:hover {
    background-color: #3965ee;
    color: #fff;
  }

  &:focus {
    background-color: #3965ee;
    color: #fff;
  }

  &:nth-child(1) svg {
    margin-right: 8px;
  }
`;
