import { VFC, memo, useEffect, useMemo, useRef, useState } from 'react';

import { PostContentModel } from 'services/api';
import { Ellipsis } from 'ui/Ellipsis';

import { StyledContainer } from './styles';

export type PostTextContentProps = {
  data: PostContentModel;
};

const TEXT_COLLAPSE_THRESHOLD = 24 * 4 + 16;

export const PostTextContent: VFC<PostTextContentProps> = memo(({ data }) => {
  const container = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleContainerResize = () => {
      setWidth(container.current?.clientWidth || 0);
    };
    const el = container.current;
    setWidth(el?.clientWidth || 0);

    el?.addEventListener('resize', handleContainerResize);
    return () => {
      el?.removeEventListener('resize', handleContainerResize);
    };
  }, []);

  const processMentions = (text: string | null | undefined) =>
    (text || '')
      ?.replaceAll('data-mention-id', 'data-id')
      .replace(
        /<span class="mention" data-id="(\d+)">(@[\wа-яА-ЯЁ]+\s[\wа-яА-ЯЁ]+)<\/span>/gi,
        '<a class="mention" data-id="$1" href="profile/$1">$2</a>',
      );
  const processHashtags = (text: string | null | undefined) =>
    (text || '')
      .replace(/<p>(#[a-zа-яё\d_]+\s*)+<\/p>\s*$/, '')
      .replaceAll(
        /(#([a-zа-яё\d_]+))/gi,
        '<a href="/search/posts?query=$2&searchByTags=1" class="hashtag inline">$1</a>',
      );

  // TODO get rid of regexp (optional)
  const postTextMarkdown = useMemo(
    () => processHashtags(processMentions(data.text)),
    [data.text],
  );
  const postHashtags = useMemo(
    () =>
      Array.from((data.text || '').matchAll(/#[a-zа-яё\d_]+/gi)).map(
        (v) => v[0],
      ),
    [data.text],
  );

  return (
    <StyledContainer ref={container}>
      <Ellipsis
        expectingHeight={TEXT_COLLAPSE_THRESHOLD}
        expectingWidth={width}
        html={postTextMarkdown as string}
      />
      {postHashtags.length > 0 && (
        <p>
          {postHashtags.map((hash) => (
            <a
              className="hashtag inblock"
              href={`/search/posts?query=${hash.replace(
                /^#/,
                '',
              )}&searchByTags=1`}
            >
              {hash}
            </a>
          ))}
        </p>
      )}
    </StyledContainer>
  );
});
