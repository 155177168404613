import { message as alertMessage } from 'antd';

export const useSystemNotifyService = () => {
  const pushNotify = (message: string) =>
    alertMessage.warning({ content: message, duration: 2 });

  const pushErrorNotify = (message: string) =>
    alertMessage.error({ content: message, duration: 2 });

  const pushSuccessNotify = (message: string) =>
    alertMessage.success({ content: message, duration: 2 });

  return {
    pushNotify,
    pushSuccessNotify,
    pushErrorNotify,
  };
};
