import { VFC } from 'react';

import { Delete, Edit } from '@styled-icons/material-outlined';
import { CheckCircle as PublishIcon } from '@styled-icons/material-outlined/CheckCircle';
import { MoreHoriz as MoreIcon } from '@styled-icons/material-outlined/MoreHoriz';
import { Dropdown } from 'antd';

import { useConfirm } from 'hooks/useConfirm';
import { RoleName } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { Icon, IconButton, Menu } from 'ui';

import { StyledContainer, StyledMenuItem } from './styles';

export type PostRightMenuProps = {
  userId: number;
  isPinned: boolean;
  isDisableNotifications: boolean;
  isIdea: boolean;
  isPublished: boolean;
  onPushPost: () => Promise<void>;
  onCopyPost: () => Promise<void>;
  onPinPost: () => Promise<void>;
  onEditPost: () => Promise<void>;
  onPublishPost: () => void;
  onDeletePost: () => void;
  onSwitchNotifications: () => Promise<void>;
};
export const PostRightMenu: VFC<PostRightMenuProps> = ({
  userId,
  isPinned,
  isDisableNotifications,
  isPublished,
  isIdea,
  onPushPost,
  onCopyPost,
  onPinPost,
  onEditPost,
  onPublishPost,
  onDeletePost,
  onSwitchNotifications,
}) => {
  const { user, hasRole } = useUserCredential();
  const { t } = useTranslation('common');

  const isPostOwner = userId === user?.id;
  const isAdmin = hasRole(RoleName.Administrator);
  const showMenu = isPostOwner || isAdmin;

  const { Confirm: DeleteConfirm, handleOpenConfirm: handleDeletePost } =
    useConfirm({
      type: 'danger',
      title: t('post_remove_confirm_title'),
      text: t('post_remove_confirm'),
      confirmText: t('delete'),
      onConfirm: () => {
        onDeletePost();
      },
    });

  return (
    <StyledContainer>
      {isPinned && (
        <Icon
          iconName="push_pin"
          size={22}
          style={{ height: 40, color: '#888', marginRight: 10 }}
        />
      )}
      {showMenu && (
        <Dropdown
          overlay={
            <Menu>
              {isPublished && (
                <StyledMenuItem onClick={onSwitchNotifications}>
                  <Icon
                    iconName={
                      !isDisableNotifications ? 'volume_off' : 'volume_up'
                    }
                    size={18}
                  />
                  {!isDisableNotifications
                    ? t('disable_notifications')
                    : t('enable_notifications')}
                </StyledMenuItem>
              )}
              {isAdmin && (
                <StyledMenuItem onClick={onPushPost}>
                  <Icon iconName="reply" size={18} />
                  {t('send_as_notification')}
                </StyledMenuItem>
              )}
              {!isIdea && (
                <StyledMenuItem onClick={onCopyPost}>
                  <Icon iconName="copy" size={18} />
                  {t('copy')}
                </StyledMenuItem>
              )}
              {isPublished && (
                <StyledMenuItem onClick={onPinPost}>
                  <Icon iconName="push_pin" size={18} />
                  {!isPinned ? t('pin_post') : t('unpin_post')}
                </StyledMenuItem>
              )}
              <StyledMenuItem onClick={onEditPost}>
                <Edit size={18} />
                {t('edit')}
              </StyledMenuItem>
              <StyledMenuItem onClick={handleDeletePost}>
                <Delete size={18} />
                {t('delete')}
              </StyledMenuItem>
              {!isPublished && (
                <StyledMenuItem onClick={onPublishPost}>
                  <PublishIcon size={18} />
                  {t('publish')}
                </StyledMenuItem>
              )}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <IconButton edge="right">
            <MoreIcon size={22} />
          </IconButton>
        </Dropdown>
      )}
      <DeleteConfirm />
    </StyledContainer>
  );
};
