import { useCallback } from 'react';

import { LocalizationValueReadModel, api } from 'services/api';
import { LanguageId, useTranslation } from 'services/i18n';

type LocalizationValue = Required<LocalizationValueReadModel>;

export const useLoadingLanguage = () => {
  const { i18n } = useTranslation();

  const convertToResourceBundle = (data: LocalizationValue[]) => {
    const LocalizationValuePairs = data.map((item) => [item.key, item.value]);
    return Object.fromEntries(LocalizationValuePairs);
  };

  const setLangFromId = useCallback(
    async (languageId: LanguageId) => {
      const { data } = await api.localizationValue.apiV1LocalizationValuesGet({
        languageId,
      });

      const preparedData = convertToResourceBundle(data as LocalizationValue[]);

      await i18n
        .addResourceBundle(LanguageId[languageId], 'common', preparedData)
        .changeLanguage(LanguageId[languageId]);

      await api.user.apiV1UsersLanguagePost({ body: languageId });
    },
    [i18n],
  );

  return { setLangFromId };
};
