import styled from 'styled-components';

export const StyledGiftcard = styled.div`
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
