import React, { FC, useCallback, useMemo, useState } from 'react';

import { LocationOn } from '@styled-icons/material-outlined';
import { LabeledValue } from 'antd/lib/select';
import { debounce } from 'lodash';

import { api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { IconButton, Select } from 'ui';

import { FormButtons } from '../FormButtons';
import { StyledModal } from './styles';
import { GeoPickerProps } from './types';

export const GeoPicker: FC<GeoPickerProps> = ({ setGeoPickerPos }) => {
  const { t } = useTranslation('common');

  const [visible, setVisible] = useState(false);
  const [selectedGeoObject, setSelectedGeoObject] =
    useState<LabeledValue | null>(null);
  const [geoObjects, setGeoObjects] = useState<LabeledValue[]>([]);
  const showModal = () => {
    setVisible(true);
  };

  const handleSearch = useCallback(async (name: string) => {
    const { data } = await api.geocoder.apiV1GeocoderGet({ name, count: 10 });
    const result: Record<string, LabeledValue> = {};

    if (data.items) {
      Object.entries(data.items).forEach(([key, value]) => {
        if (value.geoObject && value.geoObject.point?.pos)
          result[key] = {
            label: value.geoObject.metaDataProperty?.geocoderMetaData?.text,
            value: value.geoObject.point.pos,
          };
      });
      setGeoObjects([...Object.values(result)]);
    }
  }, []);

  const handleOk = () => {
    if (selectedGeoObject) setGeoPickerPos(selectedGeoObject);
    setVisible(false);
    setSelectedGeoObject(null);
    setGeoObjects([]);
  };

  const handleAutoCompleteSelect = (value: string, option: any) => {
    setSelectedGeoObject(option);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedGeoObject(null);
    setGeoObjects([]);
  };

  const debouncedSearch = useMemo(
    () => debounce(handleSearch, 400),
    [handleSearch],
  );

  return (
    <>
      <IconButton onClick={showModal}>
        <LocationOn size={22} />
      </IconButton>

      <StyledModal
        centered
        footer={[
          <FormButtons
            handleCancel={handleCancel}
            handleSubmit={handleOk}
            submitText={t('select')}
          />,
        ]}
        title={t('add_location')}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <Select
          showSearch
          filterOption={false}
          options={geoObjects}
          placeholder={t('input_address_part')}
          style={{
            width: 472,
          }}
          onSearch={debouncedSearch}
          onSelect={handleAutoCompleteSelect}
        />
      </StyledModal>
    </>
  );
};
