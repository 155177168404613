import styled from 'styled-components';

import { Paper } from 'ui';

export const ControlsPaper = styled(Paper)`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const ContentPaper = styled(Paper)`
  padding: 24px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const Header = styled.div`
  display: flex;

  & > :first-child {
    margin-right: auto;
  }
`;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`;
