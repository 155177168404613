import { FC } from 'react';

import { Link, LinkProps } from 'react-router-dom';

/**
 * @deprecated use UniversalLink
 */
export const NavLink: FC<LinkProps> = ({ to, children, ...props }) => {
  const href = to.toString();

  if (href.startsWith('http') || href.startsWith('sip:')) {
    return (
      <a href={href} rel="noreferrer" target="_blank" {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
};
