import { VFC } from 'react';

import { useQuery } from 'react-query';

import { ChevronLeft as PrevIcon } from '@styled-icons/material-outlined/ChevronLeft';
import { ChevronRight as NextIcon } from '@styled-icons/material-outlined/ChevronRight';
import { Spin } from 'antd';

import { getPhotoPreview, getVideoPreview } from 'domain/photo';
import { api } from 'services/api';
import { useMediaGallery } from 'services/mediaGallery';

import {
  ButtonContainer,
  MediaContainer,
  MediaContentRoot,
  SlideButton,
} from './styles';

export interface MediaContentProps {
  mediaFileId: number;
}
interface GetMediaFileParams {
  id: number;
  signal?: AbortSignal;
}

const getMediaFile = async ({ id, signal }: GetMediaFileParams) => {
  const { data } = await api.postedFile.apiV1FilesIdGalleryGet(
    { id },
    { signal },
  );
  return data;
};
export const MediaContent: VFC<MediaContentProps> = ({ mediaFileId }) => {
  const { hasMediaAlbum, prevMedia, nextMedia } = useMediaGallery();

  const { data: media, isLoading } = useQuery(
    ['mediaGallery', mediaFileId],
    ({ signal }) => getMediaFile({ id: mediaFileId, signal }),
    {
      suspense: false,
      refetchOnMount: false,
      staleTime: 5 * 60 * 1000,
    },
  );

  return (
    <MediaContentRoot>
      <ButtonContainer>
        {hasMediaAlbum && (
          <SlideButton onClick={() => prevMedia()}>
            <PrevIcon size={32} />
          </SlideButton>
        )}
      </ButtonContainer>
      <MediaContainer>
        {isLoading && <Spin />}
        {media &&
          (media.videoId ? (
            <video controls poster={getVideoPreview(media)}>
              <track kind="captions" />
              <source src={media.postedFile?.fileUrl ?? undefined} />
            </video>
          ) : (
            <img
              alt={`mediafile${media?.postedFileId}`}
              src={getPhotoPreview(media, 720)}
            />
          ))}
      </MediaContainer>
      <ButtonContainer>
        {hasMediaAlbum && (
          <SlideButton onClick={() => nextMedia()}>
            <NextIcon size={32} />
          </SlideButton>
        )}
      </ButtonContainer>
    </MediaContentRoot>
  );
};
