import { VFC, useCallback, useEffect, useMemo, useState } from 'react';

import { useSearchURL } from 'hooks/useSearchURL';
import { useTranslation } from 'services/i18n';

import { Button, Container } from './styles';

const countries = [
  { id: 0, name: 'global' },
  { id: 1, name: 'russia' },
  { id: 2, name: 'ukraine' },
  { id: 3, name: 'baltics' },
  { id: 4, name: 'turkey' },
  { id: 5, name: 'kazakhstan' },
];

export const CountryFilter: VFC = () => {
  const { t } = useTranslation('common');
  const [activeCountry, setActiveCountry] = useState<number>(0);

  const { setUrlParams, searchParams, deleteUrlParams } = useSearchURL();

  useEffect(() => {
    const searchParam = searchParams.get('countryId');

    if (!searchParam) return;

    setActiveCountry(Number(searchParam));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    (id) => {
      if (id === 0 || activeCountry === id) {
        setActiveCountry(0);
        deleteUrlParams('countryId');
      } else {
        setActiveCountry(id);
        setUrlParams({ countryId: id });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeCountry, searchParams],
  );

  const buttonsList = useMemo(
    () =>
      countries.map((item) => (
        <Button
          key={item.name}
          color="primary"
          isActive={item.id === activeCountry}
          onClick={() => handleClick(item.id)}
        >
          {t(item.name)}
        </Button>
      )),
    [activeCountry, handleClick, t],
  );

  return <Container>{buttonsList}</Container>;
};
