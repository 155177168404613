/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { MailingModel } from '../models';
// @ts-ignore
import { MailingType } from '../models';
// @ts-ignore
import { MailingsModel } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * MailingApi - axios parameter creator
 * @export
 */
export const MailingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsGet: async (count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mailings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MailingsIdDelete', 'id', id)
            const localVarPath = `/api/v1/mailings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MailingsIdGet', 'id', id)
            const localVarPath = `/api/v1/mailings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MailingsIdPatch', 'id', id)
            const localVarPath = `/api/v1/mailings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {MailingModel} [mailingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsPost: async (mailingModel?: MailingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mailings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {MailingModel} [mailingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsPut: async (mailingModel?: MailingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mailings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by type
         * @param {MailingType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsTypeGet: async (type?: MailingType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mailings/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailingApi - functional programming interface
 * @export
 */
export const MailingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MailingsGet(count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MailingsGet(count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MailingsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MailingsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MailingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MailingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MailingsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MailingsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {MailingModel} [mailingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MailingsPost(mailingModel?: MailingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MailingsPost(mailingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {MailingModel} [mailingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MailingsPut(mailingModel?: MailingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MailingsPut(mailingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by type
         * @param {MailingType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MailingsTypeGet(type?: MailingType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MailingsTypeGet(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailingApi - factory interface
 * @export
 */
export const MailingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsGet(count?: number, offset?: number, options?: any): AxiosPromise<MailingsModel> {
            return localVarFp.apiV1MailingsGet(count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MailingsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsIdGet(id: number, options?: any): AxiosPromise<MailingModel> {
            return localVarFp.apiV1MailingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<MailingModel> {
            return localVarFp.apiV1MailingsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {MailingModel} [mailingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsPost(mailingModel?: MailingModel, options?: any): AxiosPromise<MailingModel> {
            return localVarFp.apiV1MailingsPost(mailingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {MailingModel} [mailingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsPut(mailingModel?: MailingModel, options?: any): AxiosPromise<MailingModel> {
            return localVarFp.apiV1MailingsPut(mailingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by type
         * @param {MailingType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MailingsTypeGet(type?: MailingType, options?: any): AxiosPromise<MailingModel> {
            return localVarFp.apiV1MailingsTypeGet(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MailingsGet operation in MailingApi.
 * @export
 * @interface MailingApiApiV1MailingsGetRequest
 */
export interface MailingApiApiV1MailingsGetRequest {
    /**
     * Количеству объектов
     * @type {number}
     * @memberof MailingApiApiV1MailingsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof MailingApiApiV1MailingsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1MailingsIdDelete operation in MailingApi.
 * @export
 * @interface MailingApiApiV1MailingsIdDeleteRequest
 */
export interface MailingApiApiV1MailingsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiApiV1MailingsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1MailingsIdGet operation in MailingApi.
 * @export
 * @interface MailingApiApiV1MailingsIdGetRequest
 */
export interface MailingApiApiV1MailingsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiApiV1MailingsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1MailingsIdPatch operation in MailingApi.
 * @export
 * @interface MailingApiApiV1MailingsIdPatchRequest
 */
export interface MailingApiApiV1MailingsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof MailingApiApiV1MailingsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof MailingApiApiV1MailingsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1MailingsPost operation in MailingApi.
 * @export
 * @interface MailingApiApiV1MailingsPostRequest
 */
export interface MailingApiApiV1MailingsPostRequest {
    /**
     * 
     * @type {MailingModel}
     * @memberof MailingApiApiV1MailingsPost
     */
    readonly mailingModel?: MailingModel
}

/**
 * Request parameters for apiV1MailingsPut operation in MailingApi.
 * @export
 * @interface MailingApiApiV1MailingsPutRequest
 */
export interface MailingApiApiV1MailingsPutRequest {
    /**
     * 
     * @type {MailingModel}
     * @memberof MailingApiApiV1MailingsPut
     */
    readonly mailingModel?: MailingModel
}

/**
 * Request parameters for apiV1MailingsTypeGet operation in MailingApi.
 * @export
 * @interface MailingApiApiV1MailingsTypeGetRequest
 */
export interface MailingApiApiV1MailingsTypeGetRequest {
    /**
     * 
     * @type {MailingType}
     * @memberof MailingApiApiV1MailingsTypeGet
     */
    readonly type?: MailingType
}

/**
 * MailingApi - object-oriented interface
 * @export
 * @class MailingApi
 * @extends {BaseAPI}
 */
export class MailingApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {MailingApiApiV1MailingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public apiV1MailingsGet(requestParameters: MailingApiApiV1MailingsGetRequest = {}, options?: AxiosRequestConfig) {
        return MailingApiFp(this.configuration).apiV1MailingsGet(requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {MailingApiApiV1MailingsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public apiV1MailingsIdDelete(requestParameters: MailingApiApiV1MailingsIdDeleteRequest, options?: AxiosRequestConfig) {
        return MailingApiFp(this.configuration).apiV1MailingsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {MailingApiApiV1MailingsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public apiV1MailingsIdGet(requestParameters: MailingApiApiV1MailingsIdGetRequest, options?: AxiosRequestConfig) {
        return MailingApiFp(this.configuration).apiV1MailingsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {MailingApiApiV1MailingsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public apiV1MailingsIdPatch(requestParameters: MailingApiApiV1MailingsIdPatchRequest, options?: AxiosRequestConfig) {
        return MailingApiFp(this.configuration).apiV1MailingsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {MailingApiApiV1MailingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public apiV1MailingsPost(requestParameters: MailingApiApiV1MailingsPostRequest = {}, options?: AxiosRequestConfig) {
        return MailingApiFp(this.configuration).apiV1MailingsPost(requestParameters.mailingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {MailingApiApiV1MailingsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public apiV1MailingsPut(requestParameters: MailingApiApiV1MailingsPutRequest = {}, options?: AxiosRequestConfig) {
        return MailingApiFp(this.configuration).apiV1MailingsPut(requestParameters.mailingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by type
     * @param {MailingApiApiV1MailingsTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailingApi
     */
    public apiV1MailingsTypeGet(requestParameters: MailingApiApiV1MailingsTypeGetRequest = {}, options?: AxiosRequestConfig) {
        return MailingApiFp(this.configuration).apiV1MailingsTypeGet(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}
