/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DiscoverySystemModel } from '../models';
// @ts-ignore
import { DiscoverySystemsModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * DiscoverySystemApi - axios parameter creator
 * @export
 */
export const DiscoverySystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsGet: async (count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/discovery-systems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DiscoverySystemsIdDelete', 'id', id)
            const localVarPath = `/api/v1/discovery-systems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DiscoverySystemsIdGet', 'id', id)
            const localVarPath = `/api/v1/discovery-systems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DiscoverySystemsIdPatch', 'id', id)
            const localVarPath = `/api/v1/discovery-systems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {DiscoverySystemModel} [discoverySystemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsPost: async (discoverySystemModel?: DiscoverySystemModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/discovery-systems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discoverySystemModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {DiscoverySystemModel} [discoverySystemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsPut: async (discoverySystemModel?: DiscoverySystemModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/discovery-systems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discoverySystemModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscoverySystemApi - functional programming interface
 * @export
 */
export const DiscoverySystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscoverySystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoverySystemsGet(count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoverySystemsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoverySystemsGet(count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoverySystemsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoverySystemsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoverySystemsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoverySystemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoverySystemsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoverySystemsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoverySystemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoverySystemsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {DiscoverySystemModel} [discoverySystemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoverySystemsPost(discoverySystemModel?: DiscoverySystemModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoverySystemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoverySystemsPost(discoverySystemModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {DiscoverySystemModel} [discoverySystemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoverySystemsPut(discoverySystemModel?: DiscoverySystemModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoverySystemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoverySystemsPut(discoverySystemModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscoverySystemApi - factory interface
 * @export
 */
export const DiscoverySystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscoverySystemApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsGet(count?: number, offset?: number, options?: any): AxiosPromise<DiscoverySystemsModel> {
            return localVarFp.apiV1DiscoverySystemsGet(count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DiscoverySystemsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsIdGet(id: number, options?: any): AxiosPromise<DiscoverySystemModel> {
            return localVarFp.apiV1DiscoverySystemsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<DiscoverySystemModel> {
            return localVarFp.apiV1DiscoverySystemsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {DiscoverySystemModel} [discoverySystemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsPost(discoverySystemModel?: DiscoverySystemModel, options?: any): AxiosPromise<DiscoverySystemModel> {
            return localVarFp.apiV1DiscoverySystemsPost(discoverySystemModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {DiscoverySystemModel} [discoverySystemModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoverySystemsPut(discoverySystemModel?: DiscoverySystemModel, options?: any): AxiosPromise<DiscoverySystemModel> {
            return localVarFp.apiV1DiscoverySystemsPut(discoverySystemModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DiscoverySystemsGet operation in DiscoverySystemApi.
 * @export
 * @interface DiscoverySystemApiApiV1DiscoverySystemsGetRequest
 */
export interface DiscoverySystemApiApiV1DiscoverySystemsGetRequest {
    /**
     * Количеству объектов
     * @type {number}
     * @memberof DiscoverySystemApiApiV1DiscoverySystemsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof DiscoverySystemApiApiV1DiscoverySystemsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1DiscoverySystemsIdDelete operation in DiscoverySystemApi.
 * @export
 * @interface DiscoverySystemApiApiV1DiscoverySystemsIdDeleteRequest
 */
export interface DiscoverySystemApiApiV1DiscoverySystemsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DiscoverySystemApiApiV1DiscoverySystemsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1DiscoverySystemsIdGet operation in DiscoverySystemApi.
 * @export
 * @interface DiscoverySystemApiApiV1DiscoverySystemsIdGetRequest
 */
export interface DiscoverySystemApiApiV1DiscoverySystemsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DiscoverySystemApiApiV1DiscoverySystemsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1DiscoverySystemsIdPatch operation in DiscoverySystemApi.
 * @export
 * @interface DiscoverySystemApiApiV1DiscoverySystemsIdPatchRequest
 */
export interface DiscoverySystemApiApiV1DiscoverySystemsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof DiscoverySystemApiApiV1DiscoverySystemsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof DiscoverySystemApiApiV1DiscoverySystemsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1DiscoverySystemsPost operation in DiscoverySystemApi.
 * @export
 * @interface DiscoverySystemApiApiV1DiscoverySystemsPostRequest
 */
export interface DiscoverySystemApiApiV1DiscoverySystemsPostRequest {
    /**
     * 
     * @type {DiscoverySystemModel}
     * @memberof DiscoverySystemApiApiV1DiscoverySystemsPost
     */
    readonly discoverySystemModel?: DiscoverySystemModel
}

/**
 * Request parameters for apiV1DiscoverySystemsPut operation in DiscoverySystemApi.
 * @export
 * @interface DiscoverySystemApiApiV1DiscoverySystemsPutRequest
 */
export interface DiscoverySystemApiApiV1DiscoverySystemsPutRequest {
    /**
     * 
     * @type {DiscoverySystemModel}
     * @memberof DiscoverySystemApiApiV1DiscoverySystemsPut
     */
    readonly discoverySystemModel?: DiscoverySystemModel
}

/**
 * DiscoverySystemApi - object-oriented interface
 * @export
 * @class DiscoverySystemApi
 * @extends {BaseAPI}
 */
export class DiscoverySystemApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {DiscoverySystemApiApiV1DiscoverySystemsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoverySystemApi
     */
    public apiV1DiscoverySystemsGet(requestParameters: DiscoverySystemApiApiV1DiscoverySystemsGetRequest = {}, options?: AxiosRequestConfig) {
        return DiscoverySystemApiFp(this.configuration).apiV1DiscoverySystemsGet(requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {DiscoverySystemApiApiV1DiscoverySystemsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoverySystemApi
     */
    public apiV1DiscoverySystemsIdDelete(requestParameters: DiscoverySystemApiApiV1DiscoverySystemsIdDeleteRequest, options?: AxiosRequestConfig) {
        return DiscoverySystemApiFp(this.configuration).apiV1DiscoverySystemsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {DiscoverySystemApiApiV1DiscoverySystemsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoverySystemApi
     */
    public apiV1DiscoverySystemsIdGet(requestParameters: DiscoverySystemApiApiV1DiscoverySystemsIdGetRequest, options?: AxiosRequestConfig) {
        return DiscoverySystemApiFp(this.configuration).apiV1DiscoverySystemsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {DiscoverySystemApiApiV1DiscoverySystemsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoverySystemApi
     */
    public apiV1DiscoverySystemsIdPatch(requestParameters: DiscoverySystemApiApiV1DiscoverySystemsIdPatchRequest, options?: AxiosRequestConfig) {
        return DiscoverySystemApiFp(this.configuration).apiV1DiscoverySystemsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {DiscoverySystemApiApiV1DiscoverySystemsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoverySystemApi
     */
    public apiV1DiscoverySystemsPost(requestParameters: DiscoverySystemApiApiV1DiscoverySystemsPostRequest = {}, options?: AxiosRequestConfig) {
        return DiscoverySystemApiFp(this.configuration).apiV1DiscoverySystemsPost(requestParameters.discoverySystemModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {DiscoverySystemApiApiV1DiscoverySystemsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoverySystemApi
     */
    public apiV1DiscoverySystemsPut(requestParameters: DiscoverySystemApiApiV1DiscoverySystemsPutRequest = {}, options?: AxiosRequestConfig) {
        return DiscoverySystemApiFp(this.configuration).apiV1DiscoverySystemsPut(requestParameters.discoverySystemModel, options).then((request) => request(this.axios, this.basePath));
    }
}
