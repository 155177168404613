import React, { createContext, useContext, useState } from 'react';

import { DataNode } from 'antd/lib/tree';

import { UserProfileModel } from 'services/api';

type TreeDataType = DataNode[] | undefined;
type ProfilesType = UserProfileModel[] | undefined;
type SupervisorType = UserProfileModel | undefined;

export interface StructureContextValue {
  treeData: TreeDataType;
  usersList: ProfilesType;
  supervisor: SupervisorType;
  setTreeData: React.Dispatch<React.SetStateAction<TreeDataType>>;
  setUsersList: React.Dispatch<React.SetStateAction<ProfilesType>>;
  setSupervisor: React.Dispatch<React.SetStateAction<SupervisorType>>;
}

export const StructureContext = createContext<StructureContextValue>(
  {} as StructureContextValue,
);

export const StructureProvider: React.FunctionComponent = ({ children }) => {
  const [treeData, setTreeData] = useState<TreeDataType>();
  const [usersList, setUsersList] = useState<UserProfileModel[]>();
  const [supervisor, setSupervisor] = useState<SupervisorType>();

  return (
    <StructureContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        treeData,
        setTreeData,
        usersList,
        setUsersList,
        supervisor,
        setSupervisor,
      }}
    >
      {children}
    </StructureContext.Provider>
  );
};

export const useStructureContext = () => useContext(StructureContext);
