import React, { FC } from 'react';

import { useControlled } from '../utils/useControlled';
import { CompletedIcon } from './CompletedIcon';
import {
  StyledIconContainer,
  StyledLabel,
  StyledTodoCheckboxRoot,
} from './styles';
import { UnCompletedIcon } from './UnCompletedIcon';

export interface TodoCheckboxProps {
  name: string;

  completed: boolean;
  size?: number;
  disable?: boolean;
  onChange?: (newValue: boolean) => void;
}

export const TodoCheckbox: FC<TodoCheckboxProps> = ({
  name,
  completed,
  size,
  disable,
  onChange,
  ...props
}) => {
  const [value, setValue] = useControlled({
    controlledValue: completed,
    defaultValue: undefined,
  });

  const handleClick = () => {
    if (!disable) {
      if (onChange) onChange(!value);
      setValue(!value);
    }
  };

  return (
    <StyledTodoCheckboxRoot {...props}>
      <StyledIconContainer size={size} onClick={handleClick}>
        {value ? (
          <CompletedIcon size={size} />
        ) : (
          <UnCompletedIcon size={size} />
        )}
      </StyledIconContainer>
      <StyledLabel data-completed={value} onClick={handleClick}>
        <span title={name}>{name}</span>
      </StyledLabel>
    </StyledTodoCheckboxRoot>
  );
};
