import styled from 'styled-components';

import { StyledLink as BaseStyledLink } from 'ui/Link';

export const StyledLink = styled(BaseStyledLink)`
  margin-left: 0.5em;
`;

export const CollapseIconLink = styled(BaseStyledLink)`
  font-size: 16px;
  line-height: 1;
  min-width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  place-content: center;
  margin-bottom: 10px;

  svg {
    width: 16px;
    height: 16px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const StyledLastNodeContainer = styled.div`
  margin-bottom: 16px;
  & > * {
    display: inline;
  }
`;
