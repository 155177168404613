import { VFC, useMemo } from 'react';

import { Avatar } from 'antd';

import { getUserCountryShort } from 'domain/user';
import { GeoBadge, GeoBadgeProps } from 'ui/GeoBadge';

import {
  ItemAction,
  ItemAvatar,
  ItemContainer,
  ItemData,
  ItemName,
  ItemRole,
} from './styles';
import { UserMenuItemProps } from './types';

const geoBadgeOffset = [-6, 32] as GeoBadgeProps['offset'];
const avatarSize = 40;

export const UserMenuListItem: VFC<UserMenuItemProps> = (props) => {
  const { userData, actionElement, onClick } = props;

  const userCountryShort = useMemo(
    () => getUserCountryShort(userData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userData.country?.id],
  ) as GeoBadgeProps['locale'];

  const handleClick = () => {
    if (onClick) onClick(userData);
  };

  return (
    <ItemContainer onClick={handleClick}>
      <ItemAvatar>
        <GeoBadge locale={userCountryShort} offset={geoBadgeOffset}>
          <Avatar
            shape="circle"
            size={avatarSize}
            src={userData.avatar?.fileUrl}
          />
        </GeoBadge>
      </ItemAvatar>
      <ItemData>
        <ItemName>
          {userData.firstName} {userData.lastName}
        </ItemName>
        <ItemRole>{userData.positionName}</ItemRole>
      </ItemData>
      <ItemAction>{actionElement}</ItemAction>
    </ItemContainer>
  );
};
