import styled from 'styled-components';

import { Paper } from 'ui';

export const ModalPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 32px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const IconContainer = styled.div`
  margin-right: 16px;

  &[data-color='info'] {
    color: ${({ theme }) => theme.palette.primary.light};
  }
  &[data-color='success'] {
    color: #09a73f;
  }
  &[data-color='warning'] {
    color: #ffcd00;
  }
  &[data-color='danger'] {
    color: ${({ theme }) => theme.palette.error.light};
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 24px;
  margin-left: 40px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;

  & > :not(:last-child) {
    margin-right: 12px;
  }
`;
