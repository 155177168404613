/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { GiftCardSendUserModel } from '../models';
// @ts-ignore
import { GiftCardTemplateModel } from '../models';
// @ts-ignore
import { GiftCardTemplatesModel } from '../models';
// @ts-ignore
import { GiftCardType } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { PhotoChildModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * GiftCardApi - axios parameter creator
 * @export
 */
export const GiftCardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [id] Фильтр по шаблону подарка
         * @param {GiftCardType} [type] Фильтр по типу подарка
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsGet: async (id?: number, type?: GiftCardType, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/gift-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send gift card to user
         * @param {string} giftCardTemplateId 
         * @param {GiftCardSendUserModel} [giftCardSendUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost: async (giftCardTemplateId: string, giftCardSendUserModel?: GiftCardSendUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftCardTemplateId' is not null or undefined
            assertParamExists('apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost', 'giftCardTemplateId', giftCardTemplateId)
            const localVarPath = `/api/v1/gift-cards/{GiftCardTemplateId}/send-gift-card`
                .replace(`{${"GiftCardTemplateId"}}`, encodeURIComponent(String(giftCardTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftCardSendUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GiftCardsIdDelete', 'id', id)
            const localVarPath = `/api/v1/gift-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GiftCardsIdGet', 'id', id)
            const localVarPath = `/api/v1/gift-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GiftCardsIdPatch', 'id', id)
            const localVarPath = `/api/v1/gift-cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {number} [id] 
         * @param {GiftCardType} [type] 
         * @param {number} [maxTextSize] 
         * @param {any} [imageUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsPost: async (id?: number, type?: GiftCardType, maxTextSize?: number, imageUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/gift-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', new Blob([JSON.stringify(type)], { type: "application/json", }));
            }
    
            if (maxTextSize !== undefined) { 
                localVarFormParams.append('MaxTextSize', maxTextSize as any);
            }
    
            if (imageUpload !== undefined) { 
                localVarFormParams.append('ImageUpload', imageUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {GiftCardType} [type] 
         * @param {number} [maxTextSize] 
         * @param {any} [newImageUpload] 
         * @param {number} [ownerAvatarId] 
         * @param {string} [ownerAvatarFileUrl] 
         * @param {string} [ownerAvatarCreatedDate] 
         * @param {number} [ownerAvatarSort] 
         * @param {Array<PhotoChildModel>} [ownerAvatarPreviews] 
         * @param {number} [ownerCountryId] 
         * @param {string} [ownerCountryName] 
         * @param {number} [ownerCountryLanguageId] 
         * @param {string} [ownerName] 
         * @param {string} [ownerPositionName] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsPut: async (id?: number, type?: GiftCardType, maxTextSize?: number, newImageUpload?: any, ownerAvatarId?: number, ownerAvatarFileUrl?: string, ownerAvatarCreatedDate?: string, ownerAvatarSort?: number, ownerAvatarPreviews?: Array<PhotoChildModel>, ownerCountryId?: number, ownerCountryName?: string, ownerCountryLanguageId?: number, ownerName?: string, ownerPositionName?: string, ownerUserId?: number, ownerGroupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/gift-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', new Blob([JSON.stringify(type)], { type: "application/json", }));
            }
    
            if (maxTextSize !== undefined) { 
                localVarFormParams.append('MaxTextSize', maxTextSize as any);
            }
    
            if (newImageUpload !== undefined) { 
                localVarFormParams.append('NewImageUpload', newImageUpload as any);
            }
    
            if (ownerAvatarId !== undefined) { 
                localVarFormParams.append('Owner.Avatar.Id', ownerAvatarId as any);
            }
    
            if (ownerAvatarFileUrl !== undefined) { 
                localVarFormParams.append('Owner.Avatar.FileUrl', ownerAvatarFileUrl as any);
            }
    
            if (ownerAvatarCreatedDate !== undefined) { 
                localVarFormParams.append('Owner.Avatar.CreatedDate', ownerAvatarCreatedDate as any);
            }
    
            if (ownerAvatarSort !== undefined) { 
                localVarFormParams.append('Owner.Avatar.Sort', ownerAvatarSort as any);
            }
                if (ownerAvatarPreviews) {
                localVarFormParams.append('Owner.Avatar.Previews', ownerAvatarPreviews.join(COLLECTION_FORMATS.csv));
            }

    
            if (ownerCountryId !== undefined) { 
                localVarFormParams.append('Owner.Country.Id', ownerCountryId as any);
            }
    
            if (ownerCountryName !== undefined) { 
                localVarFormParams.append('Owner.Country.Name', ownerCountryName as any);
            }
    
            if (ownerCountryLanguageId !== undefined) { 
                localVarFormParams.append('Owner.Country.LanguageId', ownerCountryLanguageId as any);
            }
    
            if (ownerName !== undefined) { 
                localVarFormParams.append('Owner.Name', ownerName as any);
            }
    
            if (ownerPositionName !== undefined) { 
                localVarFormParams.append('Owner.PositionName', ownerPositionName as any);
            }
    
            if (ownerUserId !== undefined) { 
                localVarFormParams.append('Owner.UserId', ownerUserId as any);
            }
    
            if (ownerGroupId !== undefined) { 
                localVarFormParams.append('Owner.GroupId', ownerGroupId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GiftCardApi - functional programming interface
 * @export
 */
export const GiftCardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GiftCardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [id] Фильтр по шаблону подарка
         * @param {GiftCardType} [type] Фильтр по типу подарка
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsGet(id?: number, type?: GiftCardType, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardTemplatesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsGet(id, type, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send gift card to user
         * @param {string} giftCardTemplateId 
         * @param {GiftCardSendUserModel} [giftCardSendUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost(giftCardTemplateId: string, giftCardSendUserModel?: GiftCardSendUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost(giftCardTemplateId, giftCardSendUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {number} [id] 
         * @param {GiftCardType} [type] 
         * @param {number} [maxTextSize] 
         * @param {any} [imageUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsPost(id?: number, type?: GiftCardType, maxTextSize?: number, imageUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsPost(id, type, maxTextSize, imageUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {GiftCardType} [type] 
         * @param {number} [maxTextSize] 
         * @param {any} [newImageUpload] 
         * @param {number} [ownerAvatarId] 
         * @param {string} [ownerAvatarFileUrl] 
         * @param {string} [ownerAvatarCreatedDate] 
         * @param {number} [ownerAvatarSort] 
         * @param {Array<PhotoChildModel>} [ownerAvatarPreviews] 
         * @param {number} [ownerCountryId] 
         * @param {string} [ownerCountryName] 
         * @param {number} [ownerCountryLanguageId] 
         * @param {string} [ownerName] 
         * @param {string} [ownerPositionName] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GiftCardsPut(id?: number, type?: GiftCardType, maxTextSize?: number, newImageUpload?: any, ownerAvatarId?: number, ownerAvatarFileUrl?: string, ownerAvatarCreatedDate?: string, ownerAvatarSort?: number, ownerAvatarPreviews?: Array<PhotoChildModel>, ownerCountryId?: number, ownerCountryName?: string, ownerCountryLanguageId?: number, ownerName?: string, ownerPositionName?: string, ownerUserId?: number, ownerGroupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftCardTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GiftCardsPut(id, type, maxTextSize, newImageUpload, ownerAvatarId, ownerAvatarFileUrl, ownerAvatarCreatedDate, ownerAvatarSort, ownerAvatarPreviews, ownerCountryId, ownerCountryName, ownerCountryLanguageId, ownerName, ownerPositionName, ownerUserId, ownerGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GiftCardApi - factory interface
 * @export
 */
export const GiftCardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GiftCardApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [id] Фильтр по шаблону подарка
         * @param {GiftCardType} [type] Фильтр по типу подарка
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsGet(id?: number, type?: GiftCardType, count?: number, offset?: number, options?: any): AxiosPromise<GiftCardTemplatesModel> {
            return localVarFp.apiV1GiftCardsGet(id, type, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send gift card to user
         * @param {string} giftCardTemplateId 
         * @param {GiftCardSendUserModel} [giftCardSendUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost(giftCardTemplateId: string, giftCardSendUserModel?: GiftCardSendUserModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost(giftCardTemplateId, giftCardSendUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1GiftCardsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsIdGet(id: number, options?: any): AxiosPromise<GiftCardTemplateModel> {
            return localVarFp.apiV1GiftCardsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<GiftCardTemplateModel> {
            return localVarFp.apiV1GiftCardsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {number} [id] 
         * @param {GiftCardType} [type] 
         * @param {number} [maxTextSize] 
         * @param {any} [imageUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsPost(id?: number, type?: GiftCardType, maxTextSize?: number, imageUpload?: any, options?: any): AxiosPromise<GiftCardTemplateModel> {
            return localVarFp.apiV1GiftCardsPost(id, type, maxTextSize, imageUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {GiftCardType} [type] 
         * @param {number} [maxTextSize] 
         * @param {any} [newImageUpload] 
         * @param {number} [ownerAvatarId] 
         * @param {string} [ownerAvatarFileUrl] 
         * @param {string} [ownerAvatarCreatedDate] 
         * @param {number} [ownerAvatarSort] 
         * @param {Array<PhotoChildModel>} [ownerAvatarPreviews] 
         * @param {number} [ownerCountryId] 
         * @param {string} [ownerCountryName] 
         * @param {number} [ownerCountryLanguageId] 
         * @param {string} [ownerName] 
         * @param {string} [ownerPositionName] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GiftCardsPut(id?: number, type?: GiftCardType, maxTextSize?: number, newImageUpload?: any, ownerAvatarId?: number, ownerAvatarFileUrl?: string, ownerAvatarCreatedDate?: string, ownerAvatarSort?: number, ownerAvatarPreviews?: Array<PhotoChildModel>, ownerCountryId?: number, ownerCountryName?: string, ownerCountryLanguageId?: number, ownerName?: string, ownerPositionName?: string, ownerUserId?: number, ownerGroupId?: number, options?: any): AxiosPromise<GiftCardTemplateModel> {
            return localVarFp.apiV1GiftCardsPut(id, type, maxTextSize, newImageUpload, ownerAvatarId, ownerAvatarFileUrl, ownerAvatarCreatedDate, ownerAvatarSort, ownerAvatarPreviews, ownerCountryId, ownerCountryName, ownerCountryLanguageId, ownerName, ownerPositionName, ownerUserId, ownerGroupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1GiftCardsGet operation in GiftCardApi.
 * @export
 * @interface GiftCardApiApiV1GiftCardsGetRequest
 */
export interface GiftCardApiApiV1GiftCardsGetRequest {
    /**
     * Фильтр по шаблону подарка
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsGet
     */
    readonly id?: number

    /**
     * Фильтр по типу подарка
     * @type {GiftCardType}
     * @memberof GiftCardApiApiV1GiftCardsGet
     */
    readonly type?: GiftCardType

    /**
     * Количеству объектов
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost operation in GiftCardApi.
 * @export
 * @interface GiftCardApiApiV1GiftCardsGiftCardTemplateIdSendGiftCardPostRequest
 */
export interface GiftCardApiApiV1GiftCardsGiftCardTemplateIdSendGiftCardPostRequest {
    /**
     * 
     * @type {string}
     * @memberof GiftCardApiApiV1GiftCardsGiftCardTemplateIdSendGiftCardPost
     */
    readonly giftCardTemplateId: string

    /**
     * 
     * @type {GiftCardSendUserModel}
     * @memberof GiftCardApiApiV1GiftCardsGiftCardTemplateIdSendGiftCardPost
     */
    readonly giftCardSendUserModel?: GiftCardSendUserModel
}

/**
 * Request parameters for apiV1GiftCardsIdDelete operation in GiftCardApi.
 * @export
 * @interface GiftCardApiApiV1GiftCardsIdDeleteRequest
 */
export interface GiftCardApiApiV1GiftCardsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1GiftCardsIdGet operation in GiftCardApi.
 * @export
 * @interface GiftCardApiApiV1GiftCardsIdGetRequest
 */
export interface GiftCardApiApiV1GiftCardsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1GiftCardsIdPatch operation in GiftCardApi.
 * @export
 * @interface GiftCardApiApiV1GiftCardsIdPatchRequest
 */
export interface GiftCardApiApiV1GiftCardsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof GiftCardApiApiV1GiftCardsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1GiftCardsPost operation in GiftCardApi.
 * @export
 * @interface GiftCardApiApiV1GiftCardsPostRequest
 */
export interface GiftCardApiApiV1GiftCardsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPost
     */
    readonly id?: number

    /**
     * 
     * @type {GiftCardType}
     * @memberof GiftCardApiApiV1GiftCardsPost
     */
    readonly type?: GiftCardType

    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPost
     */
    readonly maxTextSize?: number

    /**
     * 
     * @type {any}
     * @memberof GiftCardApiApiV1GiftCardsPost
     */
    readonly imageUpload?: any
}

/**
 * Request parameters for apiV1GiftCardsPut operation in GiftCardApi.
 * @export
 * @interface GiftCardApiApiV1GiftCardsPutRequest
 */
export interface GiftCardApiApiV1GiftCardsPutRequest {
    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly id?: number

    /**
     * 
     * @type {GiftCardType}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly type?: GiftCardType

    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly maxTextSize?: number

    /**
     * 
     * @type {any}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly newImageUpload?: any

    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerAvatarId?: number

    /**
     * 
     * @type {string}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerAvatarFileUrl?: string

    /**
     * 
     * @type {string}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerAvatarCreatedDate?: string

    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerAvatarSort?: number

    /**
     * 
     * @type {Array<PhotoChildModel>}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerAvatarPreviews?: Array<PhotoChildModel>

    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerCountryId?: number

    /**
     * 
     * @type {string}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerCountryName?: string

    /**
     * 
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerCountryLanguageId?: number

    /**
     * 
     * @type {string}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerName?: string

    /**
     * 
     * @type {string}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerPositionName?: string

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerUserId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof GiftCardApiApiV1GiftCardsPut
     */
    readonly ownerGroupId?: number
}

/**
 * GiftCardApi - object-oriented interface
 * @export
 * @class GiftCardApi
 * @extends {BaseAPI}
 */
export class GiftCardApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {GiftCardApiApiV1GiftCardsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public apiV1GiftCardsGet(requestParameters: GiftCardApiApiV1GiftCardsGetRequest = {}, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).apiV1GiftCardsGet(requestParameters.id, requestParameters.type, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send gift card to user
     * @param {GiftCardApiApiV1GiftCardsGiftCardTemplateIdSendGiftCardPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost(requestParameters: GiftCardApiApiV1GiftCardsGiftCardTemplateIdSendGiftCardPostRequest, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).apiV1GiftCardsGiftCardTemplateIdSendGiftCardPost(requestParameters.giftCardTemplateId, requestParameters.giftCardSendUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {GiftCardApiApiV1GiftCardsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public apiV1GiftCardsIdDelete(requestParameters: GiftCardApiApiV1GiftCardsIdDeleteRequest, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).apiV1GiftCardsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {GiftCardApiApiV1GiftCardsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public apiV1GiftCardsIdGet(requestParameters: GiftCardApiApiV1GiftCardsIdGetRequest, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).apiV1GiftCardsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {GiftCardApiApiV1GiftCardsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public apiV1GiftCardsIdPatch(requestParameters: GiftCardApiApiV1GiftCardsIdPatchRequest, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).apiV1GiftCardsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {GiftCardApiApiV1GiftCardsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public apiV1GiftCardsPost(requestParameters: GiftCardApiApiV1GiftCardsPostRequest = {}, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).apiV1GiftCardsPost(requestParameters.id, requestParameters.type, requestParameters.maxTextSize, requestParameters.imageUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {GiftCardApiApiV1GiftCardsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftCardApi
     */
    public apiV1GiftCardsPut(requestParameters: GiftCardApiApiV1GiftCardsPutRequest = {}, options?: AxiosRequestConfig) {
        return GiftCardApiFp(this.configuration).apiV1GiftCardsPut(requestParameters.id, requestParameters.type, requestParameters.maxTextSize, requestParameters.newImageUpload, requestParameters.ownerAvatarId, requestParameters.ownerAvatarFileUrl, requestParameters.ownerAvatarCreatedDate, requestParameters.ownerAvatarSort, requestParameters.ownerAvatarPreviews, requestParameters.ownerCountryId, requestParameters.ownerCountryName, requestParameters.ownerCountryLanguageId, requestParameters.ownerName, requestParameters.ownerPositionName, requestParameters.ownerUserId, requestParameters.ownerGroupId, options).then((request) => request(this.axios, this.basePath));
    }
}
