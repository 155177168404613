import { useCallback, useMemo, useState } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UsersPreviewModel } from 'services/api';

import { useUserApi } from './useUserApi';

const COUNT = 7;

export const useLoadItems = (query: string) => {
  const [offset, setOffset] = useState(COUNT);

  const { getUserPreviewsByQuery } = useUserApi();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['usersAutoComplete', query],
    () =>
      getUserPreviewsByQuery({
        count: COUNT,
        offset: 0,
        query,
      }),
    {
      suspense: false,
      enabled: query.length > 2,
    },
  );

  const handleSuccess = useCallback(
    (newData: UsersPreviewModel) => {
      setOffset((prewState) => prewState + COUNT);

      queryClient.setQueryData<UsersPreviewModel>(
        ['usersAutoComplete', query],
        (oldData) => {
          const oldItems = oldData?.items ?? [];
          const newItems = newData?.items ?? [];

          return {
            ...newData,
            items: [...oldItems, ...newItems],
          };
        },
      );
    },
    [query, queryClient],
  );

  const hasNextPage = useMemo(
    () => Boolean(data && data.total && data.total > offset),
    [offset, data],
  );

  const mutation = useMutation(
    () => getUserPreviewsByQuery({ count: COUNT, offset, query }),
    { onSuccess: handleSuccess },
  );

  const [sentryRef] = useInfiniteScroll({
    loading: mutation.isLoading,
    disabled: mutation.isError,
    hasNextPage,
    onLoadMore: mutation.mutate,
  });

  return {
    data,
    isLoading,
    hasNextPage,
    sentryRef,
  };
};
