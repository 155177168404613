import styled from 'styled-components';

export const MentionListItems = styled.div`
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px;
  width: 576px;
`;

export const MentionListItem = styled.button`
  border: 1px solid transparent;
  display: block;
  margin: 0;
  padding: 0.2rem 0.4rem;
  text-align: left;
  width: 100%;
  background: ${(props) => (props.itemScope ? '#EDF1FE' : 'transparent')};
`;
