import React, { FC, useCallback } from 'react';

// import Mention from '@tiptap/extension-mention';
import { BaseEmoji } from 'emoji-mart';

import { useIdeaEditor } from 'services/editors/idea/ideaEditorContext';

import { EmojiDropdownPicker } from './EmojiDropdownPicker';
import {
  ControlPaper,
  EditorButton,
  StyledContainer,
  StyledEditorContent,
  StyledFormItem,
} from './styles';

export type IdeaDescriptionFieldProps = {};
export const IdeaDescriptionField: FC<IdeaDescriptionFieldProps> = () => {
  const editor = useIdeaEditor();

  const handleEmojiClick = useCallback(
    (emoji: BaseEmoji) => {
      editor?.chain().insertContent(`${emoji.native}`).run();
    },
    [editor],
  );

  return (
    <>
      <StyledContainer>
        <StyledFormItem>
          <StyledEditorContent editor={editor as any} />
          <ControlPaper>
            <EditorButton>
              <EmojiDropdownPicker onClick={handleEmojiClick} />
            </EditorButton>
          </ControlPaper>
        </StyledFormItem>
      </StyledContainer>
    </>
  );
};
