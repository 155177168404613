import { VFC } from 'react';

import { WallContentType, WallReplyModel } from 'services/api';

import { PostMeta } from '../../PostMeta';
import { IdeaContent } from '../IdeaContent';
import { PostGiftContent } from '../PostGiftContent';
import { PostStandardContent } from '../PostStandardContent';
import { PostHeader, ReplyPost, ReplyPostWrapper } from './styles';

export type PostContentProps = {
  data: WallReplyModel;
};

export const PostReplyContent: VFC<PostContentProps> = ({ data }) => {
  return (
    <ReplyPostWrapper>
      <ReplyPost>
        <PostHeader>
          <PostMeta isSmall postMetaData={data} />
        </PostHeader>
        {data.wallContentType === WallContentType.Common && (
          <>
            {data.postContent && (
              <PostStandardContent data={data.postContent} />
            )}
          </>
        )}
        {data.wallContentType === WallContentType.GiftCard && (
          <>
            <PostGiftContent
              giftImage={data.giftCardImageUrl as string}
              text={data.postContent?.text as string}
            />
          </>
        )}
        {data.wallContentType === WallContentType.Idea && (
          <>{data.postContent && <IdeaContent data={data.postContent} />}</>
        )}
      </ReplyPost>
    </ReplyPostWrapper>
  );
};
