import { Close as CloseIcon } from '@styled-icons/material-outlined/Close';

import { Users } from 'components/Employees';
import { AnyUser } from 'domain/user';
import { ModalScrollBody, Title } from 'ui';

import { CloseButton, ModalPaper, StyledModal } from './styles';

interface UsersModalProps<T extends AnyUser> {
  title: string;
  open: boolean;
  users?: T[];
  onClose?: () => void;
}

export const UsersModal = <T extends AnyUser>(props: UsersModalProps<T>) => {
  const { open, title, users = [], onClose } = props;

  return (
    <StyledModal destroyOnClose visible={open}>
      <ModalPaper>
        <div>
          <Title variant="h2">{title}</Title>
          <CloseButton onClick={onClose}>
            <CloseIcon size={24} />
          </CloseButton>
        </div>
        <ModalScrollBody>
          <Users users={users} />
        </ModalScrollBody>
      </ModalPaper>
    </StyledModal>
  );
};
