/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BannerModel } from '../models';
// @ts-ignore
import { BannerPosition } from '../models';
// @ts-ignore
import { BannersModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * BannerApi - axios parameter creator
 * @export
 */
export const BannerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {string} [page] Страница
         * @param {BannerPosition} [position] Позиция на странице
         * @param {number} [countryId] Идентификатор страны
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersGet: async (page?: string, position?: BannerPosition, countryId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (position !== undefined) {
                localVarQueryParameter['Position'] = position;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BannersIdDelete', 'id', id)
            const localVarPath = `/api/v1/banners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BannersIdGet', 'id', id)
            const localVarPath = `/api/v1/banners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update partially
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BannersIdPatch', 'id', id)
            const localVarPath = `/api/v1/banners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {number} [id] 
         * @param {string} [page] 
         * @param {string} [redirectUrl] 
         * @param {number} [position] 
         * @param {any} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersPost: async (id?: number, page?: string, redirectUrl?: string, position?: number, fileUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (page !== undefined) { 
                localVarFormParams.append('Page', page as any);
            }
    
            if (redirectUrl !== undefined) { 
                localVarFormParams.append('RedirectUrl', redirectUrl as any);
            }
    
            if (position !== undefined) { 
                localVarFormParams.append('Position', position as any);
            }
    
            if (fileUpload !== undefined) { 
                localVarFormParams.append('FileUpload', fileUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {string} [page] 
         * @param {string} [redirectUrl] 
         * @param {number} [position] 
         * @param {any} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersPut: async (id?: number, page?: string, redirectUrl?: string, position?: number, fileUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (page !== undefined) { 
                localVarFormParams.append('Page', page as any);
            }
    
            if (redirectUrl !== undefined) { 
                localVarFormParams.append('RedirectUrl', redirectUrl as any);
            }
    
            if (position !== undefined) { 
                localVarFormParams.append('Position', position as any);
            }
    
            if (fileUpload !== undefined) { 
                localVarFormParams.append('FileUpload', fileUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerApi - functional programming interface
 * @export
 */
export const BannerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {string} [page] Страница
         * @param {BannerPosition} [position] Позиция на странице
         * @param {number} [countryId] Идентификатор страны
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BannersGet(page?: string, position?: BannerPosition, countryId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BannersGet(page, position, countryId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BannersIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BannersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BannersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BannersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update partially
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BannersIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BannersIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {number} [id] 
         * @param {string} [page] 
         * @param {string} [redirectUrl] 
         * @param {number} [position] 
         * @param {any} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BannersPost(id?: number, page?: string, redirectUrl?: string, position?: number, fileUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BannersPost(id, page, redirectUrl, position, fileUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {string} [page] 
         * @param {string} [redirectUrl] 
         * @param {number} [position] 
         * @param {any} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BannersPut(id?: number, page?: string, redirectUrl?: string, position?: number, fileUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BannersPut(id, page, redirectUrl, position, fileUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannerApi - factory interface
 * @export
 */
export const BannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {string} [page] Страница
         * @param {BannerPosition} [position] Позиция на странице
         * @param {number} [countryId] Идентификатор страны
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersGet(page?: string, position?: BannerPosition, countryId?: number, count?: number, offset?: number, options?: any): AxiosPromise<BannersModel> {
            return localVarFp.apiV1BannersGet(page, position, countryId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1BannersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersIdGet(id: number, options?: any): AxiosPromise<BannerModel> {
            return localVarFp.apiV1BannersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update partially
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<BannerModel> {
            return localVarFp.apiV1BannersIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {number} [id] 
         * @param {string} [page] 
         * @param {string} [redirectUrl] 
         * @param {number} [position] 
         * @param {any} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersPost(id?: number, page?: string, redirectUrl?: string, position?: number, fileUpload?: any, options?: any): AxiosPromise<BannerModel> {
            return localVarFp.apiV1BannersPost(id, page, redirectUrl, position, fileUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {string} [page] 
         * @param {string} [redirectUrl] 
         * @param {number} [position] 
         * @param {any} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BannersPut(id?: number, page?: string, redirectUrl?: string, position?: number, fileUpload?: any, options?: any): AxiosPromise<BannerModel> {
            return localVarFp.apiV1BannersPut(id, page, redirectUrl, position, fileUpload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1BannersGet operation in BannerApi.
 * @export
 * @interface BannerApiApiV1BannersGetRequest
 */
export interface BannerApiApiV1BannersGetRequest {
    /**
     * Страница
     * @type {string}
     * @memberof BannerApiApiV1BannersGet
     */
    readonly page?: string

    /**
     * Позиция на странице
     * @type {BannerPosition}
     * @memberof BannerApiApiV1BannersGet
     */
    readonly position?: BannerPosition

    /**
     * Идентификатор страны
     * @type {number}
     * @memberof BannerApiApiV1BannersGet
     */
    readonly countryId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof BannerApiApiV1BannersGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof BannerApiApiV1BannersGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1BannersIdDelete operation in BannerApi.
 * @export
 * @interface BannerApiApiV1BannersIdDeleteRequest
 */
export interface BannerApiApiV1BannersIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof BannerApiApiV1BannersIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1BannersIdGet operation in BannerApi.
 * @export
 * @interface BannerApiApiV1BannersIdGetRequest
 */
export interface BannerApiApiV1BannersIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BannerApiApiV1BannersIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1BannersIdPatch operation in BannerApi.
 * @export
 * @interface BannerApiApiV1BannersIdPatchRequest
 */
export interface BannerApiApiV1BannersIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof BannerApiApiV1BannersIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof BannerApiApiV1BannersIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1BannersPost operation in BannerApi.
 * @export
 * @interface BannerApiApiV1BannersPostRequest
 */
export interface BannerApiApiV1BannersPostRequest {
    /**
     * 
     * @type {number}
     * @memberof BannerApiApiV1BannersPost
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof BannerApiApiV1BannersPost
     */
    readonly page?: string

    /**
     * 
     * @type {string}
     * @memberof BannerApiApiV1BannersPost
     */
    readonly redirectUrl?: string

    /**
     * 
     * @type {number}
     * @memberof BannerApiApiV1BannersPost
     */
    readonly position?: number

    /**
     * 
     * @type {any}
     * @memberof BannerApiApiV1BannersPost
     */
    readonly fileUpload?: any
}

/**
 * Request parameters for apiV1BannersPut operation in BannerApi.
 * @export
 * @interface BannerApiApiV1BannersPutRequest
 */
export interface BannerApiApiV1BannersPutRequest {
    /**
     * 
     * @type {number}
     * @memberof BannerApiApiV1BannersPut
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof BannerApiApiV1BannersPut
     */
    readonly page?: string

    /**
     * 
     * @type {string}
     * @memberof BannerApiApiV1BannersPut
     */
    readonly redirectUrl?: string

    /**
     * 
     * @type {number}
     * @memberof BannerApiApiV1BannersPut
     */
    readonly position?: number

    /**
     * 
     * @type {any}
     * @memberof BannerApiApiV1BannersPut
     */
    readonly fileUpload?: any
}

/**
 * BannerApi - object-oriented interface
 * @export
 * @class BannerApi
 * @extends {BaseAPI}
 */
export class BannerApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {BannerApiApiV1BannersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public apiV1BannersGet(requestParameters: BannerApiApiV1BannersGetRequest = {}, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).apiV1BannersGet(requestParameters.page, requestParameters.position, requestParameters.countryId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model
     * @param {BannerApiApiV1BannersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public apiV1BannersIdDelete(requestParameters: BannerApiApiV1BannersIdDeleteRequest, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).apiV1BannersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {BannerApiApiV1BannersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public apiV1BannersIdGet(requestParameters: BannerApiApiV1BannersIdGetRequest, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).apiV1BannersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update partially
     * @param {BannerApiApiV1BannersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public apiV1BannersIdPatch(requestParameters: BannerApiApiV1BannersIdPatchRequest, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).apiV1BannersIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {BannerApiApiV1BannersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public apiV1BannersPost(requestParameters: BannerApiApiV1BannersPostRequest = {}, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).apiV1BannersPost(requestParameters.id, requestParameters.page, requestParameters.redirectUrl, requestParameters.position, requestParameters.fileUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {BannerApiApiV1BannersPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public apiV1BannersPut(requestParameters: BannerApiApiV1BannersPutRequest = {}, options?: AxiosRequestConfig) {
        return BannerApiFp(this.configuration).apiV1BannersPut(requestParameters.id, requestParameters.page, requestParameters.redirectUrl, requestParameters.position, requestParameters.fileUpload, options).then((request) => request(this.axios, this.basePath));
    }
}
