import styled from 'styled-components';

import { Paper } from 'ui';

export const UsersAutoCompleteRoot = styled.div`
  position: relative;
`;

export const Popover = styled(Paper).attrs({
  elevation: 2,
  role: 'tooltip',
  tabIndex: -1,
})`
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 8px 16px 8px 0;
  width: 100%;
  z-index: 10;
  max-height: 256px;
`;
