import { Key, VFC, useCallback, useMemo, useState } from 'react';

import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { ExpandMore } from '@styled-icons/material-outlined/ExpandMore';
import Spin from 'antd/lib/spin';
import { DataNode } from 'antd/lib/tree';

import { useStructureContext } from 'pages/Employees/structureContext';
import {
  DepartmentApiApiV1DepartmentsGetRequest,
  DepartmentModel,
  api,
} from 'services/api';
import { useTranslation } from 'services/i18n';
import { PAGE_PARAM, Pagination } from 'ui/Pagination';
import { Widget } from 'ui/Widget';

import { PaginationContainer, Tree } from './styles';
import { COUNTRY_PARAM, ITEM_PER_PAGE, STRUCTURE_PARAM } from './types';

export const StructureTree: VFC = () => {
  const [expandKeys, setExpandKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [autoExpand, setAutoExpand] = useState(true);
  const [totalItems, setTotalItems] = useState<number>();

  const [searchParams, setSearchParams] = useSearchParams();

  const { setTreeData, setUsersList, treeData } = useStructureContext();

  const { t } = useTranslation('common');

  const countryIdParam = useMemo(
    () => searchParams.get(COUNTRY_PARAM),
    [searchParams],
  );

  const structureIdParam = useMemo(
    () => searchParams.get(STRUCTURE_PARAM),
    [searchParams],
  );

  const numberPageParam = useMemo(
    () => searchParams.get(PAGE_PARAM),
    [searchParams],
  );

  const prepareTreeData = (list: DepartmentModel[]) => {
    return list.reduce((prev, curr) => {
      if (!curr.id || !curr.name) return prev;

      const preparedItem = {
        key: curr.id,
        title: curr.name,
        users: curr.users,
        isLeaf: !curr.children?.length,
        children: prepareTreeData(curr.children || []),
        selected: false,
      };
      prev?.push(preparedItem);
      return prev;
    }, [] as DataNode[] | undefined);
  };

  const getStructureIsHeadOnly = async (
    params: DepartmentApiApiV1DepartmentsGetRequest,
  ) => {
    const { data } = await api.department.apiV1DepartmentsGet(params);
    return { items: data.items ?? [], total: data.total };
  };

  const { isLoading } = useQuery(
    [structureIdParam, countryIdParam, numberPageParam],
    () => {
      return getStructureIsHeadOnly({
        ...(countryIdParam && { countryId: Number(countryIdParam) }),
        count: ITEM_PER_PAGE,
        offset:
          numberPageParam && Number(numberPageParam) === 1
            ? 0
            : Number(numberPageParam) * ITEM_PER_PAGE - ITEM_PER_PAGE,
        isHeadOnly: true,
      });
    },
    {
      suspense: false,
      onSuccess: (newData) => {
        setTotalItems(newData.total);
        setTreeData(prepareTreeData(newData.items));
      },
    },
  );

  const handleSelect = useCallback(
    (value, node) => {
      const selectedKey = node.node.key;
      const selectedUsers = node.node.users;

      setExpandKeys((prevState) => {
        const exist = prevState.indexOf(selectedKey);
        const newState = [...prevState];

        if (exist >= 0) {
          newState.splice(exist, 1);
          return newState;
        }

        newState.push(node.node.key);
        return newState;
      });
      if (node.node.isLeaf) setUsersList(selectedUsers);
      setSelectedKeys([selectedKey]);
      setSearchParams(searchParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedKeys, searchParams, setSearchParams],
  );

  const handleExpand = useCallback((value) => {
    setAutoExpand(false);
    setExpandKeys(value);
  }, []);

  useQuery([countryIdParam], () => {
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  });

  return (
    <Widget title={t('structure')}>
      <Spin spinning={isLoading}>
        <Tree
          showIcon
          autoExpandParent={autoExpand}
          expandedKeys={expandKeys}
          icon={false}
          selectedKeys={selectedKeys}
          switcherIcon={<ExpandMore />}
          treeData={treeData}
          onExpand={handleExpand}
          onSelect={handleSelect}
        />
        <PaginationContainer>
          <Pagination
            hideOnSinglePage
            showLessItems
            pageSize={ITEM_PER_PAGE}
            showSizeChanger={false}
            size="small"
            total={totalItems}
          />
        </PaginationContainer>
      </Spin>
    </Widget>
  );
};
