import styled from 'styled-components';

import { Link } from 'ui/Link';

export const StyledMoreLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: 600;
  padding: 17px 14px 17px 0;

  &:hover {
    text-decoration: underline ${({ theme }) => theme.palette.primary.light};
  }
`;
