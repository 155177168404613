import { FC, MouseEventHandler } from 'react';

import { ButtonWrapper } from './styles';

type SelectPeopleButtonProps = {
  onClick: MouseEventHandler<any>;
};

export const SelectPeopleButton: FC<SelectPeopleButtonProps> = ({
  onClick,
}) => {
  return (
    <ButtonWrapper type="button" onClick={onClick}>
      <svg
        fill="none"
        height="15"
        viewBox="0 0 17 15"
        width="17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.16667 6.99967C9.00833 6.99967 10.5 5.50801 10.5 3.66634C10.5 1.82467 9.00833 0.333008 7.16667 0.333008C5.325 0.333008 3.83333 1.82467 3.83333 3.66634C3.83333 5.50801 5.325 6.99967 7.16667 6.99967ZM7.16667 1.99967C8.08333 1.99967 8.83333 2.74967 8.83333 3.66634C8.83333 4.58301 8.08333 5.33301 7.16667 5.33301C6.25 5.33301 5.5 4.58301 5.5 3.66634C5.5 2.74967 6.25 1.99967 7.16667 1.99967ZM2.16667 11.9997C2.33333 11.4747 4.30833 10.5997 6.3 10.383L8 8.71634C7.675 8.68301 7.43333 8.66634 7.16667 8.66634C4.94167 8.66634 0.5 9.78301 0.5 11.9997V13.6663H8L6.33333 11.9997H2.16667ZM15.1667 7.41634L10.8917 11.7247L9.16667 9.99134L8 11.1663L10.8917 14.083L16.3333 8.59134L15.1667 7.41634Z"
          fill="white"
        />
      </svg>
    </ButtonWrapper>
  );
};
