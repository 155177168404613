import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface PostState {
  isCommentsVisible: boolean;
  isReplyVisible: boolean;
  isContentExpanded: boolean;
  cutContent: { html: string; width: number } | null;
}
export interface SavePostState {
  (state: PostState): void;
}
export interface FetchPostState {
  (): PostState | null;
}

export const DEFAULT_POST_STATE: PostState = {
  isCommentsVisible: false,
  isReplyVisible: false,
  isContentExpanded: false,
  cutContent: null,
};

export const PostContext = createContext({
  id: undefined as number | undefined,
  postState: null as PostState | null,
  savePostState: {} as SavePostState,
  fetchPostState: {} as FetchPostState,
});

export const usePostContext = () => {
  const context = useContext(PostContext);

  if (!context) {
    throw new Error('usePostContext must be used within PostContext');
  }
  return context;
};

export interface PostContextProviderProps {
  id: number;
}

export const PostContextProvider: FC<PostContextProviderProps> = ({
  id,
  children,
}) => {
  const [postState, setPostState] = useState<PostState | null>(null);

  const pathKey = useMemo(
    () => `${document.location.pathname}/post/${id}`,
    [id],
  );

  const fetchPostState: FetchPostState = useCallback(() => {
    const strState = sessionStorage.getItem(pathKey);
    let state: PostState | null = null;
    try {
      state = strState ? JSON.parse(strState) : null;
    } finally {
      setPostState(state);
    }
    return state;
  }, [pathKey]);

  const savePostState: SavePostState = useCallback(
    (state) => {
      setPostState(state);
      sessionStorage.setItem(pathKey, JSON.stringify(state));
    },
    [pathKey],
  );

  useEffect(() => {
    if (id) fetchPostState();
  }, [fetchPostState, id]);

  const postContextValue = useMemo(
    () => ({
      id,
      postState,
      savePostState,
      fetchPostState,
    }),

    [fetchPostState, id, postState, savePostState],
  );

  return (
    <PostContext.Provider value={postContextValue}>
      {children}
    </PostContext.Provider>
  );
};
