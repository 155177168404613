import { FC, memo } from 'react';

import { Add } from '@styled-icons/material-outlined';
import { Input } from 'antd';

import { useTranslation } from 'services/i18n';
import { IconButton } from 'ui';

import { StyledContainer, StyledFormItem } from './styles';

export type PostEditorPlaceholderProps = {
  condition: boolean;
  placeholder?: string;
  onClick?: () => void;
};

export const PostEditorPlaceholder: FC<PostEditorPlaceholderProps> = memo(
  ({ condition, placeholder, onClick, children }) => {
    const { t } = useTranslation('common');

    return (
      <>
        {condition ? (
          children
        ) : (
          <StyledContainer>
            <StyledFormItem>
              <Input
                bordered={false}
                placeholder={placeholder ?? `${t('what_on_your_mind')}?`}
                onClick={onClick}
              />
              <IconButton>
                <Add size={22} />
              </IconButton>
            </StyledFormItem>
          </StyledContainer>
        )}
      </>
    );
  },
);
