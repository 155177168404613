import { List } from 'react-virtualized';

import styled from 'styled-components';

import { ItemContainer } from 'components/UserCardItem/styles';
import { styledButtonCss } from 'ui/Button/styles';

export const VirtualizedList = styled(List)`
  &.ReactVirtualized__List {
    list-style: none;
    margin: 0;
  }
  background-color: white;
`;

export const Gap = styled.div`
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16px;
  }
`;

export const ItemButton = styled.a`
  ${styledButtonCss};
  font-size: 14px;
  height: 32px;
  &:hover {
    color: white;
  }
`;

export const EmployeeItemContainer = styled.div`
  position: relative;
  ${ItemContainer} {
    margin-left: 55px;
  }
`;

export const Letter = styled.div`
  color: #dbe0e5;
  position: absolute;
  left: 8px;
  top: 25px;
  font-size: 28px;
  font-weight: 700;
`;
