import { VFC, memo, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Favorite, FavoriteBorder } from '@styled-icons/material-outlined';

import { UsersModal } from 'components/Employees/UsersModal';
import { UsersTooltip } from 'components/Employees/UsersTooltip';
import { UserProfileModel } from 'services/api';
import { useTranslation } from 'services/i18n';
import { IconButton } from 'ui';

import { LikeButtonProps } from './types';

export const LikeButton: VFC<LikeButtonProps> = memo((props) => {
  const { likes, isLiked, ...buttonProps } = props;
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleModalCloseClick = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleModalOpenClick = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleAvatarClick = useCallback(
    (usr: UserProfileModel) => {
      navigate(`/profile/${usr.id}`);
    },
    [navigate],
  );

  return (
    <>
      <UsersTooltip
        active={!buttonProps.disabled && likes.total > 0}
        title={t(
          likes?.total === 1
            ? 'liked_tooltip_title_one'
            : 'liked_tooltip_title_many',
          {
            count: likes?.total as number,
          },
        )}
        users={likes.items as UserProfileModel[]}
        onAvatarClick={handleAvatarClick}
        onChevroneClick={handleModalOpenClick}
      >
        <IconButton type="button" {...buttonProps}>
          {isLiked ? <Favorite size={14} /> : <FavoriteBorder size={14} />}
          {likes.total}
        </IconButton>
      </UsersTooltip>
      <UsersModal
        open={openModal}
        title={t(
          likes?.total === 1
            ? 'liked_tooltip_title_one'
            : 'liked_tooltip_title_many',
          {
            count: likes?.total as number,
          },
        )}
        users={likes?.items as UserProfileModel[]}
        onClose={handleModalCloseClick}
      />
    </>
  );
});
