/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { LinkCreateModel } from '../models';
// @ts-ignore
import { LinkModel } from '../models';
// @ts-ignore
import { LinkType } from '../models';
// @ts-ignore
import { LinkTypesModel } from '../models';
// @ts-ignore
import { LinkUpdateModel } from '../models';
// @ts-ignore
import { LinksModel } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { UpdateVisibilityLinksRequest } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * LinkApi - axios parameter creator
 * @export
 */
export const LinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get links by filter
         * @param {LinkType} [type] Фильтр по типу
         * @param {Array<number>} [countries] Фильтр по странам
         * @param {boolean} [isVisible] Фильтр по скрытым ссылкам
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksGet: async (type?: LinkType, countries?: Array<number>, isVisible?: boolean, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (countries) {
                localVarQueryParameter['Countries'] = countries;
            }

            if (isVisible !== undefined) {
                localVarQueryParameter['IsVisible'] = isVisible;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1LinksIdDelete', 'id', id)
            const localVarPath = `/api/v1/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get link by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1LinksIdGet', 'id', id)
            const localVarPath = `/api/v1/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1LinksIdPatch', 'id', id)
            const localVarPath = `/api/v1/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {LinkCreateModel} [linkCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksPost: async (linkCreateModel?: LinkCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {LinkUpdateModel} [linkUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksPut: async (linkUpdateModel?: LinkUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of types
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksTypesGet: async (count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/links/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {UpdateVisibilityLinksRequest} [updateVisibilityLinksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksVisiblePut: async (updateVisibilityLinksRequest?: UpdateVisibilityLinksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/links/visible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVisibilityLinksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkApi - functional programming interface
 * @export
 */
export const LinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get links by filter
         * @param {LinkType} [type] Фильтр по типу
         * @param {Array<number>} [countries] Фильтр по странам
         * @param {boolean} [isVisible] Фильтр по скрытым ссылкам
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LinksGet(type?: LinkType, countries?: Array<number>, isVisible?: boolean, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinksModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LinksGet(type, countries, isVisible, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LinksIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LinksIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get link by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LinksIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LinksIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LinksIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LinksIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {LinkCreateModel} [linkCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LinksPost(linkCreateModel?: LinkCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LinksPost(linkCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {LinkUpdateModel} [linkUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LinksPut(linkUpdateModel?: LinkUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LinksPut(linkUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of types
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LinksTypesGet(count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkTypesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LinksTypesGet(count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {UpdateVisibilityLinksRequest} [updateVisibilityLinksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LinksVisiblePut(updateVisibilityLinksRequest?: UpdateVisibilityLinksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LinksVisiblePut(updateVisibilityLinksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LinkApi - factory interface
 * @export
 */
export const LinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkApiFp(configuration)
    return {
        /**
         * 
         * @summary Get links by filter
         * @param {LinkType} [type] Фильтр по типу
         * @param {Array<number>} [countries] Фильтр по странам
         * @param {boolean} [isVisible] Фильтр по скрытым ссылкам
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksGet(type?: LinkType, countries?: Array<number>, isVisible?: boolean, count?: number, offset?: number, options?: any): AxiosPromise<LinksModel> {
            return localVarFp.apiV1LinksGet(type, countries, isVisible, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1LinksIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get link by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksIdGet(id: number, options?: any): AxiosPromise<LinkModel> {
            return localVarFp.apiV1LinksIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<LinkModel> {
            return localVarFp.apiV1LinksIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {LinkCreateModel} [linkCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksPost(linkCreateModel?: LinkCreateModel, options?: any): AxiosPromise<LinkModel> {
            return localVarFp.apiV1LinksPost(linkCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {LinkUpdateModel} [linkUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksPut(linkUpdateModel?: LinkUpdateModel, options?: any): AxiosPromise<LinkModel> {
            return localVarFp.apiV1LinksPut(linkUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of types
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksTypesGet(count?: number, offset?: number, options?: any): AxiosPromise<LinkTypesModel> {
            return localVarFp.apiV1LinksTypesGet(count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {UpdateVisibilityLinksRequest} [updateVisibilityLinksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LinksVisiblePut(updateVisibilityLinksRequest?: UpdateVisibilityLinksRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1LinksVisiblePut(updateVisibilityLinksRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1LinksGet operation in LinkApi.
 * @export
 * @interface LinkApiApiV1LinksGetRequest
 */
export interface LinkApiApiV1LinksGetRequest {
    /**
     * Фильтр по типу
     * @type {LinkType}
     * @memberof LinkApiApiV1LinksGet
     */
    readonly type?: LinkType

    /**
     * Фильтр по странам
     * @type {Array<number>}
     * @memberof LinkApiApiV1LinksGet
     */
    readonly countries?: Array<number>

    /**
     * Фильтр по скрытым ссылкам
     * @type {boolean}
     * @memberof LinkApiApiV1LinksGet
     */
    readonly isVisible?: boolean

    /**
     * Количеству объектов
     * @type {number}
     * @memberof LinkApiApiV1LinksGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof LinkApiApiV1LinksGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1LinksIdDelete operation in LinkApi.
 * @export
 * @interface LinkApiApiV1LinksIdDeleteRequest
 */
export interface LinkApiApiV1LinksIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkApiApiV1LinksIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1LinksIdGet operation in LinkApi.
 * @export
 * @interface LinkApiApiV1LinksIdGetRequest
 */
export interface LinkApiApiV1LinksIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkApiApiV1LinksIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1LinksIdPatch operation in LinkApi.
 * @export
 * @interface LinkApiApiV1LinksIdPatchRequest
 */
export interface LinkApiApiV1LinksIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkApiApiV1LinksIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof LinkApiApiV1LinksIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1LinksPost operation in LinkApi.
 * @export
 * @interface LinkApiApiV1LinksPostRequest
 */
export interface LinkApiApiV1LinksPostRequest {
    /**
     * 
     * @type {LinkCreateModel}
     * @memberof LinkApiApiV1LinksPost
     */
    readonly linkCreateModel?: LinkCreateModel
}

/**
 * Request parameters for apiV1LinksPut operation in LinkApi.
 * @export
 * @interface LinkApiApiV1LinksPutRequest
 */
export interface LinkApiApiV1LinksPutRequest {
    /**
     * 
     * @type {LinkUpdateModel}
     * @memberof LinkApiApiV1LinksPut
     */
    readonly linkUpdateModel?: LinkUpdateModel
}

/**
 * Request parameters for apiV1LinksTypesGet operation in LinkApi.
 * @export
 * @interface LinkApiApiV1LinksTypesGetRequest
 */
export interface LinkApiApiV1LinksTypesGetRequest {
    /**
     * Количеству объектов
     * @type {number}
     * @memberof LinkApiApiV1LinksTypesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof LinkApiApiV1LinksTypesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1LinksVisiblePut operation in LinkApi.
 * @export
 * @interface LinkApiApiV1LinksVisiblePutRequest
 */
export interface LinkApiApiV1LinksVisiblePutRequest {
    /**
     * 
     * @type {UpdateVisibilityLinksRequest}
     * @memberof LinkApiApiV1LinksVisiblePut
     */
    readonly updateVisibilityLinksRequest?: UpdateVisibilityLinksRequest
}

/**
 * LinkApi - object-oriented interface
 * @export
 * @class LinkApi
 * @extends {BaseAPI}
 */
export class LinkApi extends BaseAPI {
    /**
     * 
     * @summary Get links by filter
     * @param {LinkApiApiV1LinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkApi
     */
    public apiV1LinksGet(requestParameters: LinkApiApiV1LinksGetRequest = {}, options?: AxiosRequestConfig) {
        return LinkApiFp(this.configuration).apiV1LinksGet(requestParameters.type, requestParameters.countries, requestParameters.isVisible, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {LinkApiApiV1LinksIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkApi
     */
    public apiV1LinksIdDelete(requestParameters: LinkApiApiV1LinksIdDeleteRequest, options?: AxiosRequestConfig) {
        return LinkApiFp(this.configuration).apiV1LinksIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get link by id
     * @param {LinkApiApiV1LinksIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkApi
     */
    public apiV1LinksIdGet(requestParameters: LinkApiApiV1LinksIdGetRequest, options?: AxiosRequestConfig) {
        return LinkApiFp(this.configuration).apiV1LinksIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {LinkApiApiV1LinksIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkApi
     */
    public apiV1LinksIdPatch(requestParameters: LinkApiApiV1LinksIdPatchRequest, options?: AxiosRequestConfig) {
        return LinkApiFp(this.configuration).apiV1LinksIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {LinkApiApiV1LinksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkApi
     */
    public apiV1LinksPost(requestParameters: LinkApiApiV1LinksPostRequest = {}, options?: AxiosRequestConfig) {
        return LinkApiFp(this.configuration).apiV1LinksPost(requestParameters.linkCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {LinkApiApiV1LinksPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkApi
     */
    public apiV1LinksPut(requestParameters: LinkApiApiV1LinksPutRequest = {}, options?: AxiosRequestConfig) {
        return LinkApiFp(this.configuration).apiV1LinksPut(requestParameters.linkUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of types
     * @param {LinkApiApiV1LinksTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkApi
     */
    public apiV1LinksTypesGet(requestParameters: LinkApiApiV1LinksTypesGetRequest = {}, options?: AxiosRequestConfig) {
        return LinkApiFp(this.configuration).apiV1LinksTypesGet(requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update visible Tuilife section pages
     * @param {LinkApiApiV1LinksVisiblePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkApi
     */
    public apiV1LinksVisiblePut(requestParameters: LinkApiApiV1LinksVisiblePutRequest = {}, options?: AxiosRequestConfig) {
        return LinkApiFp(this.configuration).apiV1LinksVisiblePut(requestParameters.updateVisibilityLinksRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
