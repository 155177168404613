/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AlbumCreateModel } from '../models';
// @ts-ignore
import { AlbumModel } from '../models';
// @ts-ignore
import { AlbumUpdateModel } from '../models';
// @ts-ignore
import { AlbumsModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * AlbumApi - axios parameter creator
 * @export
 */
export const AlbumApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [albumId] Фильтр по идентификатору альбома
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsGet: async (userId?: number, groupId?: number, albumId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/albums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (albumId !== undefined) {
                localVarQueryParameter['AlbumId'] = albumId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AlbumsIdDelete', 'id', id)
            const localVarPath = `/api/v1/albums/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AlbumsIdGet', 'id', id)
            const localVarPath = `/api/v1/albums/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AlbumsIdPatch', 'id', id)
            const localVarPath = `/api/v1/albums/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {AlbumCreateModel} [albumCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsPost: async (albumCreateModel?: AlbumCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/albums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(albumCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {AlbumUpdateModel} [albumUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsPut: async (albumUpdateModel?: AlbumUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/albums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(albumUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlbumApi - functional programming interface
 * @export
 */
export const AlbumApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlbumApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [albumId] Фильтр по идентификатору альбома
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AlbumsGet(userId?: number, groupId?: number, albumId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlbumsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AlbumsGet(userId, groupId, albumId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AlbumsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AlbumsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AlbumsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlbumModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AlbumsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AlbumsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlbumModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AlbumsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {AlbumCreateModel} [albumCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AlbumsPost(albumCreateModel?: AlbumCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlbumModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AlbumsPost(albumCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {AlbumUpdateModel} [albumUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AlbumsPut(albumUpdateModel?: AlbumUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlbumModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AlbumsPut(albumUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlbumApi - factory interface
 * @export
 */
export const AlbumApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlbumApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [albumId] Фильтр по идентификатору альбома
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsGet(userId?: number, groupId?: number, albumId?: number, count?: number, offset?: number, options?: any): AxiosPromise<AlbumsModel> {
            return localVarFp.apiV1AlbumsGet(userId, groupId, albumId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AlbumsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsIdGet(id: number, options?: any): AxiosPromise<AlbumModel> {
            return localVarFp.apiV1AlbumsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<AlbumModel> {
            return localVarFp.apiV1AlbumsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {AlbumCreateModel} [albumCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsPost(albumCreateModel?: AlbumCreateModel, options?: any): AxiosPromise<AlbumModel> {
            return localVarFp.apiV1AlbumsPost(albumCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {AlbumUpdateModel} [albumUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AlbumsPut(albumUpdateModel?: AlbumUpdateModel, options?: any): AxiosPromise<AlbumModel> {
            return localVarFp.apiV1AlbumsPut(albumUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AlbumsGet operation in AlbumApi.
 * @export
 * @interface AlbumApiApiV1AlbumsGetRequest
 */
export interface AlbumApiApiV1AlbumsGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof AlbumApiApiV1AlbumsGet
     */
    readonly userId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof AlbumApiApiV1AlbumsGet
     */
    readonly groupId?: number

    /**
     * Фильтр по идентификатору альбома
     * @type {number}
     * @memberof AlbumApiApiV1AlbumsGet
     */
    readonly albumId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof AlbumApiApiV1AlbumsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof AlbumApiApiV1AlbumsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1AlbumsIdDelete operation in AlbumApi.
 * @export
 * @interface AlbumApiApiV1AlbumsIdDeleteRequest
 */
export interface AlbumApiApiV1AlbumsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof AlbumApiApiV1AlbumsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1AlbumsIdGet operation in AlbumApi.
 * @export
 * @interface AlbumApiApiV1AlbumsIdGetRequest
 */
export interface AlbumApiApiV1AlbumsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AlbumApiApiV1AlbumsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1AlbumsIdPatch operation in AlbumApi.
 * @export
 * @interface AlbumApiApiV1AlbumsIdPatchRequest
 */
export interface AlbumApiApiV1AlbumsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof AlbumApiApiV1AlbumsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof AlbumApiApiV1AlbumsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1AlbumsPost operation in AlbumApi.
 * @export
 * @interface AlbumApiApiV1AlbumsPostRequest
 */
export interface AlbumApiApiV1AlbumsPostRequest {
    /**
     * 
     * @type {AlbumCreateModel}
     * @memberof AlbumApiApiV1AlbumsPost
     */
    readonly albumCreateModel?: AlbumCreateModel
}

/**
 * Request parameters for apiV1AlbumsPut operation in AlbumApi.
 * @export
 * @interface AlbumApiApiV1AlbumsPutRequest
 */
export interface AlbumApiApiV1AlbumsPutRequest {
    /**
     * 
     * @type {AlbumUpdateModel}
     * @memberof AlbumApiApiV1AlbumsPut
     */
    readonly albumUpdateModel?: AlbumUpdateModel
}

/**
 * AlbumApi - object-oriented interface
 * @export
 * @class AlbumApi
 * @extends {BaseAPI}
 */
export class AlbumApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {AlbumApiApiV1AlbumsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public apiV1AlbumsGet(requestParameters: AlbumApiApiV1AlbumsGetRequest = {}, options?: AxiosRequestConfig) {
        return AlbumApiFp(this.configuration).apiV1AlbumsGet(requestParameters.userId, requestParameters.groupId, requestParameters.albumId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {AlbumApiApiV1AlbumsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public apiV1AlbumsIdDelete(requestParameters: AlbumApiApiV1AlbumsIdDeleteRequest, options?: AxiosRequestConfig) {
        return AlbumApiFp(this.configuration).apiV1AlbumsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {AlbumApiApiV1AlbumsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public apiV1AlbumsIdGet(requestParameters: AlbumApiApiV1AlbumsIdGetRequest, options?: AxiosRequestConfig) {
        return AlbumApiFp(this.configuration).apiV1AlbumsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {AlbumApiApiV1AlbumsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public apiV1AlbumsIdPatch(requestParameters: AlbumApiApiV1AlbumsIdPatchRequest, options?: AxiosRequestConfig) {
        return AlbumApiFp(this.configuration).apiV1AlbumsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {AlbumApiApiV1AlbumsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public apiV1AlbumsPost(requestParameters: AlbumApiApiV1AlbumsPostRequest = {}, options?: AxiosRequestConfig) {
        return AlbumApiFp(this.configuration).apiV1AlbumsPost(requestParameters.albumCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {AlbumApiApiV1AlbumsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumApi
     */
    public apiV1AlbumsPut(requestParameters: AlbumApiApiV1AlbumsPutRequest = {}, options?: AxiosRequestConfig) {
        return AlbumApiFp(this.configuration).apiV1AlbumsPut(requestParameters.albumUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
