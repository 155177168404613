import { VFC } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Spin } from 'antd';

import { FormButtons } from 'components/FormButtons';
import { api } from 'services/api';
import { CountryId, useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { Form } from 'ui/Form';
import { FormItem } from 'ui/FormItem';
import { Option, Select } from 'ui/Select';

type ChangeCountryModalFormProps = {
  onCancel?: () => void;
  onSave?: () => void;
};

interface ChangeCountryFormValues {
  countryId: number;
}

const getCountries = async () => {
  const { data } = await api.country.apiV1CountriesGet();

  return data;
};

const changeCountry = async (countryId: number) => {
  await api.user.apiV1UsersCountryPost({
    body: countryId,
  });

  return countryId;
};

export const ChangeCountryModalForm: VFC<ChangeCountryModalFormProps> = (
  props,
) => {
  const { onSave, onCancel } = props;
  const { t } = useTranslation('common');
  const { user } = useUserCredential();
  const client = useQueryClient();

  const { data: countries = [] } = useQuery(
    ['countries'],
    () => getCountries(),
    {
      suspense: false,
    },
  );

  const { handleSubmit, formState, control } = useForm<ChangeCountryFormValues>(
    {
      defaultValues: {
        countryId: user?.country.id,
      },
    },
  );

  const { mutate, isLoading } = useMutation(changeCountry, {
    onSuccess: () => {
      client.invalidateQueries('user');
      onCancel?.();
    },
  });

  const handleSave = ({ countryId }: ChangeCountryFormValues) => {
    mutate(countryId);
    onSave?.();
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(handleSave)}>
      <Spin spinning={isLoading}>
        <Controller
          control={control}
          name="countryId"
          render={({ field: { ref: _, ...fieldProps } }) => (
            <FormItem>
              <Select {...fieldProps}>
                {countries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {t(CountryId[country.id as number])}
                  </Option>
                ))}
              </Select>
            </FormItem>
          )}
        />
      </Spin>
      <FormButtons
        handleCancel={onCancel}
        submitDisabled={
          !formState.isDirty || formState.isSubmitting || isLoading
        }
        submitText={t('select')}
      />
    </Form>
  );
};
