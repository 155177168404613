import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { useMutation } from 'react-query';

import useResizeObserver from '@react-hook/resize-observer';

import { useOnScreen } from 'hooks/useOnScreen';
import { WallModel } from 'services/api';
import { useWallData } from 'services/wall';

import { viewPost } from '../api';
import { PostProps } from '../types';

export const useViews = ({
  clearCache,
  post,
  updateLocalPost,
  isReplyVisible,
  isCommentsVisible,
}: Pick<PostProps, 'clearCache'> & {
  post: WallModel;
  isCommentsVisible: boolean;
  isReplyVisible: boolean;
  updateLocalPost: (data: WallModel) => void;
}) => {
  const wallDataContext = useWallData();
  const postWrapper = useRef<HTMLDivElement>(null);

  const resizeObserverCallback = useCallback(() => {
    if (clearCache) clearCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentsVisible, isReplyVisible, clearCache]);

  useResizeObserver(postWrapper, resizeObserverCallback);

  const onScreen = useOnScreen<HTMLDivElement>(
    postWrapper as MutableRefObject<HTMLDivElement>,
  );

  const viewsMutation = useMutation(() => viewPost(Number(post.id)), {
    onSuccess: (views) => {
      updateLocalPost({ ...post, views, isViewed: true });

      if (wallDataContext) {
        wallDataContext.setList((prevState) => {
          return prevState.map((p) =>
            p.id === post.id ? { ...post, isViewed: true, views } : p,
          );
        });
        wallDataContext.forceUpdate();
      }
    },
  });

  useEffect(() => {
    if (onScreen && !post.isViewed) viewsMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  return {
    postWrapper,
  };
};
