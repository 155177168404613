import { SectionPagePreviewModel, api } from 'services/api';
import { CountryId } from 'services/i18n';

import { HeaderLink } from './types';

export * from './otherLinks';
export type { HeaderLink } from './types';

export const getTopLevelLinks = async (
  countryId?: number,
): Promise<HeaderLink[]> => {
  const { data } = await api.sectionPage.apiV1SectionPagesGet({
    count: -1,
    isTopLevelOnly: true,
    countryId,
    isVisible: true,
    isDraft: false,
  });

  const sectionPages = [
    ...((data.items ?? []) as Required<SectionPagePreviewModel>[]),
  ];

  return sectionPages
    .sort((a, b) => (a.id < b.id ? 1 : -1))
    .map((page) => ({
      title: page.title ?? '',
      path: `/pages/${page.id}`,
      iconName: page.iconNew ?? 'article',
      availableIn: [page.country.id as CountryId],
    }));
};
