import * as yup from 'yup';

import { i18n } from 'services/i18n';

yup.setLocale({
  mixed: {
    notType: () => i18n.t('sign-in.form.defaultErrors.not_type'),
    required: () => i18n.t('sign-in.form.defaultErrors.required'),
  },
  string: {
    matches: () => i18n.t('sign-in.form.defaultErrors.matches'),
    email: () => i18n.t('sign-in.form.defaultErrors.email'),
    url: () => i18n.t('sign-in.form.defaultErrors.url'),
  },
});

// eslint-disable-next-line import/no-default-export
export default yup;
