import AntAvatar from 'antd/lib/avatar';
import styled from 'styled-components';

export const { Group: AvatarGroup } = AntAvatar;

export const StyledAntAvatar = styled(AntAvatar)`
  &.ant-avatar-square {
    border-radius: 8px;
  }
`;
