import { VFC } from 'react';

import { PostForm } from 'components/PostForm';
import { PostUpdateModel } from 'components/PostForm/types';
import { WallUpdateModel } from 'services/api';
import { useTranslation } from 'services/i18n';
import { Modal } from 'ui/Modal';

type PostCloneModalProps = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  defaultValues?: WallUpdateModel;
  mode?: 'edit' | 'copy';
  type?: 'post' | 'idea';
};

export const PostEditModal: VFC<PostCloneModalProps> = ({
  type = 'post',
  mode = 'edit',
  visible,
  setVisible,
  defaultValues,
}) => {
  const { t } = useTranslation('common');

  console.log('WallUpdateModel', defaultValues);
  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      destroyOnClose
      bodyStyle={{ padding: 0, paddingTop: 20 }}
      footer={false}
      title={mode === 'copy' ? t('post_copy_modal') : t('post_edit_modal')}
      visible={visible}
      width={680}
      onCancel={handleClose}
    >
      <PostForm
        isForceFocused
        defaultValues={defaultValues as PostUpdateModel}
        mode={mode}
        setParentIsVisible={handleClose}
        type={type}
      />
    </Modal>
  );
};
