import AntTabs, { TabsProps } from 'antd/lib/tabs';
import styled from 'styled-components';

export type { TabsProps } from 'antd/lib/tabs';

export const Tabs = styled((props: TabsProps) => <AntTabs {...props} />)`
  .ant-tabs-nav {
    padding: 0 24px;
    .ant-tabs-ink-bar {
      background-color: ${({ theme }) => theme.palette.primary.main};
      height: 4px;
      border-radius: 4px 4px 0 0;
    }
    .ant-tabs-tab {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      padding: 24px 0;

      :hover {
        color: ${({ theme }) => theme.palette.primary.light};
      }

      &-active {
        .ant-tabs-tab-btn {
          color: ${({ theme }) => theme.palette.primary.main};
          text-shadow: unset;
        }
      }
    }
  }
`;
