export interface MeasurerAttrs {
  (data: {
    node?: HTMLElement | Text;
    nodes?: (HTMLElement | Text)[];
    markup?: string;
    expectingWidth?: number;
    enhancer?: (el: HTMLElement | Text) => HTMLElement | Text;
    clearEmpty?: boolean;
    fontParams?: FontParams;
  }): { height: number; width: number; elements: (HTMLElement | Text)[] };
}

export type FontParams = {
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
};

export const DEF_FONT_PARAMS: FontParams = {
  fontSize: '16px',
  lineHeight: '1.5',
  fontWeight: '500',
};

export interface AppendNodes {
  (
    container: HTMLElement,
    elements: (HTMLElement | Text)[],
    enhancer?: (el: HTMLElement | Text) => HTMLElement | Text,
  ): void;
}

export interface GetFilledInHeight {
  (data: {
    nodes: (HTMLElement | Text)[];
    expectingHeight: number;
    expectingWidth: number;
    fontParams?: FontParams;
  }): {
    nodes: (HTMLElement | Text)[];
    last: HTMLElement | Text | null;
  };
}

export interface CutNode {
  (data: {
    node: HTMLElement | Text;
    count?: number;
    expectingWidth?: number;
    fontParams?: FontParams;
  }): {
    node: HTMLElement | Text | null;
    rest: number;
    height?: number;
  };
}

export interface CutHTMLArgs {
  (data: {
    html: string;
    expectingWidth: number;
    expectingHeight: number;
    fontParams?: FontParams;
  }): {
    cut: (HTMLElement | Text)[];
    full: (HTMLElement | Text)[];
  };
}
