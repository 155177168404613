import React from 'react';

import { useControlled } from '../utils/useControlled';
import { BaseInputProps, StyledInput, StyledSvgIconRoot } from './styles';

export interface BaseSwitchProps extends BaseInputProps {
  icon: React.ReactNode;
  checkedIcon: React.ReactNode;
}

export const BaseSwitch = React.forwardRef<HTMLInputElement, BaseSwitchProps>(
  (
    {
      icon,
      checkedIcon,
      checked,
      defaultChecked,
      disabled,
      onChange,
      ...inputProps
    },
    ref,
  ) => {
    const [value, setValue] = useControlled({
      controlledValue: checked,
      defaultValue: Boolean(defaultChecked),
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.checked);
      if (onChange) onChange(event);
    };

    return (
      <StyledSvgIconRoot checked={value} disabled={disabled}>
        {value ? checkedIcon : icon}
        <StyledInput
          key="input"
          checked={value}
          disabled={disabled}
          ref={ref}
          onChange={handleInputChange}
          {...inputProps}
        />
      </StyledSvgIconRoot>
    );
  },
);
