import { FC } from 'react';
import * as React from 'react';

import { Avatar } from '../Avatar';
import { Badge } from '../Badge';
import { geoIcons } from './geoIcons';

export interface GeoBadgeProps {
  locale: string;
  size?: number;
  offset?: [string | number, string | number];
  style?: React.CSSProperties;
}

export const GeoBadge: FC<GeoBadgeProps> = ({
  children,
  size = 24,
  locale,
  offset,
  style,
}) => (
  <Badge
    count={
      <Avatar
        size={size}
        src={geoIcons[locale.toUpperCase() as keyof typeof geoIcons]}
      />
    }
    offset={offset}
    style={style}
  >
    {children}
  </Badge>
);
