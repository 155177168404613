import { VFC } from 'react';

import { Delete } from '@styled-icons/material-outlined';

import {
  OpenGraphContent,
  OpenGraphImage,
  OpenGraphTitle,
  OpenGraphToolbar,
  OpenGraphToolbarButton,
  OpenGraphWrapper,
} from 'components/Wall/Post/PostContent/PostStandardContent/PostOpenGraphContent/styles';
import { OpenGraphItemModel } from 'services/api';
import { StyledLink } from 'ui/Link';
import { Text } from 'ui/Text';

export type PostOpenGraphContentProps = {
  openGraph: OpenGraphItemModel;
  type?: 'default' | 'small';
  handleDelete?: () => void;
};

const MAX_DESCRIPTION_LENGTH = 100;

export const PostOpenGraphContent: VFC<PostOpenGraphContentProps> = ({
  openGraph,
  type = 'default',
  handleDelete,
}) => {
  const ogDescriptionEllipsis =
    openGraph.description &&
    openGraph.description.length > MAX_DESCRIPTION_LENGTH
      ? `${openGraph.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
      : openGraph.description;

  return (
    <>
      <OpenGraphWrapper type={type}>
        {handleDelete && (
          <OpenGraphToolbar>
            <OpenGraphToolbarButton onClick={handleDelete}>
              <Delete size={15} />
            </OpenGraphToolbarButton>
          </OpenGraphToolbar>
        )}
        {openGraph.imageUrl && (
          <OpenGraphImage
            onClick={() => {
              if (openGraph.originalUrl)
                window.open(openGraph.originalUrl, '_blank');
            }}
          >
            <img alt={openGraph.title || ''} src={openGraph.imageUrl} />
          </OpenGraphImage>
        )}
        <OpenGraphContent>
          {openGraph.originalUrl && (
            <StyledLink href={openGraph.originalUrl} target="_blank">
              {new URL(openGraph.originalUrl).hostname}
            </StyledLink>
          )}
          <OpenGraphTitle>
            {openGraph.title || openGraph.originalUrl}
          </OpenGraphTitle>
          {ogDescriptionEllipsis && (
            <Text style={{ paddingRight: handleDelete ? '60px' : '12px' }}>
              {ogDescriptionEllipsis}
            </Text>
          )}
        </OpenGraphContent>
      </OpenGraphWrapper>
    </>
  );
};
