import styled from 'styled-components';

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledDescriptionItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledDescriptionItemLable = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  flex: 0 1 35%;
`;

export const StyledDescriptionItemContent = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  flex: 0 1 65%;
`;
