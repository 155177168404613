/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DiscoveryStep } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { UserDiscoveryStepModel } from '../models';
// @ts-ignore
import { UserDiscoveryStepUpdateModel } from '../models';
// @ts-ignore
import { UserDiscoveryStepsModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * UserDiscoveryStepApi - axios parameter creator
 * @export
 */
export const UserDiscoveryStepApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userDiscoveryId] Фильтр по дискавери пользователя
         * @param {DiscoveryStep} [discoveryStep] Фильтр по шагу дискавери
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsGet: async (userDiscoveryId?: number, discoveryStep?: DiscoveryStep, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-discovery-steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userDiscoveryId !== undefined) {
                localVarQueryParameter['UserDiscoveryId'] = userDiscoveryId;
            }

            if (discoveryStep !== undefined) {
                localVarQueryParameter['DiscoveryStep'] = discoveryStep;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserDiscoveryStepsIdDelete', 'id', id)
            const localVarPath = `/api/v1/user-discovery-steps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserDiscoveryStepsIdGet', 'id', id)
            const localVarPath = `/api/v1/user-discovery-steps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserDiscoveryStepsIdPatch', 'id', id)
            const localVarPath = `/api/v1/user-discovery-steps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {UserDiscoveryStepUpdateModel} [userDiscoveryStepUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsPost: async (userDiscoveryStepUpdateModel?: UserDiscoveryStepUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-discovery-steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDiscoveryStepUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {UserDiscoveryStepUpdateModel} [userDiscoveryStepUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsPut: async (userDiscoveryStepUpdateModel?: UserDiscoveryStepUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-discovery-steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDiscoveryStepUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDiscoveryStepApi - functional programming interface
 * @export
 */
export const UserDiscoveryStepApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDiscoveryStepApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userDiscoveryId] Фильтр по дискавери пользователя
         * @param {DiscoveryStep} [discoveryStep] Фильтр по шагу дискавери
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveryStepsGet(userDiscoveryId?: number, discoveryStep?: DiscoveryStep, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryStepsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveryStepsGet(userDiscoveryId, discoveryStep, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveryStepsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveryStepsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveryStepsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryStepModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveryStepsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveryStepsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryStepModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveryStepsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {UserDiscoveryStepUpdateModel} [userDiscoveryStepUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveryStepsPost(userDiscoveryStepUpdateModel?: UserDiscoveryStepUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryStepModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveryStepsPost(userDiscoveryStepUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {UserDiscoveryStepUpdateModel} [userDiscoveryStepUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveryStepsPut(userDiscoveryStepUpdateModel?: UserDiscoveryStepUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryStepModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveryStepsPut(userDiscoveryStepUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDiscoveryStepApi - factory interface
 * @export
 */
export const UserDiscoveryStepApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDiscoveryStepApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userDiscoveryId] Фильтр по дискавери пользователя
         * @param {DiscoveryStep} [discoveryStep] Фильтр по шагу дискавери
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsGet(userDiscoveryId?: number, discoveryStep?: DiscoveryStep, count?: number, offset?: number, options?: any): AxiosPromise<UserDiscoveryStepsModel> {
            return localVarFp.apiV1UserDiscoveryStepsGet(userDiscoveryId, discoveryStep, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserDiscoveryStepsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsIdGet(id: number, options?: any): AxiosPromise<UserDiscoveryStepModel> {
            return localVarFp.apiV1UserDiscoveryStepsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<UserDiscoveryStepModel> {
            return localVarFp.apiV1UserDiscoveryStepsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {UserDiscoveryStepUpdateModel} [userDiscoveryStepUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsPost(userDiscoveryStepUpdateModel?: UserDiscoveryStepUpdateModel, options?: any): AxiosPromise<UserDiscoveryStepModel> {
            return localVarFp.apiV1UserDiscoveryStepsPost(userDiscoveryStepUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {UserDiscoveryStepUpdateModel} [userDiscoveryStepUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveryStepsPut(userDiscoveryStepUpdateModel?: UserDiscoveryStepUpdateModel, options?: any): AxiosPromise<UserDiscoveryStepModel> {
            return localVarFp.apiV1UserDiscoveryStepsPut(userDiscoveryStepUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UserDiscoveryStepsGet operation in UserDiscoveryStepApi.
 * @export
 * @interface UserDiscoveryStepApiApiV1UserDiscoveryStepsGetRequest
 */
export interface UserDiscoveryStepApiApiV1UserDiscoveryStepsGetRequest {
    /**
     * Фильтр по дискавери пользователя
     * @type {number}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsGet
     */
    readonly userDiscoveryId?: number

    /**
     * Фильтр по шагу дискавери
     * @type {DiscoveryStep}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsGet
     */
    readonly discoveryStep?: DiscoveryStep

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UserDiscoveryStepsIdDelete operation in UserDiscoveryStepApi.
 * @export
 * @interface UserDiscoveryStepApiApiV1UserDiscoveryStepsIdDeleteRequest
 */
export interface UserDiscoveryStepApiApiV1UserDiscoveryStepsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1UserDiscoveryStepsIdGet operation in UserDiscoveryStepApi.
 * @export
 * @interface UserDiscoveryStepApiApiV1UserDiscoveryStepsIdGetRequest
 */
export interface UserDiscoveryStepApiApiV1UserDiscoveryStepsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1UserDiscoveryStepsIdPatch operation in UserDiscoveryStepApi.
 * @export
 * @interface UserDiscoveryStepApiApiV1UserDiscoveryStepsIdPatchRequest
 */
export interface UserDiscoveryStepApiApiV1UserDiscoveryStepsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1UserDiscoveryStepsPost operation in UserDiscoveryStepApi.
 * @export
 * @interface UserDiscoveryStepApiApiV1UserDiscoveryStepsPostRequest
 */
export interface UserDiscoveryStepApiApiV1UserDiscoveryStepsPostRequest {
    /**
     * 
     * @type {UserDiscoveryStepUpdateModel}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsPost
     */
    readonly userDiscoveryStepUpdateModel?: UserDiscoveryStepUpdateModel
}

/**
 * Request parameters for apiV1UserDiscoveryStepsPut operation in UserDiscoveryStepApi.
 * @export
 * @interface UserDiscoveryStepApiApiV1UserDiscoveryStepsPutRequest
 */
export interface UserDiscoveryStepApiApiV1UserDiscoveryStepsPutRequest {
    /**
     * 
     * @type {UserDiscoveryStepUpdateModel}
     * @memberof UserDiscoveryStepApiApiV1UserDiscoveryStepsPut
     */
    readonly userDiscoveryStepUpdateModel?: UserDiscoveryStepUpdateModel
}

/**
 * UserDiscoveryStepApi - object-oriented interface
 * @export
 * @class UserDiscoveryStepApi
 * @extends {BaseAPI}
 */
export class UserDiscoveryStepApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {UserDiscoveryStepApiApiV1UserDiscoveryStepsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryStepApi
     */
    public apiV1UserDiscoveryStepsGet(requestParameters: UserDiscoveryStepApiApiV1UserDiscoveryStepsGetRequest = {}, options?: AxiosRequestConfig) {
        return UserDiscoveryStepApiFp(this.configuration).apiV1UserDiscoveryStepsGet(requestParameters.userDiscoveryId, requestParameters.discoveryStep, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {UserDiscoveryStepApiApiV1UserDiscoveryStepsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryStepApi
     */
    public apiV1UserDiscoveryStepsIdDelete(requestParameters: UserDiscoveryStepApiApiV1UserDiscoveryStepsIdDeleteRequest, options?: AxiosRequestConfig) {
        return UserDiscoveryStepApiFp(this.configuration).apiV1UserDiscoveryStepsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {UserDiscoveryStepApiApiV1UserDiscoveryStepsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryStepApi
     */
    public apiV1UserDiscoveryStepsIdGet(requestParameters: UserDiscoveryStepApiApiV1UserDiscoveryStepsIdGetRequest, options?: AxiosRequestConfig) {
        return UserDiscoveryStepApiFp(this.configuration).apiV1UserDiscoveryStepsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {UserDiscoveryStepApiApiV1UserDiscoveryStepsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryStepApi
     */
    public apiV1UserDiscoveryStepsIdPatch(requestParameters: UserDiscoveryStepApiApiV1UserDiscoveryStepsIdPatchRequest, options?: AxiosRequestConfig) {
        return UserDiscoveryStepApiFp(this.configuration).apiV1UserDiscoveryStepsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {UserDiscoveryStepApiApiV1UserDiscoveryStepsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryStepApi
     */
    public apiV1UserDiscoveryStepsPost(requestParameters: UserDiscoveryStepApiApiV1UserDiscoveryStepsPostRequest = {}, options?: AxiosRequestConfig) {
        return UserDiscoveryStepApiFp(this.configuration).apiV1UserDiscoveryStepsPost(requestParameters.userDiscoveryStepUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {UserDiscoveryStepApiApiV1UserDiscoveryStepsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryStepApi
     */
    public apiV1UserDiscoveryStepsPut(requestParameters: UserDiscoveryStepApiApiV1UserDiscoveryStepsPutRequest = {}, options?: AxiosRequestConfig) {
        return UserDiscoveryStepApiFp(this.configuration).apiV1UserDiscoveryStepsPut(requestParameters.userDiscoveryStepUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
