import { VFC } from 'react';

import { useNavigate } from 'react-router-dom';

import { formatRelative, parseISO } from 'date-fns';

import {
  notificationRepresentation,
  notificationRoute,
} from 'domain/notificationTypes';
import { getPhotoPreview } from 'domain/photo';
import { getUserCountryShort } from 'domain/user';
import { NotificationPreviewModel, PhotoPreviewModel, api } from 'services/api';
import { useDateFnsLocale } from 'services/i18n';
import { useNotification } from 'services/userCredential/userNotificationContext';
import { Avatar, GeoBadge } from 'ui';

import {
  PopupDate,
  PopupInfo,
  PopupItem,
  PopupMessage,
  PopupPublisher,
} from './styles';

const setRead = async (notificationId: number) => {
  await api.notification.apiV1NotificationsPut({
    notificationUpdateModel: { id: notificationId, isRead: true },
  });
};

export interface PopupElementProps {
  notification: NotificationPreviewModel;
}

export const PopupElement: VFC<PopupElementProps> = ({ notification }) => {
  const { locale } = useDateFnsLocale();
  const navigate = useNavigate();
  const publisher = notification.byUser || notification.byGroup;
  const { notify, setNotify } = useNotification();

  const handleRowClick = (rowData: NotificationPreviewModel) => {
    setRead(rowData.id as number);
    navigate(
      notificationRoute(
        rowData.notificationType,
        rowData.wallId,
        rowData.byUser?.userId,
        rowData.byGroup?.groupId,
      ),
    );
    setNotify(notify + 1);
  };
  return (
    <PopupItem onClick={() => handleRowClick(notification)}>
      {publisher?.avatar && (
        <GeoBadge
          locale={getUserCountryShort(publisher)}
          offset={[-5, 30]}
          size={14}
        >
          <Avatar
            size={40}
            src={getPhotoPreview(publisher?.avatar as PhotoPreviewModel)}
          />
        </GeoBadge>
      )}
      <PopupInfo>
        {publisher && (
          <PopupPublisher style={{ width: 342 - (publisher.avatar ? 40 : 0) }}>
            {publisher?.name}
          </PopupPublisher>
        )}
        <PopupDate>
          {formatRelative(
            parseISO(notification.createdDate as string),
            new Date(),
            { locale },
          )}
        </PopupDate>
        <PopupMessage>{notificationRepresentation(notification)}</PopupMessage>
      </PopupInfo>
    </PopupItem>
  );
};
