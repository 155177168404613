import { Modal } from 'antd';
import styled from 'styled-components';

import { IconButton, Paper } from 'ui';

export const StyledModal = styled(Modal).attrs({
  bodyStyle: { all: 'unset', maxHeight: 400 },
  closable: false,
  footer: null,
  width: 680,
})`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const ModalPaper = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 14px;
`;
