import { sortBy } from 'lodash';

import { LinkType, api } from 'services/api';

export const getLinks = async (countryId: number, type: LinkType) => {
  const { data } = await api.link.apiV1LinksGet({
    countries: [countryId],
    isVisible: true,
    count: -1,
    type,
  });

  return sortBy(data.items, 'order');
};

export const useLinksApi = () => ({
  getLinks,
});
