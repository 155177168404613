import { EditorContent } from '@tiptap/react';
import { Input } from 'antd';
import styled, { css } from 'styled-components';

import { FormItem, IconButton, Paper } from 'ui';
import { headingStyles } from 'ui/Title/styles';

export const StyledInput = styled(Input)`
  padding: 8px 84px 8px 12px;
  border-radius: 8px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  margin: 0;
  border-radius: 8px;
  padding-top: 20px;
  box-shadow: none !important;
`;

export const StyledAttachesContainer = styled.div`
  padding-left: 44px;
`;

export const StyledContainer = styled.div`
  display: flex;
`;

export const StyledFormItem = styled(FormItem)`
  flex: 1;
  margin: 0 14px 0 12px;
`;

export const ControlPaper = styled.div`
  position: absolute;
  right: 14px;
  top: 0;

  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 14px;
  justify-content: start;
`;

export const CommentFormButton = styled(IconButton)`
  grid-template-columns: repeat(2, min-content);
  width: auto;
`;

const paragraph = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
`;

export const StyledEditorContent = styled(EditorContent)`
  .ProseMirror {
    &:focus {
      outline: 0;
      border: solid 1px #4872f2;
    }

    padding: 8px 76px 8px 12px;
    border: solid 1px #dbe0e5;
    min-height: 92px;
    max-width: 100%;
    overflow: auto;
    border-radius: 8px;
  }

  h1 {
    ${headingStyles.h1}
    margin: 0;
  }

  h2 {
    ${headingStyles.h2}
    margin: 0;
  }

  h3 {
    ${headingStyles.h3}
    margin: 0;
  }

  p {
    ${paragraph}
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  /* Placeholder (at the top) */

  p.is-editor-empty:only-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`;
