/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbsenceCreateModel } from '../models';
// @ts-ignore
import { AbsenceModel } from '../models';
// @ts-ignore
import { AbsenceReasonsModel } from '../models';
// @ts-ignore
import { AbsenceUpdateModel } from '../models';
// @ts-ignore
import { AbsencesModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * AbsenceApi - axios parameter creator
 * @export
 */
export const AbsenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [reasonId] Фильтр по причинам
         * @param {string} [startDate] Фильтр по дате начала отсутствия
         * @param {string} [endDate] Фильтр по дате окончания отсутствия
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesGet: async (userId?: number, reasonId?: number, startDate?: string, endDate?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/absences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (reasonId !== undefined) {
                localVarQueryParameter['ReasonId'] = reasonId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AbsencesIdDelete', 'id', id)
            const localVarPath = `/api/v1/absences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AbsencesIdGet', 'id', id)
            const localVarPath = `/api/v1/absences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AbsencesIdPatch', 'id', id)
            const localVarPath = `/api/v1/absences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {AbsenceCreateModel} [absenceCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesPost: async (absenceCreateModel?: AbsenceCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/absences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {AbsenceUpdateModel} [absenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesPut: async (absenceUpdateModel?: AbsenceUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/absences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of absences
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesReasonsGet: async (count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/absences/reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbsenceApi - functional programming interface
 * @export
 */
export const AbsenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbsenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [reasonId] Фильтр по причинам
         * @param {string} [startDate] Фильтр по дате начала отсутствия
         * @param {string} [endDate] Фильтр по дате окончания отсутствия
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AbsencesGet(userId?: number, reasonId?: number, startDate?: string, endDate?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsencesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AbsencesGet(userId, reasonId, startDate, endDate, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AbsencesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AbsencesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AbsencesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AbsencesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AbsencesIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AbsencesIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {AbsenceCreateModel} [absenceCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AbsencesPost(absenceCreateModel?: AbsenceCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AbsencesPost(absenceCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {AbsenceUpdateModel} [absenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AbsencesPut(absenceUpdateModel?: AbsenceUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AbsencesPut(absenceUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of absences
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AbsencesReasonsGet(count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceReasonsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AbsencesReasonsGet(count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbsenceApi - factory interface
 * @export
 */
export const AbsenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbsenceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [reasonId] Фильтр по причинам
         * @param {string} [startDate] Фильтр по дате начала отсутствия
         * @param {string} [endDate] Фильтр по дате окончания отсутствия
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesGet(userId?: number, reasonId?: number, startDate?: string, endDate?: string, count?: number, offset?: number, options?: any): AxiosPromise<AbsencesModel> {
            return localVarFp.apiV1AbsencesGet(userId, reasonId, startDate, endDate, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AbsencesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesIdGet(id: number, options?: any): AxiosPromise<AbsenceModel> {
            return localVarFp.apiV1AbsencesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<AbsenceModel> {
            return localVarFp.apiV1AbsencesIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {AbsenceCreateModel} [absenceCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesPost(absenceCreateModel?: AbsenceCreateModel, options?: any): AxiosPromise<AbsenceModel> {
            return localVarFp.apiV1AbsencesPost(absenceCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {AbsenceUpdateModel} [absenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesPut(absenceUpdateModel?: AbsenceUpdateModel, options?: any): AxiosPromise<AbsenceModel> {
            return localVarFp.apiV1AbsencesPut(absenceUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of absences
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AbsencesReasonsGet(count?: number, offset?: number, options?: any): AxiosPromise<AbsenceReasonsModel> {
            return localVarFp.apiV1AbsencesReasonsGet(count, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AbsencesGet operation in AbsenceApi.
 * @export
 * @interface AbsenceApiApiV1AbsencesGetRequest
 */
export interface AbsenceApiApiV1AbsencesGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesGet
     */
    readonly userId?: number

    /**
     * Фильтр по причинам
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesGet
     */
    readonly reasonId?: number

    /**
     * Фильтр по дате начала отсутствия
     * @type {string}
     * @memberof AbsenceApiApiV1AbsencesGet
     */
    readonly startDate?: string

    /**
     * Фильтр по дате окончания отсутствия
     * @type {string}
     * @memberof AbsenceApiApiV1AbsencesGet
     */
    readonly endDate?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1AbsencesIdDelete operation in AbsenceApi.
 * @export
 * @interface AbsenceApiApiV1AbsencesIdDeleteRequest
 */
export interface AbsenceApiApiV1AbsencesIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1AbsencesIdGet operation in AbsenceApi.
 * @export
 * @interface AbsenceApiApiV1AbsencesIdGetRequest
 */
export interface AbsenceApiApiV1AbsencesIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1AbsencesIdPatch operation in AbsenceApi.
 * @export
 * @interface AbsenceApiApiV1AbsencesIdPatchRequest
 */
export interface AbsenceApiApiV1AbsencesIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof AbsenceApiApiV1AbsencesIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1AbsencesPost operation in AbsenceApi.
 * @export
 * @interface AbsenceApiApiV1AbsencesPostRequest
 */
export interface AbsenceApiApiV1AbsencesPostRequest {
    /**
     * 
     * @type {AbsenceCreateModel}
     * @memberof AbsenceApiApiV1AbsencesPost
     */
    readonly absenceCreateModel?: AbsenceCreateModel
}

/**
 * Request parameters for apiV1AbsencesPut operation in AbsenceApi.
 * @export
 * @interface AbsenceApiApiV1AbsencesPutRequest
 */
export interface AbsenceApiApiV1AbsencesPutRequest {
    /**
     * 
     * @type {AbsenceUpdateModel}
     * @memberof AbsenceApiApiV1AbsencesPut
     */
    readonly absenceUpdateModel?: AbsenceUpdateModel
}

/**
 * Request parameters for apiV1AbsencesReasonsGet operation in AbsenceApi.
 * @export
 * @interface AbsenceApiApiV1AbsencesReasonsGetRequest
 */
export interface AbsenceApiApiV1AbsencesReasonsGetRequest {
    /**
     * Количеству объектов
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesReasonsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof AbsenceApiApiV1AbsencesReasonsGet
     */
    readonly offset?: number
}

/**
 * AbsenceApi - object-oriented interface
 * @export
 * @class AbsenceApi
 * @extends {BaseAPI}
 */
export class AbsenceApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {AbsenceApiApiV1AbsencesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiV1AbsencesGet(requestParameters: AbsenceApiApiV1AbsencesGetRequest = {}, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiV1AbsencesGet(requestParameters.userId, requestParameters.reasonId, requestParameters.startDate, requestParameters.endDate, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {AbsenceApiApiV1AbsencesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiV1AbsencesIdDelete(requestParameters: AbsenceApiApiV1AbsencesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiV1AbsencesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {AbsenceApiApiV1AbsencesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiV1AbsencesIdGet(requestParameters: AbsenceApiApiV1AbsencesIdGetRequest, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiV1AbsencesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {AbsenceApiApiV1AbsencesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiV1AbsencesIdPatch(requestParameters: AbsenceApiApiV1AbsencesIdPatchRequest, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiV1AbsencesIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {AbsenceApiApiV1AbsencesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiV1AbsencesPost(requestParameters: AbsenceApiApiV1AbsencesPostRequest = {}, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiV1AbsencesPost(requestParameters.absenceCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {AbsenceApiApiV1AbsencesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiV1AbsencesPut(requestParameters: AbsenceApiApiV1AbsencesPutRequest = {}, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiV1AbsencesPut(requestParameters.absenceUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of absences
     * @param {AbsenceApiApiV1AbsencesReasonsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiV1AbsencesReasonsGet(requestParameters: AbsenceApiApiV1AbsencesReasonsGetRequest = {}, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiV1AbsencesReasonsGet(requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}
