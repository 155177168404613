import { useCallback } from 'react';

import { useQuery } from 'react-query';

import { UserRoles } from 'domain/roles';
import { UserProfile } from 'domain/user';
import { useLoadingLanguage } from 'hooks/useChangeLanguage';

import { RoleName } from '../api';
import { useAuth } from '../auth';
import { useUserCredentialApi } from './useUserCredentialApi';

export interface UserCredentialService {
  user: Nullable<UserProfile>;
  roles: Nullable<UserRoles>;
  hasRole: (role: RoleName) => boolean;
  isHeadOf: (user: UserProfile) => boolean;
}

export const useUserCredentialService = (): UserCredentialService => {
  const { isLoggedIn } = useAuth();
  const { getCurrentUser, getUserRoles } = useUserCredentialApi();
  const { setLangFromId } = useLoadingLanguage();

  const { data: user = null } = useQuery('user', getCurrentUser, {
    enabled: isLoggedIn,
    onSuccess: (userData) => {
      setLangFromId(userData.country.languageId);
    },
  });

  const { data: userRoles = [] } = useQuery(
    ['user', 'roles'],
    () => getUserRoles(user?.id as number),
    {
      enabled: Boolean(user?.id),
    },
  );

  const isHeadOf = useCallback(
    (usr: UserProfile) => Boolean(user && user.id === usr.supervisorUserId),
    [user],
  );

  const hasRole = useCallback(
    (role: RoleName) => userRoles.includes(role),
    [userRoles],
  );

  return { user, roles: userRoles, hasRole, isHeadOf };
};
