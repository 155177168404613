import AntTextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export type { TextAreaProps } from 'antd/lib/input/TextArea';

export const TextArea = styled((props: TextAreaProps) => (
  <AntTextArea {...props} />
))`
  & {
    padding: 10px 12px;
    border-radius: 8px;
    line-height: 18px;
  }
`;
