import { RefObject, useEffect, useRef } from 'react';

export const useClickAway = <T extends HTMLElement = HTMLElement>(
  handler: (event: MouseEvent) => void,
): RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event);
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [handler, ref]);

  return ref;
};
