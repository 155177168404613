import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { GroupModel, UserProfileModel, WallModel } from '../api';

export type WallFilterData = {
  user?: UserProfileModel;
  group?: GroupModel;
  createdDateFrom?: string;
  createdDateTo?: string;
  wallContentType?: number;
};
export type WallFilterState = {
  visible: boolean;
  editing: boolean;
  applied: boolean;
};

const WallDataContext = createContext({
  list: {} as WallModel[],
  setList: {} as Dispatch<SetStateAction<WallModel[]>>,
  forceUpdate: {} as () => void,
  wallForceUpdateTrigger: {} as boolean,
  filterState: {} as WallFilterState,
  setFilterState: {} as Dispatch<SetStateAction<WallFilterState>>,
  filter: {} as WallFilterData,
  setFilter: {} as Dispatch<SetStateAction<WallFilterData>>,
});

export const useWallData = () => {
  const context = useContext(WallDataContext);

  if (!context) {
    throw new Error('useWallData must be used within WallDataContext');
  }
  return context;
};

export const WallDataProvider: FC = ({ children }) => {
  const [list, setList] = useState<WallModel[]>([]);
  const [filter, setFilter] = useState<WallFilterData>({});
  const [filterState, setFilterState] = useState<WallFilterState>({
    visible: false,
    editing: false,
    applied: false,
  });
  const [wallForceUpdateTrigger, setWallForceUpdateTrigger] =
    useState<boolean>(false);

  const forceUpdate = () => {
    setWallForceUpdateTrigger((prevState) => !prevState);
  };

  const value = useMemo(
    () => ({
      list,
      setList,
      forceUpdate,
      wallForceUpdateTrigger,
      filter,
      setFilter,
      filterState,
      setFilterState,
    }),
    [list, wallForceUpdateTrigger, filter, filterState],
  );

  return (
    <WallDataContext.Provider value={value}>
      {children}
    </WallDataContext.Provider>
  );
};
