import { VFC } from 'react';

import { Media } from 'components/MediaGallery/Media';
import { MediaAlbum } from 'components/MediaGallery/MediaAlbum';
import { PostedFileModel } from 'services/api';

import { MediaGrid } from './styles';

export type PostMediaAttachmentGridProps = {
  attachments: PostedFileModel[];
  attachmentPreviewHeight?: number;
};

const CLASSNAMES = ['', 'single', 'double', 'triple'];

export const PostMediaAttachmentGrid: VFC<PostMediaAttachmentGridProps> = ({
  attachments,
  attachmentPreviewHeight = 320,
}) => {
  const mediaAttachments = attachments.filter(
    (entry) => entry.photoId || entry.videoId,
  );
  const album = mediaAttachments.map((entry) => Number(entry.postedFileId));
  const media = mediaAttachments.slice(0, 3);
  const otherMedia = mediaAttachments.length - media.length;

  return (
    <MediaAlbum album={album}>
      <MediaGrid
        className={CLASSNAMES[media.length]}
        style={{ height: attachmentPreviewHeight }}
      >
        {media.map((item, index) =>
          otherMedia > 0 && index === 2 ? (
            <Media
              key={`${item.postedFileId}`}
              media={item}
              otherMedia={otherMedia}
            />
          ) : (
            <Media key={`${item.postedFileId}`} media={item} />
          ),
        )}
      </MediaGrid>
    </MediaAlbum>
  );
};
