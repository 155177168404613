import { useCallback, useState } from 'react';

export interface UseControlledParams<T> {
  controlledValue: T | undefined;
  defaultValue: T | undefined;
}

export const useControlled = <T>({
  controlledValue,
  defaultValue,
}: UseControlledParams<T>) => {
  const [valueState, setValue] = useState(defaultValue);
  const value = controlledValue ?? valueState;

  const handleChange = useCallback(
    (newValue: T) => {
      if (!controlledValue) {
        setValue(newValue);
      }
    },
    [controlledValue],
  );

  return [value, handleChange] as const;
};
