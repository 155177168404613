import { Input } from 'antd';
import styled from 'styled-components';

import { IconButton, Title } from 'ui';
import { FormItem } from 'ui/FormItem';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const Container = styled.div`
  padding: 12px;
  flex-direction: column;
  display: flex;
  gap: 32px;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;
export const CenteredTitle = styled(Title)`
  margin: 0 auto;
`;
export const ClickableTitle = styled(Title)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
export const ProgressDiv = styled.div<{ percent: number; isDisabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  transition: width 0.3s;
  width: ${({ percent, isDisabled }) => (isDisabled ? `${percent}%` : 0)};
  height: 40px;
  opacity: 0.05;
  border-radius: 8px;
`;
export const DeleteButton = styled(IconButton)`
  justify-self: end;
  height: 24px;
  width: 24px;
  border-radius: 20px;
  background-color: white;
  box-shadow: black;
  flex-shrink: 0;
`;
export const StyledInput = styled(Input)<{ isSelected: boolean }>`
  border-radius: 8px;
  cursor: pointer;

  :disabled {
    cursor: default;
    padding-right: 95px;
    color: ${({ theme, isSelected }) =>
      isSelected && theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.actions.hover};
  }
`;
export const StyledFormItem = styled(FormItem)`
  position: relative;
  margin: 0;
  overflow-x: hidden;
  &:hover button {
    opacity: 1;
  }
`;

export const VotePercent = styled.div<{ isDisabled: boolean }>`
  position: absolute;

  top: 10px;
  right: -80px;
  transition: right 0.2s;
  ${({ isDisabled }) => isDisabled && 'right: 10px;'};
  display: flex;
  gap: 4px;
`;
export const Num = styled.div``;
