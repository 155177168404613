import { FC } from 'react';

import { Delete, Refresh } from '@styled-icons/material-outlined';

import { useTranslation } from 'services/i18n';
import { Divider } from 'ui/Divider';

import { Action, ActionList, AvatarEditActionsWrapper } from './styles';

type AvatarEditActionsProps = {
  filled: boolean;
  deleteAction?: () => void;
  editAction: () => void;
};

export const AvatarEditActions: FC<AvatarEditActionsProps> = ({
  editAction,
  deleteAction,
  filled,
}) => {
  const { t } = useTranslation('common');
  return (
    <AvatarEditActionsWrapper>
      <ActionList>
        <Action onClick={editAction}>
          <Refresh size={20} />
          <div>{t(filled ? 'edit_avatar' : 'upload_avatar')}</div>
        </Action>
        {filled && (
          <>
            <Divider />
            <Action onClick={deleteAction}>
              <Delete size={20} />
              <div>{t('delete_avatar')}</div>
            </Action>
          </>
        )}
      </ActionList>
    </AvatarEditActionsWrapper>
  );
};
