import { useCallback, useMemo, useState } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { SearchHistoryDtoSearchResult, api } from 'services/api';
import { useUserCredential } from 'services/userCredential';

const COUNT = 7;

type SearchHistoryByQueryParams = {
  userId: number;
  query: string;
  count: number;
  offset: number;
};

const getSearchHistory = async ({
  userId,
  query,
  count,
  offset,
}: SearchHistoryByQueryParams) => {
  const { data } = await api.searchHistory.apiV1UsersUserIdSearchHistoriesGet({
    userId,
    text: query,
    count,
    offset,
  });
  return data;
};

export const useLoadItems = (query: string) => {
  const [offset, setOffset] = useState(COUNT);
  const { user } = useUserCredential();

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['searchAutoComplete', query],
    () =>
      getSearchHistory({
        userId: Number(user?.id),
        count: COUNT,
        offset: 0,
        query,
      }),
    {
      suspense: false,
      enabled: query.length > 2,
    },
  );

  const handleSuccess = useCallback(
    (newData: SearchHistoryDtoSearchResult) => {
      setOffset((prevState) => prevState + COUNT);

      queryClient.setQueryData<SearchHistoryDtoSearchResult>(
        ['searchAutoComplete', query],
        (oldData) => {
          const oldItems = oldData?.items ?? [];
          const newItems = newData?.items ?? [];

          return {
            ...newData,
            items: [...oldItems, ...newItems],
          };
        },
      );
    },
    [query, queryClient],
  );

  const hasNextPage = useMemo(
    () => Boolean(data && data.total && data.total > offset),
    [offset, data],
  );

  const mutation = useMutation(
    () =>
      getSearchHistory({
        userId: Number(user?.id),
        count: COUNT,
        offset: 0,
        query,
      }),
    { onSuccess: handleSuccess },
  );

  const [sentryRef] = useInfiniteScroll({
    loading: mutation.isLoading,
    disabled: mutation.isError,
    hasNextPage,
    onLoadMore: mutation.mutate,
  });

  return {
    data,
    isLoading,
    hasNextPage,
    sentryRef,
  };
};
