/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { LikedUsersModel } from '../models';
// @ts-ignore
import { UserProfileModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * LikeApi - axios parameter creator
 * @export
 */
export const LikeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete like by owner
         * @param {number} [byPhotoId] 
         * @param {number} [byVideoId] 
         * @param {number} [byWallId] 
         * @param {number} [byCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LikesDelete: async (byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (byPhotoId !== undefined) {
                localVarQueryParameter['ByPhotoId'] = byPhotoId;
            }

            if (byVideoId !== undefined) {
                localVarQueryParameter['ByVideoId'] = byVideoId;
            }

            if (byWallId !== undefined) {
                localVarQueryParameter['ByWallId'] = byWallId;
            }

            if (byCommentId !== undefined) {
                localVarQueryParameter['ByCommentId'] = byCommentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get likes by owner
         * @param {number} [ownerByPhotoId] 
         * @param {number} [ownerByVideoId] 
         * @param {number} [ownerByWallId] 
         * @param {number} [ownerByCommentId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LikesGet: async (ownerByPhotoId?: number, ownerByVideoId?: number, ownerByWallId?: number, ownerByCommentId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ownerByPhotoId !== undefined) {
                localVarQueryParameter['Owner.ByPhotoId'] = ownerByPhotoId;
            }

            if (ownerByVideoId !== undefined) {
                localVarQueryParameter['Owner.ByVideoId'] = ownerByVideoId;
            }

            if (ownerByWallId !== undefined) {
                localVarQueryParameter['Owner.ByWallId'] = ownerByWallId;
            }

            if (ownerByCommentId !== undefined) {
                localVarQueryParameter['Owner.ByCommentId'] = ownerByCommentId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create like by owner
         * @param {number} [byPhotoId] 
         * @param {number} [byVideoId] 
         * @param {number} [byWallId] 
         * @param {number} [byCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LikesPost: async (byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (byPhotoId !== undefined) {
                localVarQueryParameter['ByPhotoId'] = byPhotoId;
            }

            if (byVideoId !== undefined) {
                localVarQueryParameter['ByVideoId'] = byVideoId;
            }

            if (byWallId !== undefined) {
                localVarQueryParameter['ByWallId'] = byWallId;
            }

            if (byCommentId !== undefined) {
                localVarQueryParameter['ByCommentId'] = byCommentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LikeApi - functional programming interface
 * @export
 */
export const LikeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LikeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete like by owner
         * @param {number} [byPhotoId] 
         * @param {number} [byVideoId] 
         * @param {number} [byWallId] 
         * @param {number} [byCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LikesDelete(byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LikesDelete(byPhotoId, byVideoId, byWallId, byCommentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get likes by owner
         * @param {number} [ownerByPhotoId] 
         * @param {number} [ownerByVideoId] 
         * @param {number} [ownerByWallId] 
         * @param {number} [ownerByCommentId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LikesGet(ownerByPhotoId?: number, ownerByVideoId?: number, ownerByWallId?: number, ownerByCommentId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LikedUsersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LikesGet(ownerByPhotoId, ownerByVideoId, ownerByWallId, ownerByCommentId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create like by owner
         * @param {number} [byPhotoId] 
         * @param {number} [byVideoId] 
         * @param {number} [byWallId] 
         * @param {number} [byCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LikesPost(byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LikesPost(byPhotoId, byVideoId, byWallId, byCommentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LikeApi - factory interface
 * @export
 */
export const LikeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LikeApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete like by owner
         * @param {number} [byPhotoId] 
         * @param {number} [byVideoId] 
         * @param {number} [byWallId] 
         * @param {number} [byCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LikesDelete(byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1LikesDelete(byPhotoId, byVideoId, byWallId, byCommentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get likes by owner
         * @param {number} [ownerByPhotoId] 
         * @param {number} [ownerByVideoId] 
         * @param {number} [ownerByWallId] 
         * @param {number} [ownerByCommentId] 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LikesGet(ownerByPhotoId?: number, ownerByVideoId?: number, ownerByWallId?: number, ownerByCommentId?: number, count?: number, offset?: number, options?: any): AxiosPromise<LikedUsersModel> {
            return localVarFp.apiV1LikesGet(ownerByPhotoId, ownerByVideoId, ownerByWallId, ownerByCommentId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create like by owner
         * @param {number} [byPhotoId] 
         * @param {number} [byVideoId] 
         * @param {number} [byWallId] 
         * @param {number} [byCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LikesPost(byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, options?: any): AxiosPromise<UserProfileModel> {
            return localVarFp.apiV1LikesPost(byPhotoId, byVideoId, byWallId, byCommentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1LikesDelete operation in LikeApi.
 * @export
 * @interface LikeApiApiV1LikesDeleteRequest
 */
export interface LikeApiApiV1LikesDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesDelete
     */
    readonly byPhotoId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesDelete
     */
    readonly byVideoId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesDelete
     */
    readonly byWallId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesDelete
     */
    readonly byCommentId?: number
}

/**
 * Request parameters for apiV1LikesGet operation in LikeApi.
 * @export
 * @interface LikeApiApiV1LikesGetRequest
 */
export interface LikeApiApiV1LikesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesGet
     */
    readonly ownerByPhotoId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesGet
     */
    readonly ownerByVideoId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesGet
     */
    readonly ownerByWallId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesGet
     */
    readonly ownerByCommentId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof LikeApiApiV1LikesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof LikeApiApiV1LikesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1LikesPost operation in LikeApi.
 * @export
 * @interface LikeApiApiV1LikesPostRequest
 */
export interface LikeApiApiV1LikesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesPost
     */
    readonly byPhotoId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesPost
     */
    readonly byVideoId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesPost
     */
    readonly byWallId?: number

    /**
     * 
     * @type {number}
     * @memberof LikeApiApiV1LikesPost
     */
    readonly byCommentId?: number
}

/**
 * LikeApi - object-oriented interface
 * @export
 * @class LikeApi
 * @extends {BaseAPI}
 */
export class LikeApi extends BaseAPI {
    /**
     * 
     * @summary Delete like by owner
     * @param {LikeApiApiV1LikesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LikeApi
     */
    public apiV1LikesDelete(requestParameters: LikeApiApiV1LikesDeleteRequest = {}, options?: AxiosRequestConfig) {
        return LikeApiFp(this.configuration).apiV1LikesDelete(requestParameters.byPhotoId, requestParameters.byVideoId, requestParameters.byWallId, requestParameters.byCommentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get likes by owner
     * @param {LikeApiApiV1LikesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LikeApi
     */
    public apiV1LikesGet(requestParameters: LikeApiApiV1LikesGetRequest = {}, options?: AxiosRequestConfig) {
        return LikeApiFp(this.configuration).apiV1LikesGet(requestParameters.ownerByPhotoId, requestParameters.ownerByVideoId, requestParameters.ownerByWallId, requestParameters.ownerByCommentId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create like by owner
     * @param {LikeApiApiV1LikesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LikeApi
     */
    public apiV1LikesPost(requestParameters: LikeApiApiV1LikesPostRequest = {}, options?: AxiosRequestConfig) {
        return LikeApiFp(this.configuration).apiV1LikesPost(requestParameters.byPhotoId, requestParameters.byVideoId, requestParameters.byWallId, requestParameters.byCommentId, options).then((request) => request(this.axios, this.basePath));
    }
}
