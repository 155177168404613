import { api } from 'services/api';

export interface UserPreviewsByQueryParams {
  query: string;
  count: number;
  offset: number;
}

const getUserPreviewsByQuery = async ({
  query,
  count,
  offset,
}: UserPreviewsByQueryParams) => {
  const { data } = await api.user.apiV1UsersPreviewGet({
    query,
    offset,
    count,
    isArchived: false,
    orderByLastName: true,
  });

  return data;
};

export const useUserApi = () => ({
  getUserPreviewsByQuery,
});
