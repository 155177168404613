/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DashboardDtoSearchResult } from '../models';
// @ts-ignore
import { DashboardType } from '../models';
// @ts-ignore
import { DeleteUserDashboardBody } from '../models';
// @ts-ignore
import { UpdateUserDashboardBody } from '../models';
/**
 * UserDashboardApi - axios parameter creator
 * @export
 */
export const UserDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete user dashboard widget from block
         * @param {number} userId 
         * @param {DeleteUserDashboardBody} [deleteUserDashboardBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDashboardsBlocksDelete: async (userId: number, deleteUserDashboardBody?: DeleteUserDashboardBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDashboardsBlocksDelete', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/dashboards/blocks`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUserDashboardBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user dashboard block
         * @param {number} userId 
         * @param {UpdateUserDashboardBody} [updateUserDashboardBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDashboardsBlocksPut: async (userId: number, updateUserDashboardBody?: UpdateUserDashboardBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDashboardsBlocksPut', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/dashboards/blocks`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDashboardBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dashboards by filter
         * @param {number} userId Фильтр по пользователю
         * @param {DashboardType} [dashboardType] Фильтр по типу dashboard
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDashboardsGet: async (userId: number, dashboardType?: DashboardType, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdDashboardsGet', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/dashboards`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dashboardType !== undefined) {
                localVarQueryParameter['DashboardType'] = dashboardType;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDashboardApi - functional programming interface
 * @export
 */
export const UserDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete user dashboard widget from block
         * @param {number} userId 
         * @param {DeleteUserDashboardBody} [deleteUserDashboardBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdDashboardsBlocksDelete(userId: number, deleteUserDashboardBody?: DeleteUserDashboardBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdDashboardsBlocksDelete(userId, deleteUserDashboardBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user dashboard block
         * @param {number} userId 
         * @param {UpdateUserDashboardBody} [updateUserDashboardBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdDashboardsBlocksPut(userId: number, updateUserDashboardBody?: UpdateUserDashboardBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdDashboardsBlocksPut(userId, updateUserDashboardBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dashboards by filter
         * @param {number} userId Фильтр по пользователю
         * @param {DashboardType} [dashboardType] Фильтр по типу dashboard
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdDashboardsGet(userId: number, dashboardType?: DashboardType, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdDashboardsGet(userId, dashboardType, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDashboardApi - factory interface
 * @export
 */
export const UserDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete user dashboard widget from block
         * @param {number} userId 
         * @param {DeleteUserDashboardBody} [deleteUserDashboardBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDashboardsBlocksDelete(userId: number, deleteUserDashboardBody?: DeleteUserDashboardBody, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersUserIdDashboardsBlocksDelete(userId, deleteUserDashboardBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user dashboard block
         * @param {number} userId 
         * @param {UpdateUserDashboardBody} [updateUserDashboardBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDashboardsBlocksPut(userId: number, updateUserDashboardBody?: UpdateUserDashboardBody, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersUserIdDashboardsBlocksPut(userId, updateUserDashboardBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dashboards by filter
         * @param {number} userId Фильтр по пользователю
         * @param {DashboardType} [dashboardType] Фильтр по типу dashboard
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdDashboardsGet(userId: number, dashboardType?: DashboardType, count?: number, offset?: number, options?: any): AxiosPromise<DashboardDtoSearchResult> {
            return localVarFp.apiV1UsersUserIdDashboardsGet(userId, dashboardType, count, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UsersUserIdDashboardsBlocksDelete operation in UserDashboardApi.
 * @export
 * @interface UserDashboardApiApiV1UsersUserIdDashboardsBlocksDeleteRequest
 */
export interface UserDashboardApiApiV1UsersUserIdDashboardsBlocksDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDashboardApiApiV1UsersUserIdDashboardsBlocksDelete
     */
    readonly userId: number

    /**
     * 
     * @type {DeleteUserDashboardBody}
     * @memberof UserDashboardApiApiV1UsersUserIdDashboardsBlocksDelete
     */
    readonly deleteUserDashboardBody?: DeleteUserDashboardBody
}

/**
 * Request parameters for apiV1UsersUserIdDashboardsBlocksPut operation in UserDashboardApi.
 * @export
 * @interface UserDashboardApiApiV1UsersUserIdDashboardsBlocksPutRequest
 */
export interface UserDashboardApiApiV1UsersUserIdDashboardsBlocksPutRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDashboardApiApiV1UsersUserIdDashboardsBlocksPut
     */
    readonly userId: number

    /**
     * 
     * @type {UpdateUserDashboardBody}
     * @memberof UserDashboardApiApiV1UsersUserIdDashboardsBlocksPut
     */
    readonly updateUserDashboardBody?: UpdateUserDashboardBody
}

/**
 * Request parameters for apiV1UsersUserIdDashboardsGet operation in UserDashboardApi.
 * @export
 * @interface UserDashboardApiApiV1UsersUserIdDashboardsGetRequest
 */
export interface UserDashboardApiApiV1UsersUserIdDashboardsGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof UserDashboardApiApiV1UsersUserIdDashboardsGet
     */
    readonly userId: number

    /**
     * Фильтр по типу dashboard
     * @type {DashboardType}
     * @memberof UserDashboardApiApiV1UsersUserIdDashboardsGet
     */
    readonly dashboardType?: DashboardType

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserDashboardApiApiV1UsersUserIdDashboardsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserDashboardApiApiV1UsersUserIdDashboardsGet
     */
    readonly offset?: number
}

/**
 * UserDashboardApi - object-oriented interface
 * @export
 * @class UserDashboardApi
 * @extends {BaseAPI}
 */
export class UserDashboardApi extends BaseAPI {
    /**
     * 
     * @summary Delete user dashboard widget from block
     * @param {UserDashboardApiApiV1UsersUserIdDashboardsBlocksDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDashboardApi
     */
    public apiV1UsersUserIdDashboardsBlocksDelete(requestParameters: UserDashboardApiApiV1UsersUserIdDashboardsBlocksDeleteRequest, options?: AxiosRequestConfig) {
        return UserDashboardApiFp(this.configuration).apiV1UsersUserIdDashboardsBlocksDelete(requestParameters.userId, requestParameters.deleteUserDashboardBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user dashboard block
     * @param {UserDashboardApiApiV1UsersUserIdDashboardsBlocksPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDashboardApi
     */
    public apiV1UsersUserIdDashboardsBlocksPut(requestParameters: UserDashboardApiApiV1UsersUserIdDashboardsBlocksPutRequest, options?: AxiosRequestConfig) {
        return UserDashboardApiFp(this.configuration).apiV1UsersUserIdDashboardsBlocksPut(requestParameters.userId, requestParameters.updateUserDashboardBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dashboards by filter
     * @param {UserDashboardApiApiV1UsersUserIdDashboardsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDashboardApi
     */
    public apiV1UsersUserIdDashboardsGet(requestParameters: UserDashboardApiApiV1UsersUserIdDashboardsGetRequest, options?: AxiosRequestConfig) {
        return UserDashboardApiFp(this.configuration).apiV1UsersUserIdDashboardsGet(requestParameters.userId, requestParameters.dashboardType, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}
