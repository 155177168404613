import { Context, FC, createContext, useContext } from 'react';

import { Editor } from '@tiptap/core';

import {
  PostEditorServiceProps,
  usePostEditorService,
} from './usePostEditorService';

const PostEditorContext = createContext<Editor | null>(null);

export const usePostEditor = (): Editor | null =>
  useContext(PostEditorContext as Context<Editor | null>);

export const PostEditorProvider: FC<PostEditorServiceProps> = ({
  placeholder,
  text,
  children,
}) => {
  const editor = usePostEditorService({ placeholder, text });
  return (
    <PostEditorContext.Provider value={editor}>
      {children}
    </PostEditorContext.Provider>
  );
};
