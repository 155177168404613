/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { IdeaFocus } from '../models';
// @ts-ignore
import { IdeaStatus } from '../models';
// @ts-ignore
import { LotteryMembersModel } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { PageAccessType } from '../models';
// @ts-ignore
import { SharedUsersModel } from '../models';
// @ts-ignore
import { UsersModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
// @ts-ignore
import { WallContentType } from '../models';
// @ts-ignore
import { WallModel } from '../models';
// @ts-ignore
import { WallThemeDtoSearchResult } from '../models';
// @ts-ignore
import { WallUpdateModel } from '../models';
// @ts-ignore
import { WallsModel } from '../models';
/**
 * WallApi - axios parameter creator
 * @export
 */
export const WallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по стене пользователя
         * @param {number} [groupId] Фильтр по стене группы
         * @param {boolean} [isActiveLottery] Выводить только активные лотереи
         * @param {boolean} [isLiked] Выводить только записи по лайкам
         * @param {boolean} [isPinned] Выводить только прикреплённые записи
         * @param {boolean} [isValidation] Выводить только не опубликованные записи
         * @param {boolean} [isSharedPost] Выводить только репосты
         * @param {string} [wallContent] Фильтр по содержимому записи
         * @param {number} [createdByUserId] Фильтр по автору записи
         * @param {boolean} [isFavorite] Выводить только избранные записи
         * @param {string} [createdDate] Фильтр по дате создания записи
         * @param {string} [createdDateFrom] Фильтр по дате создания записи
         * @param {string} [createdDateTo] Фильтр по дате создания записи
         * @param {IdeaStatus} [ideaStatus] Фильтр по статусу идеи
         * @param {IdeaFocus} [ideaFocus] Фильтр по наравленности идем
         * @param {WallContentType} [wallContentType] Фильтр по типу поста
         * @param {PageAccessType} [pageAccessType] Фильтр по типу доступа к странице
         * @param {string} [tags] Фильтр по тегам записи
         * @param {boolean} [isShowHiddenIdeas] Показывать отклонённые идеи
         * @param {boolean} [isIdea] Фильтр по постам являющимися идеями
         * @param {string} [query] Фильтр по тексту
         * @param {boolean} [isMyGroupPost] Фильтр по своим группам
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [orderByPopularity] Фильтр по популярности
         * @param {boolean} [isNotExpiredIdeas] Фильтр по дате окончания голосования
         * @param {boolean} [orderByVotePeriodAsc] Сортировка по дате окончания голосования
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsGet: async (userId?: number, groupId?: number, isActiveLottery?: boolean, isLiked?: boolean, isPinned?: boolean, isValidation?: boolean, isSharedPost?: boolean, wallContent?: string, createdByUserId?: number, isFavorite?: boolean, createdDate?: string, createdDateFrom?: string, createdDateTo?: string, ideaStatus?: IdeaStatus, ideaFocus?: IdeaFocus, wallContentType?: WallContentType, pageAccessType?: PageAccessType, tags?: string, isShowHiddenIdeas?: boolean, isIdea?: boolean, query?: string, isMyGroupPost?: boolean, countryId?: number, orderByPopularity?: boolean, isNotExpiredIdeas?: boolean, orderByVotePeriodAsc?: boolean, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/walls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (isActiveLottery !== undefined) {
                localVarQueryParameter['IsActiveLottery'] = isActiveLottery;
            }

            if (isLiked !== undefined) {
                localVarQueryParameter['IsLiked'] = isLiked;
            }

            if (isPinned !== undefined) {
                localVarQueryParameter['IsPinned'] = isPinned;
            }

            if (isValidation !== undefined) {
                localVarQueryParameter['IsValidation'] = isValidation;
            }

            if (isSharedPost !== undefined) {
                localVarQueryParameter['IsSharedPost'] = isSharedPost;
            }

            if (wallContent !== undefined) {
                localVarQueryParameter['WallContent'] = wallContent;
            }

            if (createdByUserId !== undefined) {
                localVarQueryParameter['CreatedByUserId'] = createdByUserId;
            }

            if (isFavorite !== undefined) {
                localVarQueryParameter['IsFavorite'] = isFavorite;
            }

            if (createdDate !== undefined) {
                localVarQueryParameter['CreatedDate'] = (createdDate as any instanceof Date) ?
                    (createdDate as any).toISOString() :
                    createdDate;
            }

            if (createdDateFrom !== undefined) {
                localVarQueryParameter['CreatedDateFrom'] = (createdDateFrom as any instanceof Date) ?
                    (createdDateFrom as any).toISOString() :
                    createdDateFrom;
            }

            if (createdDateTo !== undefined) {
                localVarQueryParameter['CreatedDateTo'] = (createdDateTo as any instanceof Date) ?
                    (createdDateTo as any).toISOString() :
                    createdDateTo;
            }

            if (ideaStatus !== undefined) {
                localVarQueryParameter['IdeaStatus'] = ideaStatus;
            }

            if (ideaFocus !== undefined) {
                localVarQueryParameter['IdeaFocus'] = ideaFocus;
            }

            if (wallContentType !== undefined) {
                localVarQueryParameter['WallContentType'] = wallContentType;
            }

            if (pageAccessType !== undefined) {
                localVarQueryParameter['PageAccessType'] = pageAccessType;
            }

            if (tags !== undefined) {
                localVarQueryParameter['Tags'] = tags;
            }

            if (isShowHiddenIdeas !== undefined) {
                localVarQueryParameter['IsShowHiddenIdeas'] = isShowHiddenIdeas;
            }

            if (isIdea !== undefined) {
                localVarQueryParameter['IsIdea'] = isIdea;
            }

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (isMyGroupPost !== undefined) {
                localVarQueryParameter['IsMyGroupPost'] = isMyGroupPost;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (orderByPopularity !== undefined) {
                localVarQueryParameter['OrderByPopularity'] = orderByPopularity;
            }

            if (isNotExpiredIdeas !== undefined) {
                localVarQueryParameter['IsNotExpiredIdeas'] = isNotExpiredIdeas;
            }

            if (orderByVotePeriodAsc !== undefined) {
                localVarQueryParameter['OrderByVotePeriodAsc'] = orderByVotePeriodAsc;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copy wall by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdCopyPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdCopyPost', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdDelete', 'id', id)
            const localVarPath = `/api/v1/walls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete wall favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdFavoriteDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdFavoriteDelete', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/favorite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create wall favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdFavoritePost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdFavoritePost', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/favorite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdGet', 'id', id)
            const localVarPath = `/api/v1/walls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get liked users
         * @param {number} id 
         * @param {string} [query] Фильтр по запросу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLikesGet: async (id: number, query?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdLikesGet', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/likes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End lottery by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLotteryEndPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdLotteryEndPost', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/lottery-end`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete lottery member by current user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLotteryMemberDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdLotteryMemberDelete', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/lottery-member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get lottery members
         * @param {number} id 
         * @param {number} [wallId] Фильтр по постам
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLotteryMemberGet: async (id: number, wallId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdLotteryMemberGet', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/lottery-member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (wallId !== undefined) {
                localVarQueryParameter['WallId'] = wallId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create lottery member by current user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLotteryMemberPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdLotteryMemberPost', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/lottery-member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End lottery by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdOverlookPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdOverlookPost', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/overlook`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdPatch', 'id', id)
            const localVarPath = `/api/v1/walls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get shared users
         * @param {number} id 
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdSharesGet: async (id: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1WallsIdSharesGet', 'id', id)
            const localVarPath = `/api/v1/walls/{id}/shares`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {WallUpdateModel} [wallUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsPost: async (wallUpdateModel?: WallUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/walls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wallUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {WallUpdateModel} [wallUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsPut: async (wallUpdateModel?: WallUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/walls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wallUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get wall themes by filter
         * @param {boolean} [orderByPopular] Фильтр по популярности
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsThemesGet: async (orderByPopular?: boolean, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/walls/themes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderByPopular !== undefined) {
                localVarQueryParameter['OrderByPopular'] = orderByPopular;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WallApi - functional programming interface
 * @export
 */
export const WallApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WallApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по стене пользователя
         * @param {number} [groupId] Фильтр по стене группы
         * @param {boolean} [isActiveLottery] Выводить только активные лотереи
         * @param {boolean} [isLiked] Выводить только записи по лайкам
         * @param {boolean} [isPinned] Выводить только прикреплённые записи
         * @param {boolean} [isValidation] Выводить только не опубликованные записи
         * @param {boolean} [isSharedPost] Выводить только репосты
         * @param {string} [wallContent] Фильтр по содержимому записи
         * @param {number} [createdByUserId] Фильтр по автору записи
         * @param {boolean} [isFavorite] Выводить только избранные записи
         * @param {string} [createdDate] Фильтр по дате создания записи
         * @param {string} [createdDateFrom] Фильтр по дате создания записи
         * @param {string} [createdDateTo] Фильтр по дате создания записи
         * @param {IdeaStatus} [ideaStatus] Фильтр по статусу идеи
         * @param {IdeaFocus} [ideaFocus] Фильтр по наравленности идем
         * @param {WallContentType} [wallContentType] Фильтр по типу поста
         * @param {PageAccessType} [pageAccessType] Фильтр по типу доступа к странице
         * @param {string} [tags] Фильтр по тегам записи
         * @param {boolean} [isShowHiddenIdeas] Показывать отклонённые идеи
         * @param {boolean} [isIdea] Фильтр по постам являющимися идеями
         * @param {string} [query] Фильтр по тексту
         * @param {boolean} [isMyGroupPost] Фильтр по своим группам
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [orderByPopularity] Фильтр по популярности
         * @param {boolean} [isNotExpiredIdeas] Фильтр по дате окончания голосования
         * @param {boolean} [orderByVotePeriodAsc] Сортировка по дате окончания голосования
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsGet(userId?: number, groupId?: number, isActiveLottery?: boolean, isLiked?: boolean, isPinned?: boolean, isValidation?: boolean, isSharedPost?: boolean, wallContent?: string, createdByUserId?: number, isFavorite?: boolean, createdDate?: string, createdDateFrom?: string, createdDateTo?: string, ideaStatus?: IdeaStatus, ideaFocus?: IdeaFocus, wallContentType?: WallContentType, pageAccessType?: PageAccessType, tags?: string, isShowHiddenIdeas?: boolean, isIdea?: boolean, query?: string, isMyGroupPost?: boolean, countryId?: number, orderByPopularity?: boolean, isNotExpiredIdeas?: boolean, orderByVotePeriodAsc?: boolean, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WallsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsGet(userId, groupId, isActiveLottery, isLiked, isPinned, isValidation, isSharedPost, wallContent, createdByUserId, isFavorite, createdDate, createdDateFrom, createdDateTo, ideaStatus, ideaFocus, wallContentType, pageAccessType, tags, isShowHiddenIdeas, isIdea, query, isMyGroupPost, countryId, orderByPopularity, isNotExpiredIdeas, orderByVotePeriodAsc, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Copy wall by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdCopyPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WallModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdCopyPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete wall favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdFavoriteDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdFavoriteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create wall favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdFavoritePost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdFavoritePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WallModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get liked users
         * @param {number} id 
         * @param {string} [query] Фильтр по запросу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdLikesGet(id: number, query?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdLikesGet(id, query, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End lottery by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdLotteryEndPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WallModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdLotteryEndPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete lottery member by current user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdLotteryMemberDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotteryMembersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdLotteryMemberDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get lottery members
         * @param {number} id 
         * @param {number} [wallId] Фильтр по постам
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdLotteryMemberGet(id: number, wallId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotteryMembersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdLotteryMemberGet(id, wallId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create lottery member by current user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdLotteryMemberPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotteryMembersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdLotteryMemberPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End lottery by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdOverlookPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdOverlookPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WallModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get shared users
         * @param {number} id 
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsIdSharesGet(id: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedUsersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsIdSharesGet(id, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {WallUpdateModel} [wallUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsPost(wallUpdateModel?: WallUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WallModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsPost(wallUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {WallUpdateModel} [wallUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsPut(wallUpdateModel?: WallUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WallModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsPut(wallUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get wall themes by filter
         * @param {boolean} [orderByPopular] Фильтр по популярности
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1WallsThemesGet(orderByPopular?: boolean, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WallThemeDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1WallsThemesGet(orderByPopular, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WallApi - factory interface
 * @export
 */
export const WallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WallApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по стене пользователя
         * @param {number} [groupId] Фильтр по стене группы
         * @param {boolean} [isActiveLottery] Выводить только активные лотереи
         * @param {boolean} [isLiked] Выводить только записи по лайкам
         * @param {boolean} [isPinned] Выводить только прикреплённые записи
         * @param {boolean} [isValidation] Выводить только не опубликованные записи
         * @param {boolean} [isSharedPost] Выводить только репосты
         * @param {string} [wallContent] Фильтр по содержимому записи
         * @param {number} [createdByUserId] Фильтр по автору записи
         * @param {boolean} [isFavorite] Выводить только избранные записи
         * @param {string} [createdDate] Фильтр по дате создания записи
         * @param {string} [createdDateFrom] Фильтр по дате создания записи
         * @param {string} [createdDateTo] Фильтр по дате создания записи
         * @param {IdeaStatus} [ideaStatus] Фильтр по статусу идеи
         * @param {IdeaFocus} [ideaFocus] Фильтр по наравленности идем
         * @param {WallContentType} [wallContentType] Фильтр по типу поста
         * @param {PageAccessType} [pageAccessType] Фильтр по типу доступа к странице
         * @param {string} [tags] Фильтр по тегам записи
         * @param {boolean} [isShowHiddenIdeas] Показывать отклонённые идеи
         * @param {boolean} [isIdea] Фильтр по постам являющимися идеями
         * @param {string} [query] Фильтр по тексту
         * @param {boolean} [isMyGroupPost] Фильтр по своим группам
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [orderByPopularity] Фильтр по популярности
         * @param {boolean} [isNotExpiredIdeas] Фильтр по дате окончания голосования
         * @param {boolean} [orderByVotePeriodAsc] Сортировка по дате окончания голосования
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsGet(userId?: number, groupId?: number, isActiveLottery?: boolean, isLiked?: boolean, isPinned?: boolean, isValidation?: boolean, isSharedPost?: boolean, wallContent?: string, createdByUserId?: number, isFavorite?: boolean, createdDate?: string, createdDateFrom?: string, createdDateTo?: string, ideaStatus?: IdeaStatus, ideaFocus?: IdeaFocus, wallContentType?: WallContentType, pageAccessType?: PageAccessType, tags?: string, isShowHiddenIdeas?: boolean, isIdea?: boolean, query?: string, isMyGroupPost?: boolean, countryId?: number, orderByPopularity?: boolean, isNotExpiredIdeas?: boolean, orderByVotePeriodAsc?: boolean, count?: number, offset?: number, options?: any): AxiosPromise<WallsModel> {
            return localVarFp.apiV1WallsGet(userId, groupId, isActiveLottery, isLiked, isPinned, isValidation, isSharedPost, wallContent, createdByUserId, isFavorite, createdDate, createdDateFrom, createdDateTo, ideaStatus, ideaFocus, wallContentType, pageAccessType, tags, isShowHiddenIdeas, isIdea, query, isMyGroupPost, countryId, orderByPopularity, isNotExpiredIdeas, orderByVotePeriodAsc, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Copy wall by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdCopyPost(id: number, options?: any): AxiosPromise<WallModel> {
            return localVarFp.apiV1WallsIdCopyPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1WallsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete wall favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdFavoriteDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1WallsIdFavoriteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create wall favorite
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdFavoritePost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1WallsIdFavoritePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdGet(id: number, options?: any): AxiosPromise<WallModel> {
            return localVarFp.apiV1WallsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get liked users
         * @param {number} id 
         * @param {string} [query] Фильтр по запросу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLikesGet(id: number, query?: string, count?: number, offset?: number, options?: any): AxiosPromise<UsersModel> {
            return localVarFp.apiV1WallsIdLikesGet(id, query, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End lottery by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLotteryEndPost(id: number, options?: any): AxiosPromise<WallModel> {
            return localVarFp.apiV1WallsIdLotteryEndPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete lottery member by current user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLotteryMemberDelete(id: number, options?: any): AxiosPromise<LotteryMembersModel> {
            return localVarFp.apiV1WallsIdLotteryMemberDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get lottery members
         * @param {number} id 
         * @param {number} [wallId] Фильтр по постам
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLotteryMemberGet(id: number, wallId?: number, count?: number, offset?: number, options?: any): AxiosPromise<LotteryMembersModel> {
            return localVarFp.apiV1WallsIdLotteryMemberGet(id, wallId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create lottery member by current user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdLotteryMemberPost(id: number, options?: any): AxiosPromise<LotteryMembersModel> {
            return localVarFp.apiV1WallsIdLotteryMemberPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End lottery by wall id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdOverlookPost(id: number, options?: any): AxiosPromise<number> {
            return localVarFp.apiV1WallsIdOverlookPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<WallModel> {
            return localVarFp.apiV1WallsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get shared users
         * @param {number} id 
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsIdSharesGet(id: number, count?: number, offset?: number, options?: any): AxiosPromise<SharedUsersModel> {
            return localVarFp.apiV1WallsIdSharesGet(id, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {WallUpdateModel} [wallUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsPost(wallUpdateModel?: WallUpdateModel, options?: any): AxiosPromise<WallModel> {
            return localVarFp.apiV1WallsPost(wallUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {WallUpdateModel} [wallUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsPut(wallUpdateModel?: WallUpdateModel, options?: any): AxiosPromise<WallModel> {
            return localVarFp.apiV1WallsPut(wallUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get wall themes by filter
         * @param {boolean} [orderByPopular] Фильтр по популярности
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1WallsThemesGet(orderByPopular?: boolean, count?: number, offset?: number, options?: any): AxiosPromise<WallThemeDtoSearchResult> {
            return localVarFp.apiV1WallsThemesGet(orderByPopular, count, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1WallsGet operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsGetRequest
 */
export interface WallApiApiV1WallsGetRequest {
    /**
     * Фильтр по стене пользователя
     * @type {number}
     * @memberof WallApiApiV1WallsGet
     */
    readonly userId?: number

    /**
     * Фильтр по стене группы
     * @type {number}
     * @memberof WallApiApiV1WallsGet
     */
    readonly groupId?: number

    /**
     * Выводить только активные лотереи
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isActiveLottery?: boolean

    /**
     * Выводить только записи по лайкам
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isLiked?: boolean

    /**
     * Выводить только прикреплённые записи
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isPinned?: boolean

    /**
     * Выводить только не опубликованные записи
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isValidation?: boolean

    /**
     * Выводить только репосты
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isSharedPost?: boolean

    /**
     * Фильтр по содержимому записи
     * @type {string}
     * @memberof WallApiApiV1WallsGet
     */
    readonly wallContent?: string

    /**
     * Фильтр по автору записи
     * @type {number}
     * @memberof WallApiApiV1WallsGet
     */
    readonly createdByUserId?: number

    /**
     * Выводить только избранные записи
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isFavorite?: boolean

    /**
     * Фильтр по дате создания записи
     * @type {string}
     * @memberof WallApiApiV1WallsGet
     */
    readonly createdDate?: string

    /**
     * Фильтр по дате создания записи
     * @type {string}
     * @memberof WallApiApiV1WallsGet
     */
    readonly createdDateFrom?: string

    /**
     * Фильтр по дате создания записи
     * @type {string}
     * @memberof WallApiApiV1WallsGet
     */
    readonly createdDateTo?: string

    /**
     * Фильтр по статусу идеи
     * @type {IdeaStatus}
     * @memberof WallApiApiV1WallsGet
     */
    readonly ideaStatus?: IdeaStatus

    /**
     * Фильтр по наравленности идем
     * @type {IdeaFocus}
     * @memberof WallApiApiV1WallsGet
     */
    readonly ideaFocus?: IdeaFocus

    /**
     * Фильтр по типу поста
     * @type {WallContentType}
     * @memberof WallApiApiV1WallsGet
     */
    readonly wallContentType?: WallContentType

    /**
     * Фильтр по типу доступа к странице
     * @type {PageAccessType}
     * @memberof WallApiApiV1WallsGet
     */
    readonly pageAccessType?: PageAccessType

    /**
     * Фильтр по тегам записи
     * @type {string}
     * @memberof WallApiApiV1WallsGet
     */
    readonly tags?: string

    /**
     * Показывать отклонённые идеи
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isShowHiddenIdeas?: boolean

    /**
     * Фильтр по постам являющимися идеями
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isIdea?: boolean

    /**
     * Фильтр по тексту
     * @type {string}
     * @memberof WallApiApiV1WallsGet
     */
    readonly query?: string

    /**
     * Фильтр по своим группам
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isMyGroupPost?: boolean

    /**
     * Фильтр по стране
     * @type {number}
     * @memberof WallApiApiV1WallsGet
     */
    readonly countryId?: number

    /**
     * Фильтр по популярности
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly orderByPopularity?: boolean

    /**
     * Фильтр по дате окончания голосования
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly isNotExpiredIdeas?: boolean

    /**
     * Сортировка по дате окончания голосования
     * @type {boolean}
     * @memberof WallApiApiV1WallsGet
     */
    readonly orderByVotePeriodAsc?: boolean

    /**
     * Количеству объектов
     * @type {number}
     * @memberof WallApiApiV1WallsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof WallApiApiV1WallsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1WallsIdCopyPost operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdCopyPostRequest
 */
export interface WallApiApiV1WallsIdCopyPostRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdCopyPost
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdDelete operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdDeleteRequest
 */
export interface WallApiApiV1WallsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdFavoriteDelete operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdFavoriteDeleteRequest
 */
export interface WallApiApiV1WallsIdFavoriteDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdFavoriteDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdFavoritePost operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdFavoritePostRequest
 */
export interface WallApiApiV1WallsIdFavoritePostRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdFavoritePost
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdGet operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdGetRequest
 */
export interface WallApiApiV1WallsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdLikesGet operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdLikesGetRequest
 */
export interface WallApiApiV1WallsIdLikesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdLikesGet
     */
    readonly id: number

    /**
     * Фильтр по запросу
     * @type {string}
     * @memberof WallApiApiV1WallsIdLikesGet
     */
    readonly query?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof WallApiApiV1WallsIdLikesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof WallApiApiV1WallsIdLikesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1WallsIdLotteryEndPost operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdLotteryEndPostRequest
 */
export interface WallApiApiV1WallsIdLotteryEndPostRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdLotteryEndPost
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdLotteryMemberDelete operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdLotteryMemberDeleteRequest
 */
export interface WallApiApiV1WallsIdLotteryMemberDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdLotteryMemberDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdLotteryMemberGet operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdLotteryMemberGetRequest
 */
export interface WallApiApiV1WallsIdLotteryMemberGetRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdLotteryMemberGet
     */
    readonly id: number

    /**
     * Фильтр по постам
     * @type {number}
     * @memberof WallApiApiV1WallsIdLotteryMemberGet
     */
    readonly wallId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof WallApiApiV1WallsIdLotteryMemberGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof WallApiApiV1WallsIdLotteryMemberGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1WallsIdLotteryMemberPost operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdLotteryMemberPostRequest
 */
export interface WallApiApiV1WallsIdLotteryMemberPostRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdLotteryMemberPost
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdOverlookPost operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdOverlookPostRequest
 */
export interface WallApiApiV1WallsIdOverlookPostRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdOverlookPost
     */
    readonly id: number
}

/**
 * Request parameters for apiV1WallsIdPatch operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdPatchRequest
 */
export interface WallApiApiV1WallsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof WallApiApiV1WallsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1WallsIdSharesGet operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsIdSharesGetRequest
 */
export interface WallApiApiV1WallsIdSharesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdSharesGet
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdSharesGet
     */
    readonly count?: number

    /**
     * 
     * @type {number}
     * @memberof WallApiApiV1WallsIdSharesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1WallsPost operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsPostRequest
 */
export interface WallApiApiV1WallsPostRequest {
    /**
     * 
     * @type {WallUpdateModel}
     * @memberof WallApiApiV1WallsPost
     */
    readonly wallUpdateModel?: WallUpdateModel
}

/**
 * Request parameters for apiV1WallsPut operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsPutRequest
 */
export interface WallApiApiV1WallsPutRequest {
    /**
     * 
     * @type {WallUpdateModel}
     * @memberof WallApiApiV1WallsPut
     */
    readonly wallUpdateModel?: WallUpdateModel
}

/**
 * Request parameters for apiV1WallsThemesGet operation in WallApi.
 * @export
 * @interface WallApiApiV1WallsThemesGetRequest
 */
export interface WallApiApiV1WallsThemesGetRequest {
    /**
     * Фильтр по популярности
     * @type {boolean}
     * @memberof WallApiApiV1WallsThemesGet
     */
    readonly orderByPopular?: boolean

    /**
     * Количеству объектов
     * @type {number}
     * @memberof WallApiApiV1WallsThemesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof WallApiApiV1WallsThemesGet
     */
    readonly offset?: number
}

/**
 * WallApi - object-oriented interface
 * @export
 * @class WallApi
 * @extends {BaseAPI}
 */
export class WallApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {WallApiApiV1WallsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsGet(requestParameters: WallApiApiV1WallsGetRequest = {}, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsGet(requestParameters.userId, requestParameters.groupId, requestParameters.isActiveLottery, requestParameters.isLiked, requestParameters.isPinned, requestParameters.isValidation, requestParameters.isSharedPost, requestParameters.wallContent, requestParameters.createdByUserId, requestParameters.isFavorite, requestParameters.createdDate, requestParameters.createdDateFrom, requestParameters.createdDateTo, requestParameters.ideaStatus, requestParameters.ideaFocus, requestParameters.wallContentType, requestParameters.pageAccessType, requestParameters.tags, requestParameters.isShowHiddenIdeas, requestParameters.isIdea, requestParameters.query, requestParameters.isMyGroupPost, requestParameters.countryId, requestParameters.orderByPopularity, requestParameters.isNotExpiredIdeas, requestParameters.orderByVotePeriodAsc, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Copy wall by wall id
     * @param {WallApiApiV1WallsIdCopyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdCopyPost(requestParameters: WallApiApiV1WallsIdCopyPostRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdCopyPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {WallApiApiV1WallsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdDelete(requestParameters: WallApiApiV1WallsIdDeleteRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete wall favorite
     * @param {WallApiApiV1WallsIdFavoriteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdFavoriteDelete(requestParameters: WallApiApiV1WallsIdFavoriteDeleteRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdFavoriteDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create wall favorite
     * @param {WallApiApiV1WallsIdFavoritePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdFavoritePost(requestParameters: WallApiApiV1WallsIdFavoritePostRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdFavoritePost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {WallApiApiV1WallsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdGet(requestParameters: WallApiApiV1WallsIdGetRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get liked users
     * @param {WallApiApiV1WallsIdLikesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdLikesGet(requestParameters: WallApiApiV1WallsIdLikesGetRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdLikesGet(requestParameters.id, requestParameters.query, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End lottery by wall id
     * @param {WallApiApiV1WallsIdLotteryEndPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdLotteryEndPost(requestParameters: WallApiApiV1WallsIdLotteryEndPostRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdLotteryEndPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete lottery member by current user
     * @param {WallApiApiV1WallsIdLotteryMemberDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdLotteryMemberDelete(requestParameters: WallApiApiV1WallsIdLotteryMemberDeleteRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdLotteryMemberDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get lottery members
     * @param {WallApiApiV1WallsIdLotteryMemberGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdLotteryMemberGet(requestParameters: WallApiApiV1WallsIdLotteryMemberGetRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdLotteryMemberGet(requestParameters.id, requestParameters.wallId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create lottery member by current user
     * @param {WallApiApiV1WallsIdLotteryMemberPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdLotteryMemberPost(requestParameters: WallApiApiV1WallsIdLotteryMemberPostRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdLotteryMemberPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End lottery by wall id
     * @param {WallApiApiV1WallsIdOverlookPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdOverlookPost(requestParameters: WallApiApiV1WallsIdOverlookPostRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdOverlookPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {WallApiApiV1WallsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdPatch(requestParameters: WallApiApiV1WallsIdPatchRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get shared users
     * @param {WallApiApiV1WallsIdSharesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsIdSharesGet(requestParameters: WallApiApiV1WallsIdSharesGetRequest, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsIdSharesGet(requestParameters.id, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {WallApiApiV1WallsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsPost(requestParameters: WallApiApiV1WallsPostRequest = {}, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsPost(requestParameters.wallUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {WallApiApiV1WallsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsPut(requestParameters: WallApiApiV1WallsPutRequest = {}, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsPut(requestParameters.wallUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get wall themes by filter
     * @param {WallApiApiV1WallsThemesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WallApi
     */
    public apiV1WallsThemesGet(requestParameters: WallApiApiV1WallsThemesGetRequest = {}, options?: AxiosRequestConfig) {
        return WallApiFp(this.configuration).apiV1WallsThemesGet(requestParameters.orderByPopular, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}
