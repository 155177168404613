import styled from 'styled-components';

import { Link } from 'ui/Link';

export type { ListItemProps } from 'antd/lib/list/Item';

export const StyledComment = styled.div`
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: 14px;
  &:not(:first-child) {
    border-top: 1px solid #e9ecf0;
  }
  &:last-child {
    border-bottom: 1px solid #e9ecf0;
  }
`;

export const StyledReply = styled.div`
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: 14px;
`;

export const ShowMoreReplies = styled.div`
  color: #4872f2;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 12px;
`;

export const MoreButton = styled(Link)`
  font-size: 14px;
  color: #4872f2;
  font-weight: 600;
`;

export const SpinContainer = styled.div`
  padding: 16px 0 16px 52px;
`;
