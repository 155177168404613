import styled from 'styled-components';

export const StyledMenuWrapper = styled.div`
  cursor: pointer;
  white-space: nowrap;
  & > svg {
    color: ${({ theme }) => theme.palette.gray[400]};
  }
`;

export const MenuImg = styled.img`
  margin-right: 6px;
  margin-bottom: 2px;
`;

export const Container = styled.div`
  user-select: none;
  .ant-dropdown-trigger,
  svg {
    user-select: none;
  }
`;
