import { ComponentPropsWithRef } from 'react';

import styled, { css } from 'styled-components';

export type BaseInputProps = ComponentPropsWithRef<'input'>;

interface StyledGroupRootProps {
  row: boolean;
  required: boolean;
}

const requiredStyles = css`
  & > legend::after {
    color: ${({ theme }) => theme.palette.error.light};
    margin-left: 2px;
    content: '*';
  }
`;

export const StyledGroupRoot = styled.fieldset<StyledGroupRootProps>`
  position: relative;
  display: inline-flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  flex-wrap: wrap;
  vertical-align: top;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  ${({ required }) => required && requiredStyles}
  & > div:not(:last-child) {
    margin-right: 30px;
  }
`;

export const StyledLabel = styled.legend`
  display: inline-block;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 8px;
  text-align: left;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
`;
