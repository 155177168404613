import styled from 'styled-components';

import { FormItem } from 'ui';

export const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0 !important;
  width: 100%;
  .ant-form-item-control-input-content {
    display: flex;
  }
`;
