import { ComponentPropsWithRef, FC } from 'react';

import { IconContainer, InfoButtonRoot } from './styles';
import { InfoButtonProps } from './types';

export const InfoButton: FC<InfoButtonProps & ComponentPropsWithRef<'div'>> = ({
  icon,
  children,
  ...props
}) => {
  return (
    <InfoButtonRoot {...props}>
      {icon && <IconContainer>{icon}</IconContainer>}
      {children}
    </InfoButtonRoot>
  );
};
