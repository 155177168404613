/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Тип контента стены
 * @export
 * @enum {string}
 */

export enum WallContentType {
    Common = 1,
    Idea = 2,
    Lottery = 3,
    GiftCard = 4
}


