import { Tree as AntTree } from 'antd';
import styled from 'styled-components';

export const Tree = styled(AntTree)`
  .ant-tree-switcher svg {
    fill: #4872f2;
  }

  .ant-tree-switcher_close svg {
    transform: rotate(-90deg);
  }

  .ant-tree-switcher {
    width: 23px;
    height: 23px;
  }

  .ant-tree-indent {
    display: none;
  }

  .ant-tree-treenode {
    padding-left: 24px;
  }

  .ant-tree-treenode {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    height: 40px;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }

  .ant-tree-treenode-selected {
    background-color: #edf1fe;
  }

  .ant-tree-list {
    padding-right: 24px;
  }

  .ant-tree-switcher {
    align-self: auto;
  }

  .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
    color: #4872f2;
  }

  .ant-tree-node-content-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-tree-list-holder {
    overflow: hidden;
  }

  .ant-tree-treenode-motion {
    width: 100%;
  }
`;
