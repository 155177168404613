import React, { VFC, useCallback } from 'react';

import { Delete } from '@styled-icons/material-outlined';

import { File } from 'ui';

import { DeleteButton, DocumentsGrid, FilesListItem } from './styles';
import { FormFilesProps } from './types';

export const FormFilesGrid: VFC<FormFilesProps> = ({
  documents,
  onDocumentDelete: handleDocumentDelete,
}) => {
  const handleDelete = useCallback(
    (e: React.MouseEvent<HTMLElement>, index: number, id: number) => {
      e.stopPropagation();
      if (handleDocumentDelete) handleDocumentDelete({ index, id });
    },
    [handleDocumentDelete],
  );

  return (
    <DocumentsGrid>
      {documents.map((doc, index) => (
        <FilesListItem key={doc.id}>
          <File
            dateString={doc.createdDate}
            name={doc.name}
            size={doc.size}
            url={doc.fileUrl}
          />
          {handleDocumentDelete && (
            <DeleteButton onClick={(e) => handleDelete(e, index, doc.id)}>
              <Delete size={16} />
            </DeleteButton>
          )}
        </FilesListItem>
      ))}
    </DocumentsGrid>
  );
};
