import { LabeledValue } from 'antd/es/select';

import { PostedFileModel } from 'services/api';

import type { MediaFileType, TDocumentType } from './types';

export const getDefaultDocuments = (
  attachments: PostedFileModel[],
): TDocumentType[] => {
  return attachments
    .filter((attachment) => !!attachment.documentId)
    .map((attachment) => ({
      id: attachment.documentId,
      name: attachment.postedFile?.fileName,
      size: attachment.size,
      fileUrl: attachment.postedFile?.fileUrl,
      createdDate: attachment.createdDate,
    })) as TDocumentType[];
};

export const getDefaultMedia = (
  attachments: PostedFileModel[],
): MediaFileType[] => {
  const attachedPhotos: MediaFileType[] = attachments
    .filter((attachment) => attachment.photoId)
    .map(
      (attachment) =>
        ({
          id: attachment.photoId,
          fileUrl: attachment.postedFile?.fileUrl,
          type: 'image',
          postedFileId: attachment.postedFileId,
          previews: attachment.previews,
        } as MediaFileType),
    );

  const attachedVideos: MediaFileType[] = attachments
    .filter((attachment) => attachment.videoId)
    .map(
      (attachment) =>
        ({
          id: attachment.videoId,
          fileUrl: attachment.postedFile?.fileUrl,
          type: 'video',
          postedFileId: attachment.postedFileId,
          videoThumbnail: attachment.videoThumbnail,
        } as MediaFileType),
    );

  return [...attachedPhotos, ...attachedVideos];
};

export const getCoordinates = (geo: LabeledValue | null) => {
  return geo
    ? {
        latitude: parseFloat(geo.value.toString().split(' ')[1]),
        longitude: parseFloat(geo.value.toString().split(' ')[0]),
      }
    : {};
};
