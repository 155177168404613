import { Context, FC, createContext, useContext } from 'react';

import {
  MediaGalleryService,
  useMediaGalleryService,
} from './useMediaGalleryService';

const MediaGalleryContext = createContext<MediaGalleryService | null>(null);

export const useMediaGallery = (): MediaGalleryService =>
  useContext(MediaGalleryContext as Context<MediaGalleryService>);

export const MediaGalleryProvider: FC = ({ children }) => {
  const mediaGalleryService = useMediaGalleryService();

  return (
    <MediaGalleryContext.Provider value={mediaGalleryService}>
      {children}
    </MediaGalleryContext.Provider>
  );
};
