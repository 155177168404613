import { Tooltip } from 'antd';
import styled from 'styled-components';

export const UsersTooltipHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const StyledTooltip = styled(Tooltip).attrs({
  overlayInnerStyle: {
    width: 280,
    borderRadius: 8,
    fontSize: 16,
    padding: '10px 15px',
  },
})``;

export const AvatarsContainer = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ChevroneButton = styled.div.attrs({
  role: 'button',
})`
  margin-left: 8px;
  cursor: pointer;
  margin-bottom: 2px;
`;

export const AvatarButtom = styled.div.attrs({
  role: 'button',
})`
  cursor: pointer;
`;
