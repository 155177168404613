import styled from 'styled-components';

export const RealizeDate = styled.div`
  padding: 6px 12px;
  background: #f6f8fa;
  border-radius: 12px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #7e8389;
  .ant-badge-status-dot {
    width: 12px;
    height: 12px;
  }
`;
