/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CommentModel } from '../models';
// @ts-ignore
import { CommentUpdateModel } from '../models';
// @ts-ignore
import { CommentsModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [byPhotoId] Фильтр по фото
         * @param {number} [byVideoId] Фильтр по видео
         * @param {number} [byWallId] Фильтр по посту
         * @param {number} [byCommentId] Фильтр по комментарию
         * @param {boolean} [isPinned] Фильтр по заекреплённым комментариям
         * @param {boolean} [isRecursive] Рекурсивное получение дочерних комментариев
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsGet: async (byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, isPinned?: boolean, isRecursive?: boolean, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (byPhotoId !== undefined) {
                localVarQueryParameter['ByPhotoId'] = byPhotoId;
            }

            if (byVideoId !== undefined) {
                localVarQueryParameter['ByVideoId'] = byVideoId;
            }

            if (byWallId !== undefined) {
                localVarQueryParameter['ByWallId'] = byWallId;
            }

            if (byCommentId !== undefined) {
                localVarQueryParameter['ByCommentId'] = byCommentId;
            }

            if (isPinned !== undefined) {
                localVarQueryParameter['IsPinned'] = isPinned;
            }

            if (isRecursive !== undefined) {
                localVarQueryParameter['IsRecursive'] = isRecursive;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CommentsIdDelete', 'id', id)
            const localVarPath = `/api/v1/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CommentsIdGet', 'id', id)
            const localVarPath = `/api/v1/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CommentsIdPatch', 'id', id)
            const localVarPath = `/api/v1/comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {CommentUpdateModel} [commentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsPost: async (commentUpdateModel?: CommentUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {CommentUpdateModel} [commentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsPut: async (commentUpdateModel?: CommentUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [byPhotoId] Фильтр по фото
         * @param {number} [byVideoId] Фильтр по видео
         * @param {number} [byWallId] Фильтр по посту
         * @param {number} [byCommentId] Фильтр по комментарию
         * @param {boolean} [isPinned] Фильтр по заекреплённым комментариям
         * @param {boolean} [isRecursive] Рекурсивное получение дочерних комментариев
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CommentsGet(byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, isPinned?: boolean, isRecursive?: boolean, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CommentsGet(byPhotoId, byVideoId, byWallId, byCommentId, isPinned, isRecursive, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CommentsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CommentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CommentsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CommentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CommentsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CommentsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {CommentUpdateModel} [commentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CommentsPost(commentUpdateModel?: CommentUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CommentsPost(commentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {CommentUpdateModel} [commentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CommentsPut(commentUpdateModel?: CommentUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CommentsPut(commentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [byPhotoId] Фильтр по фото
         * @param {number} [byVideoId] Фильтр по видео
         * @param {number} [byWallId] Фильтр по посту
         * @param {number} [byCommentId] Фильтр по комментарию
         * @param {boolean} [isPinned] Фильтр по заекреплённым комментариям
         * @param {boolean} [isRecursive] Рекурсивное получение дочерних комментариев
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsGet(byPhotoId?: number, byVideoId?: number, byWallId?: number, byCommentId?: number, isPinned?: boolean, isRecursive?: boolean, count?: number, offset?: number, options?: any): AxiosPromise<CommentsModel> {
            return localVarFp.apiV1CommentsGet(byPhotoId, byVideoId, byWallId, byCommentId, isPinned, isRecursive, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1CommentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsIdGet(id: number, options?: any): AxiosPromise<CommentModel> {
            return localVarFp.apiV1CommentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<CommentModel> {
            return localVarFp.apiV1CommentsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {CommentUpdateModel} [commentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsPost(commentUpdateModel?: CommentUpdateModel, options?: any): AxiosPromise<CommentModel> {
            return localVarFp.apiV1CommentsPost(commentUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {CommentUpdateModel} [commentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommentsPut(commentUpdateModel?: CommentUpdateModel, options?: any): AxiosPromise<CommentModel> {
            return localVarFp.apiV1CommentsPut(commentUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1CommentsGet operation in CommentApi.
 * @export
 * @interface CommentApiApiV1CommentsGetRequest
 */
export interface CommentApiApiV1CommentsGetRequest {
    /**
     * Фильтр по фото
     * @type {number}
     * @memberof CommentApiApiV1CommentsGet
     */
    readonly byPhotoId?: number

    /**
     * Фильтр по видео
     * @type {number}
     * @memberof CommentApiApiV1CommentsGet
     */
    readonly byVideoId?: number

    /**
     * Фильтр по посту
     * @type {number}
     * @memberof CommentApiApiV1CommentsGet
     */
    readonly byWallId?: number

    /**
     * Фильтр по комментарию
     * @type {number}
     * @memberof CommentApiApiV1CommentsGet
     */
    readonly byCommentId?: number

    /**
     * Фильтр по заекреплённым комментариям
     * @type {boolean}
     * @memberof CommentApiApiV1CommentsGet
     */
    readonly isPinned?: boolean

    /**
     * Рекурсивное получение дочерних комментариев
     * @type {boolean}
     * @memberof CommentApiApiV1CommentsGet
     */
    readonly isRecursive?: boolean

    /**
     * Количеству объектов
     * @type {number}
     * @memberof CommentApiApiV1CommentsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof CommentApiApiV1CommentsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1CommentsIdDelete operation in CommentApi.
 * @export
 * @interface CommentApiApiV1CommentsIdDeleteRequest
 */
export interface CommentApiApiV1CommentsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof CommentApiApiV1CommentsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1CommentsIdGet operation in CommentApi.
 * @export
 * @interface CommentApiApiV1CommentsIdGetRequest
 */
export interface CommentApiApiV1CommentsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CommentApiApiV1CommentsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1CommentsIdPatch operation in CommentApi.
 * @export
 * @interface CommentApiApiV1CommentsIdPatchRequest
 */
export interface CommentApiApiV1CommentsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof CommentApiApiV1CommentsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof CommentApiApiV1CommentsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1CommentsPost operation in CommentApi.
 * @export
 * @interface CommentApiApiV1CommentsPostRequest
 */
export interface CommentApiApiV1CommentsPostRequest {
    /**
     * 
     * @type {CommentUpdateModel}
     * @memberof CommentApiApiV1CommentsPost
     */
    readonly commentUpdateModel?: CommentUpdateModel
}

/**
 * Request parameters for apiV1CommentsPut operation in CommentApi.
 * @export
 * @interface CommentApiApiV1CommentsPutRequest
 */
export interface CommentApiApiV1CommentsPutRequest {
    /**
     * 
     * @type {CommentUpdateModel}
     * @memberof CommentApiApiV1CommentsPut
     */
    readonly commentUpdateModel?: CommentUpdateModel
}

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {CommentApiApiV1CommentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiV1CommentsGet(requestParameters: CommentApiApiV1CommentsGetRequest = {}, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiV1CommentsGet(requestParameters.byPhotoId, requestParameters.byVideoId, requestParameters.byWallId, requestParameters.byCommentId, requestParameters.isPinned, requestParameters.isRecursive, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {CommentApiApiV1CommentsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiV1CommentsIdDelete(requestParameters: CommentApiApiV1CommentsIdDeleteRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiV1CommentsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {CommentApiApiV1CommentsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiV1CommentsIdGet(requestParameters: CommentApiApiV1CommentsIdGetRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiV1CommentsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {CommentApiApiV1CommentsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiV1CommentsIdPatch(requestParameters: CommentApiApiV1CommentsIdPatchRequest, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiV1CommentsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {CommentApiApiV1CommentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiV1CommentsPost(requestParameters: CommentApiApiV1CommentsPostRequest = {}, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiV1CommentsPost(requestParameters.commentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {CommentApiApiV1CommentsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiV1CommentsPut(requestParameters: CommentApiApiV1CommentsPutRequest = {}, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiV1CommentsPut(requestParameters.commentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
