import { FC } from 'react';

export const GroupCoverPlaceholder: FC = () => {
  return (
    <svg
      fill="none"
      height="236"
      style={{
        borderRadius: '8px',
      }}
      viewBox="0 0 1028 236"
      width="1028"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4275_171725)">
        <rect fill="white" height="236" width="1028" />
        <path
          d="M41.75 236H985.159C1034.25 189.892 1004 145.642 957 132.892C891.354 115.084 924.17 74.2422 851.67 52.9922C786.646 33.9332 761 71.3922 693.559 42.9772C640.141 20.4712 480.5 -3.85783 393 12.6422C269.432 35.9432 325.613 50.8222 176.337 56.3252C102.292 59.0552 91.072 84.8252 90.837 107.825C90.587 132.325 88 155.892 45.13 178.753C3.72099 200.835 39.05 235.498 41.75 236Z"
          fill="#F6F8FA"
        />
        <path
          d="M337.097 168.234C337.097 168.234 428.696 112.015 483.175 112.195C537.657 112.376 596.999 149.225 674.528 180.695C761.411 215.965 647.964 217.444 641.419 217.733C634.87 218.025 408.976 214.159 405.209 212.254C401.442 210.349 341.124 185.967 341.124 185.967L337.097 168.234Z"
          fill="#EFF2F6"
        />
        <path
          d="M301.477 156.923C399.332 164.812 546.162 235.703 657.995 235.703C769.83 235.703 793.911 218.747 835.849 222.332C877.788 225.918 934.088 235.703 934.088 235.703L103.038 236.893C103.037 236.894 203.62 149.036 301.477 156.923Z"
          fill="#DBE0E5"
        />
        <path
          d="M745.019 156.029C647.162 163.918 500.332 234.809 388.498 234.809C276.662 234.809 252.581 217.853 210.644 221.438C168.706 225.024 112.405 234.809 112.405 234.809L943.457 236C943.457 236 842.873 148.143 745.019 156.029Z"
          fill="#DBE0E5"
        />
        <path
          d="M103.037 236.894C103.037 236.894 210.325 191.743 348.66 188.968C395.114 188.038 585.329 206.711 745.506 221.381C752.238 221.997 766.797 228.034 773.615 228.655C849.443 235.561 920.112 236.447 942.969 236.893H103.037V236.894Z"
          fill="#D4D9DE"
        />
        <path
          d="M426.444 60.1319C425.169 54.5849 420.197 50.4439 414.256 50.4439C413.165 50.4439 412.107 50.5849 411.097 50.8459C408.165 44.5829 401.799 40.2439 394.42 40.2439C392.512 40.2439 390.671 40.5329 388.94 41.0709C384.968 34.5009 377.747 30.1069 369.5 30.1069C359.69 30.1069 351.332 36.3259 348.165 45.0319C345.924 43.7839 343.343 43.0709 340.594 43.0709C331.994 43.0709 325.023 50.0339 325.023 58.6209C325.023 59.1309 325.049 59.6349 325.098 60.1319H426.444Z"
          fill="#D4D9DE"
          opacity="0.5"
        />
        <path
          d="M917.935 42.3323C918.895 38.1473 922.644 35.0303 927.128 35.0303C927.949 35.0303 928.746 35.1343 929.507 35.3313C931.718 30.6113 936.516 27.3383 942.081 27.3383C943.521 27.3383 944.905 27.5563 946.214 27.9623C949.209 23.0083 954.65 19.6963 960.869 19.6963C968.266 19.6963 974.568 24.3853 976.954 30.9483C978.643 30.0063 980.591 29.4713 982.663 29.4713C989.148 29.4713 994.403 34.7203 994.403 41.1953C994.403 41.5783 994.385 41.9593 994.347 42.3323H917.935Z"
          fill="#EFF2F6"
        />
        <path
          d="M805.003 114.241C803.807 109.052 799.16 105.179 793.601 105.179C792.579 105.179 791.588 105.314 790.647 105.558C787.903 99.6972 781.95 95.6382 775.048 95.6382C773.261 95.6382 771.54 95.9092 769.921 96.4132C766.204 90.2672 759.453 86.1592 751.737 86.1592C742.561 86.1592 734.746 91.9752 731.784 100.117C729.686 98.9512 727.272 98.2842 724.702 98.2842C716.656 98.2842 710.139 104.799 710.139 112.828C710.139 113.305 710.162 113.774 710.208 114.24H805.003V114.241Z"
          fill="#D4D9DE"
          opacity="0.5"
        />
        <path
          d="M611.476 54.5551C610.455 50.1211 606.482 46.8131 601.735 46.8131C600.863 46.8131 600.015 46.9231 599.212 47.1341C596.866 42.1281 591.78 38.6581 585.881 38.6581C584.354 38.6581 582.884 38.8921 581.502 39.3211C578.326 34.0691 572.558 30.5571 565.964 30.5571C558.122 30.5571 551.445 35.5281 548.915 42.4851C547.122 41.4891 545.061 40.9171 542.862 40.9171C535.989 40.9171 530.417 46.4841 530.417 53.3481C530.417 53.7551 530.439 54.1551 530.477 54.5541H611.476V54.5551Z"
          fill="#D4D9DE"
          opacity="0.5"
        />
        <path
          d="M38.2839 49.5003C39.1909 45.5613 42.7209 42.6203 46.9429 42.6203C47.7179 42.6203 48.4699 42.7193 49.1869 42.9093C51.2699 38.4563 55.7919 35.3743 61.0349 35.3743C62.3929 35.3743 63.6999 35.5813 64.9299 35.9633C67.7519 31.2933 72.8799 28.1733 78.7389 28.1733C85.7099 28.1733 91.6449 32.5893 93.8959 38.7773C95.4889 37.8893 97.3209 37.3823 99.2739 37.3823C105.381 37.3823 110.336 42.3283 110.336 48.4313C110.336 48.7913 110.316 49.1483 110.282 49.5003H38.2839Z"
          fill="#EFF2F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4275_171725">
          <rect fill="white" height="236" width="1028" />
        </clipPath>
      </defs>
    </svg>
  );
};
