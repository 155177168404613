import { FC } from 'react';

import AntSkeleton, { SkeletonProps } from 'antd/lib/skeleton';

export type { SkeletonProps } from 'antd/lib/skeleton';
export type { SkeletonButtonProps } from 'antd/lib/skeleton/Button';
export type { SkeletonInputProps } from 'antd/lib/skeleton/Input';
export type { SkeletonImageProps } from 'antd/lib/skeleton/Image';

const { Button, Input, Image, Avatar } = AntSkeleton;

export const Skeleton: FC<SkeletonProps> = (props) => (
  <AntSkeleton {...props} />
);

export const SkeletonButton = Button;
export const SkeletonInput = Input;
export const SkeletonImage = Image;
export const SkeletonAvatar = Avatar;
