import { ChangeEvent, FC, useCallback, useState } from 'react';

import { useMutation } from 'react-query';

import {
  Attachment,
  Send,
  SentimentSatisfied,
} from '@styled-icons/material-outlined';
import Document from '@tiptap/extension-document';
import { HardBreak } from '@tiptap/extension-hard-break';
import LinkExt from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import { useEditor } from '@tiptap/react';
import { message } from 'antd';
import { BaseEmoji } from 'emoji-mart';
import { cloneDeep } from 'lodash';

import { EmojiPicker } from 'components/EmojiPicker';
import { FormFilesGrid } from 'components/FormFiles';
import {
  HiddenInput,
  ToolbarMenu,
  ToolbarMenuItem,
} from 'components/FormToolbar/styles';
import { GridAlbum } from 'components/GridAlbum';
import {
  MediaFileType,
  TDocumentType,
} from 'components/PostForm/PostEditorContent/types';
import { CommentUpdateModel, api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useNotify } from 'services/systemNotify';
import { useUserCredential } from 'services/userCredential';
import { useWallData } from 'services/wall';
import { Avatar, Dropdown, Form } from 'ui';

import {
  CommentFormButton,
  ControlPaper,
  StyledAttachesContainer,
  StyledContainer,
  StyledEditorContent,
  StyledFormItem,
  StyledInput,
  StyledPaper,
} from './styles';
import { CommentFormProps } from './types';

const mutateOnMediaDelete = async (index: number, id: number, type: string) => {
  switch (type) {
    case 'image': {
      await api.photo.apiV1PhotosIdDelete({
        id,
      });
      return index;
    }
    case 'video': {
      await api.video.apiV1VideosIdDelete({
        id,
      });
      return index;
    }
    default: {
      return null;
    }
  }
};

const mutateOnDocumentDelete = async (index: number, id: number) => {
  await api.document.apiV1DocumentsIdDelete({
    id,
  });
  return index;
};

export const CommentForm: FC<CommentFormProps> = ({
  author,
  submitCallback,
  isReply,
  postId,
  replyCommentId,
}) => {
  const { user } = useUserCredential();
  const { t } = useTranslation('common');

  const { pushErrorNotify } = useNotify();

  const [isFocused, setIsFocused] = useState(false);
  const [mediaFilesList, setMediaFilesList] = useState<MediaFileType[]>([]);
  const [documents, setDocuments] = useState<TDocumentType[]>([]);

  const { list, setList } = useWallData();

  const mediaDeleteMutation = useMutation(
    ({ index, id, type }: { index: number; id: number; type: string }) =>
      mutateOnMediaDelete(index, id, type),
    {
      onSuccess: (index) => {
        if (index !== null) {
          mediaFilesList.splice(index, 1);
          setMediaFilesList([...mediaFilesList]);
        }
      },
      onError: () => {
        pushErrorNotify(t('something_went_wrong'));
      },
    },
  );

  const documentDeleteMutation = useMutation(
    ({ index, id }: { index: number; id: number }) =>
      mutateOnDocumentDelete(index, id),
    {
      onSuccess: (index) => {
        if (typeof index === 'number') {
          documents.splice(index, 1);

          setDocuments([...documents]);
        }
      },
      onError: () => {
        pushErrorNotify(t('something_went_wrong'));
      },
    },
  );

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      HardBreak,
      LinkExt.configure({
        openOnClick: false,
      }),
      Placeholder.configure({
        placeholder: `${isReply ? t('answer') : t('post_comment')}?`,
        includeChildren: true,
      }),
      TextAlign.configure({
        types: ['paragraph'],
      }),
    ],
    content: `<p />`,
  });

  const handleFormSubmit = async () => {
    const newComment: CommentUpdateModel = {
      postContent: {
        text: '',
        postContentAttachments: [],
      },
      ownerPost: {
        [isReply ? 'byCommentId' : 'byWallId']: isReply
          ? replyCommentId
          : postId,
      },
    };

    if (newComment.postContent && newComment && editor) {
      newComment.postContent.text = editor.getHTML();
    }
    mediaFilesList.forEach((mediaFile) => {
      newComment.postContent?.postContentAttachments?.push({
        photoId: mediaFile.type === 'image' ? mediaFile.id : undefined,
        videoId: mediaFile.type === 'video' ? mediaFile.id : undefined,
        postedFileId: mediaFile.postedFileId,
      });
    });
    documents.forEach((document) => {
      newComment.postContent?.postContentAttachments?.push({
        documentId: document.id,
        postedFileId: document.postedFileId,
      });
    });
    api.comment
      .apiV1CommentsPost({
        commentUpdateModel: newComment,
      })
      .then((res) => {
        editor?.chain().clearContent().run();
        setIsFocused(false);
        setMediaFilesList([]);
        setDocuments([]);

        const clone = cloneDeep(list);
        const post = clone.find((entry) => entry.id === postId);

        if (post && post.comments && post.comments.items) {
          const cmt = res.data;
          if (cmt.replyCommentId) {
            const owner = post.comments.items.find(
              (entry) => entry.id === cmt.replyCommentId,
            );

            if (owner && owner.comments && owner.comments.items) {
              owner.comments.items.unshift(cmt);
              if (owner.comments.total !== undefined) {
                owner.comments.total += 1;
              }
            }

            if (post.comments.total !== undefined) {
              post.comments.total += 1;
            }
          } else {
            post.comments.items.unshift(cmt);
            if (post.comments.total !== undefined) {
              post.comments.total += 1;
            }
            if (post.comments.totalFirstLevelCount !== undefined)
              post.comments.totalFirstLevelCount += 1;
          }
        }
        setList(clone);

        if (submitCallback) submitCallback(res.data);
      });
  };

  const classifyFileType = useCallback((fileUpload: { type: string }) => {
    return fileUpload.type.split('/')[0];
  }, []);

  const handleMediaContentUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const filesArray = Array.prototype.slice.call(e.target.files);

    filesArray.forEach(async (fileUpload) => {
      switch (classifyFileType(fileUpload)) {
        case 'image': {
          const { data } = await api.photo.apiV1PhotosPost({
            fileUpload,
          });
          if (data.id && data.fileUrl && data.postedFileId) {
            const newFile: MediaFileType = {
              id: data.id,
              postedFileId: data.postedFileId,
              fileUrl: data.fileUrl,
              type: 'image',
              previews: data.previews,
            };
            setMediaFilesList([...mediaFilesList, newFile]);
          }
          break;
        }
        case 'video': {
          const voidFile: MediaFileType = {
            id: 0,
            postedFileId: 0,
            fileUrl: '',
            type: 'video',
          };
          setMediaFilesList([...mediaFilesList, voidFile]);

          const { data } = await api.video.apiV1VideosPost({
            fileUpload,
            name: fileUpload.name,
          });

          if (data.id && data.postedFileId) {
            const newFile: MediaFileType = {
              id: data.id,
              postedFileId: data.postedFileId,
              fileUrl: data.previewUrl as string,
              type: 'video',
              videoThumbnail: { fileUrl: data.previewUrl },
            };
            setMediaFilesList([...mediaFilesList, newFile]);
          } else {
            const newList = [...mediaFilesList];
            newList.pop();
            setMediaFilesList(newList);
          }
          break;
        }
        default: {
          message.error({
            content: t('invalid_file_format'),
            style: {
              marginTop: '5vh',
            },
          });
          break;
        }
      }
    });
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const filesArray = Array.prototype.slice.call(e.target.files);

    if (documents.length < 2) {
      filesArray.forEach(async (fileUpload) => {
        const { data } = await api.document.apiV1DocumentsPost({
          fileUpload,
          name: fileUpload.name,
          ownerUserId: author?.userId || user?.id,
          ownerGroupId: author?.groupId || undefined,
        });
        if (data.id && data.postedFileId) {
          const newDocument: TDocumentType = {
            id: data.id,
            postedFileId: data.postedFileId,
            name: fileUpload.name as string,
            size: fileUpload.size as number,
            createdDate: data.createdDate,
            fileUrl: data.fileUrl as string,
          };
          setDocuments([...documents, newDocument]);
        }
      });
    } else {
      message.error({
        content: t('invalid_file_count'),
        style: {
          marginTop: '5vh',
        },
      });
    }
  };

  const menu = (
    <ToolbarMenu>
      <ToolbarMenuItem>
        <label htmlFor="imageUpload">
          <HiddenInput
            accept="image/*"
            id="imageUpload"
            type="file"
            onChange={handleMediaContentUpload}
          />
          {t('image')}
        </label>
      </ToolbarMenuItem>

      <ToolbarMenuItem>
        <label htmlFor="videoUpload">
          <HiddenInput
            accept="video/*"
            id="videoUpload"
            type="file"
            onChange={handleMediaContentUpload}
          />
          {t('video')}
        </label>
      </ToolbarMenuItem>

      <ToolbarMenuItem>
        <label htmlFor="fileUpload">
          <HiddenInput
            id="fileUpload"
            type="file"
            onChange={handleFileUpload}
          />
          {t('file')}
        </label>
      </ToolbarMenuItem>
    </ToolbarMenu>
  );

  const handleFormFocus = () => {
    setIsFocused(true);
  };

  const handleEmojiClick = useCallback(
    (emoji: BaseEmoji) => {
      editor?.chain().insertContent(`${emoji.native}`).run();
    },
    [editor],
  );

  return (
    <StyledPaper>
      <Form layout="horizontal" onFocus={handleFormFocus}>
        <StyledContainer>
          {user && <Avatar size={32} src={user.avatar?.fileUrl} />}
          <StyledFormItem>
            {isFocused ? (
              <StyledEditorContent editor={editor} />
            ) : (
              <StyledInput
                bordered
                placeholder={isReply ? t('answer') : t('post_comment')}
                onClick={handleFormFocus}
              />
            )}
            <ControlPaper>
              <CommentFormButton>
                <Dropdown overlay={menu} placement="topRight">
                  <Attachment size={22} />
                </Dropdown>
              </CommentFormButton>
              <CommentFormButton>
                <Dropdown
                  overlay={
                    <EmojiPicker
                      className="bt-0"
                      onEmojiClick={handleEmojiClick}
                    />
                  }
                  placement="topRight"
                >
                  <SentimentSatisfied size={22} />
                </Dropdown>
              </CommentFormButton>
            </ControlPaper>
          </StyledFormItem>
          <CommentFormButton onClick={handleFormSubmit}>
            <Send size={20} />
          </CommentFormButton>
        </StyledContainer>
        <StyledAttachesContainer>
          {isFocused && mediaFilesList.length > 0 && (
            <GridAlbum
              handleMediaDelete={mediaDeleteMutation.mutate}
              mediaContent={mediaFilesList}
            />
          )}
          {isFocused && documents.length > 0 && (
            <FormFilesGrid
              documents={documents}
              onDocumentDelete={documentDeleteMutation.mutate}
            />
          )}
        </StyledAttachesContainer>
      </Form>
    </StyledPaper>
  );
};
