import { VFC } from 'react';

import { Delete as DeleteIcon } from '@styled-icons/material-outlined/Delete';

import { UserListContainer } from 'components/PostPushModal/UserList/styles';
import { UserCardItem } from 'components/UserCardItem';
import { UsersAutoComplete } from 'components/UsersAutoComplete';
import { UserProfileModel } from 'services/api';
import { IconButton } from 'ui/IconButton';

export interface UserListProps {
  users: UserProfileModel[];
  onChange: (users: UserProfileModel[]) => void;
}

export const UserList: VFC<UserListProps> = ({ users, onChange }) => {
  const handleSelect = (userData: UserProfileModel) => {
    if (!users.find((x) => x.id === userData.id)) {
      onChange([...users, userData]);
    }
  };

  const handleUserRemove = (newUsers: UserProfileModel[]) => {
    onChange(newUsers);
  };

  return (
    <>
      <UserListContainer>
        <UsersAutoComplete onSelect={handleSelect} />
      </UserListContainer>
      <UserListContainer>
        {users.length > 0 &&
          users.map((user) => {
            return (
              <UserCardItem
                key={user.id}
                actionElement={
                  <IconButton
                    onClick={() => {
                      const newData = users.filter(({ id }) => id !== user.id);
                      handleUserRemove(newData);
                    }}
                  >
                    <DeleteIcon size={24} />
                  </IconButton>
                }
                userData={user}
              />
            );
          })}
      </UserListContainer>
    </>
  );
};
