import styled from 'styled-components';

import { SkeletonAvatar } from 'ui/Skeleton';

export const MediaRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
`;

export const MediaButton = styled.button`
  all: unset;
  position: relative;
  width: 100%;
  height: 100%;

  &:not(:disabled) {
    cursor: pointer;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Skeleton = styled(SkeletonAvatar).attrs({
  shape: 'square',
  active: true,
})`
  width: inherit;
  height: inherit;
  & > .ant-skeleton-avatar {
    width: inherit;
    height: inherit;
  }
`;

export const CoverCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const VideoCoverRoot = styled(CoverCentered)`
  position: absolute;
  background: #2e2e32;
  opacity: 0.65;
  top: 0;
`;

export const VideoProcessedCoverRoot = styled(CoverCentered)`
  background-color: #777779;

  svg {
    color: #fff;
  }
`;

export const FileLoadFailedRoot = styled(CoverCentered)`
  background-color: #fdecec;
  svg {
    color: #fff;
  }
  span {
    color: #a9a7ab;
  }
`;

export const MediaOverlayRoot = styled(CoverCentered)`
  top: 0;
  position: absolute;
  :before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #2e2e32;
    opacity: 0.65;
  }
  span {
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    position: absolute;
    color: #ffffff;
    opacity: 1;
  }
`;
