import { FC } from 'react';

import {
  StyledCalendarEventRoot,
  StyledLabel,
  StyledTimeContainer,
} from './styles';
import { CalendarEventItemProps, itemMapColors } from './types';

export const CalendarEventItem: FC<CalendarEventItemProps> = ({
  name,
  time,
  variant,
  size,
  onClick,
}) => {
  return (
    <StyledCalendarEventRoot onClick={onClick}>
      <StyledTimeContainer size={size} variant={itemMapColors[variant]}>
        {time}
      </StyledTimeContainer>
      <StyledLabel>
        <span title={name ?? undefined}>{name}</span>
      </StyledLabel>
    </StyledCalendarEventRoot>
  );
};
