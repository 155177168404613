import { CSSProperties, VFC, useCallback, useState } from 'react';

import {
  AutoSizer,
  Index,
  IndexRange,
  InfiniteLoader,
  WindowScroller,
} from 'react-virtualized';

import { UserCardItem } from 'components/UserCardItem';
import { UserProfileModel } from 'services/api';

import { EmployeeItemContainer, Gap, Letter, VirtualizedList } from './styles';
import { AlphabetListProps } from './types';

export const AlphabetList: VFC<AlphabetListProps> = ({
  data,
  total,
  loadMoreRows,
}) => {
  const [rangeCache, setRangeCache] = useState<number[]>([-1, -1]);

  const isRowLoaded = ({ index }: Index) => Boolean(data[index]);

  const handleLoadMoreRows = async ({ startIndex, stopIndex }: IndexRange) => {
    if (rangeCache[0] === startIndex || rangeCache[1] === stopIndex) return;

    setRangeCache([startIndex, stopIndex]);
    loadMoreRows({ startIndex, stopIndex });
  };

  const isStartNewLetter = useCallback(
    (employeeData: UserProfileModel) => {
      const elemIndexInData = data.findIndex((el) => el.id === employeeData.id);

      const siblingLastName = data[elemIndexInData - 1]
        ? data[elemIndexInData - 1].lastName
        : '';

      return (!siblingLastName && employeeData.lastName) ||
        (siblingLastName &&
          employeeData.lastName &&
          employeeData.lastName[0].toLocaleLowerCase() !==
            siblingLastName[0].toLocaleLowerCase()) ? (
        <Letter>{employeeData.lastName[0]}</Letter>
      ) : (
        <></>
      );
    },
    [data],
  );

  const rowRenderer = ({
    index,
    style,
  }: {
    key: string;
    index: number;
    style: CSSProperties;
  }) => {
    const employeeData = data[index];
    if (isRowLoaded({ index })) {
      return (
        employeeData && (
          <div key={employeeData.id} style={style}>
            <EmployeeItemContainer>
              {isStartNewLetter(employeeData)}
              <UserCardItem userData={employeeData} />
            </EmployeeItemContainer>
            <Gap />
          </div>
        )
      );
    }
    return <></>;
  };

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={handleLoadMoreRows}
      minimumBatchSize={10}
      rowCount={total}
      style={{
        marginTop: 16,
      }}
      treshold={1}
    >
      {({ onRowsRendered }) => (
        <WindowScroller>
          {({ height, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <VirtualizedList
                  autoHeight
                  height={height}
                  overscanRowCount={5}
                  rowCount={data.length}
                  rowHeight={108}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                  width={width}
                  onRowsRendered={onRowsRendered}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  );
};
