import { generatePath } from 'react-router-dom';

import { LinkPreviewModel } from 'services/api';

export const getOnlyOrigin = () => {
  switch (process.env.REACT_APP_ENVIRONMENT_NAME?.toLowerCase()) {
    case 'production':
      return 'https://quickreply.fstravel.com/';
    default:
      return 'https://stage-quickreply.fstravel.com/';
  }
};

export const createLinkWithGeneratedPath =
  (token: Nullable<Token>) => (link: LinkPreviewModel) => ({
    ...link,
    href: link.href?.includes('onlyOrigin')
      ? generatePath(link.href ?? '', {
          onlyOrigin: getOnlyOrigin(),
          authToken: token ?? '',
        })
      : (link.href as string),
  });

export const isExternal = (href: string) => {
  const { origin } = window.location;
  const location = new URL('pages/', origin);
  const url = new URL(href, location);

  return origin !== url.origin;
};

export const isQuickreplyDomain = (donain: string) => {
  const domains = [
    'https://stage-quickreply.fstravel.com',
    'https://quickreply.fstravel.com',
  ];
  return domains.includes(donain);
};

export const checkHrefAndAddToken = (
  urlStr?: URLString,
  token?: Nullable<string>,
) => {
  if (!urlStr) return '';

  const { origin } = location;
  const url = new URL(urlStr, origin);

  if (isQuickreplyDomain(url.origin) && token) {
    url.searchParams.append('AuthorizationToken', token);
  }

  return url.toString();
};
