/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DiscoveryPage } from '../models';
// @ts-ignore
import { DiscoveryStep } from '../models';
// @ts-ignore
import { DiscoveryStepInfoModel } from '../models';
// @ts-ignore
import { DiscoveryStepInfosModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * DiscoveryStepInfoApi - axios parameter creator
 * @export
 */
export const DiscoveryStepInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {DiscoveryStep} [step] Фильтр по шагу дискавери
         * @param {DiscoveryPage} [page] Фильтр по странице дисквери
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosGet: async (step?: DiscoveryStep, page?: DiscoveryPage, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/discovery-step-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (step !== undefined) {
                localVarQueryParameter['Step'] = step;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DiscoveryStepInfosIdDelete', 'id', id)
            const localVarPath = `/api/v1/discovery-step-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DiscoveryStepInfosIdGet', 'id', id)
            const localVarPath = `/api/v1/discovery-step-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DiscoveryStepInfosIdPatch', 'id', id)
            const localVarPath = `/api/v1/discovery-step-infos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {DiscoveryStepInfoModel} [discoveryStepInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosPost: async (discoveryStepInfoModel?: DiscoveryStepInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/discovery-step-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discoveryStepInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {DiscoveryStepInfoModel} [discoveryStepInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosPut: async (discoveryStepInfoModel?: DiscoveryStepInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/discovery-step-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discoveryStepInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscoveryStepInfoApi - functional programming interface
 * @export
 */
export const DiscoveryStepInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscoveryStepInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {DiscoveryStep} [step] Фильтр по шагу дискавери
         * @param {DiscoveryPage} [page] Фильтр по странице дисквери
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoveryStepInfosGet(step?: DiscoveryStep, page?: DiscoveryPage, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoveryStepInfosModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoveryStepInfosGet(step, page, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoveryStepInfosIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoveryStepInfosIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoveryStepInfosIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoveryStepInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoveryStepInfosIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoveryStepInfosIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoveryStepInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoveryStepInfosIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {DiscoveryStepInfoModel} [discoveryStepInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoveryStepInfosPost(discoveryStepInfoModel?: DiscoveryStepInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoveryStepInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoveryStepInfosPost(discoveryStepInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {DiscoveryStepInfoModel} [discoveryStepInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DiscoveryStepInfosPut(discoveryStepInfoModel?: DiscoveryStepInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoveryStepInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DiscoveryStepInfosPut(discoveryStepInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscoveryStepInfoApi - factory interface
 * @export
 */
export const DiscoveryStepInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscoveryStepInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {DiscoveryStep} [step] Фильтр по шагу дискавери
         * @param {DiscoveryPage} [page] Фильтр по странице дисквери
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosGet(step?: DiscoveryStep, page?: DiscoveryPage, count?: number, offset?: number, options?: any): AxiosPromise<DiscoveryStepInfosModel> {
            return localVarFp.apiV1DiscoveryStepInfosGet(step, page, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DiscoveryStepInfosIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosIdGet(id: number, options?: any): AxiosPromise<DiscoveryStepInfoModel> {
            return localVarFp.apiV1DiscoveryStepInfosIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<DiscoveryStepInfoModel> {
            return localVarFp.apiV1DiscoveryStepInfosIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {DiscoveryStepInfoModel} [discoveryStepInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosPost(discoveryStepInfoModel?: DiscoveryStepInfoModel, options?: any): AxiosPromise<DiscoveryStepInfoModel> {
            return localVarFp.apiV1DiscoveryStepInfosPost(discoveryStepInfoModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {DiscoveryStepInfoModel} [discoveryStepInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DiscoveryStepInfosPut(discoveryStepInfoModel?: DiscoveryStepInfoModel, options?: any): AxiosPromise<DiscoveryStepInfoModel> {
            return localVarFp.apiV1DiscoveryStepInfosPut(discoveryStepInfoModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DiscoveryStepInfosGet operation in DiscoveryStepInfoApi.
 * @export
 * @interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosGetRequest
 */
export interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosGetRequest {
    /**
     * Фильтр по шагу дискавери
     * @type {DiscoveryStep}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosGet
     */
    readonly step?: DiscoveryStep

    /**
     * Фильтр по странице дисквери
     * @type {DiscoveryPage}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosGet
     */
    readonly page?: DiscoveryPage

    /**
     * Количеству объектов
     * @type {number}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1DiscoveryStepInfosIdDelete operation in DiscoveryStepInfoApi.
 * @export
 * @interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdDeleteRequest
 */
export interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1DiscoveryStepInfosIdGet operation in DiscoveryStepInfoApi.
 * @export
 * @interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdGetRequest
 */
export interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1DiscoveryStepInfosIdPatch operation in DiscoveryStepInfoApi.
 * @export
 * @interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdPatchRequest
 */
export interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1DiscoveryStepInfosPost operation in DiscoveryStepInfoApi.
 * @export
 * @interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosPostRequest
 */
export interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosPostRequest {
    /**
     * 
     * @type {DiscoveryStepInfoModel}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosPost
     */
    readonly discoveryStepInfoModel?: DiscoveryStepInfoModel
}

/**
 * Request parameters for apiV1DiscoveryStepInfosPut operation in DiscoveryStepInfoApi.
 * @export
 * @interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosPutRequest
 */
export interface DiscoveryStepInfoApiApiV1DiscoveryStepInfosPutRequest {
    /**
     * 
     * @type {DiscoveryStepInfoModel}
     * @memberof DiscoveryStepInfoApiApiV1DiscoveryStepInfosPut
     */
    readonly discoveryStepInfoModel?: DiscoveryStepInfoModel
}

/**
 * DiscoveryStepInfoApi - object-oriented interface
 * @export
 * @class DiscoveryStepInfoApi
 * @extends {BaseAPI}
 */
export class DiscoveryStepInfoApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {DiscoveryStepInfoApiApiV1DiscoveryStepInfosGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoveryStepInfoApi
     */
    public apiV1DiscoveryStepInfosGet(requestParameters: DiscoveryStepInfoApiApiV1DiscoveryStepInfosGetRequest = {}, options?: AxiosRequestConfig) {
        return DiscoveryStepInfoApiFp(this.configuration).apiV1DiscoveryStepInfosGet(requestParameters.step, requestParameters.page, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoveryStepInfoApi
     */
    public apiV1DiscoveryStepInfosIdDelete(requestParameters: DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdDeleteRequest, options?: AxiosRequestConfig) {
        return DiscoveryStepInfoApiFp(this.configuration).apiV1DiscoveryStepInfosIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoveryStepInfoApi
     */
    public apiV1DiscoveryStepInfosIdGet(requestParameters: DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdGetRequest, options?: AxiosRequestConfig) {
        return DiscoveryStepInfoApiFp(this.configuration).apiV1DiscoveryStepInfosIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoveryStepInfoApi
     */
    public apiV1DiscoveryStepInfosIdPatch(requestParameters: DiscoveryStepInfoApiApiV1DiscoveryStepInfosIdPatchRequest, options?: AxiosRequestConfig) {
        return DiscoveryStepInfoApiFp(this.configuration).apiV1DiscoveryStepInfosIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {DiscoveryStepInfoApiApiV1DiscoveryStepInfosPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoveryStepInfoApi
     */
    public apiV1DiscoveryStepInfosPost(requestParameters: DiscoveryStepInfoApiApiV1DiscoveryStepInfosPostRequest = {}, options?: AxiosRequestConfig) {
        return DiscoveryStepInfoApiFp(this.configuration).apiV1DiscoveryStepInfosPost(requestParameters.discoveryStepInfoModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {DiscoveryStepInfoApiApiV1DiscoveryStepInfosPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscoveryStepInfoApi
     */
    public apiV1DiscoveryStepInfosPut(requestParameters: DiscoveryStepInfoApiApiV1DiscoveryStepInfosPutRequest = {}, options?: AxiosRequestConfig) {
        return DiscoveryStepInfoApiFp(this.configuration).apiV1DiscoveryStepInfosPut(requestParameters.discoveryStepInfoModel, options).then((request) => request(this.axios, this.basePath));
    }
}
