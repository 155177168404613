import { ListItemMetaProps, Meta } from 'antd/lib/list/Item';
import styled from 'styled-components';

export const ListItemMeta = styled((props: ListItemMetaProps) => (
  <Meta {...props} />
))`
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
  }

  .ant-list-item-meta-title {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
    margin-bottom: 8px;
    font-weight: 600;

    & > a:hover {
      color: ${({ theme }) => theme.palette.primary.light};
    }

    &:only-child {
      margin-top: 24px;
    }
  }

  .ant-list-item-meta-description {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
