/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { PushNotificationCreateModel } from '../models';
// @ts-ignore
import { PushNotificationModel } from '../models';
// @ts-ignore
import { PushNotificationUpdateModel } from '../models';
// @ts-ignore
import { PushNotificationsModel } from '../models';
// @ts-ignore
import { UserDtoSearchResult } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * PushNotificationApi - axios parameter creator
 * @export
 */
export const PushNotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsGet: async (count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/push-notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PushNotificationsIdDelete', 'id', id)
            const localVarPath = `/api/v1/push-notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PushNotificationsIdGet', 'id', id)
            const localVarPath = `/api/v1/push-notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PushNotificationsIdPatch', 'id', id)
            const localVarPath = `/api/v1/push-notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsIdUsersGet: async (id: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PushNotificationsIdUsersGet', 'id', id)
            const localVarPath = `/api/v1/push-notifications/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {PushNotificationCreateModel} [pushNotificationCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsPost: async (pushNotificationCreateModel?: PushNotificationCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/push-notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushNotificationCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {PushNotificationUpdateModel} [pushNotificationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsPut: async (pushNotificationUpdateModel?: PushNotificationUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/push-notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushNotificationUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushNotificationApi - functional programming interface
 * @export
 */
export const PushNotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushNotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PushNotificationsGet(count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushNotificationsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PushNotificationsGet(count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PushNotificationsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PushNotificationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PushNotificationsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushNotificationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PushNotificationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PushNotificationsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushNotificationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PushNotificationsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PushNotificationsIdUsersGet(id: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PushNotificationsIdUsersGet(id, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {PushNotificationCreateModel} [pushNotificationCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PushNotificationsPost(pushNotificationCreateModel?: PushNotificationCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushNotificationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PushNotificationsPost(pushNotificationCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {PushNotificationUpdateModel} [pushNotificationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PushNotificationsPut(pushNotificationUpdateModel?: PushNotificationUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushNotificationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PushNotificationsPut(pushNotificationUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushNotificationApi - factory interface
 * @export
 */
export const PushNotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushNotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsGet(count?: number, offset?: number, options?: any): AxiosPromise<PushNotificationsModel> {
            return localVarFp.apiV1PushNotificationsGet(count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PushNotificationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsIdGet(id: number, options?: any): AxiosPromise<PushNotificationModel> {
            return localVarFp.apiV1PushNotificationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<PushNotificationModel> {
            return localVarFp.apiV1PushNotificationsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [count] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsIdUsersGet(id: number, count?: number, offset?: number, options?: any): AxiosPromise<UserDtoSearchResult> {
            return localVarFp.apiV1PushNotificationsIdUsersGet(id, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {PushNotificationCreateModel} [pushNotificationCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsPost(pushNotificationCreateModel?: PushNotificationCreateModel, options?: any): AxiosPromise<PushNotificationModel> {
            return localVarFp.apiV1PushNotificationsPost(pushNotificationCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {PushNotificationUpdateModel} [pushNotificationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PushNotificationsPut(pushNotificationUpdateModel?: PushNotificationUpdateModel, options?: any): AxiosPromise<PushNotificationModel> {
            return localVarFp.apiV1PushNotificationsPut(pushNotificationUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1PushNotificationsGet operation in PushNotificationApi.
 * @export
 * @interface PushNotificationApiApiV1PushNotificationsGetRequest
 */
export interface PushNotificationApiApiV1PushNotificationsGetRequest {
    /**
     * Количеству объектов
     * @type {number}
     * @memberof PushNotificationApiApiV1PushNotificationsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof PushNotificationApiApiV1PushNotificationsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1PushNotificationsIdDelete operation in PushNotificationApi.
 * @export
 * @interface PushNotificationApiApiV1PushNotificationsIdDeleteRequest
 */
export interface PushNotificationApiApiV1PushNotificationsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof PushNotificationApiApiV1PushNotificationsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1PushNotificationsIdGet operation in PushNotificationApi.
 * @export
 * @interface PushNotificationApiApiV1PushNotificationsIdGetRequest
 */
export interface PushNotificationApiApiV1PushNotificationsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PushNotificationApiApiV1PushNotificationsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1PushNotificationsIdPatch operation in PushNotificationApi.
 * @export
 * @interface PushNotificationApiApiV1PushNotificationsIdPatchRequest
 */
export interface PushNotificationApiApiV1PushNotificationsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof PushNotificationApiApiV1PushNotificationsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof PushNotificationApiApiV1PushNotificationsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1PushNotificationsIdUsersGet operation in PushNotificationApi.
 * @export
 * @interface PushNotificationApiApiV1PushNotificationsIdUsersGetRequest
 */
export interface PushNotificationApiApiV1PushNotificationsIdUsersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PushNotificationApiApiV1PushNotificationsIdUsersGet
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof PushNotificationApiApiV1PushNotificationsIdUsersGet
     */
    readonly count?: number

    /**
     * 
     * @type {number}
     * @memberof PushNotificationApiApiV1PushNotificationsIdUsersGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1PushNotificationsPost operation in PushNotificationApi.
 * @export
 * @interface PushNotificationApiApiV1PushNotificationsPostRequest
 */
export interface PushNotificationApiApiV1PushNotificationsPostRequest {
    /**
     * 
     * @type {PushNotificationCreateModel}
     * @memberof PushNotificationApiApiV1PushNotificationsPost
     */
    readonly pushNotificationCreateModel?: PushNotificationCreateModel
}

/**
 * Request parameters for apiV1PushNotificationsPut operation in PushNotificationApi.
 * @export
 * @interface PushNotificationApiApiV1PushNotificationsPutRequest
 */
export interface PushNotificationApiApiV1PushNotificationsPutRequest {
    /**
     * 
     * @type {PushNotificationUpdateModel}
     * @memberof PushNotificationApiApiV1PushNotificationsPut
     */
    readonly pushNotificationUpdateModel?: PushNotificationUpdateModel
}

/**
 * PushNotificationApi - object-oriented interface
 * @export
 * @class PushNotificationApi
 * @extends {BaseAPI}
 */
export class PushNotificationApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {PushNotificationApiApiV1PushNotificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public apiV1PushNotificationsGet(requestParameters: PushNotificationApiApiV1PushNotificationsGetRequest = {}, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).apiV1PushNotificationsGet(requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {PushNotificationApiApiV1PushNotificationsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public apiV1PushNotificationsIdDelete(requestParameters: PushNotificationApiApiV1PushNotificationsIdDeleteRequest, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).apiV1PushNotificationsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {PushNotificationApiApiV1PushNotificationsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public apiV1PushNotificationsIdGet(requestParameters: PushNotificationApiApiV1PushNotificationsIdGetRequest, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).apiV1PushNotificationsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {PushNotificationApiApiV1PushNotificationsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public apiV1PushNotificationsIdPatch(requestParameters: PushNotificationApiApiV1PushNotificationsIdPatchRequest, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).apiV1PushNotificationsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PushNotificationApiApiV1PushNotificationsIdUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public apiV1PushNotificationsIdUsersGet(requestParameters: PushNotificationApiApiV1PushNotificationsIdUsersGetRequest, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).apiV1PushNotificationsIdUsersGet(requestParameters.id, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {PushNotificationApiApiV1PushNotificationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public apiV1PushNotificationsPost(requestParameters: PushNotificationApiApiV1PushNotificationsPostRequest = {}, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).apiV1PushNotificationsPost(requestParameters.pushNotificationCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {PushNotificationApiApiV1PushNotificationsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public apiV1PushNotificationsPut(requestParameters: PushNotificationApiApiV1PushNotificationsPutRequest = {}, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).apiV1PushNotificationsPut(requestParameters.pushNotificationUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
