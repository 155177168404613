import { EditorContent } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { FormItem, IconButton } from 'ui';
import { headingStyles } from 'ui/Title/styles';

const paragraph = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
`;

export const StyledEditorContent = styled(EditorContent)`
  .ProseMirror {
    &:focus {
      outline: 0;
      border: solid 1px #4872f2;
    }

    padding: 8px 12px 0;
    border: solid 1px #dbe0e5;
    min-height: 92px;
    max-width: 100%;
    overflow: auto;
    border-radius: 8px;
  }

  h1 {
    ${headingStyles.h1}
    margin: 0;
  }

  h2 {
    ${headingStyles.h2}
    margin: 0;
  }

  h3 {
    ${headingStyles.h3}
    margin: 0;
  }

  p {
    ${paragraph}
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  /* Placeholder (at the top) */

  p.is-editor-empty:only-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`;

export const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0 !important;
  width: 100%;
`;

export const ControlPaper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 14px;
  justify-content: start;
`;

export const EditorButton = styled(IconButton)`
  grid-template-columns: repeat(2, min-content);
  width: auto;
`;
