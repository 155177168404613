import { VFC, useState } from 'react';

import { useMutation } from 'react-query';

import { Delete } from '@styled-icons/material-outlined';
import { Col, Row } from 'antd';
import { formatRelative, parseISO } from 'date-fns';

import { PostTextContent } from 'components/Wall/Post/PostContent/PostStandardContent/PostTextContent';
import { PostModal } from 'components/Wall/PostModal';
import { getPhotoPreview } from 'domain/photo';
import { getUserCountryShort } from 'domain/user';
import {
  PhotoPreviewModel,
  PushNotificationModel,
  WallModel,
  api,
} from 'services/api';
import { useDateFnsLocale, useTranslation } from 'services/i18n';
import { Avatar, Button, GeoBadge, Text } from 'ui/index';

import { PushNotificationViewer } from './PushNotificationViewer';
import { PushNotificationViewersList } from './PushNotificationViewersList';
import {
  PushManagementElement,
  PushNotificationDate,
  PushNotificationDetails,
  PushNotificationInfo,
  PushNotificationPublisherInfo,
} from './styles';

const getPost = async (id: number) => {
  const resp = await api.wall.apiV1WallsIdGet({
    id,
  });
  return resp.data;
};

export interface PushNotificationManagementItemProps {
  notification: PushNotificationModel;
  handleDelete: () => void;
}

export const PushManagementItem: VFC<PushNotificationManagementItemProps> = ({
  notification,
  handleDelete,
}) => {
  const { t } = useTranslation('common');
  const { locale } = useDateFnsLocale();
  const [visible, setVisible] = useState(false);
  const [post, setPost] = useState<WallModel | undefined>(undefined);

  const mutation = useMutation(getPost, {
    onSuccess: (data: WallModel) => {
      setPost(data);
    },
  });

  const handleOpenPost = async () => {
    if (!post) {
      await mutation.mutateAsync(Number(notification.wallId));
    }
    setVisible(!visible);
  };

  return (
    <PushManagementElement>
      {notification.owner?.avatar && (
        <GeoBadge
          locale={getUserCountryShort(notification.owner)}
          offset={[-10, 45]}
          size={20}
        >
          <Avatar
            shape="circle"
            size={60}
            src={getPhotoPreview(
              notification.owner?.avatar as PhotoPreviewModel,
            )}
          />
        </GeoBadge>
      )}
      <PushNotificationInfo>
        {notification.owner && (
          <PushNotificationPublisherInfo>
            {notification.owner?.name}
          </PushNotificationPublisherInfo>
        )}
        <PushNotificationDate>
          {formatRelative(
            parseISO(notification.createdDate as string),
            new Date(),
            { locale },
          )}
        </PushNotificationDate>

        {notification && notification.postContent && (
          <PushNotificationDetails>
            <PostTextContent data={notification.postContent} />
          </PushNotificationDetails>
        )}

        <Button color="primary" size="small" onClick={() => handleOpenPost()}>
          {t('show_post')}
        </Button>

        {notification.notifications?.items &&
          notification.notifications.total > 0 && (
            <>
              <Text
                style={{ marginTop: 25, marginBottom: 10, fontWeight: 600 }}
              >
                {t('push_read')}:
              </Text>
              <Row gutter={16}>
                <Col span={12}>
                  <PushNotificationViewer
                    viewer={notification.notifications.items[0]}
                  />
                </Col>
                {notification.notifications?.total === 2 && (
                  <Col span={12}>
                    <PushNotificationViewer
                      viewer={notification.notifications.items[1]}
                    />
                  </Col>
                )}
                {notification.notifications?.total > 2 && (
                  <Col span={12}>
                    <PushNotificationViewersList
                      total={notification.notifications.total}
                      viewers={notification.notifications.items.slice(
                        1,
                        notification.notifications.total,
                      )}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        {post && visible && (
          <PostModal
            handleClose={() => {
              setVisible(false);
            }}
            post={post}
            visible={visible}
          />
        )}
      </PushNotificationInfo>
      <Button
        color="primary"
        size="small"
        style={{ padding: '5px 7px', position: 'absolute' }}
        variant="outlined"
        onClick={() => handleDelete()}
      >
        <Delete size={18} />
      </Button>
    </PushManagementElement>
  );
};
