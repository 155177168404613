import React from 'react';

export const useForceUpdate = () => {
  const [update, setUpdate] = React.useState(false);

  const forceUpdate = () => {
    setUpdate((prevState) => !prevState);
  };

  return [update, forceUpdate] as const;
};
