import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Delete } from '@styled-icons/material-outlined';
import { MoreHoriz as MoreIcon } from '@styled-icons/material-outlined/MoreHoriz';
import { Dropdown, Menu } from 'antd';
import { formatRelative } from 'date-fns';
import parse from 'html-react-parser';
import { cloneDeep } from 'lodash';

import { FormFilesGrid } from 'components/FormFiles';
import { GridAlbum } from 'components/GridAlbum';
import {
  MediaFileType,
  TDocumentType,
} from 'components/PostForm/PostEditorContent/types';
import { useDateTime } from 'hooks/useDateTime';
import { CommentModel, api } from 'services/api';
import { useDateFnsLocale, useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { useWallData } from 'services/wall';
import { Avatar } from 'ui/Avatar';
import { GeoBadge } from 'ui/GeoBadge';
import { MenuItem } from 'ui/MenuItem';

import {
  Author,
  CommentButton,
  CommentContent,
  Container,
  Footer,
  Header,
  LikeCommentButton,
  MainData,
  MoreButton,
  Spacer,
} from './styles';

interface CommentViewProps {
  data: CommentModel;
  handleCommentReply: () => void;
  postId: number;
  isReply?: boolean;
  afterDelete?: (id: number) => void;
}

export const CommentView: FC<CommentViewProps> = (props) => {
  const { data, handleCommentReply, postId, isReply, afterDelete } = props;

  const { user } = useUserCredential();
  const { t } = useTranslation('common');
  const { locale } = useDateFnsLocale();
  const { parseISOWithTimezone } = useDateTime();

  const [comment, setComment] = useState({ ...data });
  const [mediaFilesList, setMediaFilesList] = useState<MediaFileType[]>([]);
  const [documentsList, setDocumentsList] = useState<TDocumentType[]>([]);

  const { list, setList } = useWallData();

  const handleDelete = useCallback(() => {
    api.comment.apiV1CommentsIdDelete({ id: comment.id as number }).then(() => {
      // forceUpdate();
      const { id, replyCommentId } = comment;
      const clone = cloneDeep(list);
      const post = clone.find((entry) => entry.id === postId);
      if (post) {
        // если удаляется ответ на комментарий
        if (replyCommentId) {
          // от в посте уменьшается на 1 общее количество комментариев
          if (post?.comments?.total !== undefined) post.comments.total -= 1;
          // находим родительский комментарий
          const owner = post?.comments?.items?.find(
            (v) => v.id === replyCommentId,
          );
          if (owner) {
            // также уменьшается количество комментариев в родительском комментарии
            if (owner.comments?.total !== undefined) owner.comments.total -= 1;
            // находим индекс удаляемого комментария
            const index = owner.comments?.items?.findIndex((v) => v.id === id);
            // и удаляемя его из массива
            if (index !== -1) owner.comments?.items?.splice(Number(index), 1);
          }
        }
        // если удаляется комментарий верхнего уровня
        else {
          // находим индекс удаляемого комментария в массиве комментариев поста
          const index = post.comments?.items?.findIndex((v) => v.id === id);
          if (Number(index) > -1 && post.comments?.items) {
            const cmt = post.comments.items[Number(index)];
            const repliesCount = cmt.comments?.total;
            if (post?.comments?.total !== undefined) {
              post.comments.total -= Number(repliesCount) + 1;
            }
            if (post.comments.totalFirstLevelCount !== undefined) {
              post.comments.totalFirstLevelCount -= 1;
            }
            post.comments.items.splice(Number(index), 1);
          }
        }

        setList(clone);
      }
      if (afterDelete) afterDelete(comment.id as number);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterDelete, comment.id]);

  const handleCommentLike = async () => {
    const { id, isLiked } = comment;
    if (isLiked) {
      await api.like.apiV1LikesDelete({ byCommentId: id });
    } else {
      await api.like.apiV1LikesPost({ byCommentId: id });
    }
    const response = await api.like.apiV1LikesGet({
      ownerByCommentId: id,
      count: -1,
    });

    setComment({
      ...comment,
      isLiked: !isLiked,
      likes: { ...response.data },
    });
  };

  const commentContent = data.postContent?.text
    ?.replaceAll('data-mention-id', 'data-id')
    .replace(
      /<span class="mention" data-id="(\d+)">(@[\wа-яА-ЯЁ]+\s[\wа-яА-ЯЁ]+)<\/span>/gi,
      '<a class="mention" data-id="$1" href="profile/$1">$2</a>',
    );

  const menu = useMemo(
    () => (
      <Menu>
        <MenuItem onClick={handleDelete}>
          <Delete
            color="#4872F2"
            size={18}
            style={{
              marginRight: 13,
            }}
          />
          {t('delete')}
        </MenuItem>
      </Menu>
    ),
    [handleDelete, t],
  );

  useEffect(() => {
    setComment({ ...data });
  }, [data]);

  useEffect(() => {
    const media: MediaFileType[] = [];
    const docs: TDocumentType[] = [];
    comment.postContent?.postContentAttachments?.forEach((entry) => {
      if (entry.documentId) {
        docs.push({
          id: entry.documentId,
          postedFileId: Number(entry.postedFileId),
          name: String(entry.postedFile?.fileName),
          size: Number(entry.size),
          fileUrl: String(entry.postedFile?.fileUrl),
        });
      } else if (entry.photoId) {
        media.push({
          id: entry.photoId,
          postedFileId: Number(entry.postedFileId),
          type: 'image',
          fileUrl: String(entry.postedFile?.fileUrl),
          previews: entry.previews,
        });
      } else if (entry.videoId) {
        media.push({
          videoThumbnail: entry.videoThumbnail,
          id: entry.videoId,
          postedFileId: Number(entry.postedFileId),
          type: 'video',
          fileUrl: entry.postedFile?.fileUrl || null,
        });
      }
    });
    setMediaFilesList(media);
    setDocumentsList(docs);
  }, [comment.postContent?.postContentAttachments]);

  return (
    <Container>
      {/* FIXME: всегда росийский флаг */}
      <GeoBadge locale="RUS" offset={isReply ? [-6, 24] : [-6, 32]} size={12}>
        <Avatar
          shape={data.fromAuthor?.groupId ? 'square' : 'circle'}
          size={isReply ? 32 : 40}
          src={data.fromAuthor?.avatar?.fileUrl}
        />
      </GeoBadge>
      <MainData>
        <Header>
          <Author to={`/profile/${data.fromAuthor?.userId}`}>
            {data.fromAuthor?.name}
          </Author>
          {data?.fromAuthor?.userId === user?.id && (
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={['click']}
            >
              <MoreButton role="button">
                <MoreIcon size={14} />
              </MoreButton>
            </Dropdown>
          )}
        </Header>

        <CommentContent>{parse(commentContent as string)}</CommentContent>

        {mediaFilesList.length > 0 && (
          <GridAlbum mediaContent={mediaFilesList} />
        )}
        {documentsList.length > 0 && (
          <FormFilesGrid documents={documentsList} />
        )}

        <Footer>
          {formatRelative(
            parseISOWithTimezone(comment.createdDate as string),
            new Date(),
            {
              locale,
            },
          )}
          <CommentButton type="button" onClick={handleCommentReply}>
            {t('answer')}
          </CommentButton>
          <Spacer />
          {comment.likes && (
            <LikeCommentButton
              isLiked={Boolean(comment.isLiked)}
              likes={comment.likes}
              onClick={handleCommentLike}
            />
          )}
        </Footer>
      </MainData>
    </Container>
  );
};
