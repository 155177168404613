import { VFC } from 'react';

import { useQuery } from 'react-query';

import { useLinksApi } from 'components/Header/Nav/useLinksApi';
import { createLinkWithGeneratedPath, isExternal } from 'domain/navigation';
import { LinkType, RoleName } from 'services/api';
import { useAuth } from 'services/auth';
import { useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { Icon, Widget } from 'ui';

import { NavItem, NavMenu } from './styles';

export const AdminSidebarMenu: VFC = () => {
  const { t } = useTranslation('common');
  const { user, hasRole } = useUserCredential();
  const { token } = useAuth();
  const { getLinks } = useLinksApi();

  const { data: links = [] } = useQuery(
    ['widgets', 'admin', user?.country.id],
    () => getLinks(user?.country.id as number, LinkType.Administration),
    {
      suspense: false,
    },
  );

  const adminLinks = links.map(createLinkWithGeneratedPath(token));

  const filteredAdminLinks = adminLinks.filter((link) => {
    if (!hasRole(RoleName.SubAdministrator)) {
      return link.href !== '/admin/subscriptions';
    }
    return true;
  });

  return (
    <Widget title={t('admin')}>
      <NavMenu>
        {filteredAdminLinks.map((link) => (
          <NavItem
            key={link.href}
            external={isExternal(link.href)}
            target={isExternal(link.href) ? '_blank' : '_self'}
            to={link.href}
          >
            <Icon iconName={link.icon ?? ''} size={24} />
            {t(link.title as string)}
          </NavItem>
        ))}
      </NavMenu>
    </Widget>
  );
};
