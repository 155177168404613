import { Dispatch, SetStateAction, useState } from 'react';

export type SetValueHandler<T> = Dispatch<SetStateAction<T>>;

export interface StorageOptions {
  restore: boolean;
  session: boolean;
}

export const useStorage = <T>(
  key: string,
  initialValue: T,
  options: Partial<StorageOptions> = {},
) => {
  const { restore = true, session = false } = options;

  const storage = session ? sessionStorage : localStorage;

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!restore) return initialValue;

    try {
      const item = storage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);

      return initialValue;
    }
  });

  const setValue: SetValueHandler<T> = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      storage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue] as const;
};
