import styled, { css } from 'styled-components';

import { Button as AntButton } from 'ui/Button';

export const Button = styled(AntButton)<{ isActive: boolean }>`
  border-radius: 100px;
  height: 32px;
  font-size: 14px;
  padding: 10px 14px;
  ${(props) =>
    !props.isActive &&
    css`
      background-color: #edf1fe;
      color: #4872f2;
    `}
  &:hover {
    color: white;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
`;
