import { ChangeEvent, useCallback, useState } from 'react';

import { useMutation } from 'react-query';

import { deleteAttachment } from 'components/PostForm/PostEditorContent/api';
import { useDeletedFilesBuffer } from 'components/PostForm/PostEditorContent/useDeletedFilesBuffer';
import { classifyFileType } from 'domain/file';
import { getPhotoPreview } from 'domain/photo';
import { api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useNotify } from 'services/systemNotify';
import { useWallData } from 'services/wall';

import { getDefaultDocuments, getDefaultMedia } from '../lib';
import type {
  MediaFileType,
  PostEditorContentProps,
  TDocumentType,
} from '../types';
import { DeleteAttachmentParams } from '../types';

export const useAttachements = ({
  defaultValues,
  mode,
  owner,
  onChangeFocus,
}: PostEditorContentProps) => {
  const { forceUpdate } = useWallData();

  const deletedAttachmentsBuffer =
    useDeletedFilesBuffer<DeleteAttachmentParams>();

  const { t } = useTranslation('common');

  const [mediaFilesList, setMediaFilesList] = useState<MediaFileType[]>(
    getDefaultMedia(defaultValues?.postContent?.postContentAttachments ?? []),
  );
  const [documents, setDocuments] = useState<TDocumentType[]>(
    getDefaultDocuments(
      defaultValues?.postContent?.postContentAttachments ?? [],
    ),
  );

  const { pushSuccessNotify, pushErrorNotify } = useNotify();

  const deleteMutation = useMutation(deleteAttachment, {
    onSuccess: (_, params) => {
      if (mode !== 'create') return;

      if (params.type === 'document') {
        setDocuments((prevState) =>
          prevState.filter((doc) => doc.id !== params.id),
        );
      } else {
        setMediaFilesList((prevState) =>
          prevState.filter((media) => media.id !== params.id),
        );
      }
    },
    onError: () => {
      pushErrorNotify(t('something_went_wrong'));
    },
  });

  const fillDeletedBuffer = useCallback(() => {
    mediaFilesList.forEach(({ id, type }) => {
      deletedAttachmentsBuffer.push({ id, type });
    });
    documents.forEach(({ id }) => {
      deletedAttachmentsBuffer.push({ id, type: 'document' });
    });
  }, [deletedAttachmentsBuffer, documents, mediaFilesList]);

  const clearAttacments = useCallback(
    async (attachments: DeleteAttachmentParams[]) => {
      const promises = attachments.map(({ id, type }) =>
        deleteMutation.mutateAsync({ id, type }),
      );

      await Promise.all(promises);
    },
    [deleteMutation],
  );

  const handleReset = async () => {
    onChangeFocus();

    if (mode === 'create') {
      fillDeletedBuffer();
      const buffer = deletedAttachmentsBuffer.getValues();
      await clearAttacments(buffer);
      deletedAttachmentsBuffer.clear();
      return;
    }

    if (mode === 'copy' && defaultValues?.id) {
      api.wall.apiV1WallsIdDelete({
        id: defaultValues.id,
      });
    }
  };

  const handleMediaContentUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const filesArray: File[] = Array.prototype.slice.call(e.target.files);

    filesArray.forEach(async (fileUpload) => {
      switch (classifyFileType(fileUpload)) {
        case 'image': {
          const { data } = await api.photo.apiV1PhotosPost({
            fileUpload,
            ownerGroupId: owner?.groupId,
            ownerUserId: owner?.userId,
          });

          if (data.id && data.fileUrl && data.postedFileId) {
            const newFile: MediaFileType = {
              id: data.id,
              postedFileId: data.postedFileId,
              fileUrl: getPhotoPreview(data) || data.fileUrl,
              type: 'image',
              previews: data.previews,
            };
            setMediaFilesList([...mediaFilesList, newFile]);
            if (defaultValues && mode === 'create') forceUpdate();
          }
          break;
        }
        case 'video': {
          const voidFile: MediaFileType = {
            id: 0,
            postedFileId: 0,
            fileUrl: '',
            type: 'video',
          };
          setMediaFilesList([...mediaFilesList, voidFile]);

          const { data } = await api.video.apiV1VideosPost({
            fileUpload,
            name: fileUpload.name,
            ownerGroupId: owner?.groupId,
            ownerUserId: owner?.userId,
          });

          if (data.id && data.postedFileId) {
            const newFile: MediaFileType = {
              id: data.id,
              postedFileId: data.postedFileId,
              fileUrl: data.previewUrl as string,
              type: 'video',
              videoThumbnail: { fileUrl: data.previewUrl },
            };
            setMediaFilesList([...mediaFilesList, newFile]);
            if (defaultValues && mode === 'create') forceUpdate();
          } else {
            const newList = [...mediaFilesList];
            newList.pop();
            setMediaFilesList(newList);
          }
          break;
        }
        default: {
          pushErrorNotify(t('invalid_file_format'));
          break;
        }
      }
    });
  };

  const handleDocUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const filesArray = Array.prototype.slice.call(e.target.files);

    if (documents.length < 2) {
      filesArray.forEach(async (fileUpload) => {
        const { data } = await api.document.apiV1DocumentsPost({
          fileUpload,
          name: fileUpload.name,
          ownerGroupId: owner?.groupId,
          ownerUserId: owner?.userId,
        });
        if (data.id && data.createdDate && data.postedFileId) {
          const newDocument: TDocumentType = {
            id: data.id,
            postedFileId: data.postedFileId,
            fileUrl: `${data.fileUrl}`,
            name: `${fileUpload.name}`,
            size: Number(fileUpload.size),
            createdDate: data.createdDate,
          };
          setDocuments([...documents, newDocument]);
        }
        if (defaultValues && mode === 'create') forceUpdate();
      });
    } else {
      pushErrorNotify(t('invalid_file_count'));
    }
  };

  const handleDeleteAttachment = useCallback(
    (params: {
      index: number;
      id: number;
      type?: 'image' | 'video' | 'document';
    }) => {
      const deleteAttachmentParams: DeleteAttachmentParams = {
        id: params.id,
        type: params.type ?? 'document',
      };

      if (mode === 'create') {
        deleteMutation.mutate(deleteAttachmentParams);
        return;
      }

      if (deleteAttachmentParams.type === 'document') {
        setDocuments((prevState) =>
          prevState.filter((doc) => doc.id !== params.id),
        );
      } else {
        setMediaFilesList((prevState) =>
          prevState.filter((media) => media.id !== params.id),
        );
      }

      deletedAttachmentsBuffer.push(deleteAttachmentParams);
    },
    [deletedAttachmentsBuffer, deleteMutation, mode],
  );

  const clearBufferOnSubmit = async () => {
    const buffer = deletedAttachmentsBuffer.getValues();
    await clearAttacments(buffer);
    deletedAttachmentsBuffer.clear();
  };

  return {
    documents,
    mediaFilesList,
    clearBufferOnSubmit,
    handleDeleteAttachment,
    handleDocUpload,
    handleMediaContentUpload,
    handleReset,
  };
};
