/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { UserDiscoveriesModel } from '../models';
// @ts-ignore
import { UserDiscoveryModel } from '../models';
// @ts-ignore
import { UserDiscoveryUpdateModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * UserDiscoveryApi - axios parameter creator
 * @export
 */
export const UserDiscoveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isSupervisor] Фильтр по руководителям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesGet: async (userId?: number, isSupervisor?: boolean, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-discoveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (isSupervisor !== undefined) {
                localVarQueryParameter['IsSupervisor'] = isSupervisor;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserDiscoveriesIdDelete', 'id', id)
            const localVarPath = `/api/v1/user-discoveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserDiscoveriesIdGet', 'id', id)
            const localVarPath = `/api/v1/user-discoveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UserDiscoveriesIdPatch', 'id', id)
            const localVarPath = `/api/v1/user-discoveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {UserDiscoveryUpdateModel} [userDiscoveryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesPost: async (userDiscoveryUpdateModel?: UserDiscoveryUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-discoveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDiscoveryUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {UserDiscoveryUpdateModel} [userDiscoveryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesPut: async (userDiscoveryUpdateModel?: UserDiscoveryUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-discoveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDiscoveryUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get report by user discoveries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesReportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-discoveries/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDiscoveryApi - functional programming interface
 * @export
 */
export const UserDiscoveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDiscoveryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isSupervisor] Фильтр по руководителям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveriesGet(userId?: number, isSupervisor?: boolean, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveriesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveriesGet(userId, isSupervisor, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveriesIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveriesIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {UserDiscoveryUpdateModel} [userDiscoveryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveriesPost(userDiscoveryUpdateModel?: UserDiscoveryUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveriesPost(userDiscoveryUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {UserDiscoveryUpdateModel} [userDiscoveryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveriesPut(userDiscoveryUpdateModel?: UserDiscoveryUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDiscoveryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveriesPut(userDiscoveryUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get report by user discoveries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserDiscoveriesReportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserDiscoveriesReportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDiscoveryApi - factory interface
 * @export
 */
export const UserDiscoveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDiscoveryApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isSupervisor] Фильтр по руководителям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesGet(userId?: number, isSupervisor?: boolean, count?: number, offset?: number, options?: any): AxiosPromise<UserDiscoveriesModel> {
            return localVarFp.apiV1UserDiscoveriesGet(userId, isSupervisor, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserDiscoveriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesIdGet(id: number, options?: any): AxiosPromise<UserDiscoveryModel> {
            return localVarFp.apiV1UserDiscoveriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<UserDiscoveryModel> {
            return localVarFp.apiV1UserDiscoveriesIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {UserDiscoveryUpdateModel} [userDiscoveryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesPost(userDiscoveryUpdateModel?: UserDiscoveryUpdateModel, options?: any): AxiosPromise<UserDiscoveryModel> {
            return localVarFp.apiV1UserDiscoveriesPost(userDiscoveryUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {UserDiscoveryUpdateModel} [userDiscoveryUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesPut(userDiscoveryUpdateModel?: UserDiscoveryUpdateModel, options?: any): AxiosPromise<UserDiscoveryModel> {
            return localVarFp.apiV1UserDiscoveriesPut(userDiscoveryUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get report by user discoveries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserDiscoveriesReportGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiV1UserDiscoveriesReportGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UserDiscoveriesGet operation in UserDiscoveryApi.
 * @export
 * @interface UserDiscoveryApiApiV1UserDiscoveriesGetRequest
 */
export interface UserDiscoveryApiApiV1UserDiscoveriesGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesGet
     */
    readonly userId?: number

    /**
     * Фильтр по руководителям
     * @type {boolean}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesGet
     */
    readonly isSupervisor?: boolean

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UserDiscoveriesIdDelete operation in UserDiscoveryApi.
 * @export
 * @interface UserDiscoveryApiApiV1UserDiscoveriesIdDeleteRequest
 */
export interface UserDiscoveryApiApiV1UserDiscoveriesIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1UserDiscoveriesIdGet operation in UserDiscoveryApi.
 * @export
 * @interface UserDiscoveryApiApiV1UserDiscoveriesIdGetRequest
 */
export interface UserDiscoveryApiApiV1UserDiscoveriesIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1UserDiscoveriesIdPatch operation in UserDiscoveryApi.
 * @export
 * @interface UserDiscoveryApiApiV1UserDiscoveriesIdPatchRequest
 */
export interface UserDiscoveryApiApiV1UserDiscoveriesIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1UserDiscoveriesPost operation in UserDiscoveryApi.
 * @export
 * @interface UserDiscoveryApiApiV1UserDiscoveriesPostRequest
 */
export interface UserDiscoveryApiApiV1UserDiscoveriesPostRequest {
    /**
     * 
     * @type {UserDiscoveryUpdateModel}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesPost
     */
    readonly userDiscoveryUpdateModel?: UserDiscoveryUpdateModel
}

/**
 * Request parameters for apiV1UserDiscoveriesPut operation in UserDiscoveryApi.
 * @export
 * @interface UserDiscoveryApiApiV1UserDiscoveriesPutRequest
 */
export interface UserDiscoveryApiApiV1UserDiscoveriesPutRequest {
    /**
     * 
     * @type {UserDiscoveryUpdateModel}
     * @memberof UserDiscoveryApiApiV1UserDiscoveriesPut
     */
    readonly userDiscoveryUpdateModel?: UserDiscoveryUpdateModel
}

/**
 * UserDiscoveryApi - object-oriented interface
 * @export
 * @class UserDiscoveryApi
 * @extends {BaseAPI}
 */
export class UserDiscoveryApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {UserDiscoveryApiApiV1UserDiscoveriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryApi
     */
    public apiV1UserDiscoveriesGet(requestParameters: UserDiscoveryApiApiV1UserDiscoveriesGetRequest = {}, options?: AxiosRequestConfig) {
        return UserDiscoveryApiFp(this.configuration).apiV1UserDiscoveriesGet(requestParameters.userId, requestParameters.isSupervisor, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {UserDiscoveryApiApiV1UserDiscoveriesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryApi
     */
    public apiV1UserDiscoveriesIdDelete(requestParameters: UserDiscoveryApiApiV1UserDiscoveriesIdDeleteRequest, options?: AxiosRequestConfig) {
        return UserDiscoveryApiFp(this.configuration).apiV1UserDiscoveriesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {UserDiscoveryApiApiV1UserDiscoveriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryApi
     */
    public apiV1UserDiscoveriesIdGet(requestParameters: UserDiscoveryApiApiV1UserDiscoveriesIdGetRequest, options?: AxiosRequestConfig) {
        return UserDiscoveryApiFp(this.configuration).apiV1UserDiscoveriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {UserDiscoveryApiApiV1UserDiscoveriesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryApi
     */
    public apiV1UserDiscoveriesIdPatch(requestParameters: UserDiscoveryApiApiV1UserDiscoveriesIdPatchRequest, options?: AxiosRequestConfig) {
        return UserDiscoveryApiFp(this.configuration).apiV1UserDiscoveriesIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {UserDiscoveryApiApiV1UserDiscoveriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryApi
     */
    public apiV1UserDiscoveriesPost(requestParameters: UserDiscoveryApiApiV1UserDiscoveriesPostRequest = {}, options?: AxiosRequestConfig) {
        return UserDiscoveryApiFp(this.configuration).apiV1UserDiscoveriesPost(requestParameters.userDiscoveryUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {UserDiscoveryApiApiV1UserDiscoveriesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryApi
     */
    public apiV1UserDiscoveriesPut(requestParameters: UserDiscoveryApiApiV1UserDiscoveriesPutRequest = {}, options?: AxiosRequestConfig) {
        return UserDiscoveryApiFp(this.configuration).apiV1UserDiscoveriesPut(requestParameters.userDiscoveryUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get report by user discoveries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDiscoveryApi
     */
    public apiV1UserDiscoveriesReportGet(options?: AxiosRequestConfig) {
        return UserDiscoveryApiFp(this.configuration).apiV1UserDiscoveriesReportGet(options).then((request) => request(this.axios, this.basePath));
    }
}
