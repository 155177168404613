/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { PostedFileModel } from '../models';
// @ts-ignore
import { PostedFilesModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * PostedFileApi - axios parameter creator
 * @export
 */
export const PostedFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete model
         * @param {PostedFileModel} [postedFileModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesDelete: async (postedFileModel?: PostedFileModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postedFileModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get models by filter
         * @param {string} [fileName] Фильтр по названию файла
         * @param {boolean} [isPhoto] Фильтр по фотографиям
         * @param {boolean} [isVideo] Фильтр по видео
         * @param {boolean} [isDocument] Фильтр по документам
         * @param {boolean} [isMailAttachment] Фильтр по прикреплениям
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isNeedOnlyId] Выводить только id файлов
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesGet: async (fileName?: string, isPhoto?: boolean, isVideo?: boolean, isDocument?: boolean, isMailAttachment?: boolean, userId?: number, groupId?: number, countryId?: number, isNeedOnlyId?: boolean, albumId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['FileName'] = fileName;
            }

            if (isPhoto !== undefined) {
                localVarQueryParameter['IsPhoto'] = isPhoto;
            }

            if (isVideo !== undefined) {
                localVarQueryParameter['IsVideo'] = isVideo;
            }

            if (isDocument !== undefined) {
                localVarQueryParameter['IsDocument'] = isDocument;
            }

            if (isMailAttachment !== undefined) {
                localVarQueryParameter['IsMailAttachment'] = isMailAttachment;
            }

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (isNeedOnlyId !== undefined) {
                localVarQueryParameter['IsNeedOnlyId'] = isNeedOnlyId;
            }

            if (albumId !== undefined) {
                localVarQueryParameter['AlbumId'] = albumId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesIdGalleryGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1FilesIdGalleryGet', 'id', id)
            const localVarPath = `/api/v1/files/{id}/gallery`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get files ids
         * @param {string} [fileName] Фильтр по названию файла
         * @param {boolean} [isPhoto] Фильтр по фотографиям
         * @param {boolean} [isVideo] Фильтр по видео
         * @param {boolean} [isDocument] Фильтр по документам
         * @param {boolean} [isMailAttachment] Фильтр по прикреплениям
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isNeedOnlyId] Выводить только id файлов
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesIdsGet: async (fileName?: string, isPhoto?: boolean, isVideo?: boolean, isDocument?: boolean, isMailAttachment?: boolean, userId?: number, groupId?: number, countryId?: number, isNeedOnlyId?: boolean, albumId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files/ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['FileName'] = fileName;
            }

            if (isPhoto !== undefined) {
                localVarQueryParameter['IsPhoto'] = isPhoto;
            }

            if (isVideo !== undefined) {
                localVarQueryParameter['IsVideo'] = isVideo;
            }

            if (isDocument !== undefined) {
                localVarQueryParameter['IsDocument'] = isDocument;
            }

            if (isMailAttachment !== undefined) {
                localVarQueryParameter['IsMailAttachment'] = isMailAttachment;
            }

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (isNeedOnlyId !== undefined) {
                localVarQueryParameter['IsNeedOnlyId'] = isNeedOnlyId;
            }

            if (albumId !== undefined) {
                localVarQueryParameter['AlbumId'] = albumId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostedFileApi - functional programming interface
 * @export
 */
export const PostedFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostedFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete model
         * @param {PostedFileModel} [postedFileModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesDelete(postedFileModel?: PostedFileModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesDelete(postedFileModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get models by filter
         * @param {string} [fileName] Фильтр по названию файла
         * @param {boolean} [isPhoto] Фильтр по фотографиям
         * @param {boolean} [isVideo] Фильтр по видео
         * @param {boolean} [isDocument] Фильтр по документам
         * @param {boolean} [isMailAttachment] Фильтр по прикреплениям
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isNeedOnlyId] Выводить только id файлов
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesGet(fileName?: string, isPhoto?: boolean, isVideo?: boolean, isDocument?: boolean, isMailAttachment?: boolean, userId?: number, groupId?: number, countryId?: number, isNeedOnlyId?: boolean, albumId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostedFilesModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesGet(fileName, isPhoto, isVideo, isDocument, isMailAttachment, userId, groupId, countryId, isNeedOnlyId, albumId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesIdGalleryGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostedFileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesIdGalleryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get files ids
         * @param {string} [fileName] Фильтр по названию файла
         * @param {boolean} [isPhoto] Фильтр по фотографиям
         * @param {boolean} [isVideo] Фильтр по видео
         * @param {boolean} [isDocument] Фильтр по документам
         * @param {boolean} [isMailAttachment] Фильтр по прикреплениям
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isNeedOnlyId] Выводить только id файлов
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesIdsGet(fileName?: string, isPhoto?: boolean, isVideo?: boolean, isDocument?: boolean, isMailAttachment?: boolean, userId?: number, groupId?: number, countryId?: number, isNeedOnlyId?: boolean, albumId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesIdsGet(fileName, isPhoto, isVideo, isDocument, isMailAttachment, userId, groupId, countryId, isNeedOnlyId, albumId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostedFileApi - factory interface
 * @export
 */
export const PostedFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostedFileApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete model
         * @param {PostedFileModel} [postedFileModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesDelete(postedFileModel?: PostedFileModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1FilesDelete(postedFileModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get models by filter
         * @param {string} [fileName] Фильтр по названию файла
         * @param {boolean} [isPhoto] Фильтр по фотографиям
         * @param {boolean} [isVideo] Фильтр по видео
         * @param {boolean} [isDocument] Фильтр по документам
         * @param {boolean} [isMailAttachment] Фильтр по прикреплениям
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isNeedOnlyId] Выводить только id файлов
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesGet(fileName?: string, isPhoto?: boolean, isVideo?: boolean, isDocument?: boolean, isMailAttachment?: boolean, userId?: number, groupId?: number, countryId?: number, isNeedOnlyId?: boolean, albumId?: number, count?: number, offset?: number, options?: any): AxiosPromise<PostedFilesModel> {
            return localVarFp.apiV1FilesGet(fileName, isPhoto, isVideo, isDocument, isMailAttachment, userId, groupId, countryId, isNeedOnlyId, albumId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesIdGalleryGet(id: number, options?: any): AxiosPromise<PostedFileModel> {
            return localVarFp.apiV1FilesIdGalleryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get files ids
         * @param {string} [fileName] Фильтр по названию файла
         * @param {boolean} [isPhoto] Фильтр по фотографиям
         * @param {boolean} [isVideo] Фильтр по видео
         * @param {boolean} [isDocument] Фильтр по документам
         * @param {boolean} [isMailAttachment] Фильтр по прикреплениям
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [isNeedOnlyId] Выводить только id файлов
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesIdsGet(fileName?: string, isPhoto?: boolean, isVideo?: boolean, isDocument?: boolean, isMailAttachment?: boolean, userId?: number, groupId?: number, countryId?: number, isNeedOnlyId?: boolean, albumId?: number, count?: number, offset?: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.apiV1FilesIdsGet(fileName, isPhoto, isVideo, isDocument, isMailAttachment, userId, groupId, countryId, isNeedOnlyId, albumId, count, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1FilesDelete operation in PostedFileApi.
 * @export
 * @interface PostedFileApiApiV1FilesDeleteRequest
 */
export interface PostedFileApiApiV1FilesDeleteRequest {
    /**
     * 
     * @type {PostedFileModel}
     * @memberof PostedFileApiApiV1FilesDelete
     */
    readonly postedFileModel?: PostedFileModel
}

/**
 * Request parameters for apiV1FilesGet operation in PostedFileApi.
 * @export
 * @interface PostedFileApiApiV1FilesGetRequest
 */
export interface PostedFileApiApiV1FilesGetRequest {
    /**
     * Фильтр по названию файла
     * @type {string}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly fileName?: string

    /**
     * Фильтр по фотографиям
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly isPhoto?: boolean

    /**
     * Фильтр по видео
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly isVideo?: boolean

    /**
     * Фильтр по документам
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly isDocument?: boolean

    /**
     * Фильтр по прикреплениям
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly isMailAttachment?: boolean

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly userId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly groupId?: number

    /**
     * Фильтр по стране
     * @type {number}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly countryId?: number

    /**
     * Выводить только id файлов
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly isNeedOnlyId?: boolean

    /**
     * Фильтр по альбому
     * @type {number}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly albumId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof PostedFileApiApiV1FilesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1FilesIdGalleryGet operation in PostedFileApi.
 * @export
 * @interface PostedFileApiApiV1FilesIdGalleryGetRequest
 */
export interface PostedFileApiApiV1FilesIdGalleryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PostedFileApiApiV1FilesIdGalleryGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1FilesIdsGet operation in PostedFileApi.
 * @export
 * @interface PostedFileApiApiV1FilesIdsGetRequest
 */
export interface PostedFileApiApiV1FilesIdsGetRequest {
    /**
     * Фильтр по названию файла
     * @type {string}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly fileName?: string

    /**
     * Фильтр по фотографиям
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly isPhoto?: boolean

    /**
     * Фильтр по видео
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly isVideo?: boolean

    /**
     * Фильтр по документам
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly isDocument?: boolean

    /**
     * Фильтр по прикреплениям
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly isMailAttachment?: boolean

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly userId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly groupId?: number

    /**
     * Фильтр по стране
     * @type {number}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly countryId?: number

    /**
     * Выводить только id файлов
     * @type {boolean}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly isNeedOnlyId?: boolean

    /**
     * Фильтр по альбому
     * @type {number}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly albumId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof PostedFileApiApiV1FilesIdsGet
     */
    readonly offset?: number
}

/**
 * PostedFileApi - object-oriented interface
 * @export
 * @class PostedFileApi
 * @extends {BaseAPI}
 */
export class PostedFileApi extends BaseAPI {
    /**
     * 
     * @summary Delete model
     * @param {PostedFileApiApiV1FilesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostedFileApi
     */
    public apiV1FilesDelete(requestParameters: PostedFileApiApiV1FilesDeleteRequest = {}, options?: AxiosRequestConfig) {
        return PostedFileApiFp(this.configuration).apiV1FilesDelete(requestParameters.postedFileModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get models by filter
     * @param {PostedFileApiApiV1FilesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostedFileApi
     */
    public apiV1FilesGet(requestParameters: PostedFileApiApiV1FilesGetRequest = {}, options?: AxiosRequestConfig) {
        return PostedFileApiFp(this.configuration).apiV1FilesGet(requestParameters.fileName, requestParameters.isPhoto, requestParameters.isVideo, requestParameters.isDocument, requestParameters.isMailAttachment, requestParameters.userId, requestParameters.groupId, requestParameters.countryId, requestParameters.isNeedOnlyId, requestParameters.albumId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {PostedFileApiApiV1FilesIdGalleryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostedFileApi
     */
    public apiV1FilesIdGalleryGet(requestParameters: PostedFileApiApiV1FilesIdGalleryGetRequest, options?: AxiosRequestConfig) {
        return PostedFileApiFp(this.configuration).apiV1FilesIdGalleryGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get files ids
     * @param {PostedFileApiApiV1FilesIdsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostedFileApi
     */
    public apiV1FilesIdsGet(requestParameters: PostedFileApiApiV1FilesIdsGetRequest = {}, options?: AxiosRequestConfig) {
        return PostedFileApiFp(this.configuration).apiV1FilesIdsGet(requestParameters.fileName, requestParameters.isPhoto, requestParameters.isVideo, requestParameters.isDocument, requestParameters.isMailAttachment, requestParameters.userId, requestParameters.groupId, requestParameters.countryId, requestParameters.isNeedOnlyId, requestParameters.albumId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}
