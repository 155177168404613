import AntSelect, { SelectProps, SelectValue } from 'antd/lib/select';
import styled from 'styled-components';

export type { SelectProps, OptionProps } from 'antd/lib/select';

const StyledSelect = styled.div`
  .ant-select-single {
    & .ant-select-selector {
      border-radius: 8px;
    }
  }
`;

export const Select = <T extends SelectValue>(props: SelectProps<T>) => (
  <StyledSelect>
    <AntSelect {...props} />
  </StyledSelect>
);

export const { Option } = AntSelect;
export const { OptGroup: OptionGroup } = AntSelect;
