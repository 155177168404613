import React, { FC } from 'react';

import { SentimentSatisfied } from '@styled-icons/material-outlined';
import { BaseEmoji } from 'emoji-mart';

import { EmojiPicker } from 'components/EmojiPicker';
import { Dropdown } from 'ui';

export type EmojiDropdownPickerProps = {
  onClick: (emoji: BaseEmoji) => void;
};
export const EmojiDropdownPicker: FC<EmojiDropdownPickerProps> = ({
  onClick,
}) => {
  return (
    <Dropdown
      overlay={<EmojiPicker className="bt-0" onEmojiClick={onClick} />}
      placement="topRight"
    >
      <SentimentSatisfied size={22} />
    </Dropdown>
  );
};
