import Document from '@tiptap/extension-document';
import { HardBreak } from '@tiptap/extension-hard-break';
import LinkExt from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import { Editor, useEditor } from '@tiptap/react';

import { useTranslation } from 'services/i18n';

import { Suggestion as suggestion } from './Suggestion';

export type PostEditorServiceProps = {
  placeholder?: string;
  text?: string | null;
};

export const usePostEditorService = ({
  placeholder,
  text,
}: PostEditorServiceProps): Editor | null => {
  const { t } = useTranslation('common');
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      HardBreak,
      LinkExt.configure({
        openOnClick: false,
      }),
      Placeholder.configure({
        placeholder: placeholder ?? `${t('what_on_your_mind')}?`,
        includeChildren: true,
      }),
      TextAlign.configure({
        types: ['paragraph'],
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
      }),
    ],
    content: text || `<p />`,
  });
  return editor;
};
