/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentModel } from '../models';
// @ts-ignore
import { DocumentUpdateModel } from '../models';
// @ts-ignore
import { DocumentsModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsGet: async (userId?: number, groupId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DocumentsIdDelete', 'id', id)
            const localVarPath = `/api/v1/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DocumentsIdGet', 'id', id)
            const localVarPath = `/api/v1/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1DocumentsIdPatch', 'id', id)
            const localVarPath = `/api/v1/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {string} [name] 
         * @param {any} [fileUpload] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsPost: async (name?: string, fileUpload?: any, ownerUserId?: number, ownerGroupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileUpload !== undefined) { 
                localVarFormParams.append('FileUpload', fileUpload as any);
            }
    
            if (ownerUserId !== undefined) { 
                localVarFormParams.append('Owner.UserId', ownerUserId as any);
            }
    
            if (ownerGroupId !== undefined) { 
                localVarFormParams.append('Owner.GroupId', ownerGroupId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {DocumentUpdateModel} [documentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsPut: async (documentUpdateModel?: DocumentUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsGet(userId?: number, groupId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsGet(userId, groupId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {string} [name] 
         * @param {any} [fileUpload] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsPost(name?: string, fileUpload?: any, ownerUserId?: number, ownerGroupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsPost(name, fileUpload, ownerUserId, ownerGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {DocumentUpdateModel} [documentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DocumentsPut(documentUpdateModel?: DocumentUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DocumentsPut(documentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsGet(userId?: number, groupId?: number, count?: number, offset?: number, options?: any): AxiosPromise<DocumentsModel> {
            return localVarFp.apiV1DocumentsGet(userId, groupId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1DocumentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdGet(id: number, options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.apiV1DocumentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.apiV1DocumentsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {string} [name] 
         * @param {any} [fileUpload] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsPost(name?: string, fileUpload?: any, ownerUserId?: number, ownerGroupId?: number, options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.apiV1DocumentsPost(name, fileUpload, ownerUserId, ownerGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {DocumentUpdateModel} [documentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DocumentsPut(documentUpdateModel?: DocumentUpdateModel, options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.apiV1DocumentsPut(documentUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1DocumentsGet operation in DocumentApi.
 * @export
 * @interface DocumentApiApiV1DocumentsGetRequest
 */
export interface DocumentApiApiV1DocumentsGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsGet
     */
    readonly userId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsGet
     */
    readonly groupId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1DocumentsIdDelete operation in DocumentApi.
 * @export
 * @interface DocumentApiApiV1DocumentsIdDeleteRequest
 */
export interface DocumentApiApiV1DocumentsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1DocumentsIdGet operation in DocumentApi.
 * @export
 * @interface DocumentApiApiV1DocumentsIdGetRequest
 */
export interface DocumentApiApiV1DocumentsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1DocumentsIdPatch operation in DocumentApi.
 * @export
 * @interface DocumentApiApiV1DocumentsIdPatchRequest
 */
export interface DocumentApiApiV1DocumentsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof DocumentApiApiV1DocumentsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1DocumentsPost operation in DocumentApi.
 * @export
 * @interface DocumentApiApiV1DocumentsPostRequest
 */
export interface DocumentApiApiV1DocumentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiApiV1DocumentsPost
     */
    readonly name?: string

    /**
     * 
     * @type {any}
     * @memberof DocumentApiApiV1DocumentsPost
     */
    readonly fileUpload?: any

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsPost
     */
    readonly ownerUserId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof DocumentApiApiV1DocumentsPost
     */
    readonly ownerGroupId?: number
}

/**
 * Request parameters for apiV1DocumentsPut operation in DocumentApi.
 * @export
 * @interface DocumentApiApiV1DocumentsPutRequest
 */
export interface DocumentApiApiV1DocumentsPutRequest {
    /**
     * 
     * @type {DocumentUpdateModel}
     * @memberof DocumentApiApiV1DocumentsPut
     */
    readonly documentUpdateModel?: DocumentUpdateModel
}

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {DocumentApiApiV1DocumentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiV1DocumentsGet(requestParameters: DocumentApiApiV1DocumentsGetRequest = {}, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiV1DocumentsGet(requestParameters.userId, requestParameters.groupId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {DocumentApiApiV1DocumentsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiV1DocumentsIdDelete(requestParameters: DocumentApiApiV1DocumentsIdDeleteRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiV1DocumentsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {DocumentApiApiV1DocumentsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiV1DocumentsIdGet(requestParameters: DocumentApiApiV1DocumentsIdGetRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiV1DocumentsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {DocumentApiApiV1DocumentsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiV1DocumentsIdPatch(requestParameters: DocumentApiApiV1DocumentsIdPatchRequest, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiV1DocumentsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {DocumentApiApiV1DocumentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiV1DocumentsPost(requestParameters: DocumentApiApiV1DocumentsPostRequest = {}, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiV1DocumentsPost(requestParameters.name, requestParameters.fileUpload, requestParameters.ownerUserId, requestParameters.ownerGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {DocumentApiApiV1DocumentsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiV1DocumentsPut(requestParameters: DocumentApiApiV1DocumentsPutRequest = {}, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiV1DocumentsPut(requestParameters.documentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
