import { FC } from 'react';

import { Info, Warning } from '@styled-icons/material-outlined';
import styled from 'styled-components';

import { Title } from 'ui/Title';

import { ModalBody, ModalContentBody, StyledModal } from './styled';
import { ModalProps } from './types';

export const Modal: FC<ModalProps> = ({
  title,
  type,
  size,
  children,
  ...props
}) => {
  return (
    <StyledModal size={size} title={title} type={type} {...props} centered>
      {size === 'small' ? (
        <ModalBody type={type}>
          {type === 'warning' ? <Warning size={20} /> : <Info size={20} />}

          <ModalContentBody>
            <Title variant="h3">{title}</Title>
            {children}
          </ModalContentBody>
        </ModalBody>
      ) : (
        children
      )}
    </StyledModal>
  );
};

export const ModalScrollBody = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f6f8fa; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dbe0e5; /* color of the scroll thumb */
    border-radius: 50px; /* roundness of the scroll thumb */
  }
`;
