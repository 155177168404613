import { ComponentPropsWithRef } from 'react';

import styled from 'styled-components';

export type BaseInputProps = ComponentPropsWithRef<'input'>;

export const StyledInputRoot = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-left: 10px;
`;
