import { MentionOptions } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import {
  SuggestionKeyDownProps,
  SuggestionProps,
} from '@tiptap/suggestion/src/suggestion';
import pDebounce from 'p-debounce';
import tippy from 'tippy.js';

import { UserModel, api } from 'services/api';

import { MentionList, MentionListRef } from './MentionList';

const items = async ({ query }: { query: string }) => {
  const list: UserModel[] = [];
  const { data } = await api.user.apiV1UsersGet({
    query,
    count: 5,
  });

  return data.items ? [...list, ...data.items] : [];
};

const debouncedItems = pDebounce(items, 400);

export const Suggestion: MentionOptions['suggestion'] = {
  items: debouncedItems,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  render: () => {
    let component: ReactRenderer<MentionListRef>;
    let popup: any;

    return {
      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) return;

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },
      onUpdate(props: SuggestionProps) {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: SuggestionKeyDownProps) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
