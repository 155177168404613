/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SearchHistoryCreateRequestBody } from '../models';
// @ts-ignore
import { SearchHistoryDto } from '../models';
// @ts-ignore
import { SearchHistoryDtoSearchResult } from '../models';
/**
 * SearchHistoryApi - axios parameter creator
 * @export
 */
export const SearchHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} userId Фильтр по пользователю
         * @param {string} [text] Фильтр по содержанию
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSearchHistoriesGet: async (userId: number, text?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdSearchHistoriesGet', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/search-histories`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (text !== undefined) {
                localVarQueryParameter['Text'] = text;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {number} userId 
         * @param {SearchHistoryCreateRequestBody} [searchHistoryCreateRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSearchHistoriesPost: async (userId: number, searchHistoryCreateRequestBody?: SearchHistoryCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdSearchHistoriesPost', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/search-histories`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchHistoryCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchHistoryApi - functional programming interface
 * @export
 */
export const SearchHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} userId Фильтр по пользователю
         * @param {string} [text] Фильтр по содержанию
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdSearchHistoriesGet(userId: number, text?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchHistoryDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdSearchHistoriesGet(userId, text, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {number} userId 
         * @param {SearchHistoryCreateRequestBody} [searchHistoryCreateRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdSearchHistoriesPost(userId: number, searchHistoryCreateRequestBody?: SearchHistoryCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdSearchHistoriesPost(userId, searchHistoryCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchHistoryApi - factory interface
 * @export
 */
export const SearchHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchHistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} userId Фильтр по пользователю
         * @param {string} [text] Фильтр по содержанию
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSearchHistoriesGet(userId: number, text?: string, count?: number, offset?: number, options?: any): AxiosPromise<SearchHistoryDtoSearchResult> {
            return localVarFp.apiV1UsersUserIdSearchHistoriesGet(userId, text, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {number} userId 
         * @param {SearchHistoryCreateRequestBody} [searchHistoryCreateRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSearchHistoriesPost(userId: number, searchHistoryCreateRequestBody?: SearchHistoryCreateRequestBody, options?: any): AxiosPromise<SearchHistoryDto> {
            return localVarFp.apiV1UsersUserIdSearchHistoriesPost(userId, searchHistoryCreateRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UsersUserIdSearchHistoriesGet operation in SearchHistoryApi.
 * @export
 * @interface SearchHistoryApiApiV1UsersUserIdSearchHistoriesGetRequest
 */
export interface SearchHistoryApiApiV1UsersUserIdSearchHistoriesGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof SearchHistoryApiApiV1UsersUserIdSearchHistoriesGet
     */
    readonly userId: number

    /**
     * Фильтр по содержанию
     * @type {string}
     * @memberof SearchHistoryApiApiV1UsersUserIdSearchHistoriesGet
     */
    readonly text?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof SearchHistoryApiApiV1UsersUserIdSearchHistoriesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof SearchHistoryApiApiV1UsersUserIdSearchHistoriesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UsersUserIdSearchHistoriesPost operation in SearchHistoryApi.
 * @export
 * @interface SearchHistoryApiApiV1UsersUserIdSearchHistoriesPostRequest
 */
export interface SearchHistoryApiApiV1UsersUserIdSearchHistoriesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof SearchHistoryApiApiV1UsersUserIdSearchHistoriesPost
     */
    readonly userId: number

    /**
     * 
     * @type {SearchHistoryCreateRequestBody}
     * @memberof SearchHistoryApiApiV1UsersUserIdSearchHistoriesPost
     */
    readonly searchHistoryCreateRequestBody?: SearchHistoryCreateRequestBody
}

/**
 * SearchHistoryApi - object-oriented interface
 * @export
 * @class SearchHistoryApi
 * @extends {BaseAPI}
 */
export class SearchHistoryApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {SearchHistoryApiApiV1UsersUserIdSearchHistoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchHistoryApi
     */
    public apiV1UsersUserIdSearchHistoriesGet(requestParameters: SearchHistoryApiApiV1UsersUserIdSearchHistoriesGetRequest, options?: AxiosRequestConfig) {
        return SearchHistoryApiFp(this.configuration).apiV1UsersUserIdSearchHistoriesGet(requestParameters.userId, requestParameters.text, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {SearchHistoryApiApiV1UsersUserIdSearchHistoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchHistoryApi
     */
    public apiV1UsersUserIdSearchHistoriesPost(requestParameters: SearchHistoryApiApiV1UsersUserIdSearchHistoriesPostRequest, options?: AxiosRequestConfig) {
        return SearchHistoryApiFp(this.configuration).apiV1UsersUserIdSearchHistoriesPost(requestParameters.userId, requestParameters.searchHistoryCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
