import { WallModel, WallUpdateModel } from 'services/api';

export const convertPostDataToUpdateModel = (
  data: WallModel,
): WallUpdateModel => ({
  ...data,
  id: data.id,
  isPublished: true,
  latitude: data.geocoder?.latitude,
  longitude: data.geocoder?.longitude,
  owner: data.owner,
  postContent: {
    text: data.postContent?.text,
    openGraphItem: data.postContent?.openGraphItem,
    postContentAttachments: data.postContent?.postContentAttachments,
  },
  idea: data.idea,
  wallContentType: data.wallContentType,
  replyId: data.replyWallId,
});

export const getPostDescription = (postText: string) => {
  const htmlToText = (content: string) => {
    const span = document.createElement('span');
    span.innerHTML = content;
    return (span.textContent || span.innerText).replace('\n', '').trim();
  };

  const text = htmlToText(postText);

  return `${text.replace(/<[^>]*>/g, '').slice(0, 100)}...`;
};
