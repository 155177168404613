/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { PhotoChangeSortModel } from '../models';
// @ts-ignore
import { PhotoModel } from '../models';
// @ts-ignore
import { PhotosModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * PhotoApi - axios parameter creator
 * @export
 */
export const PhotoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change sort
         * @param {Array<PhotoChangeSortModel>} [photoChangeSortModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosChangeSortPut: async (photoChangeSortModel?: Array<PhotoChangeSortModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/photos/ChangeSort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(photoChangeSortModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get models by filter
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosGet: async (albumId?: number, userId?: number, groupId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (albumId !== undefined) {
                localVarQueryParameter['AlbumId'] = albumId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PhotosIdDelete', 'id', id)
            const localVarPath = `/api/v1/photos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PhotosIdGet', 'id', id)
            const localVarPath = `/api/v1/photos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PhotosIdPatch', 'id', id)
            const localVarPath = `/api/v1/photos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {number} [albumId] 
         * @param {string} [description] 
         * @param {any} [fileUpload] 
         * @param {string} [imageUrl] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosPost: async (albumId?: number, description?: string, fileUpload?: any, imageUrl?: string, ownerUserId?: number, ownerGroupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (albumId !== undefined) { 
                localVarFormParams.append('AlbumId', albumId as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (fileUpload !== undefined) { 
                localVarFormParams.append('FileUpload', fileUpload as any);
            }
    
            if (imageUrl !== undefined) { 
                localVarFormParams.append('ImageUrl', imageUrl as any);
            }
    
            if (ownerUserId !== undefined) { 
                localVarFormParams.append('Owner.UserId', ownerUserId as any);
            }
    
            if (ownerGroupId !== undefined) { 
                localVarFormParams.append('Owner.GroupId', ownerGroupId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {number} [albumId] 
         * @param {string} [description] 
         * @param {number} [sort] 
         * @param {any} [filePreviewUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosPut: async (id?: number, albumId?: number, description?: string, sort?: number, filePreviewUpload?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (albumId !== undefined) { 
                localVarFormParams.append('AlbumId', albumId as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (sort !== undefined) { 
                localVarFormParams.append('Sort', sort as any);
            }
    
            if (filePreviewUpload !== undefined) { 
                localVarFormParams.append('FilePreviewUpload', filePreviewUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhotoApi - functional programming interface
 * @export
 */
export const PhotoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhotoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change sort
         * @param {Array<PhotoChangeSortModel>} [photoChangeSortModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PhotosChangeSortPut(photoChangeSortModel?: Array<PhotoChangeSortModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PhotosChangeSortPut(photoChangeSortModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get models by filter
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PhotosGet(albumId?: number, userId?: number, groupId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotosModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PhotosGet(albumId, userId, groupId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PhotosIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PhotosIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PhotosIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PhotosIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PhotosIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PhotosIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {number} [albumId] 
         * @param {string} [description] 
         * @param {any} [fileUpload] 
         * @param {string} [imageUrl] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PhotosPost(albumId?: number, description?: string, fileUpload?: any, imageUrl?: string, ownerUserId?: number, ownerGroupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PhotosPost(albumId, description, fileUpload, imageUrl, ownerUserId, ownerGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {number} [albumId] 
         * @param {string} [description] 
         * @param {number} [sort] 
         * @param {any} [filePreviewUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PhotosPut(id?: number, albumId?: number, description?: string, sort?: number, filePreviewUpload?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PhotosPut(id, albumId, description, sort, filePreviewUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PhotoApi - factory interface
 * @export
 */
export const PhotoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhotoApiFp(configuration)
    return {
        /**
         * 
         * @summary Change sort
         * @param {Array<PhotoChangeSortModel>} [photoChangeSortModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosChangeSortPut(photoChangeSortModel?: Array<PhotoChangeSortModel>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PhotosChangeSortPut(photoChangeSortModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get models by filter
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [userId] Фильтр по пользователю
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosGet(albumId?: number, userId?: number, groupId?: number, count?: number, offset?: number, options?: any): AxiosPromise<PhotosModel> {
            return localVarFp.apiV1PhotosGet(albumId, userId, groupId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PhotosIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosIdGet(id: number, options?: any): AxiosPromise<PhotoModel> {
            return localVarFp.apiV1PhotosIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<PhotoModel> {
            return localVarFp.apiV1PhotosIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {number} [albumId] 
         * @param {string} [description] 
         * @param {any} [fileUpload] 
         * @param {string} [imageUrl] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosPost(albumId?: number, description?: string, fileUpload?: any, imageUrl?: string, ownerUserId?: number, ownerGroupId?: number, options?: any): AxiosPromise<PhotoModel> {
            return localVarFp.apiV1PhotosPost(albumId, description, fileUpload, imageUrl, ownerUserId, ownerGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {number} [id] 
         * @param {number} [albumId] 
         * @param {string} [description] 
         * @param {number} [sort] 
         * @param {any} [filePreviewUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PhotosPut(id?: number, albumId?: number, description?: string, sort?: number, filePreviewUpload?: any, options?: any): AxiosPromise<PhotoModel> {
            return localVarFp.apiV1PhotosPut(id, albumId, description, sort, filePreviewUpload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1PhotosChangeSortPut operation in PhotoApi.
 * @export
 * @interface PhotoApiApiV1PhotosChangeSortPutRequest
 */
export interface PhotoApiApiV1PhotosChangeSortPutRequest {
    /**
     * 
     * @type {Array<PhotoChangeSortModel>}
     * @memberof PhotoApiApiV1PhotosChangeSortPut
     */
    readonly photoChangeSortModel?: Array<PhotoChangeSortModel>
}

/**
 * Request parameters for apiV1PhotosGet operation in PhotoApi.
 * @export
 * @interface PhotoApiApiV1PhotosGetRequest
 */
export interface PhotoApiApiV1PhotosGetRequest {
    /**
     * Фильтр по альбому
     * @type {number}
     * @memberof PhotoApiApiV1PhotosGet
     */
    readonly albumId?: number

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof PhotoApiApiV1PhotosGet
     */
    readonly userId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof PhotoApiApiV1PhotosGet
     */
    readonly groupId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof PhotoApiApiV1PhotosGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof PhotoApiApiV1PhotosGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1PhotosIdDelete operation in PhotoApi.
 * @export
 * @interface PhotoApiApiV1PhotosIdDeleteRequest
 */
export interface PhotoApiApiV1PhotosIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof PhotoApiApiV1PhotosIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1PhotosIdGet operation in PhotoApi.
 * @export
 * @interface PhotoApiApiV1PhotosIdGetRequest
 */
export interface PhotoApiApiV1PhotosIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PhotoApiApiV1PhotosIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1PhotosIdPatch operation in PhotoApi.
 * @export
 * @interface PhotoApiApiV1PhotosIdPatchRequest
 */
export interface PhotoApiApiV1PhotosIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof PhotoApiApiV1PhotosIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof PhotoApiApiV1PhotosIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1PhotosPost operation in PhotoApi.
 * @export
 * @interface PhotoApiApiV1PhotosPostRequest
 */
export interface PhotoApiApiV1PhotosPostRequest {
    /**
     * 
     * @type {number}
     * @memberof PhotoApiApiV1PhotosPost
     */
    readonly albumId?: number

    /**
     * 
     * @type {string}
     * @memberof PhotoApiApiV1PhotosPost
     */
    readonly description?: string

    /**
     * 
     * @type {any}
     * @memberof PhotoApiApiV1PhotosPost
     */
    readonly fileUpload?: any

    /**
     * 
     * @type {string}
     * @memberof PhotoApiApiV1PhotosPost
     */
    readonly imageUrl?: string

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof PhotoApiApiV1PhotosPost
     */
    readonly ownerUserId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof PhotoApiApiV1PhotosPost
     */
    readonly ownerGroupId?: number
}

/**
 * Request parameters for apiV1PhotosPut operation in PhotoApi.
 * @export
 * @interface PhotoApiApiV1PhotosPutRequest
 */
export interface PhotoApiApiV1PhotosPutRequest {
    /**
     * 
     * @type {number}
     * @memberof PhotoApiApiV1PhotosPut
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof PhotoApiApiV1PhotosPut
     */
    readonly albumId?: number

    /**
     * 
     * @type {string}
     * @memberof PhotoApiApiV1PhotosPut
     */
    readonly description?: string

    /**
     * 
     * @type {number}
     * @memberof PhotoApiApiV1PhotosPut
     */
    readonly sort?: number

    /**
     * 
     * @type {any}
     * @memberof PhotoApiApiV1PhotosPut
     */
    readonly filePreviewUpload?: any
}

/**
 * PhotoApi - object-oriented interface
 * @export
 * @class PhotoApi
 * @extends {BaseAPI}
 */
export class PhotoApi extends BaseAPI {
    /**
     * 
     * @summary Change sort
     * @param {PhotoApiApiV1PhotosChangeSortPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public apiV1PhotosChangeSortPut(requestParameters: PhotoApiApiV1PhotosChangeSortPutRequest = {}, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).apiV1PhotosChangeSortPut(requestParameters.photoChangeSortModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get models by filter
     * @param {PhotoApiApiV1PhotosGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public apiV1PhotosGet(requestParameters: PhotoApiApiV1PhotosGetRequest = {}, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).apiV1PhotosGet(requestParameters.albumId, requestParameters.userId, requestParameters.groupId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {PhotoApiApiV1PhotosIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public apiV1PhotosIdDelete(requestParameters: PhotoApiApiV1PhotosIdDeleteRequest, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).apiV1PhotosIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {PhotoApiApiV1PhotosIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public apiV1PhotosIdGet(requestParameters: PhotoApiApiV1PhotosIdGetRequest, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).apiV1PhotosIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {PhotoApiApiV1PhotosIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public apiV1PhotosIdPatch(requestParameters: PhotoApiApiV1PhotosIdPatchRequest, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).apiV1PhotosIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {PhotoApiApiV1PhotosPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public apiV1PhotosPost(requestParameters: PhotoApiApiV1PhotosPostRequest = {}, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).apiV1PhotosPost(requestParameters.albumId, requestParameters.description, requestParameters.fileUpload, requestParameters.imageUrl, requestParameters.ownerUserId, requestParameters.ownerGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {PhotoApiApiV1PhotosPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public apiV1PhotosPut(requestParameters: PhotoApiApiV1PhotosPutRequest = {}, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).apiV1PhotosPut(requestParameters.id, requestParameters.albumId, requestParameters.description, requestParameters.sort, requestParameters.filePreviewUpload, options).then((request) => request(this.axios, this.basePath));
    }
}
