import React, { FC } from 'react';

import { Text } from '../Text';
import {
  StyledDescriptionItem,
  StyledDescriptionItemContent,
  StyledDescriptionItemLable,
} from './styles';

export interface DescriptionItem {
  label?: string;
}

export const DescriptionItem: FC<DescriptionItem> = ({ label, children }) => (
  <StyledDescriptionItem>
    <StyledDescriptionItemLable>
      {label && (
        <Text color="secondary" component="span">
          {label}:
        </Text>
      )}
    </StyledDescriptionItemLable>
    <StyledDescriptionItemContent>{children}</StyledDescriptionItemContent>
  </StyledDescriptionItem>
);
