import { ArrowDropUp as UpIcon } from '@styled-icons/material-outlined/ArrowDropUp';
import { BackTop } from 'antd';

import { StyledButton } from './styles';

export const BackToTopButton = () => {
  return (
    <BackTop>
      <StyledButton>
        <UpIcon size={40} />
      </StyledButton>
    </BackTop>
  );
};
