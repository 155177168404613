import { MouseEventHandler, forwardRef } from 'react';

import {
  LinkProps,
  To,
  useHref,
  useLinkClickHandler,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';

import { StyledAnchor } from './styles';

interface UniversalLinkProps extends LinkProps {
  external?: boolean;
}

const parsePath = (to: To) => {
  const { origin } = location;

  switch (typeof to) {
    case 'string': {
      const url = new URL(to, origin);

      return url;
    }

    case 'object': {
      const url = new URL(to.pathname ?? '', origin);
      if (to.search) url.search = to.search;
      if (to.hash) url.hash = to.hash;

      return url;
    }

    default:
      throw new Error('Unexpected format');
  }
};

export const RouterLink = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { onClick, replace = false, state, target, to, className, ...rest },
    ref,
  ) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });
    const href = useHref(to);
    const handleRouterLinkClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
    });

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
      onClick?.(event);
      if (!event.defaultPrevented) {
        handleRouterLinkClick(event);
      }
    };

    return (
      <StyledAnchor
        {...rest}
        className={match ? `${className} active` : className}
        href={href}
        ref={ref}
        target={target}
        onClick={handleClick}
      />
    );
  },
);

export const ExternalLink = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ onClick, target, to, ...rest }, ref) => {
    const url = parsePath(to);

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
      onClick?.(event);
    };

    return (
      <StyledAnchor
        {...rest}
        href={url.toString()}
        ref={ref}
        target={target}
        onClick={handleClick}
      />
    );
  },
);

export const UniversalLink = forwardRef<HTMLAnchorElement, UniversalLinkProps>(
  ({ external, ...props }, ref) =>
    external ? (
      <ExternalLink {...props} ref={ref} />
    ) : (
      <RouterLink {...props} ref={ref} />
    ),
);

export * from './styles';
