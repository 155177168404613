import { VFC } from 'react';

import { Close } from '@styled-icons/material-outlined';

import { Post } from 'components/Wall/Post';
import { ModalCloseButton } from 'components/Wall/PostModal/styles';
import { Modal } from 'ui/Modal';

import { PostModalProps } from './types';

export const PostModal: VFC<PostModalProps> = ({
  post,
  visible,
  handleClose,
}) => {
  return (
    <>
      <Modal
        bodyStyle={{ padding: 0 }}
        closable={false}
        footer={false}
        maskStyle={{ backgroundColor: '#ffffffe8' }}
        title=""
        visible={visible}
        width={680}
        onCancel={() => {
          handleClose();
        }}
      >
        <Post data={post} />
      </Modal>
      <ModalCloseButton
        onClick={() => {
          handleClose();
        }}
      >
        <Close size={20} />
      </ModalCloseButton>
    </>
  );
};
