import { VFC, useCallback, useState } from 'react';

import {
  Cancel,
  CheckCircleOutline,
  Info,
} from '@styled-icons/material-outlined';
import { StyledIcon } from '@styled-icons/styled-icon';
import { Modal } from 'antd';

import { useTranslation } from 'services/i18n';
import { Button } from 'ui';

import {
  Actions,
  Header,
  IconContainer,
  ModalPaper,
  Text,
  Title,
} from './styles';

type ConfirmType = 'info' | 'success' | 'warning' | 'danger';

export interface UseConfirmParms<S> {
  type: ConfirmType;
  title: string;
  text: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: (state?: S) => void;
  onCancel?: (state?: S) => void;
}

const iconsMap: Record<ConfirmType, StyledIcon> = {
  info: Info,
  success: CheckCircleOutline,
  warning: Info,
  danger: Cancel,
};

export const useConfirm = <S extends {}>(params: UseConfirmParms<S>) => {
  const { type, title, text, confirmText, cancelText, onConfirm, onCancel } =
    params;
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<S>();

  const Icon = iconsMap[type];

  const handleOpenConfirm = useCallback((s?: S) => {
    setOpen(true);
    setState(s);
  }, []);

  const handleClickConfirm = useCallback(() => {
    if (onConfirm) onConfirm(state);
    setOpen(false);
  }, [onConfirm, state]);

  const handleClickCancel = useCallback(() => {
    if (onCancel) onCancel(state);
    setOpen(false);
  }, [onCancel, state]);

  const Confirm: VFC = useCallback(
    () => (
      <Modal
        centered
        destroyOnClose
        bodyStyle={{ all: 'unset' }}
        closable={false}
        footer={null}
        mask={false}
        visible={open}
        width={400}
      >
        <ModalPaper>
          <Header>
            <IconContainer data-color={type}>
              <Icon size={24} />
            </IconContainer>
            <Title>{title}</Title>
          </Header>
          <Text>{text}</Text>
          <Actions>
            <Button
              color="primary"
              size="small"
              type="submit"
              variant="outlined"
              onClick={handleClickCancel}
            >
              {cancelText ?? t('cancel')}
            </Button>
            <Button
              color="primary"
              size="small"
              type="submit"
              onClick={handleClickConfirm}
            >
              {confirmText ?? t('ok')}
            </Button>
          </Actions>
        </ModalPaper>
      </Modal>
    ),
    [
      Icon,
      cancelText,
      confirmText,
      handleClickCancel,
      handleClickConfirm,
      open,
      t,
      text,
      title,
      type,
    ],
  );

  return {
    Confirm,
    handleOpenConfirm,
  };
};
