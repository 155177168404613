import { VFC } from 'react';

import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { ExpandMore as ExpandMoreIcon } from '@styled-icons/material-outlined/ExpandMore';

import { useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { Dropdown, Menu, MenuItem, UniversalLink } from 'ui';

import { getTopLevelLinks } from '../headerLinks';
import { NavMenuItem } from './styles';

export const TuiLifeMenu: VFC = () => {
  const { pathname } = useLocation();
  const { user } = useUserCredential();
  const { t } = useTranslation('common');

  const { data: tuiLifeLinks = [] } = useQuery(
    ['header', 'links', 'tuilife', user?.country.id],
    () => getTopLevelLinks(user?.country.id as number),
    {
      suspense: false,
    },
  );

  return (
    <Dropdown
      overlay={
        <Menu mode="vertical" selectedKeys={[pathname]}>
          {tuiLifeLinks.map((link) => (
            <MenuItem key={link.path}>
              <UniversalLink
                external={link.external}
                target={link.external ? '_blank' : '_self'}
                to={link.path}
              >
                {t(link.title)}
              </UniversalLink>
            </MenuItem>
          ))}
        </Menu>
      }
      overlayStyle={{ position: 'fixed' }}
    >
      <NavMenuItem>
        {t('tui_life_short')} <ExpandMoreIcon size={24} />
      </NavMenuItem>
    </Dropdown>
  );
};
