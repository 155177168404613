import { Input } from 'antd';
import styled from 'styled-components';

import { IconButton } from 'ui';
import { FormItem } from 'ui/FormItem';

export const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
`;
export const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FormContainer = styled.div`
  padding: 12px 0;
`;

export const CloseButton = styled(IconButton)`
  width: 14px;
  height: 14px;
`;
export const CloseButtonAbsolute = styled(IconButton)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 8px;
  width: 14px;
  height: 14px;
  opacity: 0;
  transition: opacity 0.2s;
`;

export const DeleteButton = styled(IconButton)`
  justify-self: end;
  height: 24px;
  width: 24px;
  border-radius: 20px;
  background-color: white;
  box-shadow: black;
  flex-shrink: 0;
`;
export const StyledInput = styled(Input)`
  border-radius: 8px;
  padding-right: 35px;
`;
export const StyledFormItem = styled(FormItem)`
  position: relative;
  margin: 0;
  &:hover button {
    opacity: 1;
  }
`;
