import styled from 'styled-components';

import { UniversalLink } from 'ui/Link';

export const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  border-right: white;
`;

export const NavItem = styled(UniversalLink)`
  display: flex;
  align-content: center;
  align-items: center;
  padding: 12px 12px 12px 24px;
  transition: all 0.3s;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};

  & > svg {
    color: #4872f2;
    margin-right: 12px;
  }

  &:hover {
    color: #4872f2;
  }
`;
