import React, { FC, useState } from 'react';

import { useMutation } from 'react-query';

import { Input } from 'antd';
import { addDays } from 'date-fns/esm';

import { FormButtons } from 'components/FormButtons';
import { PhotoModel, WallUpdateModel, api } from 'services/api';
import { useIdeaEditor } from 'services/editors/idea/ideaEditorContext';
import { useTranslation } from 'services/i18n';
import { useNotify } from 'services/systemNotify';
import { useWallData } from 'services/wall';
import { Form, ImageUploadBox, Title } from 'ui';

import { MediaFileType } from '../PostEditorContent/types';
import { IdeaDescriptionField } from './IdeaDescriptionField';
import { FormDivider } from './styles';
import { IdeaEditorContentProps } from './types';

const createPost = async (newPost: WallUpdateModel) => {
  const { data } = await api.wall.apiV1WallsPost({
    wallUpdateModel: newPost,
  });
  return data;
};

const updatePost = async (newPost: WallUpdateModel) => {
  const { data } = await api.wall.apiV1WallsPost({
    wallUpdateModel: newPost,
  });
  return data;
};

const mutatePost = (newPost: WallUpdateModel, operation: 'put' | 'post') => {
  return operation === 'put' ? updatePost(newPost) : createPost(newPost);
};

const uploadImage = async (fileUpload: File): Promise<PhotoModel> => {
  const { data } = await api.photo.apiV1PhotosPost({
    fileUpload,
  });
  return data;
};

const deleteImage = async (id: number) => {
  await api.photo.apiV1PhotosIdDelete({
    id,
  });
};

const convertPostedToMedia = (data: PhotoModel | undefined) => {
  if (!data) return null;
  return {
    id: Number(data.id),
    postedFileId: Number(data.postedFileId),
    fileUrl: String(data.fileUrl),
    type: 'image',
    previews: data.previews,
  } as MediaFileType;
};

export const IdeaEditorContent: FC<IdeaEditorContentProps> = ({
  onChangeFocus,
  defaultValues,
  mode = 'create',
}) => {
  const { t } = useTranslation('common');
  const { pushSuccessNotify, pushErrorNotify } = useNotify();
  const { list, setList, forceUpdate } = useWallData();
  const editor = useIdeaEditor();
  const [image, setImage] = useState<MediaFileType | null>(null);
  const [ideaName, setIdeaName] = useState<string>(
    defaultValues?.idea?.name ? defaultValues.idea.name : '',
  );

  const deleteImageMutation = useMutation(
    ({ id }: { id: number }) => deleteImage(id),
    {
      onSuccess: () => {
        setImage(null);
      },
      onError: () => {
        pushErrorNotify(t('post_create_error_notify'));
      },
    },
  );

  const uploadImageMutation = useMutation(
    ({ file }: { file: File }) => uploadImage(file),
    {
      onSuccess: (data: PhotoModel) => {
        setImage(convertPostedToMedia(data));
      },
      onError: () => {
        pushErrorNotify(t('post_create_error_notify'));
      },
    },
  );

  const submitMutation = useMutation(
    ({ newIdea }: { newIdea: WallUpdateModel }) =>
      mutatePost(newIdea, mode === 'create' ? 'post' : 'put'),
    {
      onSuccess: (data) => {
        pushSuccessNotify(t('post_added_notify'));

        if (!setList) return;

        if (mode === 'create' && defaultValues?.id) {
          setList(
            list.map((x) =>
              x.id === defaultValues.id ? { ...x, shares: data.shares } : x,
            ),
          );
        }
        if (mode === 'edit' && defaultValues?.id)
          setList(list.map((x) => (x.id === defaultValues.id ? data : x)));
        else setList([...[data], ...list]);

        forceUpdate();
      },
      onError: () => {
        if (image) deleteImageMutation.mutate({ id: image.id });
        pushErrorNotify(t('post_create_error_notify'));
      },
    },
  );

  const onFinish = async () => {
    const defaultData =
      defaultValues && mode !== 'create' ? { ...defaultValues } : {};

    const attachments = image
      ? [{ photoId: image.id, postedFileId: image.postedFileId }]
      : [];

    const newIdea = {
      defaultData,
      isPublished: true,
      owner: { groupId: 531 },
      postContent: {
        text: editor?.getHTML() ?? '',
        postContentAttachments: attachments,
      },
      idea: {
        isByDepartment: false,
        isWantToParticipate: false,
        voteEndDate: addDays(new Date(), 15).toISOString(),
        status: 1,
        ...defaultValues?.idea,
        name: ideaName,
      },
      wallContentType: 2,
    };

    onChangeFocus();
    submitMutation.mutate({ newIdea });
  };

  const onAttach = async (file: File) => {
    if (image) await deleteImageMutation.mutateAsync({ id: image.id });
    await uploadImageMutation.mutateAsync({ file });
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <>
        <Title variant="h4">{t('title_idea')}</Title>
        <Input
          placeholder={t('any_new_ideas')}
          style={{ borderRadius: '8px', marginBottom: '12px' }}
          type="text"
          value={ideaName}
          onChange={(e) => setIdeaName(e.target.value)}
        />
        <IdeaDescriptionField />
        <div style={{ height: '140px' }}>
          <ImageUploadBox
            src={
              defaultValues?.postContent?.postContentAttachments?.find(
                (x) => x.photoId,
              )?.postedFile?.fileUrl ?? undefined
            }
            text={t('present_your_idea')}
            onAttach={onAttach}
          />
        </div>
        <FormDivider />
        <FormButtons handleCancel={onChangeFocus} submitText={t('publish')} />
      </>
    </Form>
  );
};
