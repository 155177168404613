/* eslint-disable react/no-danger */
import { VFC, memo, useEffect, useState } from 'react';

import { ChevronDoubleUp } from '@styled-icons/heroicons-outline';
import { nanoid } from 'nanoid';

import { useTranslation } from 'services/i18n';
import { DEFAULT_POST_STATE, PostState, usePostContext } from 'services/post';

import {
  CollapseIconLink,
  StyledLastNodeContainer,
  StyledLink,
} from './styles';
import { FontParams } from './types';
import { cutHTML, domToHTML, htmlToDOM, measurer, trimRight } from './utils';

export interface EllipsisProps {
  html: string;
  expectingHeight: number;
  expectingWidth: number;
  fontParams?: FontParams;
  expandable?: boolean;
}

export const Ellipsis: VFC<EllipsisProps> = memo(
  ({
    html,
    expectingHeight,
    expectingWidth,
    fontParams,
    expandable = true,
  }) => {
    const { postState, savePostState } = usePostContext();
    const [expanded, setExpanded] = useState(!!postState?.isContentExpanded);
    const [cutContent, setCutContent] = useState<(HTMLElement | Text)[] | null>(
      postState?.cutContent?.html
        ? htmlToDOM(postState?.cutContent?.html)
        : null,
    );
    const { t } = useTranslation('common');

    useEffect(() => {
      if (postState) {
        const newPostState: PostState = {
          ...(postState as PostState),
          isContentExpanded: expanded,
        };
        savePostState(newPostState);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    useEffect(() => {
      if (postState) {
        setExpanded(!!postState.isContentExpanded);
        setCutContent(
          postState.cutContent?.html
            ? htmlToDOM(String(postState.cutContent.html))
            : null,
        );
      }
    }, [postState]);

    useEffect(() => {
      if (html && expectingHeight && expectingWidth) {
        if (
          postState === null ||
          (postState &&
            Math.abs((postState.cutContent?.width || 0) - expectingWidth) > 10)
        ) {
          const { height } = measurer({
            markup: html,
            expectingWidth,
            fontParams,
          });
          if (height > expectingHeight) {
            const res = cutHTML({
              html,
              expectingWidth,
              expectingHeight,
              fontParams,
            });

            setCutContent(res.cut);
            if (savePostState instanceof Function) {
              const newPostState: PostState = {
                ...(postState || DEFAULT_POST_STATE),
                cutContent: { html: domToHTML(res.cut), width: expectingWidth },
              };
              savePostState(newPostState);
            }
          }
        } else if (postState && postState.cutContent) {
          setCutContent(htmlToDOM(postState.cutContent.html));
        } else setCutContent(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [html, expectingHeight, expectingWidth]);

    const expandLink = (
      <StyledLink
        onClick={() => {
          setExpanded(true);
        }}
      >
        {t('more')}
      </StyledLink>
    );

    const collapseLink = (
      <CollapseIconLink
        onClick={() => {
          setExpanded(false);
        }}
      >
        <ChevronDoubleUp />
        <span>{t('show_less_info')}</span>
      </CollapseIconLink>
    );

    return (
      <>
        {/* если весь помещается по высоте или текст развернут */}
        {(expanded || cutContent === null) && (
          <>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            {cutContent !== null && cutContent.length > 0 && collapseLink}
          </>
        )}

        {/* если есть урезаный контент и текст свернут */}
        {!expanded &&
          Number(cutContent?.length) > 0 &&
          cutContent?.map((el, i, arr) => {
            if (el.nodeType === 3) {
              return (
                <span key={nanoid()}>
                  {i === arr.length - 1
                    ? `${trimRight(String(el.textContent), true)}...`
                    : el.textContent}
                  {i === arr.length - 1 && expandable && expandLink}
                </span>
              );
            }
            if (el.nodeType === 1) {
              if (i === arr.length - 1) {
                return (
                  <StyledLastNodeContainer>
                    <p
                      key={nanoid()}
                      dangerouslySetInnerHTML={{
                        __html: `${trimRight(
                          (el as HTMLElement).innerHTML,
                          true,
                        )}...`,
                      }}
                    />
                    {expandable && expandLink}
                  </StyledLastNodeContainer>
                );
              }
              return (
                <p
                  key={nanoid()}
                  dangerouslySetInnerHTML={{
                    __html: (el as HTMLElement).innerHTML,
                  }}
                />
              );
            }
            return null;
          })}
      </>
    );
  },
);
