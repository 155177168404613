import {
  NotificationPreviewModel,
  NotificationType,
  UserNotificationTypeModel,
} from 'services/api';
import { i18n } from 'services/i18n';

export type NotifyPeriod = 1 | 2 | 3 | 4 | 5;

export enum NotificationTypes {
  Push = 1,
  LotteryNew = 101,
  LotteryWin,
  LotteryEnd,
  PostNew = 110,
  WallRequestNew = 111,
  Congrats = 120,
  BirthDaySubscriberNew,
  BirthDaySubordinateNew,
  IdeaNew = 130,
  IdeaStatusChanged,
  CommentNew = 201,
  WallMentionNew = 202,
  GroupRequestNew = 301,
  GroupRequestAccepted,
  GroupRequestRejected,
  GroupRequestSent,
  GroupMemberRequestNew = 310,
  GroupMemberRequestAccepted = 311,
  GroupMemberRequestRejected = 312,
  UserDiscoveryStepRequestNew = 401,
  CoinsNew = 501,
  ToDoItemSoon = 601,
}

const notifyPeriodToString = (
  notifyPeriod: NotifyPeriod | null | undefined,
) => {
  switch (notifyPeriod) {
    case 1:
      return i18n.t('common:in_some_minutes', { min: 15 });
    case 2:
      return i18n.t('common:in_some_minutes', { min: 30 });
    case 3:
      return i18n.t('common:in_some_hours', { hour: 1 });
    case 4:
      return i18n.t('common:in_some_hours', { hour: 3 });
    case 5:
      return i18n.t('common:in_some_days', { day: 1 });
    default:
      return '';
  }
};

export const notificationRepresentation = (
  notify: NotificationPreviewModel,
) => {
  switch (notify.notificationType as number) {
    case NotificationTypes.Push:
      return i18n.t('common:push_notif_is_mand');
    case NotificationTypes.LotteryNew:
      return i18n.t('common:new_raffle');
    case NotificationTypes.LotteryWin:
      return i18n.t('common:user_raffle_wins');
    case NotificationTypes.LotteryEnd:
      return i18n.t('common:raffle_is_over');
    case NotificationTypes.PostNew:
      return i18n.t('common:new_post');
    case NotificationTypes.WallRequestNew:
      return i18n.t('common:new_proposed_post');
    case NotificationTypes.WallMentionNew:
      return i18n.t('common:wall_mention_new');
    case NotificationTypes.Congrats:
      return i18n.t('common:you_were_congratulated');
    case NotificationTypes.BirthDaySubscriberNew:
      return `${i18n.t('common:user_celeb_birthday_react', {
        user: notify.byUser?.name,
      })}!`;
    case NotificationTypes.BirthDaySubordinateNew:
      return i18n.t('common:subordinate_birthday_comes');
    case NotificationTypes.IdeaNew:
      return i18n.t('common:new_idea');
    case NotificationTypes.IdeaStatusChanged:
      return i18n.t('common:idea_updated_status');
    case NotificationTypes.CommentNew:
      return i18n.t('common:new_comment');
    case NotificationTypes.GroupRequestNew:
      return i18n.t('common:request_create_group');
    case NotificationTypes.GroupRequestSent:
      return i18n.t('common:new_group_request_sent');
    case NotificationTypes.GroupRequestAccepted:
      return i18n.t('common:new_group_created');
    case NotificationTypes.GroupRequestRejected:
      return i18n.t('common:group_create_reject');
    case NotificationTypes.GroupMemberRequestNew:
      return i18n.t('common:subs_request_priv_group');
    case NotificationTypes.GroupMemberRequestAccepted:
      return i18n.t('common:group_member_request_accepted');
    case NotificationTypes.GroupMemberRequestRejected:
      return i18n.t('common:group_member_request_rejected');
    case NotificationTypes.UserDiscoveryStepRequestNew:
      return i18n.t('common:step_confirmation_request');
    case NotificationTypes.CoinsNew:
      return i18n.t('common:coins_credited_you');
    case NotificationTypes.ToDoItemSoon: {
      return i18n.t('common:deal_end_react', {
        time: notifyPeriodToString(notify.toDoItem?.notifyPeriod),
        name: notify.toDoItem?.name,
      });
    }
    default:
      return i18n.t('common:unknown_notif_type');
  }
};

export const notificationTypeRepresentation = (
  n: UserNotificationTypeModel,
) => {
  switch (n.notificationType as number) {
    case NotificationTypes.Push:
      return i18n.t('common:push_notif_is_mand');
    case NotificationTypes.LotteryNew:
      return i18n.t('common:new_raffle');
    case NotificationTypes.LotteryWin:
      return i18n.t('common:you_won_the_lottery');
    case NotificationTypes.LotteryEnd:
      return i18n.t('common:the_raffle_is_over');
    case NotificationTypes.PostNew:
      return i18n.t('common:new_post');
    case NotificationTypes.WallRequestNew:
      return i18n.t('common:new_proposed_post');
    case NotificationTypes.Congrats:
      return i18n.t('common:you_were_congratulated');
    case NotificationTypes.BirthDaySubscriberNew:
      return i18n.t('common:colleague_celeb_birthday');
    case NotificationTypes.BirthDaySubordinateNew:
      return i18n.t('common:subordinate_birthday_comes');
    case NotificationTypes.IdeaNew:
      return i18n.t('common:new_idea');
    case NotificationTypes.IdeaStatusChanged:
      return i18n.t('common:idea_updated_status');
    case NotificationTypes.CommentNew:
      return i18n.t('common:new_comment');
    case NotificationTypes.GroupRequestNew:
      return i18n.t('common:request_create_group');
    case NotificationTypes.GroupRequestAccepted:
      return i18n.t('common:new_group_created');
    case NotificationTypes.GroupRequestRejected:
      return i18n.t('common:refusal_create_group');
    case NotificationTypes.GroupMemberRequestNew:
      return i18n.t('common:subs_request_priv_group');
    case NotificationTypes.GroupMemberRequestAccepted:
      return i18n.t('common:group_member_request_accepted');
    case NotificationTypes.GroupMemberRequestRejected:
      return i18n.t('common:group_member_request_rejected');
    case NotificationTypes.UserDiscoveryStepRequestNew:
      return i18n.t('common:step_confirmation_request');
    case NotificationTypes.CoinsNew:
      return i18n.t('common:coins_credited_you');
    case NotificationTypes.ToDoItemSoon:
      return i18n.t('common:reminder_coming_task');
    default:
      return i18n.t('common:unknown_notif_type');
  }
};
export const notificationRoute = (
  type: NotificationType | undefined,
  wallId: number | null | undefined,
  userId: number | null | undefined,
  groupId: number | null | undefined,
) => {
  if (type) {
    switch (type) {
      case NotificationTypes.GroupRequestNew as number:
        return '/management/new-groups';
      case NotificationTypes.GroupRequestAccepted as number:
        return `/group/${groupId?.toString()}`;
      case NotificationTypes.GroupMemberRequestNew as number:
        return `/group/${groupId?.toString()}/members`;
      case NotificationTypes.CoinsNew as number:
        return `/profile/${userId?.toString()}`;
      case NotificationTypes.BirthDaySubscriberNew as number:
        return `/birthcongrats/${userId?.toString()}`;
      case NotificationTypes.BirthDaySubordinateNew as number:
        return `/pages/2`;
      case NotificationTypes.UserDiscoveryStepRequestNew as number:
        return `/discovery/make-your-world-bigger/${userId?.toString()}`;
      default:
        if (wallId) {
          return `/posts/${wallId.toString()}`;
        }
        if (userId) {
          return `/profile/${userId.toString()}`;
        }
        if (groupId) {
          return `/group/${groupId.toString()}`;
        }
    }
  }
  return '';
};
