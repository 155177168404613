import { ComponentPropsWithRef } from 'react';

import styled, { css } from 'styled-components';

import { StyledContainerProps, StyledTimeContainerProps } from './types';

export type BaseInputProps = ComponentPropsWithRef<'input'>;

const mapSizes = {
  large: {
    time: css`
      height: 28px;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    `,
    text: css`
      height: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    `,
  },
  default: {
    time: css`
      height: 20px;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
    `,
    text: css`
      height: 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    `,
  },
};

export const StyledCalendarEventRoot = styled.div`
  cursor: pointer;
  display: flex;
  min-width: 0;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledTimeContainer = styled.div<StyledTimeContainerProps>`
  color: ${({ variant }) => variant.color};
  background-color: ${({ variant }) => variant.background};
  border-radius: 4px;
  padding: 4px;

  ${({ size = 'default' }) => mapSizes[size].time}
`;

export const StyledLabel = styled.div<StyledContainerProps>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;

  ${({ size = 'default' }) => mapSizes[size].text}
`;
