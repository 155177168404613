import {
  AuthorModel,
  SocialType,
  UserModel,
  UserPreviewModel,
  UserProfileModel,
} from 'services/api';
import { CountryId } from 'services/i18n';

export type AnyUser = UserModel | AuthorModel;

export type UserProfile = Required<DeepRequired<UserProfileModel>>;
export type UserPreview = Required<DeepRequired<UserPreviewModel>>;

export type SocialUrl = {
  url?: string;
  socialType?: SocialType;
};

export const createMonogram = (user: UserProfile | UserPreview) =>
  `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`;

export const getUserCountryShort = (
  user: UserProfileModel | UserPreviewModel | AuthorModel,
) => {
  if (!user.country?.id) return 'GLB';

  return CountryId[user.country.id];
};
