import { useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import { LabeledValue } from 'antd/es/select';

import { WallPrivacy, WallUpdateModel } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useNotify } from 'services/systemNotify';
import { useWallData } from 'services/wall';

import { mutatePost } from '../api';
import { getCoordinates } from '../lib';
import { PostEditorContentProps } from '../types';
import { useAttachements } from './useAttachements';
import { useEditorWithOg } from './useEditor';
import { usePollMethods } from './usePollMethods';
import { usePostOwner } from './usePostOwner';

export const useLocalModel = (args: PostEditorContentProps) => {
  const { defaultValues, mode, owner, postponePublish, onChangeFocus } = args;
  const { list, setList, forceUpdate } = useWallData();
  const { t } = useTranslation('common');
  const { pushSuccessNotify, pushErrorNotify } = useNotify();

  console.log('defaultValues', defaultValues);
  const client = useQueryClient();

  const postOwnerMethods = usePostOwner({ owner });
  const postEditorMethods = useEditorWithOg({ defaultValues });
  const attachementMethods = useAttachements(args);
  const { getCleanedForm, ...pollMethods } = usePollMethods(
    args.defaultValues?.poll,
  );

  const [geoPickerPos, setGeoPickerPos] = useState<LabeledValue | null>(null);

  const [wallPrivacy, setWallPrivacy] = useState<WallPrivacy>(
    WallPrivacy.Public,
  );

  const submitMutation = useMutation(
    (newPost: WallUpdateModel) =>
      mutatePost(newPost, mode === 'create' ? 'post' : 'put'),
    {
      onSuccess: async (data) => {
        await attachementMethods.clearBufferOnSubmit();

        pushSuccessNotify(t('post_added_notify'));
        client.invalidateQueries(['profile', 'widgets']);
        client.invalidateQueries(['group', 'widgets']);

        onChangeFocus();
        forceUpdate();

        if (!setList || postponePublish) return;

        if (mode === 'create' && defaultValues?.id) {
          setList(
            list.map((x) =>
              x.id === defaultValues.id ? { ...x, shares: data.shares } : x,
            ),
          );
        }
        if (mode === 'edit' && defaultValues?.id)
          setList(list.map((x) => (x.id === defaultValues.id ? data : x)));
        else setList([data, ...list]);
      },
      onError: () => {
        pushErrorNotify(t('post_create_error_notify'));
      },
    },
  );

  const handleFormSubmit = async () => {
    const defaultData =
      defaultValues && mode !== 'create'
        ? { ...defaultValues }
        : { owner: postOwnerMethods.currentOwner, replyId: defaultValues?.id };

    const newPost: WallUpdateModel = {
      ...defaultData,
      ...getCoordinates(geoPickerPos),
      isPublished: !postponePublish,
      postContent: {
        text: postEditorMethods.editor?.getHTML(),
        postContentAttachments: [
          ...attachementMethods.mediaFilesList.map((media) =>
            media.type === 'video'
              ? { videoId: media.id, postedFileId: media.postedFileId }
              : { photoId: media.id, postedFileId: media.postedFileId },
          ),
          ...attachementMethods.documents.map((doc) => ({
            documentId: doc.id,
            postedFileId: doc.postedFileId,
          })),
        ],
        openGraphItem: postEditorMethods.openGraph,
      },
      poll: pollMethods.pollForm ? getCleanedForm() : undefined,
      wallContentType: 1,
      wallPrivacy,
    };

    submitMutation.mutate(newPost);
  };

  const handleWallPrivacyChange = (privacy: number) => {
    setWallPrivacy(privacy as WallPrivacy);
  };

  return {
    ...attachementMethods,
    ...postOwnerMethods,
    ...postEditorMethods,
    ...pollMethods,
    geoPickerPos,
    setGeoPickerPos,
    handleWallPrivacyChange,
    handleFormSubmit,
    isSubmitting: submitMutation.isLoading,
  };
};
