import { VFC, useEffect } from 'react';

import { useMutation, useQuery } from 'react-query';

import { Spin } from 'antd';

import { PushWallQueryParams } from 'components/PostPushModal/types';
import { PostContent } from 'components/Wall/Post/PostContent';
import { PostMeta } from 'components/Wall/Post/PostMeta';
import { PostHeader, PostMain } from 'components/Wall/Post/styles';
import { api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useNotify } from 'services/systemNotify';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';

import { SpinContainer, StyledActions } from './styles';

type PostPushReadModalProps = {
  notificationId: number;
  postId: number;
  visible: boolean;
  handleRead: () => void;
};

export const getPostQuery = async ({ postId, signal }: PushWallQueryParams) => {
  const { data } = await api.wall.apiV1WallsIdGet(
    {
      id: postId,
    },
    { signal },
  );

  return data;
};

const mutatePushNotificationRead = async (notificationId: number) => {
  await api.notification.apiV1NotificationsPut({
    notificationUpdateModel: {
      id: notificationId,
      isRead: true,
    },
  });
};

export const PostPushReadModal: VFC<PostPushReadModalProps> = ({
  postId,
  notificationId,
  visible,
  handleRead,
}) => {
  const { t } = useTranslation('common');
  const { pushErrorNotify } = useNotify();

  const handleClose = () => {
    handleRead();
  };

  const { data, isLoading, remove } = useQuery(
    ['post', 'push', 'read', postId],
    () =>
      getPostQuery({
        postId,
      }),
    {
      suspense: false,
    },
  );

  useEffect(
    () => () => {
      remove();
    },
    [remove],
  );

  const readMutation = useMutation(mutatePushNotificationRead, {
    onSuccess: () => {
      handleClose();
    },
    onError: () => {
      pushErrorNotify(t('something_went_wrong'));
    },
  });

  const handleReadPushNotification = () => {
    readMutation.mutate(notificationId);
  };

  return (
    <Modal
      destroyOnClose
      bodyStyle={{ padding: 0, paddingTop: 20 }}
      closable={false}
      footer={false}
      title={t('new_push_notification')}
      visible={visible}
      width={680}
    >
      {data && (
        <>
          <PostMain>
            <PostHeader>
              <PostMeta postMetaData={data} />
            </PostHeader>

            {data && <PostContent data={data} />}
          </PostMain>
          <StyledActions>
            <Button color="primary" onClick={handleReadPushNotification}>
              {t('read')}
            </Button>
          </StyledActions>
        </>
      )}
      {isLoading && (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      )}
    </Modal>
  );
};
