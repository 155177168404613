import { Context, FC, createContext, useContext } from 'react';

import { Editor } from '@tiptap/core';

import {
  IdeaEditorServiceProps,
  useIdeaEditorService,
} from './useIdeaEditorService';

const IdeaEditorContext = createContext<Editor | null>(null);

export const useIdeaEditor = (): Editor | null =>
  useContext(IdeaEditorContext as Context<Editor | null>);

export const IdeaEditorProvider: FC<IdeaEditorServiceProps> = ({
  placeholder,
  text,
  children,
}) => {
  const IdeaEditor = useIdeaEditorService({ placeholder, text });

  return (
    <IdeaEditorContext.Provider value={IdeaEditor}>
      {children}
    </IdeaEditorContext.Provider>
  );
};
