/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ToDoItemModel } from '../models';
// @ts-ignore
import { ToDoItemUpdateModel } from '../models';
// @ts-ignore
import { ToDoItemsModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * ToDoItemApi - axios parameter creator
 * @export
 */
export const ToDoItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [isCompleted] Фильтр по выполненным
         * @param {string} [deadlineFrom] Фильтр по дедлайну
         * @param {string} [deadlineTo] Фильтр по дедлайну
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsGet: async (userId?: number, isArchived?: boolean, isCompleted?: boolean, deadlineFrom?: string, deadlineTo?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/todoitems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['IsArchived'] = isArchived;
            }

            if (isCompleted !== undefined) {
                localVarQueryParameter['IsCompleted'] = isCompleted;
            }

            if (deadlineFrom !== undefined) {
                localVarQueryParameter['DeadlineFrom'] = (deadlineFrom as any instanceof Date) ?
                    (deadlineFrom as any).toISOString() :
                    deadlineFrom;
            }

            if (deadlineTo !== undefined) {
                localVarQueryParameter['DeadlineTo'] = (deadlineTo as any instanceof Date) ?
                    (deadlineTo as any).toISOString() :
                    deadlineTo;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1TodoitemsIdDelete', 'id', id)
            const localVarPath = `/api/v1/todoitems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1TodoitemsIdGet', 'id', id)
            const localVarPath = `/api/v1/todoitems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1TodoitemsIdPatch', 'id', id)
            const localVarPath = `/api/v1/todoitems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {ToDoItemUpdateModel} [toDoItemUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsPost: async (toDoItemUpdateModel?: ToDoItemUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/todoitems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toDoItemUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {ToDoItemUpdateModel} [toDoItemUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsPut: async (toDoItemUpdateModel?: ToDoItemUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/todoitems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toDoItemUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ToDoItemApi - functional programming interface
 * @export
 */
export const ToDoItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ToDoItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [isCompleted] Фильтр по выполненным
         * @param {string} [deadlineFrom] Фильтр по дедлайну
         * @param {string} [deadlineTo] Фильтр по дедлайну
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TodoitemsGet(userId?: number, isArchived?: boolean, isCompleted?: boolean, deadlineFrom?: string, deadlineTo?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ToDoItemsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TodoitemsGet(userId, isArchived, isCompleted, deadlineFrom, deadlineTo, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TodoitemsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TodoitemsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TodoitemsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ToDoItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TodoitemsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TodoitemsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ToDoItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TodoitemsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {ToDoItemUpdateModel} [toDoItemUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TodoitemsPost(toDoItemUpdateModel?: ToDoItemUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ToDoItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TodoitemsPost(toDoItemUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {ToDoItemUpdateModel} [toDoItemUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TodoitemsPut(toDoItemUpdateModel?: ToDoItemUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ToDoItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TodoitemsPut(toDoItemUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ToDoItemApi - factory interface
 * @export
 */
export const ToDoItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ToDoItemApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [isCompleted] Фильтр по выполненным
         * @param {string} [deadlineFrom] Фильтр по дедлайну
         * @param {string} [deadlineTo] Фильтр по дедлайну
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsGet(userId?: number, isArchived?: boolean, isCompleted?: boolean, deadlineFrom?: string, deadlineTo?: string, count?: number, offset?: number, options?: any): AxiosPromise<ToDoItemsModel> {
            return localVarFp.apiV1TodoitemsGet(userId, isArchived, isCompleted, deadlineFrom, deadlineTo, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TodoitemsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsIdGet(id: number, options?: any): AxiosPromise<ToDoItemModel> {
            return localVarFp.apiV1TodoitemsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<ToDoItemModel> {
            return localVarFp.apiV1TodoitemsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {ToDoItemUpdateModel} [toDoItemUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsPost(toDoItemUpdateModel?: ToDoItemUpdateModel, options?: any): AxiosPromise<ToDoItemModel> {
            return localVarFp.apiV1TodoitemsPost(toDoItemUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {ToDoItemUpdateModel} [toDoItemUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TodoitemsPut(toDoItemUpdateModel?: ToDoItemUpdateModel, options?: any): AxiosPromise<ToDoItemModel> {
            return localVarFp.apiV1TodoitemsPut(toDoItemUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TodoitemsGet operation in ToDoItemApi.
 * @export
 * @interface ToDoItemApiApiV1TodoitemsGetRequest
 */
export interface ToDoItemApiApiV1TodoitemsGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof ToDoItemApiApiV1TodoitemsGet
     */
    readonly userId?: number

    /**
     * Фильтр по архивированным
     * @type {boolean}
     * @memberof ToDoItemApiApiV1TodoitemsGet
     */
    readonly isArchived?: boolean

    /**
     * Фильтр по выполненным
     * @type {boolean}
     * @memberof ToDoItemApiApiV1TodoitemsGet
     */
    readonly isCompleted?: boolean

    /**
     * Фильтр по дедлайну
     * @type {string}
     * @memberof ToDoItemApiApiV1TodoitemsGet
     */
    readonly deadlineFrom?: string

    /**
     * Фильтр по дедлайну
     * @type {string}
     * @memberof ToDoItemApiApiV1TodoitemsGet
     */
    readonly deadlineTo?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof ToDoItemApiApiV1TodoitemsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof ToDoItemApiApiV1TodoitemsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1TodoitemsIdDelete operation in ToDoItemApi.
 * @export
 * @interface ToDoItemApiApiV1TodoitemsIdDeleteRequest
 */
export interface ToDoItemApiApiV1TodoitemsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ToDoItemApiApiV1TodoitemsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1TodoitemsIdGet operation in ToDoItemApi.
 * @export
 * @interface ToDoItemApiApiV1TodoitemsIdGetRequest
 */
export interface ToDoItemApiApiV1TodoitemsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ToDoItemApiApiV1TodoitemsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1TodoitemsIdPatch operation in ToDoItemApi.
 * @export
 * @interface ToDoItemApiApiV1TodoitemsIdPatchRequest
 */
export interface ToDoItemApiApiV1TodoitemsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof ToDoItemApiApiV1TodoitemsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof ToDoItemApiApiV1TodoitemsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1TodoitemsPost operation in ToDoItemApi.
 * @export
 * @interface ToDoItemApiApiV1TodoitemsPostRequest
 */
export interface ToDoItemApiApiV1TodoitemsPostRequest {
    /**
     * 
     * @type {ToDoItemUpdateModel}
     * @memberof ToDoItemApiApiV1TodoitemsPost
     */
    readonly toDoItemUpdateModel?: ToDoItemUpdateModel
}

/**
 * Request parameters for apiV1TodoitemsPut operation in ToDoItemApi.
 * @export
 * @interface ToDoItemApiApiV1TodoitemsPutRequest
 */
export interface ToDoItemApiApiV1TodoitemsPutRequest {
    /**
     * 
     * @type {ToDoItemUpdateModel}
     * @memberof ToDoItemApiApiV1TodoitemsPut
     */
    readonly toDoItemUpdateModel?: ToDoItemUpdateModel
}

/**
 * ToDoItemApi - object-oriented interface
 * @export
 * @class ToDoItemApi
 * @extends {BaseAPI}
 */
export class ToDoItemApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {ToDoItemApiApiV1TodoitemsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoItemApi
     */
    public apiV1TodoitemsGet(requestParameters: ToDoItemApiApiV1TodoitemsGetRequest = {}, options?: AxiosRequestConfig) {
        return ToDoItemApiFp(this.configuration).apiV1TodoitemsGet(requestParameters.userId, requestParameters.isArchived, requestParameters.isCompleted, requestParameters.deadlineFrom, requestParameters.deadlineTo, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {ToDoItemApiApiV1TodoitemsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoItemApi
     */
    public apiV1TodoitemsIdDelete(requestParameters: ToDoItemApiApiV1TodoitemsIdDeleteRequest, options?: AxiosRequestConfig) {
        return ToDoItemApiFp(this.configuration).apiV1TodoitemsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {ToDoItemApiApiV1TodoitemsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoItemApi
     */
    public apiV1TodoitemsIdGet(requestParameters: ToDoItemApiApiV1TodoitemsIdGetRequest, options?: AxiosRequestConfig) {
        return ToDoItemApiFp(this.configuration).apiV1TodoitemsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {ToDoItemApiApiV1TodoitemsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoItemApi
     */
    public apiV1TodoitemsIdPatch(requestParameters: ToDoItemApiApiV1TodoitemsIdPatchRequest, options?: AxiosRequestConfig) {
        return ToDoItemApiFp(this.configuration).apiV1TodoitemsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {ToDoItemApiApiV1TodoitemsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoItemApi
     */
    public apiV1TodoitemsPost(requestParameters: ToDoItemApiApiV1TodoitemsPostRequest = {}, options?: AxiosRequestConfig) {
        return ToDoItemApiFp(this.configuration).apiV1TodoitemsPost(requestParameters.toDoItemUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {ToDoItemApiApiV1TodoitemsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToDoItemApi
     */
    public apiV1TodoitemsPut(requestParameters: ToDoItemApiApiV1TodoitemsPutRequest = {}, options?: AxiosRequestConfig) {
        return ToDoItemApiFp(this.configuration).apiV1TodoitemsPut(requestParameters.toDoItemUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
