import { VFC } from 'react';

import { Picker } from 'emoji-mart';

import { EmojiPickerWrapper } from './styles';
import { EmojiPickerProps } from './types';

export const EmojiPicker: VFC<EmojiPickerProps> = ({
  onEmojiClick,
  setIsEmojiVisible,
  className = '',
}) => {
  const hideEmojiPicker = () => {
    setTimeout(() => setIsEmojiVisible?.(false), 300);
  };

  return (
    <EmojiPickerWrapper
      withoutPositioning
      className={`emoji-picker_wrapper ${className}`}
      onMouseLeave={hideEmojiPicker}
    >
      <Picker
        emojiSize={20}
        emojiTooltip={false}
        set="google"
        sheetSize={64}
        showPreview={false}
        showSkinTones={false}
        onSelect={onEmojiClick}
      />
    </EmojiPickerWrapper>
  );
};
