/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum NotificationType {
    PushNotification = 1,
    WallLotteryNew = 101,
    WallLotteryWinner = 102,
    WallLotteryEnded = 103,
    WallNew = 110,
    WallRequestNew = 111,
    WallGiftNew = 120,
    BirthDaySubscriberNew = 121,
    BirthDaySubordinateNew = 122,
    WallIdeaNew = 130,
    WallIdeaStatusChanged = 131,
    WallCommentNew = 201,
    WallMentionNew = 202,
    GroupRequestNew = 301,
    GroupRequestAccepted = 302,
    GroupRequestRejected = 303,
    GroupRequestSent = 304,
    GroupMemberRequestNew = 310,
    GroupMemberRequestAccepted = 311,
    GroupMemberRequestRejected = 312,
    UserDiscoveryStepRequestNew = 401,
    CoinsNew = 501,
    ToDoItemSoon = 601
}


