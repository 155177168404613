export type Lang = 'rus' | 'eng';

export interface ExistLettersCountry {
  id: number;
  data: string[];
}

export interface AlphabetFilterProps {
  existLetters?: ExistLettersCountry[];
  searchUrlKey: string;
  countrySearchKey: string;
  isLoadingAlphabet?: boolean;
}

export enum CountryLanguage {
  country_0 = 'rus',
  country_1 = 'rus',
  country_2 = 'rus',
  country_3 = 'eng',
  country_4 = 'eng',
  country_5 = 'eng',
}
