import React, { FC } from 'react';

interface CompletedIconProps {
  size?: number;
}

export const CompletedIcon: FC<CompletedIconProps> = ({ size }) => {
  const width = size ?? 24;
  const height = size ?? 24;

  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#4872F2" height="22" rx="6" width="22" />
      <path
        clipRule="evenodd"
        d="M17.3095 6.81858C17.6907 7.19495 17.6944 7.80921 17.3177 8.19015L9.97562 15.6149L5.35859 11.3102C4.95892 10.9375 4.93845 10.3064 5.30505 9.90115C5.68241 9.48404 6.33576 9.45311 6.74291 9.8412L9.95712 12.905L15.9374 6.82849C16.3134 6.44648 16.928 6.44204 17.3095 6.81858Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
