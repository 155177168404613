import styled from 'styled-components';

export const NavMenu = styled.nav`
  display: flex;
  white-space: nowrap;
  margin: 0 auto;

  & > :not(:first-child) {
    margin-left: 24px;
  }
`;

export const NavMenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &.active {
    color: ${({ theme }) => theme.palette.primary.main};
    pointer-events: none;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
  }
  & > svg {
    color: ${({ theme }) => theme.palette.gray[400]};
  }
`;
