import { VFC } from 'react';

import { useTranslation } from 'services/i18n';
import { Modal } from 'ui/Modal';

import { ChangeCountryModalForm } from './ChangeCountryModalForm';

type ChangeCountryModalProps = {
  open: boolean;
  onClose?: () => void;
};

export const ChangeCountryModal: VFC<ChangeCountryModalProps> = (props) => {
  const { open, onClose } = props;
  const { t } = useTranslation('common');

  return (
    <Modal
      destroyOnClose
      bodyStyle={{ paddingTop: 20 }}
      footer={false}
      title={t('choose_country')}
      visible={open}
      width={480}
      onCancel={onClose}
    >
      <ChangeCountryModalForm onCancel={onClose} onSave={onClose} />
    </Modal>
  );
};
