import { ComponentPropsWithRef } from 'react';

import styled from 'styled-components';

export type AppBarProps = ComponentPropsWithRef<'header'>;

export const AppBar = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  line-height: 100%;
  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  z-index: 1000;
`;
