import React, { useMemo, useState } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Spin } from 'antd';

import { AdminSidebarMenu } from 'components/AdminSidebarMenu';
import { HrSidebarMenu } from 'components/HrSidebarMenu';
import { useConfirm } from 'hooks/useConfirm';
import { api } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useNotify } from 'services/systemNotify';
import { useYandexMetrikaPageview } from 'services/yandexMetrika';
import { Col, List, Paper, Row } from 'ui/index';
import { Title } from 'ui/Title';

import { SpinContainer } from '../../SearchResultsPage/styles';
import { PushManagementItem } from './PushManagementItem';

const getPushNotifications = async (count: number, offset: number) => {
  const { data } = await api.pushNotification.apiV1PushNotificationsGet({
    offset,
    count,
  });

  return data;
};

const deletePushNotification = async (id: number) => {
  await api.pushNotification.apiV1PushNotificationsIdDelete({
    id,
  });

  return id;
};

export const PushManagementPage: React.FC = () => {
  useYandexMetrikaPageview();

  const { t } = useTranslation('common');
  const { pushSuccessNotify } = useNotify();
  const [offset, setOffset] = useState(10);
  const COUNT_STEP = 10;
  const client = useQueryClient();

  const { data: notifications, isLoading } = useQuery(
    ['pushNotificationManagement'],
    () => getPushNotifications(COUNT_STEP, 0),
    {
      suspense: false,
    },
  );

  const { mutate: onLoadMore, isError } = useMutation(
    () => getPushNotifications(COUNT_STEP, offset),
    {
      onSuccess: (newData) => {
        setOffset((prevState) => prevState + COUNT_STEP);
        notifications?.items?.push(...(newData.items ?? []));
      },
    },
  );

  const deleteMutation = useMutation(
    (id: number) => deletePushNotification(id),
    {
      onSuccess: (id: number) => {
        client.setQueryData('pushNotificationManagement', {
          ...notifications,
          items: notifications?.items?.filter((x) => x.id !== id),
        });
      },
    },
  );

  const hasNextPage = useMemo(
    () =>
      notifications && notifications?.items
        ? notifications?.items.length < notifications.total
        : false,
    [notifications],
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    disabled: isError,
    hasNextPage,
    onLoadMore,
  });

  const { Confirm, handleOpenConfirm } = useConfirm<{
    notificationId: number;
  }>({
    type: 'warning',
    title: t('push_delete_confirm_title'),
    text: t('push_delete_confirm_description'),
    confirmText: t('delete'),
    onConfirm: (state) => {
      if (state) deleteMutation.mutate(state.notificationId);
      pushSuccessNotify(t('push_delete_success_notify'));
    },
  });

  return (
    <Row
      gutter={[16, 16]}
      style={{ width: '100%', padding: '24px 16px' }}
      wrap={false}
    >
      <Col flex="348px">
        <AdminSidebarMenu />
      </Col>
      <Col flex="auto">
        <Paper style={{ padding: 24 }}>
          <Title variant="h1">{t('notification_management')}</Title>
          {!isLoading && notifications && notifications.items && (
            <List>
              {notifications.items.map((item) => (
                <PushManagementItem
                  key={item.id}
                  handleDelete={() =>
                    handleOpenConfirm({ notificationId: item.id as number })
                  }
                  notification={item}
                />
              ))}
            </List>
          )}
          {(isLoading || hasNextPage) && (
            <SpinContainer ref={sentryRef}>
              <Spin />
            </SpinContainer>
          )}
        </Paper>
        <Confirm />
      </Col>
      <Col flex="348px">
        <HrSidebarMenu />
      </Col>
    </Row>
  );
};
