import styled from 'styled-components';

export const StyledButton = styled.button`
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  &:hover {
    background: ${({ theme }) => theme.palette.primary.light};
  }
  &:active {
    background: ${({ theme }) => theme.palette.primary.dark};
  }
`;
