import { ComponentPropsWithRef } from 'react';

import styled, { css } from 'styled-components';

export type BaseInputProps = ComponentPropsWithRef<'input'>;

interface StyledSvgIconRootProps {
  checked?: boolean;
  disabled?: boolean;
}

const checkedStyles = css`
  color: ${({ theme }) => theme.palette.primary.main} !important;
`;

const disabledStyles = css`
  cursor: default;
  pointer-events: none;
  color: ${({ theme }) => theme.palette.text.disabled} !important;
  & > img {
    opacity: 0.5;
  }
`;

export const StyledSvgIconRoot = styled.span<StyledSvgIconRootProps>`
  position: relative;
  line-height: 0;
  color: ${({ theme }) => theme.palette.text.secondary};
  ${({ checked }) => checked && checkedStyles}
  ${({ disabled }) => disabled && disabledStyles}
`;

export const StyledInput = styled.input`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0 !important;
  outline: 0;
  z-index: 1;
  width: 100% !important;
  height: 100% !important;
  ${({ disabled }) => disabled && disabledStyles}
`;

export const StyledLabel = styled.label`
  display: inline-block;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-left: 10px;
  user-select: none;

  &[data-disabled='true'] {
    ${disabledStyles}
  }
`;
