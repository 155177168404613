import { UserProfileModel } from 'services/api';
import { i18n } from 'services/i18n';

import Yup from './yup';

export interface PostPushSendFormValues {
  users: UserProfileModel[];
}

export const postPushSchema = Yup.object({
  users: Yup.array().test({
    test: (value) => (value ? value.length > 0 : false),
    message: () => i18n.t('sign-in.form.defaultErrors.required'),
  }),
});
