/**
 * unfinished hook, requires improvement
 */

import { useSearchParams } from 'react-router-dom';

export type SearchParams = {
  [key: string]: string;
};

export const useSearchURL = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getUrlParams = (value: string[] | string) => {
    if (typeof value === 'string') return searchParams.get(value);
    return value.reduce((prev, curr) => {
      const param = searchParams.get(curr);
      return param ? { ...prev, [curr]: param } : prev;
    }, {});
  };

  const setUrlParams = (value: SearchParams) => {
    const arrValues = Object.keys(value);
    if (arrValues.length !== 0) {
      arrValues.map((e) => searchParams.set(e, value[e]));
      setSearchParams(searchParams);
    }
  };

  const deleteUrlParams = (value: string[] | string) => {
    if (typeof value === 'string') searchParams.delete(value);
    if (Array.isArray(value)) {
      value.map((item) => searchParams.delete(String(item)));
    }
    setSearchParams(searchParams);
  };

  return { getUrlParams, setUrlParams, deleteUrlParams, searchParams };
};
