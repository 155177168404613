import { VFC } from 'react';

import { Delete, Restore } from '@styled-icons/material-outlined';
import { Tooltip } from 'antd';
import { nanoid } from 'nanoid';

import { Media } from 'components/MediaGallery/Media';
import { MediaAlbum } from 'components/MediaGallery/MediaAlbum';
import { MediaFileType } from 'components/PostForm/PostEditorContent/types';
import { PostedFileModel } from 'services/api';

import {
  Album,
  DeleteButton,
  ImageContainer,
  SpinContainer,
  StyledSpin,
  StyledVideoProcessed,
} from './styles';
import { GridAlbumProps } from './types';

export const GridAlbum: VFC<GridAlbumProps> = ({
  mediaContent,
  handleMediaDelete,
}) => {
  const mutateDataToMedia = (data: MediaFileType): PostedFileModel => {
    return data.type === 'video'
      ? {
          videoThumbnail: data.videoThumbnail,
          videoId: data.id,
          postedFile: { fileUrl: data.fileUrl },
          postedFileId: data.postedFileId,
        }
      : {
          photoId: data.id,
          postedFile: { fileUrl: data.fileUrl },
          previews: data.previews,
          postedFileId: data.postedFileId,
        };
  };
  return (
    <Album>
      <MediaAlbum
        album={mediaContent.map((entry) => Number(entry.postedFileId))}
      >
        {mediaContent.map((item, index) =>
          item.id === 0 ? (
            <SpinContainer key={nanoid()}>
              <StyledSpin />
            </SpinContainer>
          ) : (
            <ImageContainer key={item.id}>
              {item.type === 'video' && !item.fileUrl ? (
                <StyledVideoProcessed>
                  <Tooltip title="Ваше видео обрабатывается">
                    <Restore size={32} />
                  </Tooltip>
                </StyledVideoProcessed>
              ) : (
                <Media media={mutateDataToMedia(item)} />
              )}
              {!!handleMediaDelete && (
                <DeleteButton
                  onClick={() =>
                    handleMediaDelete({ index, id: item.id, type: item.type })
                  }
                >
                  <Delete size={16} />
                </DeleteButton>
              )}
            </ImageContainer>
          ),
        )}
      </MediaAlbum>
    </Album>
  );
};
