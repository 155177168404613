import { VFC, memo } from 'react';

import { AppBar, Container, ToolBar } from 'ui';

import { LogoLink } from '../LogoLink';
import { Nav } from './Nav';
import { SideNav } from './SideNav';

export interface HeaderProps {
  disabled?: boolean;
}

export const Header: VFC<HeaderProps> = memo(({ disabled = false }) => {
  return (
    <AppBar>
      <Container>
        <ToolBar size="large">
          <LogoLink />
          {!disabled && (
            <>
              <Nav />
              <SideNav />
            </>
          )}
        </ToolBar>
      </Container>
    </AppBar>
  );
});
