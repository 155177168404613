import { useEffect, useRef } from 'react';

export type KeyHandlers = Record<string, (event: KeyboardEvent) => void>;

const fireHandler = (event: KeyboardEvent, handlers: KeyHandlers) => {
  const handler = handlers[event.code];
  if (handler) handler(event);
};

export const useKeyboard = <T extends HTMLElement = HTMLElement>(
  keyHandlers: KeyHandlers,
  additionalHandlers?: KeyHandlers,
) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    let element: T;

    const handleKeyDown = (event: KeyboardEvent) => {
      fireHandler(event, keyHandlers);
      if (additionalHandlers) fireHandler(event, additionalHandlers);
    };

    if (ref.current) {
      element = ref.current;
      element.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (element) element.removeEventListener('keydown', handleKeyDown);
    };
  }, [keyHandlers, additionalHandlers]);

  return ref;
};
