import {
  Instagram,
  MicrosoftTeams,
  Twitter,
  Vk,
} from '@styled-icons/boxicons-logos';
import { ChevronDown, ChevronUp } from '@styled-icons/boxicons-regular';
import { Jira, Yammer } from '@styled-icons/fa-brands';
import { Filter, LightBulb } from '@styled-icons/heroicons-outline';
import { Diamond } from '@styled-icons/ionicons-outline/Diamond';
import {
  Api,
  ArrowRight,
  Article,
  Assignment,
  AssignmentInd,
  AssignmentTurnedIn,
  AssistantPhoto,
  Ballot,
  Campaign,
  CardGiftcard,
  ChevronRight,
  ConnectWithoutContact,
  ContactPhone,
  ContentCopy,
  DataUsage,
  DisabledByDefault,
  Drafts,
  Edit,
  EditNotifications,
  EmojiPeople,
  Explore,
  Facebook,
  Flight,
  Forum,
  ForwardToInbox,
  GroupAdd,
  Groups,
  HelpCenter,
  Home,
  Info,
  LibraryBooks,
  LocalGroceryStore,
  LocalMall,
  LowPriority,
  MenuBook,
  PeopleAlt,
  Person,
  PhoneIphone,
  Photo,
  PushPin,
  RecentActors,
  Reply,
  School,
  Search,
  Settings,
  Source,
  StickyNote2,
  TextSnippet,
  ThreeHundredSixty,
  VolumeOff,
  VolumeUp,
} from '@styled-icons/material-outlined';
import { Circle } from '@styled-icons/material-sharp';
import { StyledIcon } from '@styled-icons/styled-icon';

import { pickerIconsMap } from 'ui/IconPicker/pickerIcons';

export const otherIconsMap: Record<string, StyledIcon> = {
  default: DisabledByDefault,
  edit: Edit,
  flight: Flight,
  school: School,
  explore: Explore,
  local_mall: LocalMall,
  info: Info,
  campaign: Campaign,
  drafts: Drafts,
  forum: Forum,
  forward_to_inbox: ForwardToInbox,
  assignment_ind: AssignmentInd,
  assignment: Assignment,
  recent_actors: RecentActors,
  settings: Settings,
  help_center: HelpCenter,
  present: HelpCenter,
  diamond: Diamond,
  group_add: GroupAdd,
  card_giftcard: CardGiftcard,
  edit_notifications: EditNotifications,
  ballot: Ballot,
  low_priority: LowPriority,
  '360': ThreeHundredSixty,
  teams: MicrosoftTeams,
  vk: Vk,
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  chevronRight: ChevronRight,
  idea: LightBulb,
  photo: Photo,
  text_snippet: TextSnippet,
  groups: Groups,
  local_grocery_store: LocalGroceryStore,
  assignment_turned_in: AssignmentTurnedIn,
  yammer: Yammer,
  data_usage: DataUsage,
  api: Api,
  jira: Jira,
  emoji_people: EmojiPeople,
  article: Article,
  menu_book: MenuBook,
  connect_without_contact: ConnectWithoutContact,
  people_alt: PeopleAlt,
  phone: PhoneIphone,
  home: Home,
  search: Search,
  library_books: LibraryBooks,
  contact_phone: ContactPhone,
  push_pin: PushPin,
  sticky_note_2: StickyNote2,
  volume_off: VolumeOff,
  volume_up: VolumeUp,
  reply: Reply,
  copy: ContentCopy,
  arrow_right: ArrowRight,
  pointer: Circle,
  person: Person,
  source: Source,
  assistant_photo: AssistantPhoto,
  filter: Filter,
};

export const iconsMap = { ...otherIconsMap, ...pickerIconsMap };
