import { WallUpdateModel, api } from 'services/api';

export const deletePhoto = async (id: number) => {
  await api.photo.apiV1PhotosIdDelete({
    id,
  });
};

export const deleteVideo = async (id: number) => {
  await api.video.apiV1VideosIdDelete({
    id,
  });
};

export const deleteDocument = async (id: number) => {
  await api.document.apiV1DocumentsIdDelete({
    id,
  });
};

export const deleteAttachment = (params: {
  id: number;
  type: 'image' | 'video' | 'document';
}) => {
  if (params.type === 'image') return deletePhoto(params.id);
  if (params.type === 'video') return deleteVideo(params.id);
  return deleteDocument(params.id);
};

export const getAdminGroupsList = async (userId: number) => {
  const { data } = await api.group.apiV1GroupsGet({
    userId,
    isGroupAdmin: true,
    count: -1,
  });

  return data.items;
};

export const createPost = async (newPost: WallUpdateModel) => {
  const { data } = await api.wall.apiV1WallsPost({
    wallUpdateModel: newPost,
  });
  return data;
};

export const updatePost = async (newPost: WallUpdateModel) => {
  const { data } = await api.wall.apiV1WallsPut({
    wallUpdateModel: newPost,
  });
  return data;
};

export const mutatePost = (
  newPost: WallUpdateModel,
  operation: 'put' | 'post',
) => {
  return operation === 'put' ? updatePost(newPost) : createPost(newPost);
};
