import { ChangeEvent, useState } from 'react';

import { omit } from 'lodash';
import { customAlphabet } from 'nanoid';

import { PollAnswerUpdateModel, PollUpdateModel } from 'services/api';

type ExtendedModel = Omit<PollUpdateModel, 'pollAnswers'> & {
  pollAnswers?: Array<PollAnswerUpdateModel & { isDefault: boolean }>;
};

export const usePollMethods = (defaultValue?: PollUpdateModel) => {
  const [pollForm, setPollForm] = useState<ExtendedModel | null>(
    defaultValue
      ? {
          ...defaultValue,
          pollAnswers: defaultValue?.pollAnswers?.map((item) => ({
            ...item,
            isDefault: true,
          })),
        }
      : null,
  );

  const nanoid = customAlphabet('1234567890', 20);

  const handlePollCreate = () => {
    setPollForm({
      pollAnswers: [{ id: Number(nanoid()), text: '', isDefault: false }],
      title: '',
    });
  };
  const handleFormRemove = () => setPollForm(null);
  const handleAnswerRemove = (id: number) => () => {
    setPollForm({
      ...pollForm,
      pollAnswers: pollForm?.pollAnswers?.filter((answer) => answer.id !== id),
    });
  };
  const handleAnswerAdd = () => {
    setPollForm({
      ...pollForm,
      pollAnswers: [
        ...(pollForm?.pollAnswers ?? []),
        { id: Number(nanoid()), text: '', isDefault: false },
      ],
    });
  };
  const getCleanedForm = () => {
    return {
      ...pollForm,
      pollAnswers: pollForm!
        .pollAnswers!.filter(({ text }) => text)
        .map((answer) => (answer.isDefault ? answer : omit(answer, 'id'))),
    };
  };

  const changeQuestionTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setPollForm({
      ...pollForm,
      title: event.target.value,
    });
  };

  const changeAnswerTitle =
    (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const pollAnswers = [...(pollForm?.pollAnswers ?? [])];
      pollAnswers.find((answer) => answer.id === id)!.text = event.target.value;
      setPollForm({ ...pollForm, pollAnswers });
    };

  return {
    pollForm,
    handlePollCreate,
    handleFormRemove,
    handleAnswerRemove,
    handleAnswerAdd,
    getCleanedForm,
    changeQuestionTitle,
    changeAnswerTitle,
  };
};
