import { CSSProperties, VFC, useCallback, useMemo } from 'react';

import { ExpandMore as ExpandMoreIcon } from '@styled-icons/material-outlined/ExpandMore';

import { useLoadingLanguage } from 'hooks/useChangeLanguage';
import { LANGUAGES_DATA, useTranslation } from 'services/i18n';
import { Language, LanguageData, LanguageId } from 'services/i18n/i18n';
import { Dropdown, Menu, MenuItem } from 'ui';

import { Container, MenuImg, StyledMenuWrapper } from './styles';

const overlayStyle = {
  position: 'fixed',
  userSelect: 'none',
} as CSSProperties;

enum LanguageName {
  ru = 'RUS',
  en = 'ENG',
  tr = 'TUR',
}

export const LanguageMenu: VFC = () => {
  const { t, i18n } = useTranslation();
  const { setLangFromId } = useLoadingLanguage();

  const handleClick = useCallback(
    ({ key }) => {
      setLangFromId(LanguageId[key as Language]);
    },
    [setLangFromId],
  );

  const renderMenuList = useMemo(() => {
    return LANGUAGES_DATA.map((item: LanguageData) => {
      return (
        <MenuItem key={item.name}>
          <MenuImg alt={t('sign-in.lang.label')} src={item.flag} />
          {t(`sign-in.lang.${item.name}`)}
        </MenuItem>
      );
    });
  }, [t]);

  const renderMenuOverlay = useMemo(
    () => (
      <Menu selectedKeys={[i18n.language]} onClick={handleClick}>
        {renderMenuList}
      </Menu>
    ),
    [handleClick, i18n.language, renderMenuList],
  );

  return (
    <Container>
      <Dropdown overlay={renderMenuOverlay} overlayStyle={overlayStyle}>
        <StyledMenuWrapper>
          {LanguageName[i18n.language as Language]} <ExpandMoreIcon size={24} />
        </StyledMenuWrapper>
      </Dropdown>
    </Container>
  );
};
