import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useMutation } from 'react-query';

import { convertPostDataToUpdateModel } from 'domain/post';
import { WallModel, WallUpdateModel } from 'services/api';
import { useTranslation } from 'services/i18n';
import { DEFAULT_POST_STATE, usePostContext } from 'services/post';
import { useSystemNotifyService } from 'services/systemNotify/useSystemNotifyService';
import { useWallData } from 'services/wall';

import {
  copyPost,
  deletePost,
  likePost,
  pinPost,
  publishPost,
  switchNotifications,
} from '../api';
import { PostProps } from '../types';
import { useViews } from './useViews';

export const useLocalModel = (args: PostProps) => {
  const { data, onPostDeleted, handlePostPin, clearCache } = args;
  const { t } = useTranslation('common');
  const wallDataContext = useWallData();

  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [isReplyVisible, setIsReplyVisible] = useState(false);
  const [isPostCloneModalVisible, setIsPostCopyModalVisible] = useState(false);
  const [isPostEditModalVisible, setIsPostEditModalVisible] = useState(false);
  const [isPostPushModalVisible, setIsPostPushModalVisible] = useState(false);
  const { pushSuccessNotify, pushErrorNotify } = useSystemNotifyService();

  const [post, setPost] = useState(data);
  const [defaultValues, setDefaultValues] = useState<
    WallUpdateModel | undefined
  >(convertPostDataToUpdateModel(data));
  const { postState, fetchPostState, savePostState } = usePostContext();

  const updateLocalPost = (updatedPost: WallModel) => {
    setPost(updatedPost);
  };

  const { postWrapper } = useViews({
    clearCache: args.clearCache,
    isReplyVisible,
    isCommentsVisible,
    post,
    updateLocalPost,
  });

  const updatePollAnswer = (answerId?: number) => {
    const pollAnswers = [...post.poll!.pollAnswers!];
    pollAnswers.find((ans) => ans.id === answerId)!.votesCount! += 1;

    const updatedPost: WallModel = {
      ...post,
      poll: {
        ...post.poll,
        currentUserAnswerId: answerId,
        answersCount: post.poll!.answersCount! + 1,
        pollAnswers,
      },
    };

    updateLocalPost(updatedPost);

    setDefaultValues(convertPostDataToUpdateModel(updatedPost));

    if (wallDataContext) {
      wallDataContext.setList((prevState) => {
        return prevState.map((p) => (p.id === post.id ? updatedPost : p));
      });
      wallDataContext.forceUpdate();
    }
  };

  useEffect(() => {
    setPost(data);

    if (fetchPostState instanceof Function) {
      const savedPostState = fetchPostState();
      if (savedPostState) {
        setIsCommentsVisible(!!savedPostState.isCommentsVisible);
        setIsReplyVisible(!!savedPostState.isReplyVisible);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useLayoutEffect(() => {
    if (savePostState instanceof Function && (postState || isCommentsVisible)) {
      const newState = {
        ...(postState || DEFAULT_POST_STATE),
        isCommentsVisible,
      };
      savePostState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentsVisible]);

  const likeMutation = useMutation(
    (postData: WallModel) => likePost(postData),
    {
      onSuccess: (newData) => {
        setPost({ ...post, isLiked: !post.isLiked, likes: newData });
      },
    },
  );

  const deleteMutation = useMutation(deletePost, {
    onSuccess: (_, id) => {
      pushSuccessNotify(t('post_remove_notify'));
      setIsCommentsVisible(false);
      setIsReplyVisible(false);
      onPostDeleted?.(id);

      if (wallDataContext) {
        wallDataContext.setList((prevState) =>
          prevState.filter((p) => p.id !== id),
        );
        wallDataContext.forceUpdate();
      }
    },
    onError: () => {
      pushErrorNotify(t('something_went_wrong'));
    },
  });

  const pinMutation = useMutation(
    () => pinPost(Number(post.id), post.isPinned),
    {
      onSuccess: (newData) => {
        pushSuccessNotify(
          newData.isPinned ? t('post_pinned') : t('post_unpinned'),
        );

        setPost({ ...post, isPinned: newData.isPinned });
        if (handlePostPin) handlePostPin(Number(post.id), !!newData.isPinned);
      },
    },
  );

  const publishMutation = useMutation(publishPost, {
    onSuccess: (_, id) => {
      if (wallDataContext) {
        wallDataContext.setList((prevState) =>
          prevState.filter((p) => p.id !== id),
        );
        wallDataContext.forceUpdate();
      }
    },
  });

  const switchNotificationsMutation = useMutation(
    () => switchNotifications(Number(post.id), !post.isDisableNotifications),
    {
      onSuccess: (newData) => {
        pushSuccessNotify(
          newData.isDisableNotifications
            ? t('notifications_disabled')
            : t('notifications_enabled'),
        );

        setPost({
          ...post,
          isDisableNotifications: newData.isDisableNotifications,
        });
      },
    },
  );

  const onSwitchNotifications = useCallback(async () => {
    await switchNotificationsMutation.mutateAsync();
  }, [switchNotificationsMutation]);

  const onPushPost = useCallback(async () => {
    setIsPostPushModalVisible(true);
  }, []);

  const onCopyPost = useCallback(async () => {
    const result = await copyPost(Number(post.id));

    setDefaultValues(convertPostDataToUpdateModel(result));

    setIsPostCopyModalVisible(true);
  }, [post.id]);

  const onPinPost = useCallback(async () => {
    await pinMutation.mutateAsync();
  }, [pinMutation]);

  const onEditPost = async () => {
    setIsPostEditModalVisible(true);
  };

  const onDeletePost = useCallback(() => {
    deleteMutation.mutate(Number(post.id));
  }, [post.id, deleteMutation]);

  const handlePostPublish = useCallback(() => {
    publishMutation.mutate(Number(post.id));
  }, [post.id, publishMutation]);

  const handleCommentClick = () => {
    if (post.comments && post.comments?.total > 0) {
      setIsCommentsVisible(!isCommentsVisible);
      if (clearCache) clearCache();
    }
  };

  const handleIsReplyVisible = (value: boolean) => {
    setIsReplyVisible(value);
    if (clearCache) clearCache();
  };

  const handleReplyClick = () => {
    handleIsReplyVisible(!isReplyVisible);
  };

  const afterCommentSubmit = () => {
    setIsCommentsVisible(true);
  };
  const afterCommentDelete = () => {
    setIsCommentsVisible(true);
  };

  return {
    defaultValues,
    post,
    postWrapper,
    onPinPost,
    onPushPost,
    onCopyPost,
    onEditPost,
    onSwitchNotifications,
    onDeletePost,
    setIsPostCopyModalVisible,
    setIsPostEditModalVisible,
    setIsPostPushModalVisible,
    isCommentsVisible,
    isReplyVisible,
    isPostCloneModalVisible,
    isPostPushModalVisible,
    isPostEditModalVisible,
    isLoading: publishMutation.isLoading || deleteMutation.isLoading,
    isLikeLoading: likeMutation.isLoading,
    likeMutation,
    handleIsReplyVisible,
    updatePollAnswer,
    handlePostPublish,
    handleCommentClick,
    handleReplyClick,
    afterCommentDelete,
    afterCommentSubmit,
    setPost,
  };
};
