import { css } from 'styled-components';

export const scrollCss = css`
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f6f8fa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dbe0e5;
    border-radius: 50px;
  }
`;
