import { Context, FC, createContext, useContext } from 'react';

import {
  AuthenticationService,
  useAuthenticationService,
} from './useAuthenticationService';

const AuthContext = createContext<AuthenticationService | null>(null);

export const useAuth = (): AuthenticationService =>
  useContext(AuthContext as Context<AuthenticationService>);

export const AuthProvider: FC = ({ children }) => {
  const authenticationService = useAuthenticationService();

  return (
    <AuthContext.Provider value={authenticationService}>
      {children}
    </AuthContext.Provider>
  );
};
