/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppTokenModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete user app token
         * @param {AppTokenModel} [appTokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AppsTokenDelete: async (appTokenModel?: AppTokenModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/apps/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appTokenModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create user app token
         * @param {AppTokenModel} [appTokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AppsTokenPost: async (appTokenModel?: AppTokenModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/apps/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appTokenModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete user app token
         * @param {AppTokenModel} [appTokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AppsTokenDelete(appTokenModel?: AppTokenModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AppsTokenDelete(appTokenModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create user app token
         * @param {AppTokenModel} [appTokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AppsTokenPost(appTokenModel?: AppTokenModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AppsTokenPost(appTokenModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete user app token
         * @param {AppTokenModel} [appTokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AppsTokenDelete(appTokenModel?: AppTokenModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AppsTokenDelete(appTokenModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create user app token
         * @param {AppTokenModel} [appTokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AppsTokenPost(appTokenModel?: AppTokenModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AppsTokenPost(appTokenModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1AppsTokenDelete operation in AppApi.
 * @export
 * @interface AppApiApiV1AppsTokenDeleteRequest
 */
export interface AppApiApiV1AppsTokenDeleteRequest {
    /**
     * 
     * @type {AppTokenModel}
     * @memberof AppApiApiV1AppsTokenDelete
     */
    readonly appTokenModel?: AppTokenModel
}

/**
 * Request parameters for apiV1AppsTokenPost operation in AppApi.
 * @export
 * @interface AppApiApiV1AppsTokenPostRequest
 */
export interface AppApiApiV1AppsTokenPostRequest {
    /**
     * 
     * @type {AppTokenModel}
     * @memberof AppApiApiV1AppsTokenPost
     */
    readonly appTokenModel?: AppTokenModel
}

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @summary Delete user app token
     * @param {AppApiApiV1AppsTokenDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public apiV1AppsTokenDelete(requestParameters: AppApiApiV1AppsTokenDeleteRequest = {}, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).apiV1AppsTokenDelete(requestParameters.appTokenModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create user app token
     * @param {AppApiApiV1AppsTokenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public apiV1AppsTokenPost(requestParameters: AppApiApiV1AppsTokenPostRequest = {}, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).apiV1AppsTokenPost(requestParameters.appTokenModel, options).then((request) => request(this.axios, this.basePath));
    }
}
