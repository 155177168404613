import { VFC, memo } from 'react';

import { Delete, ExpandMore } from '@styled-icons/material-outlined';
import { Spin } from 'antd';

import { FormButtons } from 'components/FormButtons';
import { FormFilesGrid } from 'components/FormFiles';
import { FormToolbar } from 'components/FormToolbar';
import { GridAlbum } from 'components/GridAlbum';
import { PostOpenGraphContent } from 'components/Wall/Post/PostContent/PostStandardContent/PostOpenGraphContent';
import { GroupPreviewModel, WallPrivacy } from 'services/api';
import { useTranslation } from 'services/i18n';
import { Form, Option } from 'ui';

import { FormPoll } from '../../FormPoll';
import { useLocalModel } from './model';
import {
  ControlContainer,
  DeleteButton,
  FormDivider,
  FormSelect,
  GeoPosition,
  GeoTag,
  GeoText,
  SelectContainer,
  StyledContainer,
  StyledEditorContent,
  StyledFormItem,
} from './styles';
import { PostEditorContentProps } from './types';

export const PostEditorContent: VFC<PostEditorContentProps> = memo((props) => {
  const { owner, defaultValues, mode = 'create' } = props;

  const { t } = useTranslation('common');

  const {
    handleFormSubmit,
    mediaFilesList,
    documents,
    handleDeleteAttachment,
    userAdminGroups,
    handleEmojiClick,
    handleDocUpload,
    handleMediaContentUpload,
    handlePollCreate,
    handleReset,
    handleWallOwnerChange,
    openGraph,
    setOpenGraph,
    geoPickerPos,
    setGeoPickerPos,
    handleWallPrivacyChange,
    isSubmitting,
    editor,
    pollForm,
    handleFormRemove,
    handleAnswerAdd,
    handleAnswerRemove,
    changeQuestionTitle,
    changeAnswerTitle,
  } = useLocalModel(props);

  console.log('poll form', pollForm);
  return (
    <Form layout="vertical" onFinish={handleFormSubmit}>
      <Spin spinning={isSubmitting}>
        <StyledContainer>
          <StyledFormItem>
            <StyledEditorContent editor={editor as any} />
          </StyledFormItem>
        </StyledContainer>
        {geoPickerPos && (
          <GeoTag>
            <GeoPosition size={22} />
            <GeoText>{geoPickerPos.label}</GeoText>
            <DeleteButton onClick={() => setGeoPickerPos(null)}>
              <Delete size={16} />
            </DeleteButton>
          </GeoTag>
        )}
        {mediaFilesList.length > 0 && (
          <GridAlbum
            handleMediaDelete={handleDeleteAttachment}
            mediaContent={mediaFilesList}
          />
        )}
        {documents.length > 0 && (
          <FormFilesGrid
            documents={documents}
            onDocumentDelete={handleDeleteAttachment}
          />
        )}
        {pollForm && (
          <FormPoll
            changeAnswerTitle={changeAnswerTitle}
            changeQuestionTitle={changeQuestionTitle}
            pollForm={pollForm}
            onAddAnswer={handleAnswerAdd}
            onRemoveAnswer={handleAnswerRemove}
            onRemoveForm={handleFormRemove}
          />
        )}
        <ControlContainer>
          <SelectContainer>
            <FormSelect
              bordered={false}
              defaultValue={WallPrivacy.Public}
              dropdownStyle={{ borderRadius: '8px' }}
              suffixIcon={<ExpandMore color="#4872f2" size={14} />}
              onChange={(value) => handleWallPrivacyChange(value as number)}
            >
              <Option value={WallPrivacy.Public}>{t('public_access')}</Option>
              <Option value={WallPrivacy.ForColleagues}>
                {t('colleague_access')}
              </Option>
              <Option value={WallPrivacy.Private}>{t('private_access')}</Option>
            </FormSelect>
            {!owner?.groupId && (
              <FormSelect
                bordered={false}
                defaultValue={null}
                dropdownStyle={{ borderRadius: '8px' }}
                suffixIcon={<ExpandMore color="#4872f2" size={14} />}
                onChange={(value) => handleWallOwnerChange(value as number)}
              >
                <Option value={null}>{t('my_wall_publish')}</Option>
                {userAdminGroups &&
                  userAdminGroups.length > 0 &&
                  userAdminGroups.map((x: GroupPreviewModel) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
              </FormSelect>
            )}
          </SelectContainer>

          <FormToolbar
            geoPicker
            handleEmojiClick={handleEmojiClick}
            handleFileUpload={handleDocUpload}
            handleMediaContentUpload={handleMediaContentUpload}
            handlePollCreate={handlePollCreate}
            hasForm={!!pollForm}
            setGeoPickerPos={setGeoPickerPos}
          />
        </ControlContainer>
        {openGraph && (
          <div style={{ marginTop: 18 }}>
            <PostOpenGraphContent
              handleDelete={() => setOpenGraph(undefined)}
              openGraph={openGraph}
              type="small"
            />
          </div>
        )}
      </Spin>
      <FormDivider />
      <FormButtons
        cancelDisabled={isSubmitting}
        handleCancel={handleReset}
        submitDisabled={isSubmitting}
        submitText={
          defaultValues && mode === 'create' ? t('make_reply') : t('publish')
        }
      />
    </Form>
  );
});
