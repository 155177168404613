import styled from 'styled-components';

import { StyledLink as BaseStyledLink } from 'ui/Link';

export const StyledLink = styled(BaseStyledLink)`
  font-weight: 600;
  margin-left: 0.5em;
`;

export const StyledContainer = styled.div`
  .hashtag {
    color: #4872f2;

    &.inblock {
      display: inline-block;
      margin-right: 1em;
      &:last-child {
        margin: 0;
      }
    }
  }
`;
