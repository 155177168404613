import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { LikeButton } from '../../LikeButton';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 12px;
`;
export const AvatarContainer = styled.div``;
export const MainData = styled.div``;

export const Author = styled(Link)`
  color: inherit;
  font-weight: 600;
`;

export const CommentContent = styled.div`
  width: 100%;
  margin: 4px 0;
  p:last-of-type {
    margin-bottom: 0;
  }
`;
export const Footer = styled.div`
  color: #d4d9de;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
`;
export const Spacer = styled.div`
  flex: 1;
`;

export const CommentButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #d4d9de;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LikeCommentButton = styled(LikeButton)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #d4d9de;
  height: 22px;
  transition: color 0.1s linear;

  &:hover {
    color: #3965ee;
  }

  & svg {
    margin-right: 4px;
  }
`;

export const MoreButton = styled.div`
  cursor: pointer;
  &:hover {
    color: #4d4d4d;
  }
`;

export const StyledAttachesContainer = styled.div`
  padding-left: 44px;
`;
