import { SVGProps, forwardRef } from 'react';

import { StyledIconBase, StyledIconProps } from '@styled-icons/styled-icon';

export const CompletedIcon = forwardRef<SVGSVGElement, StyledIconProps>(
  (props, ref) => {
    const attrs: SVGProps<SVGSVGElement> = {
      fill: 'currentColor',
    };

    return (
      <StyledIconBase
        iconAttrs={attrs}
        iconVerticalAlign="middle"
        iconViewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <rect fill="#4872F2" height="24" rx="5" width="24" />
        <path
          clipRule="evenodd"
          d="M18.4619 7.36933C18.8552 7.75756 18.859 8.39118 18.4704 8.78412L10.4203 16.9249L5.31806 12.1677C4.9184 11.795 4.89185 11.1706 5.25845 10.7654L5.39467 10.6148C5.77203 10.1977 6.41905 10.1738 6.82619 10.5619L10.4001 13.9686L16.9646 7.29856C17.3524 6.90451 17.9864 6.89993 18.3798 7.28834L18.4619 7.36933Z"
          fill="white"
          fillRule="evenodd"
        />
      </StyledIconBase>
    );
  },
);
