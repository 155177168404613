import styled from 'styled-components';

import 'react-virtualized/styles.css';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LoadingMoreItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 8px 16px;
`;

export const EmptyItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 8px 16px;
`;
