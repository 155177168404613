import { VFC } from 'react';

import { PostPushSendForm } from 'components/PostPushSendForm';
import { getPostDescription } from 'domain/post';
import { useTranslation } from 'services/i18n';
import { Modal } from 'ui/Modal';

import { ModalPaper, PushDescription } from './styles';

type PostPushModalProps = {
  postId: number;
  postText?: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
};

export const PostPushModal: VFC<PostPushModalProps> = ({
  postId,
  postText,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation('common');

  const handleClose = () => {
    setVisible(false);
  };

  const pushDescription = postText ? getPostDescription(postText) : undefined;

  return (
    <Modal
      destroyOnClose
      bodyStyle={{ padding: 0, paddingTop: 20 }}
      footer={false}
      title={t('send_as_notification')}
      visible={visible}
      width={680}
      onCancel={handleClose}
    >
      <ModalPaper>
        {pushDescription && (
          <PushDescription>{pushDescription}</PushDescription>
        )}
        <PostPushSendForm handleClose={handleClose} postId={postId} />
      </ModalPaper>
    </Modal>
  );
};
