import styled from 'styled-components';

import { Paper } from 'ui';

export const StyledPaper = styled(Paper)`
  padding: 16px 0;
  margin-bottom: 12px;
  border-radius: 8px;
  transition: height 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: min-content minmax(auto, 596px);
  grid-column-gap: 12px;
  grid-row-gap: 16px;
  padding: 0 24px;
`;
