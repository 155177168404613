import { VFC } from 'react';

import { useQuery } from 'react-query';

import { useLinksApi } from 'components/Header/Nav/useLinksApi';
import { createLinkWithGeneratedPath, isExternal } from 'domain/navigation';
import { LinkType } from 'services/api';
import { useAuth } from 'services/auth';
import { useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { Widget } from 'ui';

import { NavItem, NavMenu } from './styles';

export const HrSidebarMenu: VFC = () => {
  const { t } = useTranslation('common');
  const { user } = useUserCredential();
  const { token } = useAuth();
  const { getLinks } = useLinksApi();

  const { data: links = [] } = useQuery(
    ['widgets', 'hr', user?.country.id],
    () => getLinks(user?.country.id as number, LinkType.HrManagement),
    {
      suspense: false,
    },
  );

  const hrLinks = links.map(createLinkWithGeneratedPath(token));

  return (
    <Widget title={t('hr_management')}>
      <NavMenu>
        {hrLinks.map((link) => (
          <NavItem
            key={link.id}
            external={isExternal(link.href as string)}
            target={isExternal(link.href as string) ? '_blank' : '_self'}
            to={link.href}
          >
            {t(link.title as string)}
          </NavItem>
        ))}
      </NavMenu>
    </Widget>
  );
};
