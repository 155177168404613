import { SVGProps, forwardRef } from 'react';

import { StyledIconBase, StyledIconProps } from '@styled-icons/styled-icon';

export const UnCompletedIcon = forwardRef<SVGSVGElement, StyledIconProps>(
  (props, ref) => {
    const attrs: SVGProps<SVGSVGElement> = {
      fill: 'currentColor',
    };

    return (
      <StyledIconBase
        iconAttrs={attrs}
        iconVerticalAlign="middle"
        iconViewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <path
          clipRule="evenodd"
          d="M19 1H5C2.79086 1 1 2.79086 1 5V19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1ZM5 0C2.23858 0 0 2.23858 0 5V19C0 21.7614 2.23858 24 5 24H19C21.7614 24 24 21.7614 24 19V5C24 2.23858 21.7614 0 19 0H5Z"
          fill="#D4D9DE"
          fillRule="evenodd"
        />
      </StyledIconBase>
    );
  },
);
