export type Sizes = 'large' | 'default';

export type ItemColor = {
  color: string;
  background: string;
};

export const itemMapColors = {
  red: {
    color: '#000',
    background: '#fff',
  },
  yellow: {
    color: '#FFCD00',
    background: '#FFFCE6',
  },
  green: {
    color: '#49C8C0',
    background: '#EDFAF9',
  },
};
export interface StyledContainerProps {
  size?: Sizes;
}
export interface StyledTimeContainerProps {
  size?: Sizes;
  variant: ItemColor;
}
export interface CalendarEventItemProps {
  size?: Sizes;
  name: Nullable<string>;
  variant: keyof typeof itemMapColors;
  time: string;
  onClick?: () => void;
}
