import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { Container, Content } from 'ui';

export const Layout: FC = () => {
  return (
    <Content>
      <Container>
        <Outlet />
      </Container>
    </Content>
  );
};
