import { LocationOn } from '@styled-icons/material-outlined';
import { EditorContent } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { Divider, FormItem, IconButton, Select, Text } from 'ui';
import { headingStyles } from 'ui/Title/styles';

const paragraph = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
`;

export const StyledEditorContent = styled(EditorContent)`
  .ProseMirror {
    &:focus {
      outline: 0;
      border: solid 1px #4872f2;
    }

    padding: 8px 12px 0;
    border: solid 1px #dbe0e5;
    min-height: 92px;
    max-width: 100%;
    overflow: auto;
    border-radius: 8px;
  }

  h1 {
    ${headingStyles.h1}
    margin: 0;
  }

  h2 {
    ${headingStyles.h2}
    margin: 0;
  }

  h3 {
    ${headingStyles.h3}
    margin: 0;
  }

  p {
    ${paragraph}
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  /* Placeholder (at the top) */

  p.is-editor-empty:only-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`;

export const DeleteButton = styled(IconButton)`
  justify-self: end;
  height: 24px;
  width: 24px;
  border-radius: 20px;
  background-color: white;
  box-shadow: black;
`;

export const GeoTag = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  flex-direction: row;
  justify-content: start;
`;

export const GeoPosition = styled(LocationOn)`
  color: #4872f2;
`;

export const GeoText = styled(Text)`
  margin-left: 4px;
  line-height: 20px;
`;

export const StyledContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ControlContainer = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormSelect = styled(Select)`
  .ant-select-selection-item {
    font-weight: 600;
  }

  .ant-select-item {
    font-weight: 600;
  }

  width: 150px;
  color: #4872f2;
`;

export const FormDivider = styled(Divider)`
  margin: 18px 0 16px;
`;

export const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 25px;
  font-weight: 600;
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0 !important;
  width: 100%;
`;
