import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { SuggestionKeyDownProps } from '@tiptap/suggestion/src/suggestion';

import { UserModel } from 'services/api';
import { Avatar, ListItemMeta } from 'ui';

import { MentionListItem, MentionListItems } from './styles';

export type MentionListRef = {
  onKeyDown: (props: SuggestionKeyDownProps) => boolean;
};

export type MentionListProps = {
  items: UserModel[];
  command: (props: any) => void;
};

export const MentionList = forwardRef<MentionListRef, MentionListProps>(
  ({ items, command }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      if (items[index]) {
        command({
          id: items[index].id,
          label: `${items[index].firstName} ${items[index].lastName} `,
        });
      }
    };

    const upHandler = () => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: (keyDownProps: SuggestionKeyDownProps) => {
        if (keyDownProps.event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (keyDownProps.event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (keyDownProps.event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    return (
      <MentionListItems>
        {items.map((item: UserModel, index: number) => (
          <MentionListItem
            itemScope={index === selectedIndex}
            type="button"
            onClick={() => selectItem(index)}
          >
            <ListItemMeta
              avatar={<Avatar size={40} src={item.avatar?.fileUrl} />}
              description={item.positionName}
              title={`${item.firstName} ${item.lastName}`}
            />
          </MentionListItem>
        ))}
      </MentionListItems>
    );
  },
);
