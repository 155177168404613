import { VFC } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { RoleName } from 'services/api';
import { useUserCredential } from 'services/userCredential';

export interface RoleRouteProps {
  roles: RoleName | RoleName[];
}

export const RoleRoute: VFC<RoleRouteProps> = ({ roles }) => {
  const { hasRole } = useUserCredential();

  if (Array.isArray(roles)) {
    return roles.some(hasRole) ? <Outlet /> : <Navigate to="/profile" />;
  }

  if (hasRole(roles)) {
    return <Outlet />;
  }

  return <Navigate to="/profile" />;
};
