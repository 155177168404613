import { WallModel, api } from 'services/api';

export const deletePost = async (id: number) => {
  await api.wall.apiV1WallsIdDelete({
    id: Number(id),
  });
};

export const pinPost = async (id: number, isPinned?: boolean) => {
  const { data } = await api.wall.apiV1WallsIdPatch({
    id,
    operation: [
      {
        op: 'replace',
        path: '/isPinned',
        value: !isPinned,
      },
    ],
  });

  return data;
};

export const publishPost = async (id: number) => {
  const { data } = await api.wall.apiV1WallsIdPatch({
    id,
    operation: [
      {
        op: 'replace',
        path: '/isPublished',
        value: true,
      },
    ],
  });

  return data;
};

export const switchNotifications = async (
  id: number,
  isDisableNotifications: boolean,
) => {
  const { data } = await api.wall.apiV1WallsIdPatch({
    id,
    operation: [
      {
        op: 'replace',
        path: '/isDisableNotifications',
        value: isDisableNotifications,
      },
    ],
  });
  return data;
};

export const copyPost = async (id: number) => {
  const { data } = await api.wall.apiV1WallsIdCopyPost({
    id,
  });

  return data;
};

export const viewPost = async (id: number) => {
  const { data } = await api.wall.apiV1WallsIdOverlookPost({ id });
  return data;
};

export const likePost = async (post: WallModel) => {
  const byWallId = post.id;

  if (post.isLiked) {
    await api.like.apiV1LikesDelete({ byWallId });

    await api.wall.apiV1WallsIdFavoriteDelete({
      id: Number(byWallId),
    });
  } else {
    await api.like.apiV1LikesPost({ byWallId });

    await api.wall.apiV1WallsIdFavoritePost({
      id: Number(byWallId),
    });
  }
  const response = await api.like.apiV1LikesGet({
    ownerByWallId: byWallId,
    count: -1,
  });

  return response.data;
};
