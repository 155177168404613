/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateGroupMembersFilterRequest } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { GroupMemberModel } from '../models';
// @ts-ignore
import { GroupMemberUpdateModel } from '../models';
// @ts-ignore
import { GroupMembersModel } from '../models';
// @ts-ignore
import { GroupModel } from '../models';
// @ts-ignore
import { GroupUpdateModel } from '../models';
// @ts-ignore
import { GroupVisitHistoryModelSearchResult } from '../models';
// @ts-ignore
import { GroupsModel } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { UserPreviewModelSearchResult } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
// @ts-ignore
import { WallContentType } from '../models';
/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isGroupAdmin] Фильтр по администратору группы
         * @param {WallContentType} [wallContentType] Фильтр по типу содержания поста
         * @param {boolean} [isNotApproved] Фильтр для отображения не подтверждённых постов
         * @param {boolean} [isArchived] Фильтр для отображения постов в архиве
         * @param {number} [countryCategoryId] Фильтр по группе
         * @param {string} [query] Фильтр по тексту
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsGet: async (userId?: number, isGroupAdmin?: boolean, wallContentType?: WallContentType, isNotApproved?: boolean, isArchived?: boolean, countryCategoryId?: number, query?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (isGroupAdmin !== undefined) {
                localVarQueryParameter['IsGroupAdmin'] = isGroupAdmin;
            }

            if (wallContentType !== undefined) {
                localVarQueryParameter['WallContentType'] = wallContentType;
            }

            if (isNotApproved !== undefined) {
                localVarQueryParameter['IsNotApproved'] = isNotApproved;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['IsArchived'] = isArchived;
            }

            if (countryCategoryId !== undefined) {
                localVarQueryParameter['CountryCategoryId'] = countryCategoryId;
            }

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdDelete', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdGet', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete group member
         * @param {number} id 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMembersDelete: async (id: number, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdMembersDelete', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get group members by filter
         * @param {number} id 
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isNotApproved] Фильтр по не подтвердённым пользователям
         * @param {string} [query] Фильтр по запросу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMembersGet: async (id: number, groupId?: number, userId?: number, isNotApproved?: boolean, query?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdMembersGet', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (isNotApproved !== undefined) {
                localVarQueryParameter['IsNotApproved'] = isNotApproved;
            }

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create group member
         * @param {number} id 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMembersPost: async (id: number, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdMembersPost', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update group member
         * @param {number} id 
         * @param {GroupMemberUpdateModel} [groupMemberUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMembersPut: async (id: number, groupMemberUpdateModel?: GroupMemberUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdMembersPut', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupMemberUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create group members
         * @param {number} id 
         * @param {CreateGroupMembersFilterRequest} [createGroupMembersFilterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMultiMembersPost: async (id: number, createGroupMembersFilterRequest?: CreateGroupMembersFilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdMultiMembersPost', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/multi-members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupMembersFilterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get not group members by filter
         * @param {number} id Фильтр по группе
         * @param {string} [query] Фильтр по запросу
         * @param {number} [supervisorUserId] Фильтр по руководителю пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdNotMembersGet: async (id: number, query?: string, supervisorUserId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdNotMembersGet', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/not-members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (supervisorUserId !== undefined) {
                localVarQueryParameter['SupervisorUserId'] = supervisorUserId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdPatch', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get report by group id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdReportGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdReportGet', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/report`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Visit group
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdVisitPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1GroupsIdVisitPost', 'id', id)
            const localVarPath = `/api/v1/groups/{id}/visit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {GroupUpdateModel} [groupUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsPost: async (groupUpdateModel?: GroupUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {GroupUpdateModel} [groupUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsPut: async (groupUpdateModel?: GroupUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get group visit history
         * @param {number} [userId] ID пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsVisitHistoryGet: async (userId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups/visit-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['UserId'] = userId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isGroupAdmin] Фильтр по администратору группы
         * @param {WallContentType} [wallContentType] Фильтр по типу содержания поста
         * @param {boolean} [isNotApproved] Фильтр для отображения не подтверждённых постов
         * @param {boolean} [isArchived] Фильтр для отображения постов в архиве
         * @param {number} [countryCategoryId] Фильтр по группе
         * @param {string} [query] Фильтр по тексту
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsGet(userId?: number, isGroupAdmin?: boolean, wallContentType?: WallContentType, isNotApproved?: boolean, isArchived?: boolean, countryCategoryId?: number, query?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsGet(userId, isGroupAdmin, wallContentType, isNotApproved, isArchived, countryCategoryId, query, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete group member
         * @param {number} id 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdMembersDelete(id: number, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdMembersDelete(id, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get group members by filter
         * @param {number} id 
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isNotApproved] Фильтр по не подтвердённым пользователям
         * @param {string} [query] Фильтр по запросу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdMembersGet(id: number, groupId?: number, userId?: number, isNotApproved?: boolean, query?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupMembersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdMembersGet(id, groupId, userId, isNotApproved, query, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create group member
         * @param {number} id 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdMembersPost(id: number, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupMemberModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdMembersPost(id, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update group member
         * @param {number} id 
         * @param {GroupMemberUpdateModel} [groupMemberUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdMembersPut(id: number, groupMemberUpdateModel?: GroupMemberUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupMemberModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdMembersPut(id, groupMemberUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create group members
         * @param {number} id 
         * @param {CreateGroupMembersFilterRequest} [createGroupMembersFilterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdMultiMembersPost(id: number, createGroupMembersFilterRequest?: CreateGroupMembersFilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdMultiMembersPost(id, createGroupMembersFilterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get not group members by filter
         * @param {number} id Фильтр по группе
         * @param {string} [query] Фильтр по запросу
         * @param {number} [supervisorUserId] Фильтр по руководителю пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdNotMembersGet(id: number, query?: string, supervisorUserId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPreviewModelSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdNotMembersGet(id, query, supervisorUserId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get report by group id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdReportGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdReportGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Visit group
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsIdVisitPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsIdVisitPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {GroupUpdateModel} [groupUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsPost(groupUpdateModel?: GroupUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsPost(groupUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {GroupUpdateModel} [groupUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsPut(groupUpdateModel?: GroupUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsPut(groupUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get group visit history
         * @param {number} [userId] ID пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GroupsVisitHistoryGet(userId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupVisitHistoryModelSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GroupsVisitHistoryGet(userId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isGroupAdmin] Фильтр по администратору группы
         * @param {WallContentType} [wallContentType] Фильтр по типу содержания поста
         * @param {boolean} [isNotApproved] Фильтр для отображения не подтверждённых постов
         * @param {boolean} [isArchived] Фильтр для отображения постов в архиве
         * @param {number} [countryCategoryId] Фильтр по группе
         * @param {string} [query] Фильтр по тексту
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsGet(userId?: number, isGroupAdmin?: boolean, wallContentType?: WallContentType, isNotApproved?: boolean, isArchived?: boolean, countryCategoryId?: number, query?: string, count?: number, offset?: number, options?: any): AxiosPromise<GroupsModel> {
            return localVarFp.apiV1GroupsGet(userId, isGroupAdmin, wallContentType, isNotApproved, isArchived, countryCategoryId, query, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1GroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdGet(id: number, options?: any): AxiosPromise<GroupModel> {
            return localVarFp.apiV1GroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete group member
         * @param {number} id 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMembersDelete(id: number, userId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1GroupsIdMembersDelete(id, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get group members by filter
         * @param {number} id 
         * @param {number} [groupId] Фильтр по группе
         * @param {number} [userId] Фильтр по пользователю
         * @param {boolean} [isNotApproved] Фильтр по не подтвердённым пользователям
         * @param {string} [query] Фильтр по запросу
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMembersGet(id: number, groupId?: number, userId?: number, isNotApproved?: boolean, query?: string, count?: number, offset?: number, options?: any): AxiosPromise<GroupMembersModel> {
            return localVarFp.apiV1GroupsIdMembersGet(id, groupId, userId, isNotApproved, query, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create group member
         * @param {number} id 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMembersPost(id: number, userId?: number, options?: any): AxiosPromise<GroupMemberModel> {
            return localVarFp.apiV1GroupsIdMembersPost(id, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update group member
         * @param {number} id 
         * @param {GroupMemberUpdateModel} [groupMemberUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMembersPut(id: number, groupMemberUpdateModel?: GroupMemberUpdateModel, options?: any): AxiosPromise<GroupMemberModel> {
            return localVarFp.apiV1GroupsIdMembersPut(id, groupMemberUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create group members
         * @param {number} id 
         * @param {CreateGroupMembersFilterRequest} [createGroupMembersFilterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdMultiMembersPost(id: number, createGroupMembersFilterRequest?: CreateGroupMembersFilterRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1GroupsIdMultiMembersPost(id, createGroupMembersFilterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get not group members by filter
         * @param {number} id Фильтр по группе
         * @param {string} [query] Фильтр по запросу
         * @param {number} [supervisorUserId] Фильтр по руководителю пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdNotMembersGet(id: number, query?: string, supervisorUserId?: number, count?: number, offset?: number, options?: any): AxiosPromise<UserPreviewModelSearchResult> {
            return localVarFp.apiV1GroupsIdNotMembersGet(id, query, supervisorUserId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<GroupModel> {
            return localVarFp.apiV1GroupsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get report by group id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdReportGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1GroupsIdReportGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Visit group
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsIdVisitPost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1GroupsIdVisitPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {GroupUpdateModel} [groupUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsPost(groupUpdateModel?: GroupUpdateModel, options?: any): AxiosPromise<GroupModel> {
            return localVarFp.apiV1GroupsPost(groupUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {GroupUpdateModel} [groupUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsPut(groupUpdateModel?: GroupUpdateModel, options?: any): AxiosPromise<GroupModel> {
            return localVarFp.apiV1GroupsPut(groupUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get group visit history
         * @param {number} [userId] ID пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GroupsVisitHistoryGet(userId?: number, count?: number, offset?: number, options?: any): AxiosPromise<GroupVisitHistoryModelSearchResult> {
            return localVarFp.apiV1GroupsVisitHistoryGet(userId, count, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1GroupsGet operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsGetRequest
 */
export interface GroupApiApiV1GroupsGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly userId?: number

    /**
     * Фильтр по администратору группы
     * @type {boolean}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly isGroupAdmin?: boolean

    /**
     * Фильтр по типу содержания поста
     * @type {WallContentType}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly wallContentType?: WallContentType

    /**
     * Фильтр для отображения не подтверждённых постов
     * @type {boolean}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly isNotApproved?: boolean

    /**
     * Фильтр для отображения постов в архиве
     * @type {boolean}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly isArchived?: boolean

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly countryCategoryId?: number

    /**
     * Фильтр по тексту
     * @type {string}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly query?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof GroupApiApiV1GroupsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1GroupsIdDelete operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdDeleteRequest
 */
export interface GroupApiApiV1GroupsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1GroupsIdGet operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdGetRequest
 */
export interface GroupApiApiV1GroupsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1GroupsIdMembersDelete operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdMembersDeleteRequest
 */
export interface GroupApiApiV1GroupsIdMembersDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersDelete
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersDelete
     */
    readonly userId?: number
}

/**
 * Request parameters for apiV1GroupsIdMembersGet operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdMembersGetRequest
 */
export interface GroupApiApiV1GroupsIdMembersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersGet
     */
    readonly id: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersGet
     */
    readonly groupId?: number

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersGet
     */
    readonly userId?: number

    /**
     * Фильтр по не подтвердённым пользователям
     * @type {boolean}
     * @memberof GroupApiApiV1GroupsIdMembersGet
     */
    readonly isNotApproved?: boolean

    /**
     * Фильтр по запросу
     * @type {string}
     * @memberof GroupApiApiV1GroupsIdMembersGet
     */
    readonly query?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1GroupsIdMembersPost operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdMembersPostRequest
 */
export interface GroupApiApiV1GroupsIdMembersPostRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersPost
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersPost
     */
    readonly userId?: number
}

/**
 * Request parameters for apiV1GroupsIdMembersPut operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdMembersPutRequest
 */
export interface GroupApiApiV1GroupsIdMembersPutRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMembersPut
     */
    readonly id: number

    /**
     * 
     * @type {GroupMemberUpdateModel}
     * @memberof GroupApiApiV1GroupsIdMembersPut
     */
    readonly groupMemberUpdateModel?: GroupMemberUpdateModel
}

/**
 * Request parameters for apiV1GroupsIdMultiMembersPost operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdMultiMembersPostRequest
 */
export interface GroupApiApiV1GroupsIdMultiMembersPostRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdMultiMembersPost
     */
    readonly id: number

    /**
     * 
     * @type {CreateGroupMembersFilterRequest}
     * @memberof GroupApiApiV1GroupsIdMultiMembersPost
     */
    readonly createGroupMembersFilterRequest?: CreateGroupMembersFilterRequest
}

/**
 * Request parameters for apiV1GroupsIdNotMembersGet operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdNotMembersGetRequest
 */
export interface GroupApiApiV1GroupsIdNotMembersGetRequest {
    /**
     * Фильтр по группе
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdNotMembersGet
     */
    readonly id: number

    /**
     * Фильтр по запросу
     * @type {string}
     * @memberof GroupApiApiV1GroupsIdNotMembersGet
     */
    readonly query?: string

    /**
     * Фильтр по руководителю пользователя
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdNotMembersGet
     */
    readonly supervisorUserId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdNotMembersGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdNotMembersGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1GroupsIdPatch operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdPatchRequest
 */
export interface GroupApiApiV1GroupsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof GroupApiApiV1GroupsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1GroupsIdReportGet operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdReportGetRequest
 */
export interface GroupApiApiV1GroupsIdReportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdReportGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1GroupsIdVisitPost operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsIdVisitPostRequest
 */
export interface GroupApiApiV1GroupsIdVisitPostRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupApiApiV1GroupsIdVisitPost
     */
    readonly id: number
}

/**
 * Request parameters for apiV1GroupsPost operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsPostRequest
 */
export interface GroupApiApiV1GroupsPostRequest {
    /**
     * 
     * @type {GroupUpdateModel}
     * @memberof GroupApiApiV1GroupsPost
     */
    readonly groupUpdateModel?: GroupUpdateModel
}

/**
 * Request parameters for apiV1GroupsPut operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsPutRequest
 */
export interface GroupApiApiV1GroupsPutRequest {
    /**
     * 
     * @type {GroupUpdateModel}
     * @memberof GroupApiApiV1GroupsPut
     */
    readonly groupUpdateModel?: GroupUpdateModel
}

/**
 * Request parameters for apiV1GroupsVisitHistoryGet operation in GroupApi.
 * @export
 * @interface GroupApiApiV1GroupsVisitHistoryGetRequest
 */
export interface GroupApiApiV1GroupsVisitHistoryGetRequest {
    /**
     * ID пользователя
     * @type {number}
     * @memberof GroupApiApiV1GroupsVisitHistoryGet
     */
    readonly userId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof GroupApiApiV1GroupsVisitHistoryGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof GroupApiApiV1GroupsVisitHistoryGet
     */
    readonly offset?: number
}

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {GroupApiApiV1GroupsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsGet(requestParameters: GroupApiApiV1GroupsGetRequest = {}, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsGet(requestParameters.userId, requestParameters.isGroupAdmin, requestParameters.wallContentType, requestParameters.isNotApproved, requestParameters.isArchived, requestParameters.countryCategoryId, requestParameters.query, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {GroupApiApiV1GroupsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdDelete(requestParameters: GroupApiApiV1GroupsIdDeleteRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {GroupApiApiV1GroupsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdGet(requestParameters: GroupApiApiV1GroupsIdGetRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete group member
     * @param {GroupApiApiV1GroupsIdMembersDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdMembersDelete(requestParameters: GroupApiApiV1GroupsIdMembersDeleteRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdMembersDelete(requestParameters.id, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get group members by filter
     * @param {GroupApiApiV1GroupsIdMembersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdMembersGet(requestParameters: GroupApiApiV1GroupsIdMembersGetRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdMembersGet(requestParameters.id, requestParameters.groupId, requestParameters.userId, requestParameters.isNotApproved, requestParameters.query, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create group member
     * @param {GroupApiApiV1GroupsIdMembersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdMembersPost(requestParameters: GroupApiApiV1GroupsIdMembersPostRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdMembersPost(requestParameters.id, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update group member
     * @param {GroupApiApiV1GroupsIdMembersPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdMembersPut(requestParameters: GroupApiApiV1GroupsIdMembersPutRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdMembersPut(requestParameters.id, requestParameters.groupMemberUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create group members
     * @param {GroupApiApiV1GroupsIdMultiMembersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdMultiMembersPost(requestParameters: GroupApiApiV1GroupsIdMultiMembersPostRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdMultiMembersPost(requestParameters.id, requestParameters.createGroupMembersFilterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get not group members by filter
     * @param {GroupApiApiV1GroupsIdNotMembersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdNotMembersGet(requestParameters: GroupApiApiV1GroupsIdNotMembersGetRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdNotMembersGet(requestParameters.id, requestParameters.query, requestParameters.supervisorUserId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {GroupApiApiV1GroupsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdPatch(requestParameters: GroupApiApiV1GroupsIdPatchRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get report by group id
     * @param {GroupApiApiV1GroupsIdReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdReportGet(requestParameters: GroupApiApiV1GroupsIdReportGetRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdReportGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Visit group
     * @param {GroupApiApiV1GroupsIdVisitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsIdVisitPost(requestParameters: GroupApiApiV1GroupsIdVisitPostRequest, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsIdVisitPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {GroupApiApiV1GroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsPost(requestParameters: GroupApiApiV1GroupsPostRequest = {}, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsPost(requestParameters.groupUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {GroupApiApiV1GroupsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsPut(requestParameters: GroupApiApiV1GroupsPutRequest = {}, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsPut(requestParameters.groupUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get group visit history
     * @param {GroupApiApiV1GroupsVisitHistoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiV1GroupsVisitHistoryGet(requestParameters: GroupApiApiV1GroupsVisitHistoryGetRequest = {}, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).apiV1GroupsVisitHistoryGet(requestParameters.userId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}
