import { VFC } from 'react';

import { PostContentModel } from 'services/api';

import { PostMediaAttachmentGrid } from '../PostStandardContent/PostMediaAttachmentGrid';
import { PostOpenGraphContent } from '../PostStandardContent/PostOpenGraphContent';
import { PostTextContent } from '../PostStandardContent/PostTextContent';

export type IdeaContentProps = {
  data: PostContentModel;
};

export const IdeaContent: VFC<IdeaContentProps> = ({ data }) => {
  const isSlimOpenGraph = () => {
    return (
      data &&
      data.postContentAttachments &&
      data.postContentAttachments?.length > 0
    );
  };

  return (
    <>
      <PostTextContent data={data} />
      {data.postContentAttachments &&
        data.postContentAttachments?.length > 0 && (
          <PostMediaAttachmentGrid
            attachmentPreviewHeight={260}
            attachments={data.postContentAttachments}
          />
        )}
      {data.openGraphItem && (
        <PostOpenGraphContent
          openGraph={data.openGraphItem}
          type={isSlimOpenGraph() ? 'small' : 'default'}
        />
      )}
    </>
  );
};
