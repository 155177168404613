import styled from 'styled-components';

import { Paper } from 'ui/index';

export const ModalPaper = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
`;

export const PushDescription = styled.div`
  border-radius: 8px;
  padding: 8px 16px;
  background-color: #f6f8fa;
  margin-bottom: 16px;
`;
