/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { OfferCreateModel } from '../models';
// @ts-ignore
import { OfferLogModel } from '../models';
// @ts-ignore
import { OfferModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get offers by filters
         * @param {string} [clientId] ID клиента GA
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OffersGet: async (clientId?: string, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['ClientId'] = clientId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create user offer
         * @param {OfferCreateModel} [offerCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OffersPost: async (offerCreateModel?: OfferCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get offers by filters
         * @param {string} [clientId] ID клиента GA
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OffersGet(clientId?: string, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfferModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OffersGet(clientId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create user offer
         * @param {OfferCreateModel} [offerCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1OffersPost(offerCreateModel?: OfferCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferLogModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1OffersPost(offerCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Get offers by filters
         * @param {string} [clientId] ID клиента GA
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OffersGet(clientId?: string, count?: number, offset?: number, options?: any): AxiosPromise<Array<OfferModel>> {
            return localVarFp.apiV1OffersGet(clientId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create user offer
         * @param {OfferCreateModel} [offerCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1OffersPost(offerCreateModel?: OfferCreateModel, options?: any): AxiosPromise<OfferLogModel> {
            return localVarFp.apiV1OffersPost(offerCreateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1OffersGet operation in OfferApi.
 * @export
 * @interface OfferApiApiV1OffersGetRequest
 */
export interface OfferApiApiV1OffersGetRequest {
    /**
     * ID клиента GA
     * @type {string}
     * @memberof OfferApiApiV1OffersGet
     */
    readonly clientId?: string

    /**
     * Количеству объектов
     * @type {number}
     * @memberof OfferApiApiV1OffersGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof OfferApiApiV1OffersGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1OffersPost operation in OfferApi.
 * @export
 * @interface OfferApiApiV1OffersPostRequest
 */
export interface OfferApiApiV1OffersPostRequest {
    /**
     * 
     * @type {OfferCreateModel}
     * @memberof OfferApiApiV1OffersPost
     */
    readonly offerCreateModel?: OfferCreateModel
}

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Get offers by filters
     * @param {OfferApiApiV1OffersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiV1OffersGet(requestParameters: OfferApiApiV1OffersGetRequest = {}, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).apiV1OffersGet(requestParameters.clientId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create user offer
     * @param {OfferApiApiV1OffersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiV1OffersPost(requestParameters: OfferApiApiV1OffersPostRequest = {}, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).apiV1OffersPost(requestParameters.offerCreateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
