import { VFC } from 'react';

import { useNavigate } from 'react-router-dom';

import { TwoColumnList } from 'components/Employees/TwoColumnList';
import { ExtendedUserPreview } from 'components/Employees/TwoColumnList/types';

import { UsersListProps } from './types';

export const Users: VFC<UsersListProps> = ({ users }) => {
  const navigate = useNavigate();

  return (
    <>
      {users && (
        <TwoColumnList
          employees={users as ExtendedUserPreview[]}
          handleClick={(fellowId: number) => {
            navigate(`/profile/${fellowId}`);
          }}
        />
      )}
    </>
  );
};
