import { VFC } from 'react';

import { Spin } from 'antd';

import { useTranslation } from 'services/i18n';

import { EmptyItem, ItemContainer, List, LoadingMoreItem } from './styles';
import { QueryHistoryListProps } from './types';

export const QueryHistoryList: VFC<QueryHistoryListProps> = ({
  data,
  showLoadingMoreElement = false,
  loadingMoreElementRef,
  onItemClick,
}) => {
  const { t } = useTranslation('common');

  const isEmpty = data.length === 0;

  return (
    <List>
      {data.map((searchData) => (
        <ItemContainer onClick={() => onItemClick(searchData.text)}>
          {searchData.text}
        </ItemContainer>
      ))}
      {isEmpty && <EmptyItem>{t('empty_list')}</EmptyItem>}
      {!isEmpty && showLoadingMoreElement && (
        <LoadingMoreItem ref={loadingMoreElementRef}>
          <Spin />
        </LoadingMoreItem>
      )}
    </List>
  );
};
