import { VFC, useMemo, useState } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Spin } from 'antd';

import { TwoColumnList } from 'components/Employees/TwoColumnList';
import { ExtendedUserPreview } from 'components/Employees/TwoColumnList/types';
import { UserPreview } from 'domain/user';
import { SpinContainer } from 'pages/SearchResultsPage/styles';
import { api } from 'services/api';

import { ColleaguesListProps, EmployeeQueryParams } from './types';

export const getColleaguesQuery = async ({
  userId,
  groupId,
  count,
  offset,
  signal,
}: EmployeeQueryParams) => {
  if (groupId) {
    const { data } = await api.group.apiV1GroupsIdMembersGet(
      {
        id: Number(groupId),
        count,
        offset,
      },
      { signal },
    );

    const users = data.items?.map(({ user }) => user);

    return { items: (users as UserPreview[]) ?? [], total: data.total };
  }

  const { data } = await api.user.apiV1UsersUserIdColleaguesGet(
    {
      userId: Number(userId),
      count,
      offset,
    },
    { signal },
  );

  return { items: data.items ?? [], total: data.total };
};

export const Colleagues: VFC<ColleaguesListProps> = ({
  groupId,
  userId,
  handleUserClick,
}) => {
  const [offset, setOffset] = useState(10);
  const COUNT_STEP = 10;

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    [],
    () =>
      getColleaguesQuery({
        userId,
        groupId,
        count: COUNT_STEP,
        offset: 0,
      }),
    {
      suspense: false,
    },
  );

  const { mutate: onLoadMore, isError } = useMutation(
    () =>
      getColleaguesQuery({
        userId,
        groupId,
        offset,
        count: COUNT_STEP,
      }),
    {
      onSuccess: (newData) => {
        setOffset((prewState) => prewState + COUNT_STEP);
        data?.items.push(...newData.items);
      },
    },
  );

  const hasNextPage = useMemo(
    () => (data ? data?.items.length < data.total : false),
    [data],
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    disabled: isError,
    hasNextPage,
    onLoadMore,
  });

  return (
    <>
      {data && (
        <TwoColumnList
          employees={data.items as ExtendedUserPreview[]}
          handleClick={(fellowId: number) => {
            navigate(`/profile/${fellowId}`);
            handleUserClick();
          }}
          isLoading={isLoading}
        />
      )}
      {(isLoading || hasNextPage) && (
        <SpinContainer ref={sentryRef}>
          <Spin />
        </SpinContainer>
      )}
    </>
  );
};
