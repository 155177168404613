/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { NotificationModel } from '../models';
// @ts-ignore
import { NotificationPreviewsModel } from '../models';
// @ts-ignore
import { NotificationUpdateModel } from '../models';
// @ts-ignore
import { NotificationsModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {boolean} [showRead] Фильтр для отображения прочитанных уведомлений
         * @param {boolean} [isPushNotification] Фильтр по пуш-уведомлениям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsGet: async (showRead?: boolean, isPushNotification?: boolean, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (showRead !== undefined) {
                localVarQueryParameter['ShowRead'] = showRead;
            }

            if (isPushNotification !== undefined) {
                localVarQueryParameter['IsPushNotification'] = isPushNotification;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1NotificationsIdGet', 'id', id)
            const localVarPath = `/api/v1/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get preview models by filter
         * @param {boolean} [showRead] Фильтр для отображения прочитанных уведомлений
         * @param {boolean} [isPushNotification] Фильтр по пуш-уведомлениям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsPreviewGet: async (showRead?: boolean, isPushNotification?: boolean, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (showRead !== undefined) {
                localVarQueryParameter['ShowRead'] = showRead;
            }

            if (isPushNotification !== undefined) {
                localVarQueryParameter['IsPushNotification'] = isPushNotification;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {NotificationUpdateModel} [notificationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsPut: async (notificationUpdateModel?: NotificationUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsReadAllPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications/read-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {boolean} [showRead] Фильтр для отображения прочитанных уведомлений
         * @param {boolean} [isPushNotification] Фильтр по пуш-уведомлениям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsGet(showRead?: boolean, isPushNotification?: boolean, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsGet(showRead, isPushNotification, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get preview models by filter
         * @param {boolean} [showRead] Фильтр для отображения прочитанных уведомлений
         * @param {boolean} [isPushNotification] Фильтр по пуш-уведомлениям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsPreviewGet(showRead?: boolean, isPushNotification?: boolean, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationPreviewsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsPreviewGet(showRead, isPushNotification, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {NotificationUpdateModel} [notificationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsPut(notificationUpdateModel?: NotificationUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsPut(notificationUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationsReadAllPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationsReadAllPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {boolean} [showRead] Фильтр для отображения прочитанных уведомлений
         * @param {boolean} [isPushNotification] Фильтр по пуш-уведомлениям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsGet(showRead?: boolean, isPushNotification?: boolean, count?: number, offset?: number, options?: any): AxiosPromise<NotificationsModel> {
            return localVarFp.apiV1NotificationsGet(showRead, isPushNotification, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsIdGet(id: number, options?: any): AxiosPromise<NotificationModel> {
            return localVarFp.apiV1NotificationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get preview models by filter
         * @param {boolean} [showRead] Фильтр для отображения прочитанных уведомлений
         * @param {boolean} [isPushNotification] Фильтр по пуш-уведомлениям
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsPreviewGet(showRead?: boolean, isPushNotification?: boolean, count?: number, offset?: number, options?: any): AxiosPromise<NotificationPreviewsModel> {
            return localVarFp.apiV1NotificationsPreviewGet(showRead, isPushNotification, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {NotificationUpdateModel} [notificationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsPut(notificationUpdateModel?: NotificationUpdateModel, options?: any): AxiosPromise<NotificationModel> {
            return localVarFp.apiV1NotificationsPut(notificationUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationsReadAllPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1NotificationsReadAllPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1NotificationsGet operation in NotificationApi.
 * @export
 * @interface NotificationApiApiV1NotificationsGetRequest
 */
export interface NotificationApiApiV1NotificationsGetRequest {
    /**
     * Фильтр для отображения прочитанных уведомлений
     * @type {boolean}
     * @memberof NotificationApiApiV1NotificationsGet
     */
    readonly showRead?: boolean

    /**
     * Фильтр по пуш-уведомлениям
     * @type {boolean}
     * @memberof NotificationApiApiV1NotificationsGet
     */
    readonly isPushNotification?: boolean

    /**
     * Количеству объектов
     * @type {number}
     * @memberof NotificationApiApiV1NotificationsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof NotificationApiApiV1NotificationsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1NotificationsIdGet operation in NotificationApi.
 * @export
 * @interface NotificationApiApiV1NotificationsIdGetRequest
 */
export interface NotificationApiApiV1NotificationsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiApiV1NotificationsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1NotificationsPreviewGet operation in NotificationApi.
 * @export
 * @interface NotificationApiApiV1NotificationsPreviewGetRequest
 */
export interface NotificationApiApiV1NotificationsPreviewGetRequest {
    /**
     * Фильтр для отображения прочитанных уведомлений
     * @type {boolean}
     * @memberof NotificationApiApiV1NotificationsPreviewGet
     */
    readonly showRead?: boolean

    /**
     * Фильтр по пуш-уведомлениям
     * @type {boolean}
     * @memberof NotificationApiApiV1NotificationsPreviewGet
     */
    readonly isPushNotification?: boolean

    /**
     * Количеству объектов
     * @type {number}
     * @memberof NotificationApiApiV1NotificationsPreviewGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof NotificationApiApiV1NotificationsPreviewGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1NotificationsPut operation in NotificationApi.
 * @export
 * @interface NotificationApiApiV1NotificationsPutRequest
 */
export interface NotificationApiApiV1NotificationsPutRequest {
    /**
     * 
     * @type {NotificationUpdateModel}
     * @memberof NotificationApiApiV1NotificationsPut
     */
    readonly notificationUpdateModel?: NotificationUpdateModel
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {NotificationApiApiV1NotificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiV1NotificationsGet(requestParameters: NotificationApiApiV1NotificationsGetRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiV1NotificationsGet(requestParameters.showRead, requestParameters.isPushNotification, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {NotificationApiApiV1NotificationsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiV1NotificationsIdGet(requestParameters: NotificationApiApiV1NotificationsIdGetRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiV1NotificationsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get preview models by filter
     * @param {NotificationApiApiV1NotificationsPreviewGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiV1NotificationsPreviewGet(requestParameters: NotificationApiApiV1NotificationsPreviewGetRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiV1NotificationsPreviewGet(requestParameters.showRead, requestParameters.isPushNotification, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {NotificationApiApiV1NotificationsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiV1NotificationsPut(requestParameters: NotificationApiApiV1NotificationsPutRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiV1NotificationsPut(requestParameters.notificationUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiV1NotificationsReadAllPost(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiV1NotificationsReadAllPost(options).then((request) => request(this.axios, this.basePath));
    }
}
