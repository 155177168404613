import { Context, FC, createContext, useContext } from 'react';

import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';
import {
  DateFnsLocaleService,
  useDateFnsLocaleService,
} from './useDateFnsLocaleService';

export { useTranslation, Trans } from 'react-i18next';

const DateFnsLocaleContext = createContext<DateFnsLocaleService | null>(null);

export const useDateFnsLocale = (): DateFnsLocaleService =>
  useContext(DateFnsLocaleContext as Context<DateFnsLocaleService>);

export const I18nProvider: FC = ({ children }) => {
  const dateFnsLocaleService = useDateFnsLocaleService();

  return (
    <I18nextProvider i18n={i18n}>
      <DateFnsLocaleContext.Provider value={dateFnsLocaleService}>
        {children}
      </DateFnsLocaleContext.Provider>
    </I18nextProvider>
  );
};
