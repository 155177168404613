import { PropsWithChildren, useCallback } from 'react';

import { ChevronDoubleRight as ChevronIcon } from '@styled-icons/heroicons-outline';

import { getPhotoPreview } from 'domain/photo';
import { AnyUser } from 'domain/user';
import { Avatar } from 'ui/Avatar';

import {
  AvatarButtom,
  AvatarsContainer,
  ChevroneButton,
  StyledTooltip,
  UsersTooltipHeader,
} from './styles';
import { UsersTooltipProps } from './types';

export const UsersTooltip = <T extends AnyUser>(
  props: PropsWithChildren<UsersTooltipProps<T>>,
) => {
  const {
    active = true,
    title,
    users = [],
    placement = 'top',
    children,
    onAvatarClick,
    onChevroneClick,
  } = props;

  const handleChevroneClick = useCallback(() => {
    onChevroneClick?.();
  }, [onChevroneClick]);

  const handleAvatarClick = useCallback(
    (user: T) => {
      onAvatarClick?.(user);
    },
    [onAvatarClick],
  );

  const renderToltipContent = useCallback(
    () => (
      <>
        <UsersTooltipHeader>
          {title}
          {users?.length > 5 && (
            <ChevroneButton onClick={handleChevroneClick}>
              <ChevronIcon size={16} />
            </ChevroneButton>
          )}
        </UsersTooltipHeader>
        <AvatarsContainer>
          {users.slice(0, 5).map((user) => (
            <AvatarButtom
              key={user?.avatar?.id}
              onClick={() => handleAvatarClick(user)}
            >
              <Avatar size={40} src={getPhotoPreview(user.avatar)} />
            </AvatarButtom>
          ))}
        </AvatarsContainer>
      </>
    ),
    [handleAvatarClick, handleChevroneClick, title, users],
  );

  return (
    <>
      {active ? (
        <StyledTooltip
          placement={placement}
          title={renderToltipContent()}
          trigger={['hover', 'focus']}
        >
          {children}
        </StyledTooltip>
      ) : (
        children
      )}
    </>
  );
};
