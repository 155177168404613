/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { PollCreateRequest } from '../models';
// @ts-ignore
import { PollDto } from '../models';
// @ts-ignore
import { PollUpdateRequest } from '../models';
// @ts-ignore
import { PollUserVoteRequestBody } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * PollApi - axios parameter creator
 * @export
 */
export const PollApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete wall poll
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PollsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PollsIdDelete', 'id', id)
            const localVarPath = `/api/v1/polls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Vote to poll
         * @param {number} id 
         * @param {PollUserVoteRequestBody} [pollUserVoteRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PollsIdVotePost: async (id: number, pollUserVoteRequestBody?: PollUserVoteRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PollsIdVotePost', 'id', id)
            const localVarPath = `/api/v1/polls/{id}/vote`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pollUserVoteRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create wall poll
         * @param {PollCreateRequest} [pollCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PollsPost: async (pollCreateRequest?: PollCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/polls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pollCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create wall poll
         * @param {PollUpdateRequest} [pollUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PollsPut: async (pollUpdateRequest?: PollUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/polls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pollUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PollApi - functional programming interface
 * @export
 */
export const PollApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PollApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete wall poll
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PollsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PollsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Vote to poll
         * @param {number} id 
         * @param {PollUserVoteRequestBody} [pollUserVoteRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PollsIdVotePost(id: number, pollUserVoteRequestBody?: PollUserVoteRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PollsIdVotePost(id, pollUserVoteRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create wall poll
         * @param {PollCreateRequest} [pollCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PollsPost(pollCreateRequest?: PollCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PollDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PollsPost(pollCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create wall poll
         * @param {PollUpdateRequest} [pollUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PollsPut(pollUpdateRequest?: PollUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PollDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PollsPut(pollUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PollApi - factory interface
 * @export
 */
export const PollApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PollApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete wall poll
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PollsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PollsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Vote to poll
         * @param {number} id 
         * @param {PollUserVoteRequestBody} [pollUserVoteRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PollsIdVotePost(id: number, pollUserVoteRequestBody?: PollUserVoteRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PollsIdVotePost(id, pollUserVoteRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create wall poll
         * @param {PollCreateRequest} [pollCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PollsPost(pollCreateRequest?: PollCreateRequest, options?: any): AxiosPromise<PollDto> {
            return localVarFp.apiV1PollsPost(pollCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create wall poll
         * @param {PollUpdateRequest} [pollUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PollsPut(pollUpdateRequest?: PollUpdateRequest, options?: any): AxiosPromise<PollDto> {
            return localVarFp.apiV1PollsPut(pollUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1PollsIdDelete operation in PollApi.
 * @export
 * @interface PollApiApiV1PollsIdDeleteRequest
 */
export interface PollApiApiV1PollsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof PollApiApiV1PollsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1PollsIdVotePost operation in PollApi.
 * @export
 * @interface PollApiApiV1PollsIdVotePostRequest
 */
export interface PollApiApiV1PollsIdVotePostRequest {
    /**
     * 
     * @type {number}
     * @memberof PollApiApiV1PollsIdVotePost
     */
    readonly id: number

    /**
     * 
     * @type {PollUserVoteRequestBody}
     * @memberof PollApiApiV1PollsIdVotePost
     */
    readonly pollUserVoteRequestBody?: PollUserVoteRequestBody
}

/**
 * Request parameters for apiV1PollsPost operation in PollApi.
 * @export
 * @interface PollApiApiV1PollsPostRequest
 */
export interface PollApiApiV1PollsPostRequest {
    /**
     * 
     * @type {PollCreateRequest}
     * @memberof PollApiApiV1PollsPost
     */
    readonly pollCreateRequest?: PollCreateRequest
}

/**
 * Request parameters for apiV1PollsPut operation in PollApi.
 * @export
 * @interface PollApiApiV1PollsPutRequest
 */
export interface PollApiApiV1PollsPutRequest {
    /**
     * 
     * @type {PollUpdateRequest}
     * @memberof PollApiApiV1PollsPut
     */
    readonly pollUpdateRequest?: PollUpdateRequest
}

/**
 * PollApi - object-oriented interface
 * @export
 * @class PollApi
 * @extends {BaseAPI}
 */
export class PollApi extends BaseAPI {
    /**
     * 
     * @summary Delete wall poll
     * @param {PollApiApiV1PollsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollApi
     */
    public apiV1PollsIdDelete(requestParameters: PollApiApiV1PollsIdDeleteRequest, options?: AxiosRequestConfig) {
        return PollApiFp(this.configuration).apiV1PollsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Vote to poll
     * @param {PollApiApiV1PollsIdVotePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollApi
     */
    public apiV1PollsIdVotePost(requestParameters: PollApiApiV1PollsIdVotePostRequest, options?: AxiosRequestConfig) {
        return PollApiFp(this.configuration).apiV1PollsIdVotePost(requestParameters.id, requestParameters.pollUserVoteRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create wall poll
     * @param {PollApiApiV1PollsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollApi
     */
    public apiV1PollsPost(requestParameters: PollApiApiV1PollsPostRequest = {}, options?: AxiosRequestConfig) {
        return PollApiFp(this.configuration).apiV1PollsPost(requestParameters.pollCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create wall poll
     * @param {PollApiApiV1PollsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollApi
     */
    public apiV1PollsPut(requestParameters: PollApiApiV1PollsPutRequest = {}, options?: AxiosRequestConfig) {
        return PollApiFp(this.configuration).apiV1PollsPut(requestParameters.pollUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
