/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { RoleName } from '../models';
// @ts-ignore
import { SectionPageLinkDtoSearchResult } from '../models';
// @ts-ignore
import { UpdateUserVisibleSectionPagesBody } from '../models';
// @ts-ignore
import { UserFriendModel } from '../models';
// @ts-ignore
import { UserNavBarModel } from '../models';
// @ts-ignore
import { UserProfileModel } from '../models';
// @ts-ignore
import { UserUpdateModel } from '../models';
// @ts-ignore
import { UsersModel } from '../models';
// @ts-ignore
import { UsersPreviewModel } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Set country for current user
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersCountryPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersCurrentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export users by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersExportGet: async (supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (supervisorUserId !== undefined) {
                localVarQueryParameter['SupervisorUserId'] = supervisorUserId;
            }

            if (departmentUserId !== undefined) {
                localVarQueryParameter['DepartmentUserId'] = departmentUserId;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (hasSupervisorUserId !== undefined) {
                localVarQueryParameter['HasSupervisorUserId'] = hasSupervisorUserId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['DepartmentId'] = departmentId;
            }

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (isSubscriber !== undefined) {
                localVarQueryParameter['IsSubscriber'] = isSubscriber;
            }

            if (isSubscription !== undefined) {
                localVarQueryParameter['IsSubscription'] = isSubscription;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['IsArchived'] = isArchived;
            }

            if (orderByCoins !== undefined) {
                localVarQueryParameter['OrderByCoins'] = orderByCoins;
            }

            if (birthDateFrom !== undefined) {
                localVarQueryParameter['BirthDateFrom'] = (birthDateFrom as any instanceof Date) ?
                    (birthDateFrom as any).toISOString() :
                    birthDateFrom;
            }

            if (birthDateTo !== undefined) {
                localVarQueryParameter['BirthDateTo'] = (birthDateTo as any instanceof Date) ?
                    (birthDateTo as any).toISOString() :
                    birthDateTo;
            }

            if (dateOfEmploymentFrom !== undefined) {
                localVarQueryParameter['DateOfEmploymentFrom'] = (dateOfEmploymentFrom as any instanceof Date) ?
                    (dateOfEmploymentFrom as any).toISOString() :
                    dateOfEmploymentFrom;
            }

            if (dateOfEmploymentTo !== undefined) {
                localVarQueryParameter['DateOfEmploymentTo'] = (dateOfEmploymentTo as any instanceof Date) ?
                    (dateOfEmploymentTo as any).toISOString() :
                    dateOfEmploymentTo;
            }

            if (exceptUserId !== undefined) {
                localVarQueryParameter['ExceptUserId'] = exceptUserId;
            }

            if (isColleguesSearch !== undefined) {
                localVarQueryParameter['IsColleguesSearch'] = isColleguesSearch;
            }

            if (firstLastNameLetter !== undefined) {
                localVarQueryParameter['FirstLastNameLetter'] = firstLastNameLetter;
            }

            if (orderByLastName !== undefined) {
                localVarQueryParameter['OrderByLastName'] = orderByLastName;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user friend
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersFriendDelete: async (userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/friend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add user friend
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersFriendPost: async (userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/friend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get models by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersGet: async (supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (supervisorUserId !== undefined) {
                localVarQueryParameter['SupervisorUserId'] = supervisorUserId;
            }

            if (departmentUserId !== undefined) {
                localVarQueryParameter['DepartmentUserId'] = departmentUserId;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (hasSupervisorUserId !== undefined) {
                localVarQueryParameter['HasSupervisorUserId'] = hasSupervisorUserId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['DepartmentId'] = departmentId;
            }

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (isSubscriber !== undefined) {
                localVarQueryParameter['IsSubscriber'] = isSubscriber;
            }

            if (isSubscription !== undefined) {
                localVarQueryParameter['IsSubscription'] = isSubscription;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['IsArchived'] = isArchived;
            }

            if (orderByCoins !== undefined) {
                localVarQueryParameter['OrderByCoins'] = orderByCoins;
            }

            if (birthDateFrom !== undefined) {
                localVarQueryParameter['BirthDateFrom'] = (birthDateFrom as any instanceof Date) ?
                    (birthDateFrom as any).toISOString() :
                    birthDateFrom;
            }

            if (birthDateTo !== undefined) {
                localVarQueryParameter['BirthDateTo'] = (birthDateTo as any instanceof Date) ?
                    (birthDateTo as any).toISOString() :
                    birthDateTo;
            }

            if (dateOfEmploymentFrom !== undefined) {
                localVarQueryParameter['DateOfEmploymentFrom'] = (dateOfEmploymentFrom as any instanceof Date) ?
                    (dateOfEmploymentFrom as any).toISOString() :
                    dateOfEmploymentFrom;
            }

            if (dateOfEmploymentTo !== undefined) {
                localVarQueryParameter['DateOfEmploymentTo'] = (dateOfEmploymentTo as any instanceof Date) ?
                    (dateOfEmploymentTo as any).toISOString() :
                    dateOfEmploymentTo;
            }

            if (exceptUserId !== undefined) {
                localVarQueryParameter['ExceptUserId'] = exceptUserId;
            }

            if (isColleguesSearch !== undefined) {
                localVarQueryParameter['IsColleguesSearch'] = isColleguesSearch;
            }

            if (firstLastNameLetter !== undefined) {
                localVarQueryParameter['FirstLastNameLetter'] = firstLastNameLetter;
            }

            if (orderByLastName !== undefined) {
                localVarQueryParameter['OrderByLastName'] = orderByLastName;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UsersIdDelete', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UsersIdGet', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UsersIdPatch', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set language for current user
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersLanguagePost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get last name letters by country
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersLettersGet: async (countryId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/letters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user navbar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersNavbarGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/navbar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update navbar
         * @param {UserNavBarModel} [userNavBarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersNavbarPut: async (userNavBarModel?: UserNavBarModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/navbar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNavBarModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {UserProfileModel} [userProfileModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersPost: async (userProfileModel?: UserProfileModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get preview models by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersPreviewGet: async (supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (supervisorUserId !== undefined) {
                localVarQueryParameter['SupervisorUserId'] = supervisorUserId;
            }

            if (departmentUserId !== undefined) {
                localVarQueryParameter['DepartmentUserId'] = departmentUserId;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (hasSupervisorUserId !== undefined) {
                localVarQueryParameter['HasSupervisorUserId'] = hasSupervisorUserId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['DepartmentId'] = departmentId;
            }

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (isSubscriber !== undefined) {
                localVarQueryParameter['IsSubscriber'] = isSubscriber;
            }

            if (isSubscription !== undefined) {
                localVarQueryParameter['IsSubscription'] = isSubscription;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['IsArchived'] = isArchived;
            }

            if (orderByCoins !== undefined) {
                localVarQueryParameter['OrderByCoins'] = orderByCoins;
            }

            if (birthDateFrom !== undefined) {
                localVarQueryParameter['BirthDateFrom'] = (birthDateFrom as any instanceof Date) ?
                    (birthDateFrom as any).toISOString() :
                    birthDateFrom;
            }

            if (birthDateTo !== undefined) {
                localVarQueryParameter['BirthDateTo'] = (birthDateTo as any instanceof Date) ?
                    (birthDateTo as any).toISOString() :
                    birthDateTo;
            }

            if (dateOfEmploymentFrom !== undefined) {
                localVarQueryParameter['DateOfEmploymentFrom'] = (dateOfEmploymentFrom as any instanceof Date) ?
                    (dateOfEmploymentFrom as any).toISOString() :
                    dateOfEmploymentFrom;
            }

            if (dateOfEmploymentTo !== undefined) {
                localVarQueryParameter['DateOfEmploymentTo'] = (dateOfEmploymentTo as any instanceof Date) ?
                    (dateOfEmploymentTo as any).toISOString() :
                    dateOfEmploymentTo;
            }

            if (exceptUserId !== undefined) {
                localVarQueryParameter['ExceptUserId'] = exceptUserId;
            }

            if (isColleguesSearch !== undefined) {
                localVarQueryParameter['IsColleguesSearch'] = isColleguesSearch;
            }

            if (firstLastNameLetter !== undefined) {
                localVarQueryParameter['FirstLastNameLetter'] = firstLastNameLetter;
            }

            if (orderByLastName !== undefined) {
                localVarQueryParameter['OrderByLastName'] = orderByLastName;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersPut: async (userUpdateModel?: UserUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdColleaguesGet: async (userId: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdColleaguesGet', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/colleagues`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get visible Tuilife section pages
         * @param {number} userId Фильтр по пользователю
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSectionPagesVisibleGet: async (userId: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdSectionPagesVisibleGet', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/section-pages/visible`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {number} userId 
         * @param {UpdateUserVisibleSectionPagesBody} [updateUserVisibleSectionPagesBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSectionPagesVisiblePut: async (userId: number, updateUserVisibleSectionPagesBody?: UpdateUserVisibleSectionPagesBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdSectionPagesVisiblePut', 'userId', userId)
            const localVarPath = `/api/v1/users/{UserId}/section-pages/visible`
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserVisibleSectionPagesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All subscribed to the user
         * @param {number} userId User ID that others will subscribe to
         * @param {Array<number>} requestBody List of country identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSubscriptionsSignedAllPost: async (userId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiV1UsersUserIdSubscriptionsSignedAllPost', 'userId', userId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('apiV1UsersUserIdSubscriptionsSignedAllPost', 'requestBody', requestBody)
            const localVarPath = `/api/v1/users/{userId}/subscriptions/signed-all`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Set country for current user
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersCountryPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersCountryPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersCurrentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersCurrentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export users by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersExportGet(supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersExportGet(supervisorUserId, departmentUserId, countryId, hasSupervisorUserId, departmentId, query, isSubscriber, isSubscription, isArchived, orderByCoins, birthDateFrom, birthDateTo, dateOfEmploymentFrom, dateOfEmploymentTo, exceptUserId, isColleguesSearch, firstLastNameLetter, orderByLastName, role, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user friend
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersFriendDelete(userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersFriendDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add user friend
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersFriendPost(userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFriendModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersFriendPost(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get models by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersGet(supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersGet(supervisorUserId, departmentUserId, countryId, hasSupervisorUserId, departmentId, query, isSubscriber, isSubscription, isArchived, orderByCoins, birthDateFrom, birthDateTo, dateOfEmploymentFrom, dateOfEmploymentTo, exceptUserId, isColleguesSearch, firstLastNameLetter, orderByLastName, role, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set language for current user
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersLanguagePost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersLanguagePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get last name letters by country
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersLettersGet(countryId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersLettersGet(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user navbar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersNavbarGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNavBarModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersNavbarGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update navbar
         * @param {UserNavBarModel} [userNavBarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersNavbarPut(userNavBarModel?: UserNavBarModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersNavbarPut(userNavBarModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {UserProfileModel} [userProfileModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersPost(userProfileModel?: UserProfileModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersPost(userProfileModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get preview models by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersPreviewGet(supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreviewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersPreviewGet(supervisorUserId, departmentUserId, countryId, hasSupervisorUserId, departmentId, query, isSubscriber, isSubscription, isArchived, orderByCoins, birthDateFrom, birthDateTo, dateOfEmploymentFrom, dateOfEmploymentTo, exceptUserId, isColleguesSearch, firstLastNameLetter, orderByLastName, role, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersPut(userUpdateModel?: UserUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersPut(userUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdColleaguesGet(userId: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPreviewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdColleaguesGet(userId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get visible Tuilife section pages
         * @param {number} userId Фильтр по пользователю
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdSectionPagesVisibleGet(userId: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionPageLinkDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdSectionPagesVisibleGet(userId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {number} userId 
         * @param {UpdateUserVisibleSectionPagesBody} [updateUserVisibleSectionPagesBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdSectionPagesVisiblePut(userId: number, updateUserVisibleSectionPagesBody?: UpdateUserVisibleSectionPagesBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdSectionPagesVisiblePut(userId, updateUserVisibleSectionPagesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary All subscribed to the user
         * @param {number} userId User ID that others will subscribe to
         * @param {Array<number>} requestBody List of country identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUserIdSubscriptionsSignedAllPost(userId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUserIdSubscriptionsSignedAllPost(userId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Set country for current user
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersCountryPost(body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersCountryPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersCurrentGet(options?: any): AxiosPromise<UserProfileModel> {
            return localVarFp.apiV1UsersCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export users by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersExportGet(supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1UsersExportGet(supervisorUserId, departmentUserId, countryId, hasSupervisorUserId, departmentId, query, isSubscriber, isSubscription, isArchived, orderByCoins, birthDateFrom, birthDateTo, dateOfEmploymentFrom, dateOfEmploymentTo, exceptUserId, isColleguesSearch, firstLastNameLetter, orderByLastName, role, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user friend
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersFriendDelete(userId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersFriendDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add user friend
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersFriendPost(userId?: number, options?: any): AxiosPromise<UserFriendModel> {
            return localVarFp.apiV1UsersFriendPost(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get models by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersGet(supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options?: any): AxiosPromise<UsersModel> {
            return localVarFp.apiV1UsersGet(supervisorUserId, departmentUserId, countryId, hasSupervisorUserId, departmentId, query, isSubscriber, isSubscription, isArchived, orderByCoins, birthDateFrom, birthDateTo, dateOfEmploymentFrom, dateOfEmploymentTo, exceptUserId, isColleguesSearch, firstLastNameLetter, orderByLastName, role, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersIdGet(id: number, options?: any): AxiosPromise<UserProfileModel> {
            return localVarFp.apiV1UsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<UserProfileModel> {
            return localVarFp.apiV1UsersIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set language for current user
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersLanguagePost(body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersLanguagePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get last name letters by country
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersLettersGet(countryId?: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiV1UsersLettersGet(countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user navbar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersNavbarGet(options?: any): AxiosPromise<UserNavBarModel> {
            return localVarFp.apiV1UsersNavbarGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update navbar
         * @param {UserNavBarModel} [userNavBarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersNavbarPut(userNavBarModel?: UserNavBarModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersNavbarPut(userNavBarModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {UserProfileModel} [userProfileModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersPost(userProfileModel?: UserProfileModel, options?: any): AxiosPromise<UserProfileModel> {
            return localVarFp.apiV1UsersPost(userProfileModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get preview models by filter
         * @param {number} [supervisorUserId] Фильтр по подчинённым
         * @param {number} [departmentUserId] Фильтр по подразделению
         * @param {number} [countryId] Фильтр по стране
         * @param {boolean} [hasSupervisorUserId] Фильтр по подчинённым
         * @param {string} [departmentId] Фильтр по подразделению
         * @param {string} [query] Фильтр по запросу
         * @param {boolean} [isSubscriber] Фильтр по подпискам
         * @param {boolean} [isSubscription] Фильтр по подписчикам
         * @param {boolean} [isArchived] Фильтр по архивированным
         * @param {boolean} [orderByCoins] Сортировать по коинам
         * @param {string} [birthDateFrom] Фильтр по началу дней рождений
         * @param {string} [birthDateTo] Фильтр по концу дней рождений
         * @param {string} [dateOfEmploymentFrom] Фильтр по началу даты трудоустройства
         * @param {string} [dateOfEmploymentTo] Фильтр по концу трудоустройства
         * @param {number} [exceptUserId] Фильтр по поиску коллег
         * @param {boolean} [isColleguesSearch] Фильтр по поиску коллег
         * @param {string} [firstLastNameLetter] Фильтр по первой букве фамилии
         * @param {boolean} [orderByLastName] Сортировать по фамилии в порядке возрастания
         * @param {RoleName} [role] Фильтровать по роли пользователя
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersPreviewGet(supervisorUserId?: number, departmentUserId?: number, countryId?: number, hasSupervisorUserId?: boolean, departmentId?: string, query?: string, isSubscriber?: boolean, isSubscription?: boolean, isArchived?: boolean, orderByCoins?: boolean, birthDateFrom?: string, birthDateTo?: string, dateOfEmploymentFrom?: string, dateOfEmploymentTo?: string, exceptUserId?: number, isColleguesSearch?: boolean, firstLastNameLetter?: string, orderByLastName?: boolean, role?: RoleName, count?: number, offset?: number, options?: any): AxiosPromise<UsersPreviewModel> {
            return localVarFp.apiV1UsersPreviewGet(supervisorUserId, departmentUserId, countryId, hasSupervisorUserId, departmentId, query, isSubscriber, isSubscription, isArchived, orderByCoins, birthDateFrom, birthDateTo, dateOfEmploymentFrom, dateOfEmploymentTo, exceptUserId, isColleguesSearch, firstLastNameLetter, orderByLastName, role, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersPut(userUpdateModel?: UserUpdateModel, options?: any): AxiosPromise<UserProfileModel> {
            return localVarFp.apiV1UsersPut(userUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdColleaguesGet(userId: number, count?: number, offset?: number, options?: any): AxiosPromise<UsersPreviewModel> {
            return localVarFp.apiV1UsersUserIdColleaguesGet(userId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get visible Tuilife section pages
         * @param {number} userId Фильтр по пользователю
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSectionPagesVisibleGet(userId: number, count?: number, offset?: number, options?: any): AxiosPromise<SectionPageLinkDtoSearchResult> {
            return localVarFp.apiV1UsersUserIdSectionPagesVisibleGet(userId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update visible Tuilife section pages
         * @param {number} userId 
         * @param {UpdateUserVisibleSectionPagesBody} [updateUserVisibleSectionPagesBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSectionPagesVisiblePut(userId: number, updateUserVisibleSectionPagesBody?: UpdateUserVisibleSectionPagesBody, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersUserIdSectionPagesVisiblePut(userId, updateUserVisibleSectionPagesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All subscribed to the user
         * @param {number} userId User ID that others will subscribe to
         * @param {Array<number>} requestBody List of country identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUserIdSubscriptionsSignedAllPost(userId: number, requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersUserIdSubscriptionsSignedAllPost(userId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1UsersCountryPost operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersCountryPostRequest
 */
export interface UserApiApiV1UsersCountryPostRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersCountryPost
     */
    readonly body?: number
}

/**
 * Request parameters for apiV1UsersExportGet operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersExportGetRequest
 */
export interface UserApiApiV1UsersExportGetRequest {
    /**
     * Фильтр по подчинённым
     * @type {number}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly supervisorUserId?: number

    /**
     * Фильтр по подразделению
     * @type {number}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly departmentUserId?: number

    /**
     * Фильтр по стране
     * @type {number}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly countryId?: number

    /**
     * Фильтр по подчинённым
     * @type {boolean}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly hasSupervisorUserId?: boolean

    /**
     * Фильтр по подразделению
     * @type {string}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly departmentId?: string

    /**
     * Фильтр по запросу
     * @type {string}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly query?: string

    /**
     * Фильтр по подпискам
     * @type {boolean}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly isSubscriber?: boolean

    /**
     * Фильтр по подписчикам
     * @type {boolean}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly isSubscription?: boolean

    /**
     * Фильтр по архивированным
     * @type {boolean}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly isArchived?: boolean

    /**
     * Сортировать по коинам
     * @type {boolean}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly orderByCoins?: boolean

    /**
     * Фильтр по началу дней рождений
     * @type {string}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly birthDateFrom?: string

    /**
     * Фильтр по концу дней рождений
     * @type {string}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly birthDateTo?: string

    /**
     * Фильтр по началу даты трудоустройства
     * @type {string}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly dateOfEmploymentFrom?: string

    /**
     * Фильтр по концу трудоустройства
     * @type {string}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly dateOfEmploymentTo?: string

    /**
     * Фильтр по поиску коллег
     * @type {number}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly exceptUserId?: number

    /**
     * Фильтр по поиску коллег
     * @type {boolean}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly isColleguesSearch?: boolean

    /**
     * Фильтр по первой букве фамилии
     * @type {string}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly firstLastNameLetter?: string

    /**
     * Сортировать по фамилии в порядке возрастания
     * @type {boolean}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly orderByLastName?: boolean

    /**
     * Фильтровать по роли пользователя
     * @type {RoleName}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly role?: RoleName

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserApiApiV1UsersExportGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UsersFriendDelete operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersFriendDeleteRequest
 */
export interface UserApiApiV1UsersFriendDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersFriendDelete
     */
    readonly userId?: number
}

/**
 * Request parameters for apiV1UsersFriendPost operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersFriendPostRequest
 */
export interface UserApiApiV1UsersFriendPostRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersFriendPost
     */
    readonly userId?: number
}

/**
 * Request parameters for apiV1UsersGet operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersGetRequest
 */
export interface UserApiApiV1UsersGetRequest {
    /**
     * Фильтр по подчинённым
     * @type {number}
     * @memberof UserApiApiV1UsersGet
     */
    readonly supervisorUserId?: number

    /**
     * Фильтр по подразделению
     * @type {number}
     * @memberof UserApiApiV1UsersGet
     */
    readonly departmentUserId?: number

    /**
     * Фильтр по стране
     * @type {number}
     * @memberof UserApiApiV1UsersGet
     */
    readonly countryId?: number

    /**
     * Фильтр по подчинённым
     * @type {boolean}
     * @memberof UserApiApiV1UsersGet
     */
    readonly hasSupervisorUserId?: boolean

    /**
     * Фильтр по подразделению
     * @type {string}
     * @memberof UserApiApiV1UsersGet
     */
    readonly departmentId?: string

    /**
     * Фильтр по запросу
     * @type {string}
     * @memberof UserApiApiV1UsersGet
     */
    readonly query?: string

    /**
     * Фильтр по подпискам
     * @type {boolean}
     * @memberof UserApiApiV1UsersGet
     */
    readonly isSubscriber?: boolean

    /**
     * Фильтр по подписчикам
     * @type {boolean}
     * @memberof UserApiApiV1UsersGet
     */
    readonly isSubscription?: boolean

    /**
     * Фильтр по архивированным
     * @type {boolean}
     * @memberof UserApiApiV1UsersGet
     */
    readonly isArchived?: boolean

    /**
     * Сортировать по коинам
     * @type {boolean}
     * @memberof UserApiApiV1UsersGet
     */
    readonly orderByCoins?: boolean

    /**
     * Фильтр по началу дней рождений
     * @type {string}
     * @memberof UserApiApiV1UsersGet
     */
    readonly birthDateFrom?: string

    /**
     * Фильтр по концу дней рождений
     * @type {string}
     * @memberof UserApiApiV1UsersGet
     */
    readonly birthDateTo?: string

    /**
     * Фильтр по началу даты трудоустройства
     * @type {string}
     * @memberof UserApiApiV1UsersGet
     */
    readonly dateOfEmploymentFrom?: string

    /**
     * Фильтр по концу трудоустройства
     * @type {string}
     * @memberof UserApiApiV1UsersGet
     */
    readonly dateOfEmploymentTo?: string

    /**
     * Фильтр по поиску коллег
     * @type {number}
     * @memberof UserApiApiV1UsersGet
     */
    readonly exceptUserId?: number

    /**
     * Фильтр по поиску коллег
     * @type {boolean}
     * @memberof UserApiApiV1UsersGet
     */
    readonly isColleguesSearch?: boolean

    /**
     * Фильтр по первой букве фамилии
     * @type {string}
     * @memberof UserApiApiV1UsersGet
     */
    readonly firstLastNameLetter?: string

    /**
     * Сортировать по фамилии в порядке возрастания
     * @type {boolean}
     * @memberof UserApiApiV1UsersGet
     */
    readonly orderByLastName?: boolean

    /**
     * Фильтровать по роли пользователя
     * @type {RoleName}
     * @memberof UserApiApiV1UsersGet
     */
    readonly role?: RoleName

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserApiApiV1UsersGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserApiApiV1UsersGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UsersIdDelete operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersIdDeleteRequest
 */
export interface UserApiApiV1UsersIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1UsersIdGet operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersIdGetRequest
 */
export interface UserApiApiV1UsersIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1UsersIdPatch operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersIdPatchRequest
 */
export interface UserApiApiV1UsersIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof UserApiApiV1UsersIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1UsersLanguagePost operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersLanguagePostRequest
 */
export interface UserApiApiV1UsersLanguagePostRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersLanguagePost
     */
    readonly body?: number
}

/**
 * Request parameters for apiV1UsersLettersGet operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersLettersGetRequest
 */
export interface UserApiApiV1UsersLettersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersLettersGet
     */
    readonly countryId?: number
}

/**
 * Request parameters for apiV1UsersNavbarPut operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersNavbarPutRequest
 */
export interface UserApiApiV1UsersNavbarPutRequest {
    /**
     * 
     * @type {UserNavBarModel}
     * @memberof UserApiApiV1UsersNavbarPut
     */
    readonly userNavBarModel?: UserNavBarModel
}

/**
 * Request parameters for apiV1UsersPost operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersPostRequest
 */
export interface UserApiApiV1UsersPostRequest {
    /**
     * 
     * @type {UserProfileModel}
     * @memberof UserApiApiV1UsersPost
     */
    readonly userProfileModel?: UserProfileModel
}

/**
 * Request parameters for apiV1UsersPreviewGet operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersPreviewGetRequest
 */
export interface UserApiApiV1UsersPreviewGetRequest {
    /**
     * Фильтр по подчинённым
     * @type {number}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly supervisorUserId?: number

    /**
     * Фильтр по подразделению
     * @type {number}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly departmentUserId?: number

    /**
     * Фильтр по стране
     * @type {number}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly countryId?: number

    /**
     * Фильтр по подчинённым
     * @type {boolean}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly hasSupervisorUserId?: boolean

    /**
     * Фильтр по подразделению
     * @type {string}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly departmentId?: string

    /**
     * Фильтр по запросу
     * @type {string}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly query?: string

    /**
     * Фильтр по подпискам
     * @type {boolean}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly isSubscriber?: boolean

    /**
     * Фильтр по подписчикам
     * @type {boolean}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly isSubscription?: boolean

    /**
     * Фильтр по архивированным
     * @type {boolean}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly isArchived?: boolean

    /**
     * Сортировать по коинам
     * @type {boolean}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly orderByCoins?: boolean

    /**
     * Фильтр по началу дней рождений
     * @type {string}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly birthDateFrom?: string

    /**
     * Фильтр по концу дней рождений
     * @type {string}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly birthDateTo?: string

    /**
     * Фильтр по началу даты трудоустройства
     * @type {string}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly dateOfEmploymentFrom?: string

    /**
     * Фильтр по концу трудоустройства
     * @type {string}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly dateOfEmploymentTo?: string

    /**
     * Фильтр по поиску коллег
     * @type {number}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly exceptUserId?: number

    /**
     * Фильтр по поиску коллег
     * @type {boolean}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly isColleguesSearch?: boolean

    /**
     * Фильтр по первой букве фамилии
     * @type {string}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly firstLastNameLetter?: string

    /**
     * Сортировать по фамилии в порядке возрастания
     * @type {boolean}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly orderByLastName?: boolean

    /**
     * Фильтровать по роли пользователя
     * @type {RoleName}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly role?: RoleName

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserApiApiV1UsersPreviewGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UsersPut operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersPutRequest
 */
export interface UserApiApiV1UsersPutRequest {
    /**
     * 
     * @type {UserUpdateModel}
     * @memberof UserApiApiV1UsersPut
     */
    readonly userUpdateModel?: UserUpdateModel
}

/**
 * Request parameters for apiV1UsersUserIdColleaguesGet operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersUserIdColleaguesGetRequest
 */
export interface UserApiApiV1UsersUserIdColleaguesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersUserIdColleaguesGet
     */
    readonly userId: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserApiApiV1UsersUserIdColleaguesGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserApiApiV1UsersUserIdColleaguesGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UsersUserIdSectionPagesVisibleGet operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersUserIdSectionPagesVisibleGetRequest
 */
export interface UserApiApiV1UsersUserIdSectionPagesVisibleGetRequest {
    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof UserApiApiV1UsersUserIdSectionPagesVisibleGet
     */
    readonly userId: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof UserApiApiV1UsersUserIdSectionPagesVisibleGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof UserApiApiV1UsersUserIdSectionPagesVisibleGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1UsersUserIdSectionPagesVisiblePut operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersUserIdSectionPagesVisiblePutRequest
 */
export interface UserApiApiV1UsersUserIdSectionPagesVisiblePutRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiApiV1UsersUserIdSectionPagesVisiblePut
     */
    readonly userId: number

    /**
     * 
     * @type {UpdateUserVisibleSectionPagesBody}
     * @memberof UserApiApiV1UsersUserIdSectionPagesVisiblePut
     */
    readonly updateUserVisibleSectionPagesBody?: UpdateUserVisibleSectionPagesBody
}

/**
 * Request parameters for apiV1UsersUserIdSubscriptionsSignedAllPost operation in UserApi.
 * @export
 * @interface UserApiApiV1UsersUserIdSubscriptionsSignedAllPostRequest
 */
export interface UserApiApiV1UsersUserIdSubscriptionsSignedAllPostRequest {
    /**
     * User ID that others will subscribe to
     * @type {number}
     * @memberof UserApiApiV1UsersUserIdSubscriptionsSignedAllPost
     */
    readonly userId: number

    /**
     * List of country identifiers
     * @type {Array<number>}
     * @memberof UserApiApiV1UsersUserIdSubscriptionsSignedAllPost
     */
    readonly requestBody: Array<number>
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Set country for current user
     * @param {UserApiApiV1UsersCountryPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersCountryPost(requestParameters: UserApiApiV1UsersCountryPostRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersCountryPost(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersCurrentGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export users by filter
     * @param {UserApiApiV1UsersExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersExportGet(requestParameters: UserApiApiV1UsersExportGetRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersExportGet(requestParameters.supervisorUserId, requestParameters.departmentUserId, requestParameters.countryId, requestParameters.hasSupervisorUserId, requestParameters.departmentId, requestParameters.query, requestParameters.isSubscriber, requestParameters.isSubscription, requestParameters.isArchived, requestParameters.orderByCoins, requestParameters.birthDateFrom, requestParameters.birthDateTo, requestParameters.dateOfEmploymentFrom, requestParameters.dateOfEmploymentTo, requestParameters.exceptUserId, requestParameters.isColleguesSearch, requestParameters.firstLastNameLetter, requestParameters.orderByLastName, requestParameters.role, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user friend
     * @param {UserApiApiV1UsersFriendDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersFriendDelete(requestParameters: UserApiApiV1UsersFriendDeleteRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersFriendDelete(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add user friend
     * @param {UserApiApiV1UsersFriendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersFriendPost(requestParameters: UserApiApiV1UsersFriendPostRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersFriendPost(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get models by filter
     * @param {UserApiApiV1UsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersGet(requestParameters: UserApiApiV1UsersGetRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersGet(requestParameters.supervisorUserId, requestParameters.departmentUserId, requestParameters.countryId, requestParameters.hasSupervisorUserId, requestParameters.departmentId, requestParameters.query, requestParameters.isSubscriber, requestParameters.isSubscription, requestParameters.isArchived, requestParameters.orderByCoins, requestParameters.birthDateFrom, requestParameters.birthDateTo, requestParameters.dateOfEmploymentFrom, requestParameters.dateOfEmploymentTo, requestParameters.exceptUserId, requestParameters.isColleguesSearch, requestParameters.firstLastNameLetter, requestParameters.orderByLastName, requestParameters.role, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {UserApiApiV1UsersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersIdDelete(requestParameters: UserApiApiV1UsersIdDeleteRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {UserApiApiV1UsersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersIdGet(requestParameters: UserApiApiV1UsersIdGetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {UserApiApiV1UsersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersIdPatch(requestParameters: UserApiApiV1UsersIdPatchRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set language for current user
     * @param {UserApiApiV1UsersLanguagePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersLanguagePost(requestParameters: UserApiApiV1UsersLanguagePostRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersLanguagePost(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get last name letters by country
     * @param {UserApiApiV1UsersLettersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersLettersGet(requestParameters: UserApiApiV1UsersLettersGetRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersLettersGet(requestParameters.countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user navbar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersNavbarGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersNavbarGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update navbar
     * @param {UserApiApiV1UsersNavbarPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersNavbarPut(requestParameters: UserApiApiV1UsersNavbarPutRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersNavbarPut(requestParameters.userNavBarModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {UserApiApiV1UsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersPost(requestParameters: UserApiApiV1UsersPostRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersPost(requestParameters.userProfileModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get preview models by filter
     * @param {UserApiApiV1UsersPreviewGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersPreviewGet(requestParameters: UserApiApiV1UsersPreviewGetRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersPreviewGet(requestParameters.supervisorUserId, requestParameters.departmentUserId, requestParameters.countryId, requestParameters.hasSupervisorUserId, requestParameters.departmentId, requestParameters.query, requestParameters.isSubscriber, requestParameters.isSubscription, requestParameters.isArchived, requestParameters.orderByCoins, requestParameters.birthDateFrom, requestParameters.birthDateTo, requestParameters.dateOfEmploymentFrom, requestParameters.dateOfEmploymentTo, requestParameters.exceptUserId, requestParameters.isColleguesSearch, requestParameters.firstLastNameLetter, requestParameters.orderByLastName, requestParameters.role, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {UserApiApiV1UsersPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersPut(requestParameters: UserApiApiV1UsersPutRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersPut(requestParameters.userUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiApiV1UsersUserIdColleaguesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersUserIdColleaguesGet(requestParameters: UserApiApiV1UsersUserIdColleaguesGetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersUserIdColleaguesGet(requestParameters.userId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get visible Tuilife section pages
     * @param {UserApiApiV1UsersUserIdSectionPagesVisibleGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersUserIdSectionPagesVisibleGet(requestParameters: UserApiApiV1UsersUserIdSectionPagesVisibleGetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersUserIdSectionPagesVisibleGet(requestParameters.userId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update visible Tuilife section pages
     * @param {UserApiApiV1UsersUserIdSectionPagesVisiblePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersUserIdSectionPagesVisiblePut(requestParameters: UserApiApiV1UsersUserIdSectionPagesVisiblePutRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersUserIdSectionPagesVisiblePut(requestParameters.userId, requestParameters.updateUserVisibleSectionPagesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All subscribed to the user
     * @param {UserApiApiV1UsersUserIdSubscriptionsSignedAllPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiV1UsersUserIdSubscriptionsSignedAllPost(requestParameters: UserApiApiV1UsersUserIdSubscriptionsSignedAllPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiV1UsersUserIdSubscriptionsSignedAllPost(requestParameters.userId, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
