import { ComponentPropsWithRef } from 'react';

import styled from 'styled-components';

export type LinkProps = ComponentPropsWithRef<'button'>;

/**
 * @deprecated use UniversalLink
 */
export const Link = styled.a`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

/**
 * @deprecated use UniversalLink
 */
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: 600;

  &:hover {
    text-decoration: underline ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StyledAnchor = styled.a``;
