import { ComponentPropsWithRef } from 'react';

import styled, { css } from 'styled-components';

export type BaseInputProps = ComponentPropsWithRef<'input'>;

interface StyledInputRootProps {
  required: boolean;
}

const requiredStyles = css`
  & > label::after {
    color: ${({ theme }) => theme.palette.error.light};
    margin-left: 2px;
    content: '*';
  }
`;

export const StyledInputRoot = styled.div<StyledInputRootProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  ${({ required }) => required && requiredStyles}
`;
