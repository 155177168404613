import { VFC } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { formatRelative } from 'date-fns';

import { getPhotoPreview } from 'domain/photo';
import { getUserCountryShort } from 'domain/user';
import { useDateTime } from 'hooks/useDateTime';
import { WallContentType, WallModel, WallReplyModel } from 'services/api';
import { useDateFnsLocale } from 'services/i18n';
import { GeoBadge, Icon } from 'ui';

import {
  StyledAvatar,
  StyledColleagueDescription,
  StyledColleagueDescriptionWrapper,
  StyledColleagueListItemRoot,
  StyledColleagueName,
} from './styles';

type PostMetaProps = {
  postMetaData: WallModel | WallReplyModel;
  isSmall?: boolean;
};

export const PostMeta: VFC<PostMetaProps> = ({ postMetaData, isSmall }) => {
  const { locale } = useDateFnsLocale();
  const { parseISOWithTimezone } = useDateTime();

  if (!postMetaData.owner) return null;

  return (
    <StyledColleagueListItemRoot>
      <GeoBadge
        locale={getUserCountryShort(postMetaData.owner)}
        offset={isSmall ? [-20, 35] : [-22, 53]}
        size={isSmall ? 12 : 24}
      >
        <StyledAvatar
          shape={
            postMetaData.owner?.groupId &&
            postMetaData.wallContentType !== WallContentType.Idea
              ? 'square'
              : 'circle'
          }
          size={isSmall ? 40 : 64}
          src={getPhotoPreview(
            postMetaData.owner?.groupId &&
              postMetaData.wallContentType !== WallContentType.Idea
              ? postMetaData.owner?.avatar
              : postMetaData.from?.avatar,
            360,
          )}
        />
      </GeoBadge>
      <StyledColleagueDescriptionWrapper>
        <StyledColleagueName>
          {postMetaData.owner?.groupId &&
          postMetaData.wallContentType !== WallContentType.Idea ? (
            <RouterLink to={`/group/${postMetaData.owner?.groupId}`}>
              {postMetaData.owner?.name}
            </RouterLink>
          ) : (
            <RouterLink to={`/profile/${postMetaData.from?.userId}`}>
              {postMetaData.from?.name}
            </RouterLink>
          )}
          {!postMetaData.owner?.groupId &&
            postMetaData.owner?.userId !== postMetaData.from?.userId && (
              <>
                <Icon iconName="arrow_right" size={22} />
                <RouterLink to={`/profile/${postMetaData.owner?.userId}`}>
                  {postMetaData.owner?.name}
                </RouterLink>
              </>
            )}
        </StyledColleagueName>

        <StyledColleagueDescription>
          {postMetaData.publishedTime &&
            formatRelative(
              parseISOWithTimezone(postMetaData.publishedTime),
              new Date(),
              {
                locale,
              },
            )}
          {postMetaData.geocoder && (
            <Icon iconName="pointer" size={4} style={{ margin: '0 7px' }} />
          )}
          {postMetaData.geocoder?.countryName
            ? `${postMetaData.geocoder?.countryName}, `
            : ''}
          {postMetaData.geocoder?.cityName
            ? postMetaData.geocoder.cityName
            : ''}
        </StyledColleagueDescription>
      </StyledColleagueDescriptionWrapper>
    </StyledColleagueListItemRoot>
  );
};
