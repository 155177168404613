import React, { FC } from 'react';

import { Title } from '../Title';
import {
  ExtraContainer,
  MoreLinkContainer,
  WidgetPaper,
  WidgetSubtitle,
  WidgetToolBar,
} from './styles';

export interface WidgetProps {
  title: string;
  subtitle?: string;
  extra?: React.ReactNode;
  moreLink?: React.ReactNode;
  /**
   * @deprecated use type prop
   */
  isSmall?: boolean;
  /**
   * @deprecated use type prop
   */
  isChildWidget?: boolean;
  type?: 'default' | 'dashboard';
  onBackgroundClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Widget: FC<WidgetProps> = (props) => {
  const {
    children,
    title,
    subtitle,
    extra,
    moreLink,
    isSmall,
    type,
    onBackgroundClick,
  } = props;

  // TODO: рефакторинг api и стилей виджета

  return (
    <WidgetPaper
      data-type={type}
      role={onBackgroundClick ? 'button' : undefined}
      style={{
        padding: isSmall ? '16px 0 8px' : '24px 0',
        height: isSmall ? '210px' : 'auto',
      }}
      onClick={onBackgroundClick}
    >
      {extra && <ExtraContainer>{extra}</ExtraContainer>}
      <WidgetToolBar>
        <Title variant={`${isSmall || type === 'dashboard' ? 'h4' : 'h2'}`}>
          {title}
        </Title>
        {subtitle && (
          <WidgetSubtitle style={{ marginBottom: 16 }}>
            {subtitle}
          </WidgetSubtitle>
        )}
      </WidgetToolBar>
      {children}
      {moreLink && <MoreLinkContainer>{moreLink}</MoreLinkContainer>}
    </WidgetPaper>
  );
};
