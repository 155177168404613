import { Context, FC, createContext, useContext } from 'react';

import {
  UserCredentialService,
  useUserCredentialService,
} from './useUserCredentialService';

const UserCredentialContext = createContext<UserCredentialService | null>(null);

export const useUserCredential = (): UserCredentialService =>
  useContext(UserCredentialContext as Context<UserCredentialService>);

export const UserCredentialProvider: FC = ({ children }) => {
  const userCredentialService = useUserCredentialService();

  return (
    <UserCredentialContext.Provider value={userCredentialService}>
      {children}
    </UserCredentialContext.Provider>
  );
};
