/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum MeetingRecurrenceType {
    DailyPattern = 11,
    WeeklyPattern = 12,
    MonthlyPattern = 13,
    YearlyPattern = 14,
    DailyRegenerationPattern = 21,
    WeeklyRegenerationPattern = 22,
    MonthlyRegenerationPattern = 23,
    YearlyRegenerationPattern = 24,
    RelativeMonthlyPattern = 31,
    RelativeYearlyPattern = 32
}


