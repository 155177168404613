/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryCategoryModel } from '../models';
// @ts-ignore
import { CountryModel } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/countries/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CountriesCategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryCategoryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CountriesCategoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CountriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CountriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @summary Get categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesCategoriesGet(options?: any): AxiosPromise<Array<CountryCategoryModel>> {
            return localVarFp.apiV1CountriesCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CountriesGet(options?: any): AxiosPromise<Array<CountryModel>> {
            return localVarFp.apiV1CountriesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @summary Get categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public apiV1CountriesCategoriesGet(options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).apiV1CountriesCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public apiV1CountriesGet(options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).apiV1CountriesGet(options).then((request) => request(this.axios, this.basePath));
    }
}
