import React, { VFC } from 'react';

import { Close as CloseIcon } from '@styled-icons/material-outlined/Close';
import { Tooltip } from 'antd';

import { useTranslation } from 'services/i18n';
import { Title } from 'ui/Title';

import {
  AnswersContainer,
  CloseButton,
  CloseButtonAbsolute,
  FormContainer,
  QuestionContainer,
  StyledFormItem,
  StyledInput,
} from './styles';
import { FormPollProps } from './types';

export const FormPoll: VFC<FormPollProps> = ({
  pollForm,
  onRemoveForm,
  onAddAnswer,
  onRemoveAnswer,
  changeAnswerTitle,
  changeQuestionTitle,
}) => {
  const { t } = useTranslation('common');

  return (
    <FormContainer>
      <QuestionContainer>
        <Title variant="h3">{t('question')}</Title>
        <Tooltip title={t('remove_poll_form')}>
          <CloseButton onClick={onRemoveForm}>
            <CloseIcon />
          </CloseButton>
        </Tooltip>
      </QuestionContainer>
      <StyledFormItem>
        <StyledInput
          required
          maxLength={128}
          size="large"
          value={pollForm.title!}
          onChange={changeQuestionTitle}
        />
      </StyledFormItem>
      <AnswersContainer>
        <Title variant="h3">{t('poll_answers')}</Title>
        {pollForm.pollAnswers?.map(({ id }) => (
          <StyledFormItem key={id}>
            <StyledInput
              required={!pollForm.pollAnswers?.some(({ text }) => text !== '')}
              size="large"
              value={
                pollForm.pollAnswers!.find((answer) => answer.id === id)!.text!
              }
              onChange={changeAnswerTitle(id as number)}
            />
            {pollForm.pollAnswers!.length > 1 && (
              <Tooltip title={t('remove_answer')}>
                <CloseButtonAbsolute onClick={onRemoveAnswer(id as number)}>
                  <CloseIcon />
                </CloseButtonAbsolute>
              </Tooltip>
            )}
          </StyledFormItem>
        ))}
        <StyledFormItem>
          <StyledInput
            readOnly
            placeholder={t('add_answer')}
            size="large"
            style={{ cursor: 'pointer' }}
            onClick={onAddAnswer}
          />
        </StyledFormItem>
      </AnswersContainer>
    </FormContainer>
  );
};
