import styled from 'styled-components';

import { Tree as StructureTree } from 'components/StyledTree/styles';

export const Tree = styled(StructureTree)`
  .ant-tree-treenode-selected[class*='ant-tree-treenode-leaf-'] {
    pointer-events: none;
  }
`;

export const PaginationContainer = styled.div`
  margin-top: 16px;
`;
