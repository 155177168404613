import React, { VFC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PollModel, WallModel } from 'services/api';
import { Title } from 'ui/Title';

import { useLocalModel } from './model';
import {
  CenteredTitle,
  ClickableTitle,
  Container,
  Num,
  ProgressDiv,
  QuestionContainer,
  StyledFormItem,
  StyledInput,
  TitleContainer,
  VotePercent,
} from './styles';

type PostPollProps = PollModel &
  Pick<WallModel, 'from'> & { updatePollAnswer?: (answerId?: number) => void };
export const PostPoll: VFC<PostPollProps> = ({
  pollAnswers,
  title,
  from,
  id: pollId,
  updatePollAnswer,
  currentUserAnswerId,
  answersCount,
}) => {
  const navigate = useNavigate();
  const { selectAnswer } = useLocalModel(updatePollAnswer);
  const { t } = useTranslation('common');

  return (
    <Container>
      <TitleContainer>
        <Title style={{ maxWidth: '480px', textAlign: 'center' }} variant="h1">
          {title}
        </Title>
        <ClickableTitle
          variant="h4"
          onClick={() => navigate(`/profile/${from?.userId}`)}
        >
          {from?.name}
        </ClickableTitle>
      </TitleContainer>
      <QuestionContainer>
        {pollAnswers?.map(({ text, id, votesCount }) => (
          <StyledFormItem key={id}>
            <StyledInput
              readOnly
              disabled={
                typeof currentUserAnswerId === 'number' ||
                selectAnswer.isLoading
              }
              isSelected={currentUserAnswerId === id}
              size="large"
              value={
                typeof currentUserAnswerId === 'number'
                  ? `${text!} • ${votesCount}`
                  : text!
              }
              onClick={() => selectAnswer.mutate({ id, pollId })}
            />
            <ProgressDiv
              isDisabled={typeof currentUserAnswerId === 'number'}
              percent={100 / (answersCount! / votesCount!)}
            />
            <VotePercent isDisabled={typeof currentUserAnswerId === 'number'}>
              <Num>{(100 / (answersCount! / votesCount!)).toFixed(1)}</Num>%
            </VotePercent>
          </StyledFormItem>
        ))}
      </QuestionContainer>

      <CenteredTitle variant="h4">
        {answersCount
          ? `${t('all_answered_count')}: ${answersCount}`
          : `${t('be_the_first_to_vote')}`}
      </CenteredTitle>
    </Container>
  );
};
