import { DefaultTheme } from 'styled-components';

import * as colors from './colors';

const lightTheme: DefaultTheme = {
  palette: {
    type: 'light',
    primary: {
      main: colors.blue,
      light: colors.blueLight,
      dark: colors.blueDark,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.yellow,
      light: colors.yellowLight,
      dark: colors.yellowDark,
      contrastText: colors.gray[600],
    },
    error: {
      main: colors.red,
      light: colors.redLight,
      dark: colors.redDark,
      contrastText: colors.white,
    },
    background: {
      default: '#EFF2F6',
      backdrop: 'rgba(0, 0, 0, 0.6)',
      paper: colors.white,
    },
    text: {
      primary: colors.gray[800],
      secondary: colors.gray[400],
      disabled: colors.gray[300],
    },
    actions: {
      active: 'rgba(0, 0, 0, 0.08)',
      hover: 'rgba(0, 0, 0, 0.02)',
      disabled: colors.gray[100],
    },
    gray: colors.gray,
  },
  font: {
    fontFamily: '"Open Sans", sans-serif',
  },
  shadows: [
    'none',
    '0px 0px 5px rgba(0, 0, 0, 0.05)',
    '0px 5px 10px rgba(0, 0, 0, 0.05)',
  ],
};

// eslint-disable-next-line import/no-default-export
export default {
  light: lightTheme,
};
