import { VFC } from 'react';

import { Col, Row } from 'antd';

import { getPhotoPreview } from 'domain/photo';
import { createMonogram, getUserCountryShort } from 'domain/user';
import { AuthorModel } from 'services/api';
import { Avatar, GeoBadge } from 'ui';
import { StyledLink } from 'ui/Link';
import { ListItem } from 'ui/ListItem';
import { ListItemMeta } from 'ui/ListItemMeta';
import { Skeleton } from 'ui/Skeleton';

import { ExtendedUserPreview, TwoColumnListProps } from './types';

export const TwoColumnList: VFC<TwoColumnListProps> = ({
  employees,
  isLoading,
  handleClick,
}) => {
  return (
    <Row gutter={16}>
      {employees.map((fellow: ExtendedUserPreview) => (
        <Col span={12}>
          <ListItem key={fellow.id}>
            <Skeleton active avatar loading={isLoading}>
              <ListItemMeta
                avatar={
                  <GeoBadge
                    locale={getUserCountryShort(fellow as AuthorModel)}
                    offset={[-8, 32]}
                    size={16}
                    style={{ border: '2px solid #fff' }}
                  >
                    <Avatar
                      size={40}
                      src={
                        fellow.avatar
                          ? getPhotoPreview(fellow.avatar)
                          : undefined
                      }
                    >
                      {createMonogram(fellow)}
                    </Avatar>
                  </GeoBadge>
                }
                description={fellow.positionName ?? ' '}
                title={
                  <StyledLink
                    onClick={() => handleClick(fellow.id ?? fellow.userId)}
                  >
                    {fellow.name || `${fellow.firstName} ${fellow.lastName}`}
                  </StyledLink>
                }
              />
            </Skeleton>
          </ListItem>
        </Col>
      ))}
    </Row>
  );
};
