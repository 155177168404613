import { VFC } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Close as CloseIcon } from '@styled-icons/material-outlined/Close';
import { Modal } from 'antd';

import { useMediaGallery } from 'services/mediaGallery';

import { MediaContent } from '../MediaContent';
import { CloseButton } from '../MediaContent/styles';

export const MediaModal: VFC = () => {
  const [searchParams] = useSearchParams();
  const { closeMediaGallery } = useMediaGallery();

  const mediaFileId = searchParams.get('media');

  return (
    <Modal
      destroyOnClose
      bodyStyle={{ all: 'unset' }}
      closable={false}
      footer={null}
      visible={Boolean(mediaFileId)}
      width="100%"
      onCancel={closeMediaGallery}
    >
      <CloseButton onClick={closeMediaGallery}>
        <CloseIcon size={24} />
      </CloseButton>
      {mediaFileId && <MediaContent mediaFileId={Number(mediaFileId)} />}
    </Modal>
  );
};
