import { VFC, memo, useState } from 'react';

import { IdeaEditorProvider } from 'services/editors/idea/ideaEditorContext';
import { PostEditorProvider } from 'services/editors/post/postEditorContext';
import { useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { Avatar } from 'ui';

import { IdeaEditorContent } from './IdeaEditorContent';
import { PostEditorContent } from './PostEditorContent';
import { PostEditorPlaceholder } from './PostEditorPlaceholder';
import { FormContainer, StyledPaper } from './styles';
import { PostFormProps } from './types';

export const PostForm: VFC<PostFormProps> = memo(
  ({
    isForceFocused,
    setParentIsVisible,
    defaultValues,
    owner,
    type = 'post',
    mode = 'create',
    postponePublish,
  }) => {
    const { t } = useTranslation('common');
    const { user } = useUserCredential();
    const [isFocused, setIsFocused] = useState<boolean>(
      isForceFocused ?? false,
    );

    const placeholder = (() => {
      if ((mode === 'create' && defaultValues?.id) || isForceFocused)
        return t('what_do_you_think');
      if (type === 'idea') return t('describe_your_idea');
      if (owner?.groupId) return `${t('suggest_post')}?`;
      return undefined;
    })();

    const onChangeFocus = () => {
      if (setParentIsVisible) setParentIsVisible(!isFocused);
      setIsFocused(!isFocused);
    };

    return (
      <StyledPaper>
        <FormContainer onFocus={() => setIsFocused(true)}>
          <Avatar size={40} src={user?.avatar?.fileUrl} />
          <PostEditorPlaceholder
            condition={isFocused}
            placeholder={placeholder}
            onClick={onChangeFocus}
          >
            {type === 'post' && (
              <PostEditorProvider
                placeholder={placeholder}
                text={defaultValues?.postContent?.text}
              >
                <PostEditorContent
                  defaultValues={defaultValues}
                  mode={mode}
                  owner={owner}
                  postponePublish={postponePublish}
                  onChangeFocus={onChangeFocus}
                />
              </PostEditorProvider>
            )}
            {type === 'idea' && (
              <IdeaEditorProvider
                placeholder={placeholder}
                text={defaultValues?.postContent?.text}
              >
                <IdeaEditorContent
                  defaultValues={defaultValues}
                  mode={mode}
                  onChangeFocus={onChangeFocus}
                />
              </IdeaEditorProvider>
            )}
          </PostEditorPlaceholder>
        </FormContainer>
      </StyledPaper>
    );
  },
);
