import { VFC } from 'react';

import { Badge } from 'antd';
import {
  differenceInDays,
  differenceInMinutes,
  formatDistanceToNowStrict,
  parseISO,
} from 'date-fns';

import { useDateFnsLocale, useTranslation } from 'services/i18n';

import { RealizeDate } from './styles';

export type VoteIdeaDateProps = {
  voteDate: string;
};
export const VoteIdeaDate: VFC<VoteIdeaDateProps> = ({ voteDate }) => {
  const { t } = useTranslation('common');
  const { locale } = useDateFnsLocale();

  const ideaRealizeDate = parseISO(voteDate);

  const ideaStatus = (
    date: Date,
  ): 'default' | 'success' | 'error' | 'warning' | 'processing' | undefined => {
    if (date.toString() === 'Invalid Date') return 'success';
    if (date < new Date()) return 'default';

    const days = differenceInDays(date, new Date());
    if (days >= 10) return 'success';
    if (days >= 5) return 'warning';
    return 'error';
  };

  const ideaStatusText = (date: Date) => {
    if (date.toString() === 'Invalid Date') return t('indefinitely');
    if (date < new Date()) return t('completed');

    const days = differenceInDays(date, new Date());
    const minutes = differenceInMinutes(date, new Date());
    const datetime = formatDistanceToNowStrict(date, { locale });
    if (days === 1) return t('one_left', { datetime });
    if (days === 0)
      return minutes % 10 === 1
        ? t('one_left', { datetime })
        : t('many_left', { datetime });

    return t('many_left', { datetime });
  };

  return (
    <>
      <RealizeDate>
        <Badge status={ideaStatus(ideaRealizeDate)} />
        {ideaStatusText(ideaRealizeDate)}
      </RealizeDate>
    </>
  );
};
