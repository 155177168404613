import React, { FC } from 'react';

interface UnCompletedIconProps {
  size?: number;
}

export const UnCompletedIcon: FC<UnCompletedIconProps> = ({ size }) => {
  const width = size ?? 24;
  const height = size ?? 24;
  return (
    <svg
      fill="none"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        height={height - 1}
        rx="3.5"
        stroke="#DCDCDC"
        width={width - 1}
        x="0.5"
        y="0.5"
      />
    </svg>
  );
};
