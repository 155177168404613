import styled from 'styled-components';

export const WidgetBackgroundButton = styled.button`
  width: 332px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  text-align: left;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
