import { colord } from 'colord';

export const white = '#fff';

export const gray = {
  50: colord(white).darken(0.05).toHex(),
  100: colord(white).darken(0.1).toHex(),
  200: colord(white).darken(0.2).toHex(),
  300: colord(white).darken(0.3).toHex(),
  400: colord(white).darken(0.4).toHex(),
  500: colord(white).darken(0.5).toHex(),
  600: colord(white).darken(0.6).toHex(),
  700: colord(white).darken(0.7).toHex(),
  800: colord(white).darken(0.8).toHex(),
  900: colord(white).darken(0.9).toHex(),
};

export const blue = 'hsl(225,87%,62%)';
export const blueBrilliant = 'hsl(226, 89%, 96%)';
export const blueLight = 'hsl(225,100%,61%)';
export const blueDark = 'hsl(225, 90%, 52%)';

export const yellow = 'hsl(53, 100%, 50%)';
export const yellowLight = 'hsl(53, 100%, 62%)';
export const yellowDark = 'hsl(48, 100%, 50%)';

export const red = 'hsl(359, 78%, 57%)';
export const redLight = 'hsl(359, 87%, 65%)';
export const redDark = 'hsl(359, 87%, 49%)';
