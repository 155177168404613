import globalAxios from 'axios';

import { globalResponseHandler } from "domain/error";
import {
  AbsenceApi,
  AlbumApi,
  AppApi,
  BannerApi,
  CoinsApi,
  CommentApi,
  CompanySettingsApi,
  CountryApi,
  CourseApi,
  DashboardApi,
  DepartmentApi,
  DiscoveryStepInfoApi,
  DiscoverySystemApi,
  DocumentApi,
  GeocoderApi,
  GiftCardApi,
  GroupApi,
  IdentityApi,
  LikeApi,
  LinkApi,
  LocalizationKeyApi,
  LocalizationValueApi,
  MailingApi,
  MeetingApi,
  NotificationApi,
  OfferApi,
  OpenGraphApi,
  PhotoApi,
  PostedFileApi,
  PushNotificationApi,
  RoleApi,
  SearchHistoryApi,
  SectionPageApi,
  TestApi,
  ToDoItemApi,
  UserApi,
  UserDashboardApi,
  UserDiscoveryApi,
  UserDiscoveryStepApi,
  UserLinkApi,
  UserNotificationTypeApi,
  VideoApi,
  WallApi,PollApi
} from 'services/api/index';

class Api {
  absence: AbsenceApi = new AbsenceApi();

  poll: PollApi = new PollApi();

  album: AlbumApi = new AlbumApi();

  app: AppApi = new AppApi();

  banner: BannerApi = new BannerApi();

  coins: CoinsApi = new CoinsApi();

  comment: CommentApi = new CommentApi();

  companySettings: CompanySettingsApi = new CompanySettingsApi();

  country: CountryApi = new CountryApi();

  course: CourseApi = new CourseApi();

  dashboard: DashboardApi = new DashboardApi();

  department: DepartmentApi = new DepartmentApi();

  discoveryStepInfo: DiscoveryStepInfoApi = new DiscoveryStepInfoApi();

  discoverySystem: DiscoverySystemApi = new DiscoverySystemApi();

  document: DocumentApi = new DocumentApi();

  geocoder: GeocoderApi = new GeocoderApi();

  giftCard: GiftCardApi = new GiftCardApi();

  group: GroupApi = new GroupApi();

  identity: IdentityApi = new IdentityApi();

  like: LikeApi = new LikeApi();

  link: LinkApi = new LinkApi();

  localizationKey: LocalizationKeyApi = new LocalizationKeyApi();

  localizationValue: LocalizationValueApi = new LocalizationValueApi();

  mailing: MailingApi = new MailingApi();

  meeting: MeetingApi = new MeetingApi();

  notification: NotificationApi = new NotificationApi();

  offer: OfferApi = new OfferApi();

  openGraph: OpenGraphApi = new OpenGraphApi();

  photo: PhotoApi = new PhotoApi();

  postedFile: PostedFileApi = new PostedFileApi();

  pushNotification: PushNotificationApi = new PushNotificationApi();

  role: RoleApi = new RoleApi();

  searchHistory: SearchHistoryApi = new SearchHistoryApi();

  sectionPage: SectionPageApi = new SectionPageApi();

  test: TestApi = new TestApi();

  toDoItem: ToDoItemApi = new ToDoItemApi();

  user: UserApi = new UserApi();

  userDashboard: UserDashboardApi = new UserDashboardApi();

  userDiscovery: UserDiscoveryApi = new UserDiscoveryApi();

  userDiscoveryStep: UserDiscoveryStepApi = new UserDiscoveryStepApi();

  userLink: UserLinkApi = new UserLinkApi();

  userNotificationType: UserNotificationTypeApi = new UserNotificationTypeApi();

  video: VideoApi = new VideoApi();

  wall: WallApi = new WallApi();

  constructor() {
    this.setDefaults();
  }

  // eslint-disable-next-line class-methods-use-this
  private setDefaults = () => {
    globalAxios.interceptors.response.use(
      (config) => config,
      async (error) => {
       if (process.env.NODE_ENV === 'development') globalResponseHandler(error.response);
        throw error;
      },
    );
  };
}

export const api = new Api();
