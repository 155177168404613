/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
// @ts-ignore
import { VideoModel } from '../models';
// @ts-ignore
import { VideoUpdateModel } from '../models';
// @ts-ignore
import { VideosModel } from '../models';
/**
 * VideoApi - axios parameter creator
 * @export
 */
export const VideoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosGet: async (albumId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/videos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (albumId !== undefined) {
                localVarQueryParameter['AlbumId'] = albumId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1VideosIdDelete', 'id', id)
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1VideosIdGet', 'id', id)
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1VideosIdPatch', 'id', id)
            const localVarPath = `/api/v1/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {number} [albumId] 
         * @param {any} [fileUpload] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosPost: async (name?: string, description?: string, albumId?: number, fileUpload?: any, ownerUserId?: number, ownerGroupId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/videos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (albumId !== undefined) { 
                localVarFormParams.append('AlbumId', albumId as any);
            }
    
            if (fileUpload !== undefined) { 
                localVarFormParams.append('FileUpload', fileUpload as any);
            }
    
            if (ownerUserId !== undefined) { 
                localVarFormParams.append('Owner.UserId', ownerUserId as any);
            }
    
            if (ownerGroupId !== undefined) { 
                localVarFormParams.append('Owner.GroupId', ownerGroupId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {VideoUpdateModel} [videoUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosPut: async (videoUpdateModel?: VideoUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/videos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videoUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoApi - functional programming interface
 * @export
 */
export const VideoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VideosGet(albumId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideosModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VideosGet(albumId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VideosIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VideosIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VideosIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VideosIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VideosIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VideosIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {number} [albumId] 
         * @param {any} [fileUpload] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VideosPost(name?: string, description?: string, albumId?: number, fileUpload?: any, ownerUserId?: number, ownerGroupId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VideosPost(name, description, albumId, fileUpload, ownerUserId, ownerGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {VideoUpdateModel} [videoUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1VideosPut(videoUpdateModel?: VideoUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1VideosPut(videoUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VideoApi - factory interface
 * @export
 */
export const VideoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoApiFp(configuration)
    return {
        /**
         * 
         * @summary Get models by filter
         * @param {number} [albumId] Фильтр по альбому
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosGet(albumId?: number, count?: number, offset?: number, options?: any): AxiosPromise<VideosModel> {
            return localVarFp.apiV1VideosGet(albumId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1VideosIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosIdGet(id: number, options?: any): AxiosPromise<VideoModel> {
            return localVarFp.apiV1VideosIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<VideoModel> {
            return localVarFp.apiV1VideosIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {number} [albumId] 
         * @param {any} [fileUpload] 
         * @param {number} [ownerUserId] Фильтр по пользователю
         * @param {number} [ownerGroupId] Фильтр по группе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosPost(name?: string, description?: string, albumId?: number, fileUpload?: any, ownerUserId?: number, ownerGroupId?: number, options?: any): AxiosPromise<VideoModel> {
            return localVarFp.apiV1VideosPost(name, description, albumId, fileUpload, ownerUserId, ownerGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {VideoUpdateModel} [videoUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1VideosPut(videoUpdateModel?: VideoUpdateModel, options?: any): AxiosPromise<VideoModel> {
            return localVarFp.apiV1VideosPut(videoUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1VideosGet operation in VideoApi.
 * @export
 * @interface VideoApiApiV1VideosGetRequest
 */
export interface VideoApiApiV1VideosGetRequest {
    /**
     * Фильтр по альбому
     * @type {number}
     * @memberof VideoApiApiV1VideosGet
     */
    readonly albumId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof VideoApiApiV1VideosGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof VideoApiApiV1VideosGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1VideosIdDelete operation in VideoApi.
 * @export
 * @interface VideoApiApiV1VideosIdDeleteRequest
 */
export interface VideoApiApiV1VideosIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof VideoApiApiV1VideosIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1VideosIdGet operation in VideoApi.
 * @export
 * @interface VideoApiApiV1VideosIdGetRequest
 */
export interface VideoApiApiV1VideosIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof VideoApiApiV1VideosIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1VideosIdPatch operation in VideoApi.
 * @export
 * @interface VideoApiApiV1VideosIdPatchRequest
 */
export interface VideoApiApiV1VideosIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof VideoApiApiV1VideosIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof VideoApiApiV1VideosIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1VideosPost operation in VideoApi.
 * @export
 * @interface VideoApiApiV1VideosPostRequest
 */
export interface VideoApiApiV1VideosPostRequest {
    /**
     * 
     * @type {string}
     * @memberof VideoApiApiV1VideosPost
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof VideoApiApiV1VideosPost
     */
    readonly description?: string

    /**
     * 
     * @type {number}
     * @memberof VideoApiApiV1VideosPost
     */
    readonly albumId?: number

    /**
     * 
     * @type {any}
     * @memberof VideoApiApiV1VideosPost
     */
    readonly fileUpload?: any

    /**
     * Фильтр по пользователю
     * @type {number}
     * @memberof VideoApiApiV1VideosPost
     */
    readonly ownerUserId?: number

    /**
     * Фильтр по группе
     * @type {number}
     * @memberof VideoApiApiV1VideosPost
     */
    readonly ownerGroupId?: number
}

/**
 * Request parameters for apiV1VideosPut operation in VideoApi.
 * @export
 * @interface VideoApiApiV1VideosPutRequest
 */
export interface VideoApiApiV1VideosPutRequest {
    /**
     * 
     * @type {VideoUpdateModel}
     * @memberof VideoApiApiV1VideosPut
     */
    readonly videoUpdateModel?: VideoUpdateModel
}

/**
 * VideoApi - object-oriented interface
 * @export
 * @class VideoApi
 * @extends {BaseAPI}
 */
export class VideoApi extends BaseAPI {
    /**
     * 
     * @summary Get models by filter
     * @param {VideoApiApiV1VideosGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public apiV1VideosGet(requestParameters: VideoApiApiV1VideosGetRequest = {}, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).apiV1VideosGet(requestParameters.albumId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {VideoApiApiV1VideosIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public apiV1VideosIdDelete(requestParameters: VideoApiApiV1VideosIdDeleteRequest, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).apiV1VideosIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {VideoApiApiV1VideosIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public apiV1VideosIdGet(requestParameters: VideoApiApiV1VideosIdGetRequest, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).apiV1VideosIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {VideoApiApiV1VideosIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public apiV1VideosIdPatch(requestParameters: VideoApiApiV1VideosIdPatchRequest, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).apiV1VideosIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {VideoApiApiV1VideosPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public apiV1VideosPost(requestParameters: VideoApiApiV1VideosPostRequest = {}, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).apiV1VideosPost(requestParameters.name, requestParameters.description, requestParameters.albumId, requestParameters.fileUpload, requestParameters.ownerUserId, requestParameters.ownerGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {VideoApiApiV1VideosPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public apiV1VideosPut(requestParameters: VideoApiApiV1VideosPutRequest = {}, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).apiV1VideosPut(requestParameters.videoUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
