import styled from 'styled-components';

export const ItemContainer = styled.div.attrs({
  role: 'menuitem',
})`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #edf1fe;
  }
`;

export const ItemAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const ItemData = styled.div`
  flex-grow: 1;
  padding: 0 16px;
`;

export const ItemName = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const ItemRole = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ItemAction = styled.div`
  flex-shrink: 0;
`;
