/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum DashboardBlockPosition {
    Position2 = 2,
    Position3 = 3,
    Position4 = 4,
    Position5 = 5,
    Position7 = 7,
    Position8 = 8,
    Position9 = 9,
    Position11 = 11
}


