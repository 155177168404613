import React, { useEffect, useRef } from 'react';

import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

// FIXME: не подходть для деплоя
import { NotificationModel } from 'services/api';

import { BASE_PATH } from '../api/base';
import { useAuth } from '../auth';

export interface UserNotificationService {
  notify: number;
  setNotify: React.Dispatch<React.SetStateAction<number>>;
  pushNotify: number;
  pushNotification?: NotificationModel;
  setNewPushNotification: React.Dispatch<
    React.SetStateAction<NotificationModel | undefined>
  >;
  setPushNotify: React.Dispatch<React.SetStateAction<number>>;
  readCurrentPush: () => void;
}

export const useUserNotificationService = (): UserNotificationService => {
  const [notify, setNotify] = React.useState(0);
  const [pushNotify, setPushNotify] = React.useState(0);
  const [pushNotification, setNewPushNotification] = React.useState<
    NotificationModel | undefined
  >(undefined);

  const { token } = useAuth();

  const readCurrentPush = () => {
    if (pushNotification) {
      setNewPushNotification(undefined);
      setPushNotify(pushNotify - 1);
    }
  };

  React.useEffect(() => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${BASE_PATH}/hubs/notifications`, {
        accessTokenFactory: () => token ?? '',
      })
      .configureLogging(
        process.env.NODE_ENV === 'development' ? LogLevel.Debug : LogLevel.None,
      )
      .withAutomaticReconnect()
      .build();

    hubConnection.start();

    hubConnection.on('new-notification', () => {
      setNotify(notify + 1);
    });
    hubConnection.on('new-push-notification', (args: NotificationModel) => {
      setNewPushNotification(args);
      setPushNotify(pushNotify + 1);
    });

    return () => {
      hubConnection.stop();
    };
  }, [notify, pushNotify, token]);

  return {
    notify,
    setNotify,
    pushNotify,
    pushNotification,
    setNewPushNotification,
    readCurrentPush,
    setPushNotify,
  };
};

export const usePrevious = (value: number): number | undefined => {
  const ref = useRef<number>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
