import styled from 'styled-components';

export const CountryFilterContainer = styled.div`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #f6f8fa;
`;

export const Heading = styled.div`
  text-align: left;
  background-color: white;
`;
