import { VFC } from 'react';

import { useQuery } from 'react-query';

import { ExpandMore as ExpandMoreIcon } from '@styled-icons/material-outlined/ExpandMore';

import { isExternal } from 'domain/navigation';
import { LinkType } from 'services/api';
import { useTranslation } from 'services/i18n';
import { useUserCredential } from 'services/userCredential';
import { Dropdown, Menu, MenuItem, UniversalLink } from 'ui';

import { NavMenuItem } from './styles';
import { useLinksApi } from './useLinksApi';

export const QuickLinksMenu: VFC = () => {
  const { user } = useUserCredential();
  const { t } = useTranslation('common');
  const { getLinks } = useLinksApi();
  const { data: links = [] } = useQuery(
    ['header', 'links', 'quick', user?.country.id],
    () => getLinks(user?.country.id as number, LinkType.Quick),
    {
      suspense: false,
    },
  );

  return (
    <Dropdown
      overlay={
        <Menu mode="vertical">
          {links.map((link) => (
            <MenuItem key={link.href}>
              <UniversalLink
                external={isExternal(link.href as string)}
                target={isExternal(link.href as string) ? '_blank' : '_self'}
                to={link.href as string}
              >
                {t(link.title as string)}
              </UniversalLink>
            </MenuItem>
          ))}
        </Menu>
      }
      overlayStyle={{ position: 'fixed' }}
    >
      <NavMenuItem>
        {t('quick_links')} <ExpandMoreIcon size={24} />
      </NavMenuItem>
    </Dropdown>
  );
};
