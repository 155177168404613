import { VFC } from 'react';

import { WallContentType, WallModel } from 'services/api';

import { IdeaContent } from './IdeaContent';
import { PostGiftContent } from './PostGiftContent';
import { PostReplyContent } from './PostReplyContent';
import { PostStandardContent } from './PostStandardContent';

export type PostContentProps = {
  data: WallModel;
  updatePollAnswer?: (answerId?: number) => void;
};

export const PostContent: VFC<PostContentProps> = ({
  data,
  updatePollAnswer,
}) => {
  return (
    <>
      {data.wallContentType === WallContentType.Common && (
        <>
          {data.postContent && (
            <PostStandardContent
              data={data.postContent}
              from={data.from}
              poll={data.poll}
              updatePollAnswer={updatePollAnswer}
            />
          )}
          {data.replyWall && <PostReplyContent data={data.replyWall} />}
        </>
      )}
      {data.wallContentType === WallContentType.GiftCard && (
        <>
          <PostGiftContent
            giftImage={data.giftCardImageUrl as string}
            text={data.postContent?.text as string}
          />
        </>
      )}
      {data.wallContentType === WallContentType.Idea && (
        <>{data.postContent && <IdeaContent data={data.postContent} />}</>
      )}
    </>
  );
};
