import { VFC } from 'react';

import { useLocation } from 'react-router-dom';

import { useTranslation } from 'services/i18n';
import { Widget } from 'ui';

import {
  Cake,
  Category,
  Groups,
  NavLink,
  NavMenu,
  NavMenuItem,
  PersonAddAlt,
  ThumbUpOffAlt,
} from './styles';

const iconSize = 22;

export const EmployeesNav: VFC = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/').pop();
  const activeKey = path === undefined ? 'all' : path;

  const { t } = useTranslation('common');

  return (
    <Widget title={t('tui_employees')}>
      <NavMenu defaultSelectedKeys={[`${activeKey}`]}>
        <NavMenuItem key="all">
          <NavLink to="/employees/all">
            <Groups size={iconSize} />
            {t('all_employees')}
          </NavLink>
        </NavMenuItem>
        <NavMenuItem key="structure">
          <NavLink to="/employees/structure">
            <Category size={iconSize} />
            {t('structure')}
          </NavLink>
        </NavMenuItem>
        <NavMenuItem key="birthdays">
          <NavLink to="/employees/birthdays">
            <Cake size={iconSize} />
            {t('birthdays')}
          </NavLink>
        </NavMenuItem>
        <NavMenuItem key="subscribers">
          <NavLink to="/employees/subscribers">
            <PersonAddAlt size={iconSize} />
            {t('my_subscribers')}
          </NavLink>
        </NavMenuItem>
        <NavMenuItem key="subscriptions">
          <NavLink to="/employees/subscriptions">
            <ThumbUpOffAlt size={iconSize} />
            {t('my_subscriptions')}
          </NavLink>
        </NavMenuItem>
      </NavMenu>
    </Widget>
  );
};
