import { useMutation } from 'react-query';

import { api } from 'services/api';

export const useLocalModel = (
  updatePollAnswer?: (answerId?: number) => void,
) => {
  const selectAnswer = useMutation(
    ({ id, pollId }: { id?: number; pollId?: number }) =>
      api.poll.apiV1PollsIdVotePost({
        id: pollId!,
        pollUserVoteRequestBody: { pollAnswerIds: [id!] },
      }),
    {
      onSuccess: (_, { id }) => {
        updatePollAnswer?.(id);
      },
    },
  );

  return { selectAnswer };
};

/*
(post) => {
    const pollAnswers = [...post.poll!.pollAnswers!];
    pollAnswers.find((ans) => ans.id === id)!.votesCount! += 1;
    return {
        ...post,
        poll: {
            ...post.poll,
            currentUserAnswerId: id,
            answersCount: post.poll!.answersCount! + 1,
            pollAnswers,
        },
    };
} */
