import BLT from './BLT.svg';
import GLB from './GLB.svg';
import KAZ from './KAZ.svg';
import RUS from './RUS.svg';
import TUR from './TUR.svg';
import UKR from './UKR.svg';

export const geoIcons = {
  BLT,
  GLB,
  KAZ,
  RUS,
  TUR,
  UKR,
};
