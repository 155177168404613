import styled from 'styled-components';

import { IconButton, Paper } from 'ui';

export const ModalPaper = styled(Paper)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 14px;
`;

export const CardPreviewWrapper = styled.div`
  position: relative;
`;

export const CardPreviewTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 32px;
`;

export const CardPreviewText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  background-color: rgba(46, 46, 50, 0.6);
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`;

export const Header = styled.div``;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;

  & :not(:last-child) {
    margin-right: 16px;
  }
`;
