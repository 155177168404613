import { useCallback, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { debounce } from 'lodash';

import { PostedFileModel } from 'services/api';

export interface MediaGalleryService {
  openMediaGallery: (file: PostedFileModel, gallery?: number[]) => void;
  closeMediaGallery: () => void;
  prevMedia: () => void;
  nextMedia: () => void;
  hasMediaAlbum: boolean;
}

export const useMediaGalleryService = (): MediaGalleryService => {
  const [mediaAlbum, setMediaAlbum] = useState<number[]>([]);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(-1);
  const [searchParams, setSearchParams] = useSearchParams();

  const hasMediaAlbum = useMemo(
    () => mediaAlbum.length > 1 && currentMediaIndex >= 0,
    [currentMediaIndex, mediaAlbum.length],
  );

  const debouncedSetSearchParams = debounce(setSearchParams, 300);

  const prevMedia = useCallback(() => {
    if (hasMediaAlbum) {
      const prevMediaIndex =
        currentMediaIndex > 0 ? currentMediaIndex - 1 : mediaAlbum.length - 1;
      setCurrentMediaIndex(prevMediaIndex);

      searchParams.set('media', `${mediaAlbum[prevMediaIndex]}`);
      debouncedSetSearchParams(searchParams);
    }
  }, [
    currentMediaIndex,
    debouncedSetSearchParams,
    hasMediaAlbum,
    mediaAlbum,
    searchParams,
  ]);

  const nextMedia = useCallback(() => {
    if (hasMediaAlbum) {
      const nextMediaIndex =
        currentMediaIndex < mediaAlbum.length - 1 ? currentMediaIndex + 1 : 0;
      setCurrentMediaIndex(nextMediaIndex);

      searchParams.set('media', `${mediaAlbum[nextMediaIndex]}`);
      debouncedSetSearchParams(searchParams);
    }
  }, [
    currentMediaIndex,
    debouncedSetSearchParams,
    hasMediaAlbum,
    mediaAlbum,
    searchParams,
  ]);

  const openMediaGallery = (file: PostedFileModel, album: number[] = []) => {
    if (album.length > 0) {
      setMediaAlbum(album);
      setCurrentMediaIndex(album.findIndex((id) => file.postedFileId === id));
    }
    if (!file.postedFileId) return;
    searchParams.append('media', `${file.postedFileId}`);
    setSearchParams(searchParams);
  };

  const closeMediaGallery = () => {
    setMediaAlbum([]);
    setCurrentMediaIndex(-1);
    searchParams.delete('media');
    setSearchParams(searchParams);
  };

  return {
    openMediaGallery,
    closeMediaGallery,
    nextMedia,
    prevMedia,
    hasMediaAlbum,
  };
};
