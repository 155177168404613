import styled from 'styled-components';

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px 24px;

  & :not(:last-child) {
    margin-right: 16px;
  }
`;

export const SpinContainer = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  place-content: center;
`;
