import { Spin } from 'antd';
import styled from 'styled-components';

import { VideoProcessedCoverRoot } from 'components/MediaGallery/Media/styles';
import { IconButton } from 'ui';

export const Album = styled.div`
  margin: 14px 0 0;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 80px;
  width: 110px;
  border-radius: 8px;
`;

export const Image = styled.img`
  height: 80px;
  width: 110px;
  border-radius: 8px;
  object-fit: cover;
`;

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 24px;
  width: 24px;
  border-radius: 20px;
  background-color: white;
  box-shadow: black;
`;

export const StyledVideoProcessed = styled(VideoProcessedCoverRoot)`
  height: 80px;
  width: 110px;
  border-radius: 8px;
  object-fit: cover;
`;

export const SpinContainer = styled.div`
  height: 80px;
  width: 110px;
  background-color: #bbbbbb;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: white;
  }
`;
