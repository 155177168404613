import React, {
  VFC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { nanoid } from 'nanoid';

import { useSearchURL } from 'hooks/useSearchURL';

import { fullyAlphabet } from './alphabet';
import {
  Button,
  Container,
  LangButton,
  LangButtonWrapper,
  LangContainer,
  ListContainer,
} from './style';
import {
  AlphabetFilterProps,
  CountryLanguage,
  ExistLettersCountry,
  Lang,
} from './types';

export const AlphabetFilter: VFC<AlphabetFilterProps> = (props) => {
  const { existLetters, searchUrlKey, countrySearchKey } = props;

  const [activeLang, setActiveLang] = useState<Lang>('rus');
  const [activeLetter, setActiveLetter] = useState<string | null>(null);
  const [activeCountry, setActiveCountry] = useState<number | null>(null);
  const [existList, setExistList] = useState<ExistLettersCountry>();

  const firstLoad = useRef(true);

  const { deleteUrlParams, setUrlParams, searchParams, getUrlParams } =
    useSearchURL();

  const getLangFromCountry = (countryId: number) => {
    return CountryLanguage[
      `country_${countryId}` as keyof typeof CountryLanguage
    ];
  };

  useEffect(
    function onInitialization() {
      if (activeCountry !== null || activeLetter !== null) return;

      const countryKey = getUrlParams(countrySearchKey);
      const letterKey = getUrlParams(searchUrlKey);

      if (countryKey && !letterKey) {
        const lang = getLangFromCountry(Number(countryKey));
        setActiveLang(lang);
      }

      if (letterKey) {
        setActiveLetter(String(letterKey));
        Object.keys(fullyAlphabet).forEach((key) => {
          const targetLange = fullyAlphabet[key as Lang].includes(
            String(letterKey),
          );
          if (targetLange) setActiveLang(key as Lang);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLetter, searchUrlKey, searchUrlKey, activeCountry, countrySearchKey],
  );

  useEffect(
    function onChangeCountry() {
      const countryId = getUrlParams(countrySearchKey);
      if (activeCountry === null) {
        setActiveCountry(Number(countryId));
        return;
      }

      if (Number(countryId) !== activeCountry) {
        deleteUrlParams(searchUrlKey);
        setActiveLetter(null);
        const lang = getLangFromCountry(Number(countryId));
        if (lang) setActiveLang(lang);
      }

      setActiveCountry(Number(countryId));
      setExistList(existLetters?.find((el) => el.id === Number(countryId)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, countrySearchKey, activeCountry, existLetters, searchUrlKey],
  );

  const handleLetterClick = useCallback(
    (event) => {
      const { value } = event.target;
      const sameValue = activeLetter === value;
      setActiveLetter(sameValue ? null : value);
      if (sameValue) {
        deleteUrlParams(searchUrlKey);
      } else {
        setUrlParams({ [searchUrlKey]: value });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLetter, searchUrlKey, setUrlParams],
  );

  const handleChangeLang = useCallback((event) => {
    setActiveLang(event.target.lang);
  }, []);

  const alphabetList = useMemo(() => {
    const alphabet = fullyAlphabet[activeLang];
    return alphabet.map((elem) => {
      const letter = elem.toUpperCase();
      return (
        <Button
          key={nanoid()}
          isActive={elem === activeLetter}
          isDisable={!existList?.data.includes(elem.toUpperCase())}
          value={elem}
          onClick={handleLetterClick}
        >
          {letter}
        </Button>
      );
    });
  }, [handleLetterClick, activeLetter, activeLang, existList?.data]);

  useEffect(() => {
    firstLoad.current = false;
  }, [firstLoad]);

  return (
    <Container>
      <ListContainer>
        {alphabetList}
        <LangContainer>
          <LangButtonWrapper>
            <LangButton
              isActive={activeLang === 'rus'}
              lang="rus"
              role="button"
              onClick={handleChangeLang}
            />
          </LangButtonWrapper>
          <LangButtonWrapper>
            <LangButton
              isActive={activeLang === 'eng'}
              lang="eng"
              role="button"
              onClick={handleChangeLang}
            />
          </LangButtonWrapper>
        </LangContainer>
      </ListContainer>
    </Container>
  );
};
