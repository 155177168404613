import { Like } from '@styled-icons/boxicons-regular';
import { Diamond } from '@styled-icons/ionicons-outline/Diamond';
import {
  AcUnit,
  AccountBalance,
  AccountBalanceWallet,
  AirportShuttle,
  Alarm,
  Analytics,
  Anchor,
  Article,
  Assignment,
  AssignmentTurnedIn,
  Audiotrack,
  Biotech,
  Brightness2,
  Cake,
  Calculate,
  CardGiftcard,
  ConnectWithoutContact,
  ContactPhone,
  Coronavirus,
  DesignServices,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DonutSmall,
  Eco,
  EmojiEvents,
  EmojiPeople,
  ErrorOutline,
  Explore,
  Face,
  FactCheck,
  FilterDrama,
  Flight,
  Forum,
  Groups,
  HeadsetMic,
  Home,
  Info,
  LibraryBooks,
  LocalBar,
  LocalCafe,
  LocalFlorist,
  LocalGroceryStore,
  LocalMall,
  Luggage,
  MapsUgc,
  MenuBook,
  Notifications,
  Payments,
  PeopleAlt,
  Person,
  Phone,
  PrecisionManufacturing,
  PushPin,
  RecentActors,
  RoomService,
  School,
  Science,
  Search,
  SportsSoccer,
  StickyNote2,
  TwoWheeler,
  VpnKey,
  WbSunny,
} from '@styled-icons/material-outlined';
import { StyledIcon } from '@styled-icons/styled-icon';

export const pickerIconsMap: Record<string, StyledIcon> = {
  eco: Eco,
  account_balance: AccountBalance,
  account_balance_wallet: AccountBalanceWallet,
  biotech: Biotech,
  calculate: Calculate,
  contact_phone: ContactPhone,
  design_services: DesignServices,
  directions_bike: DirectionsBike,
  directions_boat: DirectionsBoat,
  directions_bus: DirectionsBus,
  directions_car: DirectionsCar,
  local_bar: LocalBar,
  local_cafe: LocalCafe,
  sports_soccer: SportsSoccer,
  coronavirus: Coronavirus,
  luggage: Luggage,
  airport_shuttle: AirportShuttle,

  anchor: Anchor,
  emoji_events: EmojiEvents,
  alarm: Alarm,
  room_service: RoomService,
  face: Face,
  donut_small: DonutSmall,
  filter_drama: FilterDrama,
  two_wheeler: TwoWheeler,
  wb_sunny: WbSunny,
  brightness_2: Brightness2,
  ac_unit: AcUnit,
  local_florist: LocalFlorist,
  science: Science,
  headset_mic: HeadsetMic,
  vpn_key: VpnKey,
  audiotrack: Audiotrack,
  precision_manufacturing: PrecisionManufacturing,

  school: School,
  explore: Explore,
  local_mall: LocalMall,
  info: Info,
  library_books: LibraryBooks,
  emoji_people: EmojiPeople,
  assignment: Assignment,
  recent_actors: RecentActors,
  groups: Groups,
  push_pin: PushPin,
  sticky_note_2: StickyNote2,
  people_alt: PeopleAlt,
  local_grocery_store: LocalGroceryStore,
  menu_book: MenuBook,
  connect_without_contact: ConnectWithoutContact,
  search: Search,
  article: Article,
  home: Home,
  assignment_turned_in: AssignmentTurnedIn,
  phone: Phone,

  Gem: Diamond,
  edit: Diamond,
  like: Like,
  person: Person,
  cake: Cake,
  present: CardGiftcard,
  fact_check: FactCheck,
  error_outline: ErrorOutline,
  notifications: Notifications,
  maps_ugc: MapsUgc,
  forum: Forum,
  analytics: Analytics,
  payments: Payments,
  flight: Flight,
};
