import { UserProfile } from 'domain/user';

import { RoleName, api } from '../api';

const getCurrentUser = async () => {
  const { data } = await api.user.apiV1UsersCurrentGet();

  return data as UserProfile;
};

const getUserRoles = async (userId: number) => {
  const { data } = await api.role.apiV1RoleUserGet({
    id: `${userId}`,
  });

  return data.roles as RoleName[];
};

export const useUserCredentialApi = () => ({
  getCurrentUser,
  getUserRoles,
});
