import styled from 'styled-components';

import { StyledLink } from 'ui/Link';
import { ListItemMeta } from 'ui/ListItemMeta';

export const PushManagementElement = styled.div`
  display: -webkit-box;
  justify-content: end;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: #f6f8fa;
  margin: 12px 28px 0 0;

  &:first-child {
    margin-top: 0;
  }
`;

export const PushNotificationDate = styled.div`
  font-weight: 500;
  line-height: 20px;
  color: #7e8389;
  margin: 2px 0;
`;

export const PushNotificationDetails = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-right: 16px;
  max-width: 600px;
  line-height: 1.5;
  box-shadow: none;
  border-radius: 8px;
  padding: 6px 0 0 0;
`;

export const PushNotificationInfo = styled.div`
  flex: none;
  width: 544px;
  margin: 0 0 0 10px;
`;

export const PushNotificationPublisherInfo = styled.div`
  font-weight: 600;
  line-height: 20px;
  color: #2e2e32;
`;

export const PushNotificationViewerWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
`;

export const PushNotificationViewerList = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PushNotificationViewerAvatar = styled.div`
  cursor: pointer;
`;

export const PushViewerLink = styled(StyledLink)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const PushViewerItemMeta = styled(ListItemMeta)`
  .ant-list-item-meta-title {
    margin: 0;
  }
`;
