import AntList, { ListProps } from 'antd/lib/list';
import styled from 'styled-components';

export type { ListProps } from 'antd/lib/list';
export { ListItemIcon } from './ListItemIcon';

export const List = styled(<T extends {}>(props: ListProps<T>) => (
  <AntList split={false} {...props} />
))`
  position: relative;
  list-style: none;
  padding: 8px 0;
  margin: 0;

  .ant-list-header,
  .ant-list-footer {
    padding: 8px 24px;

    &:first-child {
      padding-top: 0;
    }
  }

  &.ant-list-sm {
    .ant-list-item {
      padding: 4px 24px;
    }
  }
  &.ant-list-lg {
    .ant-list-item {
      padding: 24px;
    }
  }
`;
