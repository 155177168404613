import AntMenuItem, { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { colord } from 'colord';
import styled from 'styled-components';

export type { MenuItemProps } from 'antd/lib/menu/MenuItem';

export const MenuItem = styled((props: MenuItemProps) => (
  <AntMenuItem {...props} />
))`
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  white-space: unset;

  padding: 8px 16px;

  &.ant-dropdown {
    &-menu-item-selected {
      color: ${({ theme }) => theme.palette.primary.main};
      background-color: transparent;
    }

    &-menu-item-active {
      background-color: ${({ theme }) =>
        colord(theme.palette.primary.light).alpha(0.1).toHslString()};
    }
  }

  &.ant-menu-item-selected {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: transparent;
    &:after {
      content: none;
    }
  }

  &.ant-menu-item-active {
    color: ${({ theme }) => theme.palette.text.primary} !important;
    background-color: ${({ theme }) =>
      colord(theme.palette.primary.light).alpha(0.1).toHslString()};
  }
`;
