/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestErrorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return test values [ 1, 2, 3, 4 ]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return auth current user name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestUserAuthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/user/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return current user name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return posted text
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestValuesPost: async (text?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestErrorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestErrorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return test values [ 1, 2, 3, 4 ]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return auth current user name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestUserAuthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestUserAuthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return current user name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return posted text
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TestValuesPost(text?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TestValuesPost(text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @summary Return error
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestErrorGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestErrorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return test values [ 1, 2, 3, 4 ]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return auth current user name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestUserAuthGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestUserAuthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return current user name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestUserGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return posted text
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TestValuesPost(text?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TestValuesPost(text, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1TestValuesPost operation in TestApi.
 * @export
 * @interface TestApiApiV1TestValuesPostRequest
 */
export interface TestApiApiV1TestValuesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TestApiApiV1TestValuesPost
     */
    readonly text?: string
}

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @summary Return error
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestErrorGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestErrorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return test values [ 1, 2, 3, 4 ]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return auth current user name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestUserAuthGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestUserAuthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return current user name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestUserGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return posted text
     * @param {TestApiApiV1TestValuesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiV1TestValuesPost(requestParameters: TestApiApiV1TestValuesPostRequest = {}, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiV1TestValuesPost(requestParameters.text, options).then((request) => request(this.axios, this.basePath));
    }
}
