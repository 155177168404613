import { VFC } from 'react';

import { Skeleton } from 'ui';

import { SkeletonContainer } from './styles';

export const UserCardItemSkeleton: VFC = () => (
  <SkeletonContainer>
    <Skeleton
      active
      avatar={{ size: 60, shape: 'circle' }}
      paragraph={{ rows: 2 }}
      title={false}
    />
  </SkeletonContainer>
);
