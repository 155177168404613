import styled from 'styled-components';

export const MediaGrid = styled.div`
  display: grid;
  place-items: stretch start;
  grid-gap: 5px;
  width: 100%;
  margin-bottom: 10px;

  div {
    &:first-child {
      grid-area: first;
    }
    &:nth-child(2) {
      grid-area: second;
    }
    &:nth-child(3) {
      grid-area: third;
    }
  }

  &.single {
    grid-template-areas: 'first';
  }
  &.double {
    grid-template-areas: 'first second';
    grid-template-columns: 50% 50%;
  }
  &.triple {
    grid-template-areas:
      'first first second'
      'first first third';
    grid-template-rows: repeat(2, 1fr);
  }
`;
