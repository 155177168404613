import { FC, createContext, useContext } from 'react';

export interface MediaAlbumProps {
  album: number[];
}

const MediaAlbumContext = createContext<number[] | undefined>(undefined);

export const useMediaAlbum = (): number[] | undefined =>
  useContext(MediaAlbumContext);

export const MediaAlbum: FC<MediaAlbumProps> = ({ album, children }) => (
  <MediaAlbumContext.Provider value={album}>
    {children}
  </MediaAlbumContext.Provider>
);
