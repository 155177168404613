/* tslint:disable */
/* eslint-disable */
/**
 * Rest SPA API
 * A common rest API for SPA frontend
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppointmentDto } from '../models';
// @ts-ignore
import { AppointmentDtoSearchResult } from '../models';
// @ts-ignore
import { ErrorResponseDto } from '../models';
// @ts-ignore
import { MeetingModel } from '../models';
// @ts-ignore
import { MeetingUpdateModel } from '../models';
// @ts-ignore
import { MeetingsModel } from '../models';
// @ts-ignore
import { Operation } from '../models';
// @ts-ignore
import { ValidationProblemsResponseDto } from '../models';
/**
 * MeetingApi - axios parameter creator
 * @export
 */
export const MeetingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user events
         * @param {string} [dateFrom] Фильтр по началу дат событий
         * @param {string} [dateTo] Фильтр по концу дат событий
         * @param {boolean} [isCanceled] Встреча отменена
         * @param {boolean} [isOrganizer] Получить только те встречи, где текущий пользователь является организатором
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsEventsGet: async (dateFrom?: string, dateTo?: string, isCanceled?: boolean, isOrganizer?: boolean, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/meetings/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (isCanceled !== undefined) {
                localVarQueryParameter['IsCanceled'] = isCanceled;
            }

            if (isOrganizer !== undefined) {
                localVarQueryParameter['IsOrganizer'] = isOrganizer;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsEventsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MeetingsEventsIdGet', 'id', id)
            const localVarPath = `/api/v1/meetings/events/{id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get models by filter
         * @param {string} [dateFrom] Фильтр по началу дат встреч
         * @param {string} [dateTo] Фильтр по концу дат встреч
         * @param {string} [tags] Фильтр по тэгам
         * @param {string} [query] Фильтр по тексту
         * @param {boolean} [isCancelled] Фильтр по отмененным встречам
         * @param {number} [countryId] Фильтр по стране
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsGet: async (dateFrom?: string, dateTo?: string, tags?: string, query?: string, isCancelled?: boolean, countryId?: number, count?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (tags !== undefined) {
                localVarQueryParameter['Tags'] = tags;
            }

            if (query !== undefined) {
                localVarQueryParameter['Query'] = query;
            }

            if (isCancelled !== undefined) {
                localVarQueryParameter['IsCancelled'] = isCancelled;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MeetingsIdDelete', 'id', id)
            const localVarPath = `/api/v1/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MeetingsIdGet', 'id', id)
            const localVarPath = `/api/v1/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsIdPatch: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1MeetingsIdPatch', 'id', id)
            const localVarPath = `/api/v1/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create model
         * @param {MeetingUpdateModel} [meetingUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsPost: async (meetingUpdateModel?: MeetingUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model
         * @param {MeetingUpdateModel} [meetingUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsPut: async (meetingUpdateModel?: MeetingUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingApi - functional programming interface
 * @export
 */
export const MeetingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeetingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user events
         * @param {string} [dateFrom] Фильтр по началу дат событий
         * @param {string} [dateTo] Фильтр по концу дат событий
         * @param {boolean} [isCanceled] Встреча отменена
         * @param {boolean} [isOrganizer] Получить только те встречи, где текущий пользователь является организатором
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeetingsEventsGet(dateFrom?: string, dateTo?: string, isCanceled?: boolean, isOrganizer?: boolean, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentDtoSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeetingsEventsGet(dateFrom, dateTo, isCanceled, isOrganizer, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeetingsEventsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeetingsEventsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get models by filter
         * @param {string} [dateFrom] Фильтр по началу дат встреч
         * @param {string} [dateTo] Фильтр по концу дат встреч
         * @param {string} [tags] Фильтр по тэгам
         * @param {string} [query] Фильтр по тексту
         * @param {boolean} [isCancelled] Фильтр по отмененным встречам
         * @param {number} [countryId] Фильтр по стране
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeetingsGet(dateFrom?: string, dateTo?: string, tags?: string, query?: string, isCancelled?: boolean, countryId?: number, count?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeetingsGet(dateFrom, dateTo, tags, query, isCancelled, countryId, count, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeetingsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeetingsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeetingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeetingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeetingsIdPatch(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeetingsIdPatch(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create model
         * @param {MeetingUpdateModel} [meetingUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeetingsPost(meetingUpdateModel?: MeetingUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeetingsPost(meetingUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model
         * @param {MeetingUpdateModel} [meetingUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeetingsPut(meetingUpdateModel?: MeetingUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeetingsPut(meetingUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeetingApi - factory interface
 * @export
 */
export const MeetingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeetingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user events
         * @param {string} [dateFrom] Фильтр по началу дат событий
         * @param {string} [dateTo] Фильтр по концу дат событий
         * @param {boolean} [isCanceled] Встреча отменена
         * @param {boolean} [isOrganizer] Получить только те встречи, где текущий пользователь является организатором
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsEventsGet(dateFrom?: string, dateTo?: string, isCanceled?: boolean, isOrganizer?: boolean, count?: number, offset?: number, options?: any): AxiosPromise<AppointmentDtoSearchResult> {
            return localVarFp.apiV1MeetingsEventsGet(dateFrom, dateTo, isCanceled, isOrganizer, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsEventsIdGet(id: number, options?: any): AxiosPromise<AppointmentDto> {
            return localVarFp.apiV1MeetingsEventsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get models by filter
         * @param {string} [dateFrom] Фильтр по началу дат встреч
         * @param {string} [dateTo] Фильтр по концу дат встреч
         * @param {string} [tags] Фильтр по тэгам
         * @param {string} [query] Фильтр по тексту
         * @param {boolean} [isCancelled] Фильтр по отмененным встречам
         * @param {number} [countryId] Фильтр по стране
         * @param {number} [count] Количеству объектов
         * @param {number} [offset] Смещение объектов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsGet(dateFrom?: string, dateTo?: string, tags?: string, query?: string, isCancelled?: boolean, countryId?: number, count?: number, offset?: number, options?: any): AxiosPromise<MeetingsModel> {
            return localVarFp.apiV1MeetingsGet(dateFrom, dateTo, tags, query, isCancelled, countryId, count, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MeetingsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get model by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsIdGet(id: number, options?: any): AxiosPromise<MeetingModel> {
            return localVarFp.apiV1MeetingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model partially by id, used model from Put method
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsIdPatch(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<MeetingModel> {
            return localVarFp.apiV1MeetingsIdPatch(id, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create model
         * @param {MeetingUpdateModel} [meetingUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsPost(meetingUpdateModel?: MeetingUpdateModel, options?: any): AxiosPromise<MeetingModel> {
            return localVarFp.apiV1MeetingsPost(meetingUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model
         * @param {MeetingUpdateModel} [meetingUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeetingsPut(meetingUpdateModel?: MeetingUpdateModel, options?: any): AxiosPromise<MeetingModel> {
            return localVarFp.apiV1MeetingsPut(meetingUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1MeetingsEventsGet operation in MeetingApi.
 * @export
 * @interface MeetingApiApiV1MeetingsEventsGetRequest
 */
export interface MeetingApiApiV1MeetingsEventsGetRequest {
    /**
     * Фильтр по началу дат событий
     * @type {string}
     * @memberof MeetingApiApiV1MeetingsEventsGet
     */
    readonly dateFrom?: string

    /**
     * Фильтр по концу дат событий
     * @type {string}
     * @memberof MeetingApiApiV1MeetingsEventsGet
     */
    readonly dateTo?: string

    /**
     * Встреча отменена
     * @type {boolean}
     * @memberof MeetingApiApiV1MeetingsEventsGet
     */
    readonly isCanceled?: boolean

    /**
     * Получить только те встречи, где текущий пользователь является организатором
     * @type {boolean}
     * @memberof MeetingApiApiV1MeetingsEventsGet
     */
    readonly isOrganizer?: boolean

    /**
     * Количеству объектов
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsEventsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsEventsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1MeetingsEventsIdGet operation in MeetingApi.
 * @export
 * @interface MeetingApiApiV1MeetingsEventsIdGetRequest
 */
export interface MeetingApiApiV1MeetingsEventsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsEventsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1MeetingsGet operation in MeetingApi.
 * @export
 * @interface MeetingApiApiV1MeetingsGetRequest
 */
export interface MeetingApiApiV1MeetingsGetRequest {
    /**
     * Фильтр по началу дат встреч
     * @type {string}
     * @memberof MeetingApiApiV1MeetingsGet
     */
    readonly dateFrom?: string

    /**
     * Фильтр по концу дат встреч
     * @type {string}
     * @memberof MeetingApiApiV1MeetingsGet
     */
    readonly dateTo?: string

    /**
     * Фильтр по тэгам
     * @type {string}
     * @memberof MeetingApiApiV1MeetingsGet
     */
    readonly tags?: string

    /**
     * Фильтр по тексту
     * @type {string}
     * @memberof MeetingApiApiV1MeetingsGet
     */
    readonly query?: string

    /**
     * Фильтр по отмененным встречам
     * @type {boolean}
     * @memberof MeetingApiApiV1MeetingsGet
     */
    readonly isCancelled?: boolean

    /**
     * Фильтр по стране
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsGet
     */
    readonly countryId?: number

    /**
     * Количеству объектов
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsGet
     */
    readonly count?: number

    /**
     * Смещение объектов
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsGet
     */
    readonly offset?: number
}

/**
 * Request parameters for apiV1MeetingsIdDelete operation in MeetingApi.
 * @export
 * @interface MeetingApiApiV1MeetingsIdDeleteRequest
 */
export interface MeetingApiApiV1MeetingsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for apiV1MeetingsIdGet operation in MeetingApi.
 * @export
 * @interface MeetingApiApiV1MeetingsIdGetRequest
 */
export interface MeetingApiApiV1MeetingsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsIdGet
     */
    readonly id: number
}

/**
 * Request parameters for apiV1MeetingsIdPatch operation in MeetingApi.
 * @export
 * @interface MeetingApiApiV1MeetingsIdPatchRequest
 */
export interface MeetingApiApiV1MeetingsIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof MeetingApiApiV1MeetingsIdPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Operation>}
     * @memberof MeetingApiApiV1MeetingsIdPatch
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for apiV1MeetingsPost operation in MeetingApi.
 * @export
 * @interface MeetingApiApiV1MeetingsPostRequest
 */
export interface MeetingApiApiV1MeetingsPostRequest {
    /**
     * 
     * @type {MeetingUpdateModel}
     * @memberof MeetingApiApiV1MeetingsPost
     */
    readonly meetingUpdateModel?: MeetingUpdateModel
}

/**
 * Request parameters for apiV1MeetingsPut operation in MeetingApi.
 * @export
 * @interface MeetingApiApiV1MeetingsPutRequest
 */
export interface MeetingApiApiV1MeetingsPutRequest {
    /**
     * 
     * @type {MeetingUpdateModel}
     * @memberof MeetingApiApiV1MeetingsPut
     */
    readonly meetingUpdateModel?: MeetingUpdateModel
}

/**
 * MeetingApi - object-oriented interface
 * @export
 * @class MeetingApi
 * @extends {BaseAPI}
 */
export class MeetingApi extends BaseAPI {
    /**
     * 
     * @summary Get user events
     * @param {MeetingApiApiV1MeetingsEventsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public apiV1MeetingsEventsGet(requestParameters: MeetingApiApiV1MeetingsEventsGetRequest = {}, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).apiV1MeetingsEventsGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.isCanceled, requestParameters.isOrganizer, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MeetingApiApiV1MeetingsEventsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public apiV1MeetingsEventsIdGet(requestParameters: MeetingApiApiV1MeetingsEventsIdGetRequest, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).apiV1MeetingsEventsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get models by filter
     * @param {MeetingApiApiV1MeetingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public apiV1MeetingsGet(requestParameters: MeetingApiApiV1MeetingsGetRequest = {}, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).apiV1MeetingsGet(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.tags, requestParameters.query, requestParameters.isCancelled, requestParameters.countryId, requestParameters.count, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete model by id
     * @param {MeetingApiApiV1MeetingsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public apiV1MeetingsIdDelete(requestParameters: MeetingApiApiV1MeetingsIdDeleteRequest, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).apiV1MeetingsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get model by id
     * @param {MeetingApiApiV1MeetingsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public apiV1MeetingsIdGet(requestParameters: MeetingApiApiV1MeetingsIdGetRequest, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).apiV1MeetingsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model partially by id, used model from Put method
     * @param {MeetingApiApiV1MeetingsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public apiV1MeetingsIdPatch(requestParameters: MeetingApiApiV1MeetingsIdPatchRequest, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).apiV1MeetingsIdPatch(requestParameters.id, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create model
     * @param {MeetingApiApiV1MeetingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public apiV1MeetingsPost(requestParameters: MeetingApiApiV1MeetingsPostRequest = {}, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).apiV1MeetingsPost(requestParameters.meetingUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model
     * @param {MeetingApiApiV1MeetingsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingApi
     */
    public apiV1MeetingsPut(requestParameters: MeetingApiApiV1MeetingsPutRequest = {}, options?: AxiosRequestConfig) {
        return MeetingApiFp(this.configuration).apiV1MeetingsPut(requestParameters.meetingUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}
